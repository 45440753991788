<template>
  <v-form autocomplete="off" data-cy="damaged-property-form" v-model="$store.state.isDamagedPropertyComponentValid">
    <v-row >
      <v-col>
        <v-card class="card-background pl-8" elevation="0" >
          <v-row >
            <v-card-title class="section-label berninaSansCompressedFont" data-cy="damaged-property-information-label">Damaged Property</v-card-title>
          </v-row>

      <v-row>
        <v-col md="8" lg="6" >
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="has-damaged-property-label" class="label-text">WAS THERE ANY DAMAGE TO PROPERTY?</p>
            </v-col>
          </v-row>

          <v-row >
            <v-col cols="8" md="12" class="input-spacing">
              <v-radio-group data-cy="has-damaged-property-radio-group"
                             :row="!$vuetify.breakpoint.smAndDown"
                             class="radio-buttons-mobile-background"
                             v-model="submission.lossInformation.liabilityLossInformation.hasPropertyDamaged">
                <v-radio
                    class="radio-buttons-loss-info-mobile-yes"
                    label="Yes"
                    :value=true
                    data-cy="has-damaged-property-radio-yes"
                    @change="yesDamagedPropertyInfo"
                ></v-radio>
                <v-radio
                    class="radio-buttons-loss-info-mobile-no"
                    label="No"
                    :value=false
                    @change="noDamagedPropertyInfo"
                    data-cy="has-damaged-property-radio-no"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <div v-if="submission.lossInformation.liabilityLossInformation.hasPropertyDamaged" >
        <v-row >
          <v-col md="8" lg="6" class="input-spacing">
            <p data-cy="describe-damaged-property-label" class="label-text">PLEASE DESCRIBE THE DAMAGED PROPERTY / EQUIPMENT</p>
          </v-col>
        </v-row>

        <v-row >
          <v-col cols="10" sm="8" md="8" lg="6" data-cy="damaged-property-description-column" class="input-spacing">
            <v-textarea
                v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription"
                data-cy="damaged-property-description-input"
                id="damaged-property-description"
                outlined
                auto-grow
                clearable
                :maxLength="950"
                counter="950"
                dense
                background-color="white"
                :rules="damagedPropertyDescRules"
                @input="validateDamagedPropertyInformation"
            />
          </v-col>
        </v-row>

        <v-row class="pb-4">
          <v-col md="8" lg="6" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="has-property-owner-label" class="label-text">DO YOU HAVE THE PROPERTY OWNER'S INFORMATION?</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" md="12" class="input-spacing">
              <v-radio-group data-cy="has-property-owner-radio-group"
                             :row="!$vuetify.breakpoint.smAndDown"
                             class="radio-buttons-mobile-background"
                             v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.hasPropertyOwner">
                <v-radio
                    class="radio-buttons-loss-info-mobile-yes"
                    label="Yes"
                    :value=true
                    data-cy="has-property-owner-radio-yes"
                    @change="addPropertyOwnerInfo"
                ></v-radio>
                <v-radio
                    class="radio-buttons-loss-info-mobile-no"
                    label="No"
                    :value=false
                    data-cy="has-property-owner-radio-no"
                    @change="removePropertyOwnerInfo"
                ></v-radio>
              </v-radio-group>
              </v-col>
            </v-row>

          </v-col>
        </v-row>


        <v-row v-if="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.hasPropertyOwner">
          <v-col data-cy="property-owner-container">
            <v-row >
              <v-col class="input-spacing">
                <v-row>
                    <v-col cols="8" md="8" lg="6" class="input-spacing" data-cy="property-owner-same-person-column">
                      <v-switch
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact"
                          inset
                          data-cy="property-owner-same-person-switch"
                          class="v-switch-label"
                          label="Is the property owner the same as the primary contact?"
                          dense
                          @change="propertyOwnerSameAsPrimaryContactOnSwitch"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

              <v-row >
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-name-label" class="label-text">OWNER NAME<span class="req-asterisk">*</span></p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>
                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-name-column">
                      <div data-cy="property-owner-name-text" v-if="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact" class="rs-values pb-3">{{submission.customerInformation.primaryContact.firstName + " " + submission.customerInformation.primaryContact.lastName}}</div>
                        <v-text-field
                          v-else
                          data-cy="property-owner-name-input"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          counter="100"
                          maxLength="100"
                          :rules="propertyOwnerNameValidationRules"
                          @input="propertyOwnerNameOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>


              <v-row >
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-email-label" class="label-text">EMAIL</p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>

                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-email-column">
                      <div data-cy="property-owner-email-text" v-if="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact" class="rs-values pb-3">{{submission.customerInformation.primaryContact.primaryEmail}}</div>
                      <v-text-field
                          v-else
                          data-cy="property-owner-email-input"
                          id="property-owner-email"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryEmail"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          counter="50"
                          maxLength="50"
                          :rules="propertyOwnerEmailValidationRules"
                          @input="propertyOwnerEmailOnInput"
                          @blur="propertyOwnerEmailOnInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-phone-label" class="label-text">PHONE</p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>

                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-phone-column">
                      <div data-cy="property-owner-phone-text" v-if="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact" class="rs-values pb-5">{{submission.customerInformation.primaryContact.primaryPhone}}</div>
                      <v-text-field
                          v-else
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryPhone"
                          data-cy="property-owner-phone-input"
                          id="property-owner-phone"
                          dense outlined clearable
                          autocomplete="off"
                          background-color="white"
                          :rules="propertyOwnerPhoneValidationRules"
                          v-mask="'(###) ###-####'"
                          @input="propertyOwnerPhoneOnInput"
                          @blur="propertyOwnerPhoneOnInput">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            <div v-if="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact" data-cy="damaged-property-address-read-only">
              <v-row>
                <v-col cols="12">
                  <div class="label-text" data-cy="damaged-property-address-label">OWNER ADDRESS</div>
                  <div class="rs-values" data-cy="damaged-property-address-line-1-text">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1 }}</div>
                  <div class="rs-values" data-cy="damaged-property-address-line-2-text">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2 }}</div>
                  <div class="rs-values" data-cy="damaged-property-city-state-zip-text">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city }}, {{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.state }} {{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.postalCode }}</div>
                  <div class="rs-values" data-cy="damaged-property-country-text">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.country }}</div>
                </v-col>
              </v-row>
            </div>

            <div v-else>

              <v-row >
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-address-line-1-label" class="label-text">ADDRESS LINE 1</p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>

                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing"
                           data-cy="property-owner-address-line-1-column">
                      <v-text-field
                          data-cy="property-owner-address-line-1-input"
                          id="property-owner-address1"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          counter="60"
                          maxLength="60"
                          :rules="propertyOwnerAddressLine1ValidationRules"
                          @input="propertyOwnerAddressLine1OnInput"
                          @blur="propertyOwnerAddressLine1OnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-address-line-2-label" class="label-text">ADDRESS LINE 2</p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>

                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing"
                           data-cy="property-owner-address-line-2-column">
                      <v-text-field
                          data-cy="property-owner-address-line-2-input"
                          id="property-owner-address2"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          counter="60"
                          maxLength="60"
                          :rules="propertyOwnerAddressLine2ValidationRules"
                          @input="propertyOwnerAddressLine2OnInput"
                          @blur="propertyOwnerAddressLine2OnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-city-label" class="label-text">CITY</p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>

                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-city-column">
                      <v-text-field
                          data-cy="property-owner-city-input"
                          id="property-owner-city"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          counter="60"
                          maxLength="60"
                          :rules="propertyOwnerCityValidationRules"
                          @input="propertyOwnerCityOnInput"
                          @blur="propertyOwnerCityOnInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-state-label" class="label-text">STATE</p>
                    </v-col>
                    <v-col class="hidden-sm-and-down"/>
                  </v-row>
                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-state-column">
                      <v-autocomplete
                          data-cy="property-owner-state-select"
                          id="property-owner-state-select"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.state"
                          autocomplete="new-password"
                          :items="states"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @input="propertyOwnerStateOnSelect"
                          @blur="propertyOwnerStateOnSelect; uiUtils.triggerEnterKeyPress('property-owner-state-select')"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row >
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-zip-code-label" class="label-text">ZIP CODE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-zip-code-column">
                      <v-text-field
                          data-cy="property-owner-zip-code-input"
                          id="property-owner-zip"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.postalCode"
                          dense
                          outlined
                          autocomplete="off"
                          v-mask="['#####' ,'#####-####']"
                          clearable
                          background-color="white"
                          :rules="propertyOwnerZipValidationRules"
                          @input="propertyOwnerZipOnInput"
                          @blur="propertyOwnerZipOnInput"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-owner-country-label" class="label-text">COUNTRY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8" md="12" class="input-spacing" data-cy="property-owner-country-column">
                      <v-autocomplete
                          data-cy="property-owner-country-select"
                          v-model="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.country"
                          :items="countries"
                          autocomplete="new-password"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @input="propertyOwnerCountryOnInput"
                          @blur="propertyOwnerCountryOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>

          </v-col>
        </v-row>

      </div>
    </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import {States} from "../../models/typelists/States";
import {Countries} from "../../models/typelists/Countries";
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "../../models/FnolSubmission";
import {UiUtils} from "../../utils/ui-utils";
import {DamagedPropertyInformation} from '@/models/DamagedPropertyInformation';
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {PropertyOwner} from '@/models/PropertyOwner';
import {ValidationUtils} from "@/utils/validation-utils";

@Component
export default class DamagedPropertyComponent extends Vue {

  submission: FnolSubmission;
  states: Array<String> = [];
  countries: Array<String> = [];

  damagedPropertyDescRules: Array<string> = [];
  propertyOwnerNameValidationRules: Array<string> = [];
  propertyOwnerEmailValidationRules: Array<string> = [];
  propertyOwnerPhoneValidationRules: Array<string> = [];
  propertyOwnerAddressLine1ValidationRules: Array<string> = [];
  propertyOwnerAddressLine2ValidationRules: Array<string> = [];
  propertyOwnerCityValidationRules: Array<string> = [];
  propertyOwnerZipValidationRules: Array<string> = [];
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  validationUtils: ValidationUtils = new ValidationUtils();
  uiUtils: UiUtils = new UiUtils();

  created() {
    this.submission = this.$store.state.submission;
    this.states = Object.values(States)
    this.countries = Object.values(Countries)
  }

  yesDamagedPropertyInfo() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation = new DamagedPropertyInformation();
  }

  noDamagedPropertyInfo() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation = null
  }

  addPropertyOwnerInfo() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner = new PropertyOwner();
  }

  removePropertyOwnerInfo() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact = false
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner = null
  }

  validateDamagedPropertyInformation() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription)
    this.damagedPropertyDescRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }

  propertyOwnerNameOnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName)
    this.propertyOwnerNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false);
  }

  propertyOwnerEmailOnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryEmail)
      this.propertyOwnerEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  propertyOwnerPhoneOnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryPhone = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryPhone)
      this.propertyOwnerPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false);
  }

  propertyOwnerAddressLine1OnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1)
    this.propertyOwnerAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  propertyOwnerAddressLine2OnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2)
    this.propertyOwnerAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  propertyOwnerCityOnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city)
    this.propertyOwnerCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  propertyOwnerZipOnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.postalCode)
    this.propertyOwnerZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules();
  }


  propertyOwnerStateOnSelect() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.state)
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.state);
  }

  propertyOwnerCountryOnInput() {
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.country)
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.country);
  }

  propertyOwnerSameAsPrimaryContactOnSwitch() {
    this.uiUtils.contactSameAsToggle(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner,
        this.submission.customerInformation.primaryContact,
        this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.isSameAsPrimaryContact)
    if (!this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.firstName) {
      this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName = null;
      return
    }
    this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName =
        `${this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.firstName} ${this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.lastName}`
  }

}


</script>
<style scoped lang="scss">

</style>
