<template>
  <v-container class="delete-file-modal-container" data-cy="delete-file-modal-container">

    <v-dialog v-model="dialog" persistent width="750px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="remove-file-icon" :data-cy="'remove-file-icon-' + index" color="#8E0122" v-bind="attrs" v-on="on">mdi-delete</v-icon>
      </template>

      <!-- Start Over modal content -->
      <v-card data-cy="remove-file-modal-content">
        <v-card-title class="py-3 modal-header-container">
          <span class="modal-header" data-cy="remove-file-header">Remove File?</span>
            <v-col style="text-align: right; cursor: pointer" class="py-0">
              <span @click="onModalCancel" data-cy="exit-remove-file-modal-x">x</span>
            </v-col>
        </v-card-title>
        <v-col cols="12" class="pa-0">
          <v-divider color="#E6E9ED" style="opacity: 50%"/>
        </v-col>
        <v-card-text class="pt-3">
          <p class="modal-text" data-cy="remove-file-message">Are you sure you want to remove {{ fileStatus.fileName }}?</p>
        </v-card-text>
        <!-- Modal buttons -->
        <v-row class="pa-0 mr-2">
          <v-col :style="$vuetify.breakpoint.mdAndUp ? 'display: flex; justify-content: flex-end' : 'display: flex; flex-direction: column-reverse; justify-content: center; align-items: center;'" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-12 pt-0'">
            <v-btn
                class="previous-button delete-file-modal-button"
                @click="onModalCancel"
                depressed outlined text
                data-cy="modal-cancel-button">No</v-btn>
            <v-btn
                class="next-button delete-file-modal-button"
                @click="onModalConfirm"
                depressed text
                data-cy="modal-confirm-button">Yes</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script lang="ts">
import Component from 'vue-class-component'
import {Prop, Vue} from 'vue-property-decorator'
import UploadedFileStatus from '@/documentUpload/UploadedFileStatus'
import {FnolSubmission} from "@/models/FnolSubmission";
import {DocumentUploadClient} from "@/documentUpload/document-upload-client";

@Component
export default class DeleteFileModal extends Vue {

  @Prop()
  fileStatus: UploadedFileStatus
  @Prop()
  index: number;
  @Prop()
  fileInput: Vue & { click: () => void, files: FileList, value: any }
  @Prop()
  submission: FnolSubmission;

  docUploadClient = new DocumentUploadClient();
  dialog = false

  onModalConfirm(): void {
    this.dialog = false
    this.docUploadClient.removeFile(this.fileStatus.filePath)
    this.submission.additionalInformation.uploadedFileStatuses = this.submission.additionalInformation.uploadedFileStatuses.filter(fileStatus => fileStatus.filePath !== this.fileStatus.filePath)

    //This resets the fileInput's files so that it can recognize you are re-uploading the same file. This is required from QA
    this.fileInput.files = new DataTransfer().files
    this.$store.dispatch('saveSubmission', this.submission)
  }

  onModalCancel(): void {
    this.dialog = false
  }

}

</script>

<style lang="scss" scoped>

.delete-file-modal-button {
  margin: 8px 8px;
}

.v-dialog > .v-card > .v-card__title {
  letter-spacing: normal;
  line-height: normal;
  padding: 23px 22px 5px 22px
}

.v-dialog > .v-card > .v-card__text {
  letter-spacing: normal;
  line-height: normal;
  padding: 0 22px
}

.remove-file-icon {
  float: inside;
  cursor: pointer;
}

.delete-file-modal-container {
  display: inline;
}

</style>

