<template>
  <div data-cy="contact-information-review-vue">
  <!-- Contact Information -->
<!--  <div class="divider" data-cy="contact-information-section"><span class="px-4">Contact Information</span></div>-->
  <v-row data-cy="contact-information-section" class="mx-0">
    <v-btn :block="$vuetify.breakpoint.smAndDown" tile class="elevation-0" outlined>
      <span class="edit-section-label" data-cy="edit-contact-information-cta" @click="editContactInformationOnClick">
               <v-icon>mdi-pencil</v-icon> Go To Contact Information
      </span>
    </v-btn>
  </v-row>

  <!-- Contact Information Insured Information -->
  <v-row>
    <v-col cols="auto" class="pb-0 mb-n2" data-cy="insured-information-label">
      <div class="sub-section-label">Insured</div>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col  class="pb-0" >
      <v-row data-cy="rs-insured-name-label">
        INSURED NAME
      </v-row>
      <v-row class="rs-values " data-cy="rs-insured-name-value">
        {{submission.customerInformation.insured.fullName}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" class=" pb-0" data-cy="rs-insured-address-line-1-label">
      <v-row data-cy="rs-insured-address-line-1-label">
        ADDRESS LINE 1
      </v-row>
      <v-row class="rs-values" data-cy="rs-insured-address-line-1-value">
        {{submission.customerInformation.insured.location.addressLine1}}
      </v-row>
    </v-col>
    <v-col cols="8" class=" pb-0" >
      <v-row data-cy="rs-insured-address-line-2-label">
        ADDRESS LINE 2
      </v-row>
      <v-row class="rs-values" data-cy="rs-insured-address-line-2-value">
        {{submission.customerInformation.insured.location.addressLine2}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" class=" pb-0" >
      <v-row data-cy="rs-insured-city-label">
        CITY
      </v-row>
      <v-row class="rs-values" data-cy="rs-insured-city-value">
        {{submission.customerInformation.insured.location.city}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0">
      <v-row data-cy="rs-insured-state-label">
        STATE
      </v-row>
      <v-row class="rs-values" data-cy="rs-insured-state-value">
        {{submission.customerInformation.insured.location.state}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row  data-cy="rs-insured-zip-label">
        ZIP
      </v-row>
      <v-row class="rs-values" data-cy="rs-insured-zip-value">
        {{submission.customerInformation.insured.location.postalCode}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0">
      <v-row data-cy="rs-insured-country-label">
        COUNTRY
      </v-row>
      <v-row class="rs-values" data-cy="rs-insured-country-value">
        {{submission.customerInformation.insured.location.country}}
      </v-row>
    </v-col>
  </v-row>

  <!-- Contact Information Reporter Information -->
  <v-row>
    <v-col cols="auto" class="pb-0 mb-n2" data-cy="reporter-information-label">
      <div class="sub-section-label">Claim Reporter</div>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" class="pb-0" >
      <v-row data-cy="rs-reporter-first-name-label">
        FIRST NAME
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-first-name-value">
        {{submission.customerInformation.claimReporter.firstName}}
      </v-row>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0">
      <v-row data-cy="rs-reporter-last-name-label">
        LAST NAME
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-last-name-value">
        {{submission.customerInformation.claimReporter.lastName}}
      </v-row>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" data-cy="rs-reporter-suffix-label">
      <v-row data-cy="rs-reporter-suffix-label">
        SUFFIX
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-suffix-value">
        {{submission.customerInformation.claimReporter.suffix}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-reporter-email-label" >
        EMAIL
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-email-value">
        {{submission.customerInformation.claimReporter.primaryEmail}}
      </v-row>
    </v-col>
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
      <v-row data-cy="rs-reporter-phone-label">
        PHONE
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-phone-value">
        {{submission.customerInformation.claimReporter.primaryPhone}}
      </v-row>
    </v-col>
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
      <v-row data-cy="rs-reporter-phone-ext-label">
        EXTENSION
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-phone-ext-value">
        {{submission.customerInformation.claimReporter.primaryPhoneExt}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-reporter-address-line-1-label">
        ADDRESS LINE 1
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-address-line-1-value">
        {{submission.customerInformation.claimReporter.location.addressLine1}}
      </v-row>

    </v-col>
    <v-col cols="8" class="pb-0">
      <v-row data-cy="rs-reporter-address-line-2-label">
        ADDRESS LINE 2
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-address-line-2-value">
        {{submission.customerInformation.claimReporter.location.addressLine2}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-reporter-city-label">
        CITY
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-city-value">
        {{submission.customerInformation.claimReporter.location.city}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0">
      <v-row data-cy="rs-reporter-state-label">
        STATE
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-state-value">
        {{submission.customerInformation.claimReporter.location.state}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-reporter-zip-label">
        ZIP
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-zip-value">
        {{submission.customerInformation.claimReporter.location.postalCode}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0" >
      <v-row data-cy="rs-reporter-country-label">
        COUNTRY
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-country-value">
        {{submission.customerInformation.claimReporter.location.country}}
      </v-row>
    </v-col>
  </v-row>


  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-reporter-relation-to-insured-label">
        RELATIONSHIP TO INSURED
      </v-row>
      <v-row class="rs-values" data-cy="rs-reporter-relation-to-insured-value" >
        {{submission.customerInformation.claimReporter.relationToInsured}}
      </v-row>
    </v-col>
  </v-row>

  <!-- Contact Information Primary Contact Information -->
  <v-row>
    <v-col cols="auto" class="pb-0 mb-n2" data-cy="primary-contact-information-label">
      <div class="sub-section-label">Primary Contact</div>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : '4'">
      <v-row data-cy="rs-primary-contact-first-name-label">
        FIRST NAME
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-first-name-value" >
        {{submission.customerInformation.primaryContact.firstName}}
      </v-row>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : '4'" class="pb-0" >
      <v-row data-cy="rs-primary-contact-last-name-label">
        LAST NAME
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-last-name-value" >
        {{submission.customerInformation.primaryContact.lastName}}
      </v-row>
    </v-col>

    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : '4'" class="pb-0" >
      <v-row data-cy="rs-primary-contact-suffix-label">
        SUFFIX
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-suffix-value" >
        {{submission.customerInformation.primaryContact.suffix}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">

    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-primary-contact-email-label">
        EMAIL
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-email-value">
        {{submission.customerInformation.primaryContact.primaryEmail}}
      </v-row>
    </v-col>

    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" >
      <v-row data-cy="rs-primary-contact-phone-label">
        PHONE
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-phone-value">
        {{submission.customerInformation.primaryContact.primaryPhone}}
      </v-row>
    </v-col>

    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
      <v-row data-cy="rs-primary-contact-phone-ext-label">
        EXTENSION
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-phone-ext-value">
        {{submission.customerInformation.primaryContact.primaryPhoneExt}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-primary-contact-address-line-1-label" >
        ADDRESS LINE 1
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-address-line-1-value">
        {{submission.customerInformation.primaryContact.location.addressLine1}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0" >
      <v-row data-cy="rs-primary-contact-address-line-2-label">
        ADDRESS LINE 2
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-address-line-2-value">
        {{submission.customerInformation.primaryContact.location.addressLine2}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-primary-contact-city-label">
        CITY
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-city-value">
        {{submission.customerInformation.primaryContact.location.city}}
      </v-row>
    </v-col>
    <v-col cols="8">
      <v-row data-cy="rs-primary-contact-state-label">
        STATE
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-state-value">
        {{submission.customerInformation.primaryContact.location.state}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-primary-contact-zip-label">
        ZIP
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-zip-value">
        {{submission.customerInformation.primaryContact.location.postalCode}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0" >
      <v-row data-cy="rs-primary-contact-country-label">
        COUNTRY
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-country-value">
        {{submission.customerInformation.primaryContact.location.country}}
      </v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-primary-contact-relation-to-insured-label">
        RELATIONSHIP TO INSURED
      </v-row>
      <v-row class="rs-values" data-cy="rs-primary-contact-relation-to-insured-value" >
        {{submission.customerInformation.primaryContact.relationToInsured}}
      </v-row>
    </v-col>
  </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class ContactInformationReview extends Vue {

  submission: FnolSubmission;
  uiUtils: UiUtils = new UiUtils();

  created() {
    console.log('Contact Information Review component created')
    this.submission = this.$store.state.submission;
  }

  editContactInformationOnClick() {
    this.uiUtils.navigate('contact-information');
  }

}
</script>

<style scoped lang="scss">

</style>

