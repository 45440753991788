<template>
  <v-form
      autocomplete="off"
      data-cy="medical-provider-form"
      v-model="$store.state.isMedicalComponentValid">
    <v-col :data-cy="'medical-provider-vue-'+ index" v-for="(provider, index) in submission.additionalContacts.medicalProviders" :key="index" style="padding: 0 0 12px 0">
              <v-card class="card-background" elevation="0" >
                <v-row>
                  <v-col md="4" >
                    <div>
                      <span :data-cy="'medical-provider-label-'+index" class="section-label berninaSansCompressedFont">Medical Provider {{ index + 1 }}</span>
                  </div>
                </v-col >
                  <v-col md="4" v-if="$vuetify.breakpoint.mdAndDown"  class="text-right">
                    <v-btn @click="removeMedicalProvider(index)" :data-cy="'remove-medical-provider-button-' + index"
                           outlined class="mobile-remove-button"><span
                        style="color: #00274e"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove</span>
                    </v-btn>
                  </v-col>
              </v-row>
              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-fullname-label-' + index" class="label-text">NAME<span class="req-asterisk">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-fullname-column-' + index">
                      <v-text-field :data-cy="'medical-provider-fullname-input-' + index"
                                    :id="'medical-provider-fullname-input-' + index"
                                    v-model="provider.fullName"
                                    dense
                                    outlined
                                    clearable
                                    counter="100"
                                    maxLength="100"
                                    :rules="medicalProviderFullNameValidationRules"
                                    @input="medicalProviderFullNameOnInput(provider)"
                                    @blur="medicalProviderFullNameOnInput(provider)"
                                    background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.lgAndUp" class="pl-8 pt-7 input-spacing" md="4" lg="3">
                  <v-btn @click="removeMedicalProvider(index)" :data-cy="'remove-medical-provider-button-' + index"
                         outlined class="outlined-button"><span
                      style="color: #00274e"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove Medical Provider</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-email-label-' + index"
                         class="label-text">EMAIL</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-email-column-' + index">
                      <v-text-field
                          :data-cy="'medical-provider-email-input-' + index"
                          v-model="provider.primaryEmail"
                          dense
                          outlined
                          clearable
                          counter="50"
                          maxLength="50"
                          background-color="white"
                          :rules="medicalProviderEmailValidationRules"
                          @input="runMedicalProviderEmailOnInput(provider)"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-phone-label-' + index"
                         class="label-text">PHONE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-phone-column-' + index">
                      <v-text-field
                          :data-cy="'medical-provider-phone-input-' + index"
                          v-model="provider.primaryPhone"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          v-mask="'(###) ###-####'"
                          :rules="medicalProviderPhoneValidationRules"
                          @input="runMedicalProviderPhoneOnInput(provider)"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-phone-ext-label-' + index"
                         class="label-text">EXT</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-phone-ext-column-' + index">
                      <v-text-field
                          :data-cy="'medical-provider-phone-ext-input-' + index"
                          v-model="provider.primaryPhoneExt"
                          dense
                          outlined
                          clearable
                          maxLength="4"
                          v-mask="'####'"
                          @input="runMedicalProviderPhoneExtOnInput(provider)"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-address-1-label-' + index" class="label-text">ADDRESS 1</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-address-1-column-' + index">
                      <v-text-field :data-cy="'medical-provider-address-1-input-' + index"
                                    v-model="provider.location.addressLine1"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="medicalProviderAddressLine1Rules"
                                    @input="medicalProviderAddressLine1OnInput(provider)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-address-2-label-' + index" class="label-text">ADDRESS 2</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-address-2-column-' + index">
                      <v-text-field :data-cy="'medical-provider-address-2-input-' + index"
                                    v-model="provider.location.addressLine2"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="medicalProviderAddressLine2Rules"
                                    @input="medicalProviderAddressLine2OnInput(provider)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-city-label-' + index"
                         class="label-text">CITY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-city-column-' + index">
                      <v-text-field
                          :data-cy="'medical-provider-city-input-' + index"
                          v-model="provider.location.city"
                          dense
                          outlined
                          clearable
                          counter="60"
                          maxLength="60"
                          background-color="white"
                          :rules="medicalProviderCityValidationRules"
                          @input="runMedicalProviderCityOnInput(provider)"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-state-label-' + index"
                         class="label-text">STATE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-state-column-' + index">
                      <v-autocomplete
                          :data-cy="'medical-provider-state-select-' + index"
                          :id="'medical-provider-state-select-' + index"
                          v-model="provider.location.state"
                          autocomplete="new-password"
                          :items="statesList"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @blur="uiUtils.triggerEnterKeyPress('medical-provider-state-select-' + index)"
                          @input="medicalProviderStateOnSelect(provider)"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-zip-code-label-' + index"
                         class="label-text">ZIP CODE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-zip-code-column-' + index">
                      <v-text-field
                          :data-cy="'medical-provider-zip-code-input-' + index"
                          v-model="provider.location.postalCode"
                          dense
                          outlined
                          clearable
                          v-mask="['#####' ,'#####-####']"
                          background-color="white"
                          :rules="medicalProviderZipValidationRules"
                          @input="medicalProviderZipOnInput(provider)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-country-label-' + index"
                         class="label-text"
                      >COUNTRY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-country-column-' + index">
                      <v-autocomplete v-model="provider.location.country"
                                      :data-cy="'medical-provider-country-select-' + index"
                                      :items="countriesList"
                                      clearable
                                      outlined
                                      dense
                                      @input="medicalProviderCountryOnSelect(provider)"
                                      background-color="white"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <p :data-cy="'medical-provider-treatment-date-label-' + index" class="label-text">TREATMENT DATE</p>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-treatment-date-column-' + index">
                      <v-menu
                          v-model="provider.treatmentDatePicker"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="150px">
                        <template v-slot:activator="{ off }">
                          <v-text-field
                              autocomplete="off"
                              :data-cy="'medical-provider-treatment-date-input-' + index"
                              v-model="provider.treatmentDateForDisplay"
                              v-mask="'##/##/####'"
                              append-icon="mdi-calendar"
                              @click:append="provider.treatmentDatePicker = true"
                              @click:clear="provider.treatmentDate = null"
                              clearable
                              outlined
                              v-on="off"
                              dense
                              background-color="white"
                              :rules="treatmentDateValidationRules"
                              @input="validateTreatmentDateOnInput(provider)">
                          </v-text-field>
                        </template>
                        <v-date-picker :data-cy="'medical-provider-treatment-date-picker-' + index"
                                       @input="treatmentDatePickerOnSelect(provider)"
                                       v-model="provider.treatmentDate">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'medical-provider-notes-label-' + index" class="label-text">NOTES</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'medical-provider-notes-column-' + index">
                    <v-textarea v-model="provider.notes"
                                autocomplete="off"
                                auto-grow
                                background-color="white"
                                clearable
                                counter="950"
                                maxLength="950"
                                :data-cy="'medical-provider-notes-input-' + index"
                                id="medical-provider-notes"
                                :rules="medicalProviderNotesValidationRules"
                                @input="medicalProviderNotesOnInput(provider)"
                                outlined>
                    </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:1.7em;' : ''"></v-divider>
              </v-row>
            </v-card>

            <!-- START:   Delete Medical Provider Dialog   -->
            <v-dialog v-model="$store.state.showRemoveMedicalProviderModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px" :data-cy="'remove-medical-provider-dialog-'+index">
              <v-card v-if="$store.state.showRemoveMedicalProviderModal" style="overflow-x: hidden; border-radius: 12px" :data-cy="'remove-medical-provider-card-'+index" color="#f2f4f6" >
                <v-row justify="end">
                  <v-col style="text-align: right" class="mr-4 py-0 col">
                    <span class="exit-remove-button" @click="noButton" :data-cy="'remove-medical-provider-exit-'+index">x</span>
                  </v-col>
                </v-row>
                <v-card-title class="py-0">
                  <span style="color: #003366; font-size: 24px; font-weight: 500" class="headline" :data-cy="'remove-medical-provider-header-'+index">Remove Medical Provider</span>
                </v-card-title>
                <v-card-text class="pb-0 pt-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="pb-0 pt-0">
                        <p class="modal-text" :data-cy="'remove-medical-provider-text-'+index">
                          Are you sure you want to remove medical provider {{ $store.state.currentMedicalProviderFullName }}?
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-row justify="end" class="pr-8">
                  <v-card-actions class="pb-8">
                    <v-btn depressed color="primary" :data-cy="'remove-medical-provider-yes-'+index"
                           @click="yesButton($store.state.currentMedicalProviderIndex)">Yes</v-btn>
                  </v-card-actions>
                  <v-card-actions class="pb-8">
                    <v-btn depressed text color="primary" outlined :data-cy="'remove-medical-provider-no-'+index" @click="noButton">No</v-btn>
                  </v-card-actions>
                </v-row>
              </v-card>
            </v-dialog>
            <!-- END:   Delete Medical Provider Dialog   -->

  </v-col>
  </v-form>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {States} from "@/models/typelists/States";
import {UiUtils} from "@/utils/ui-utils";
import {MedicalProvider} from "@/models/MedicalProvider";
import {Countries} from "@/models/typelists/Countries";
import {DateUtils} from "@/utils/date-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";

@Component
export default class MedicalProviderComponent extends Vue {

  submission: FnolSubmission;

  uiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  dateUtils = new DateUtils();
  statesList: Array<string> = Object.values(States);
  countriesList: Array<string> = Object.values(Countries);

  medicalProviderFullNameValidationRules: Array<string> = [];
  treatmentDateValidationRules: Array<string> = [];
  medicalProviderEmailValidationRules: Array<string> = [];
  medicalProviderPhoneValidationRules: Array<string> = [];
  medicalProviderCityValidationRules: Array<string> = [];
  medicalProviderAddressLine1Rules: Array<string> = [];
  medicalProviderAddressLine2Rules: Array<string> = [];
  medicalProviderZipValidationRules: Array<string> = [];
  medicalProviderNotesValidationRules: Array<string> = [];

  created() {
    console.log('medical provider component created')
    this.submission = this.$store.state.submission;
    //this.initializeTreatmentDates()
  }
  removeMedicalProvider(mpIndex: number) {
    this.$store.state.currentMedicalProviderIndex = mpIndex
    this.$store.state.currentMedicalProviderFullName = ""

    if(this.submission.additionalContacts.medicalProviders[mpIndex].fullName){
      this.$store.state.currentMedicalProviderFullName = this.submission.additionalContacts.medicalProviders[mpIndex].fullName
    }
    this.$store.state.showRemoveMedicalProviderModal = true;
  }

  yesButton(indexMP: number){
    this.submission.additionalContacts.medicalProviders.splice(indexMP, 1);
    this.$store.state.showRemoveMedicalProviderModal = false;
  }

  noButton(){
    this.$store.state.showRemoveMedicalProviderModal = false;
  }

  medicalProviderStateOnSelect(provider: MedicalProvider) {
    provider.location.state = this.uiUtils.testForTrim(provider.location.state);
    provider.location.stateCode = this.uiUtils.getStateCode(provider.location.state);
  }

  medicalProviderCountryOnSelect(provider: MedicalProvider) {
    provider.location.country = this.uiUtils.testForTrim(provider.location.country);
    provider.location.countryCode = this.uiUtils.getCountryCode(provider.location.country);
  }

  validateTreatmentDateOnInput(provider: MedicalProvider) {
    provider.treatmentDate = this.uiUtils.testForTrim(provider.treatmentDate);
    provider.treatmentDateForDisplay = this.uiUtils.testForTrim(provider.treatmentDateForDisplay);
    provider.treatmentDate = this.dateUtils.parseFormattedDate(provider.treatmentDateForDisplay)
    this.treatmentDateValidationRules = this.aplFrontEndValidation.runDateValidationRules_isRequired(false)
  }

  treatmentDatePickerOnSelect(provider: MedicalProvider) {
    provider.treatmentDateForDisplay = this.dateUtils.formatDateForDisplay(provider.treatmentDate);
    provider.treatmentDate = this.dateUtils.parseFormattedDate(provider.treatmentDateForDisplay)
    this.treatmentDateValidationRules = this.aplFrontEndValidation.runDateValidationRules_isRequired(false)
    provider.treatmentDatePicker = false
  }

  medicalProviderFullNameOnInput(provider: MedicalProvider) {
    provider.fullName = this.uiUtils.checkIfEmpty(provider.fullName);
    this.medicalProviderFullNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false)
  }

  runMedicalProviderEmailOnInput(provider: MedicalProvider){
    provider.primaryEmail = this.uiUtils.checkIfEmpty(provider.primaryEmail);
    this.medicalProviderEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  runMedicalProviderPhoneOnInput(provider: MedicalProvider){
    provider.primaryPhone = this.uiUtils.testForTrim(provider.primaryPhone)
    this.medicalProviderPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  runMedicalProviderPhoneExtOnInput(provider: MedicalProvider){
    provider.primaryPhoneExt = this.uiUtils.testForTrim(provider.primaryPhoneExt)
  }

  runMedicalProviderCityOnInput(provider: MedicalProvider){
    provider.location.city = this.uiUtils.checkIfEmpty(provider.location.city);
    this.medicalProviderCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false);
  }

  medicalProviderAddressLine1OnInput(provider: MedicalProvider){
    provider.location.addressLine1 = this.uiUtils.checkIfEmpty(provider.location.addressLine1)
    this.medicalProviderAddressLine1Rules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  medicalProviderAddressLine2OnInput(provider: MedicalProvider){
    provider.location.addressLine2 = this.uiUtils.checkIfEmpty(provider.location.addressLine2)
    this.medicalProviderAddressLine2Rules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  medicalProviderZipOnInput(provider: MedicalProvider){
    provider.location.postalCode = this.uiUtils.testForTrim(provider.location.postalCode);
    this.medicalProviderZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  medicalProviderNotesOnInput(provider: MedicalProvider){
    provider.notes = this.uiUtils.checkIfEmpty(provider.notes);
    this.medicalProviderNotesValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }
}

</script>

<style scoped lang="scss">

</style>
