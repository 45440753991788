var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.$route.name == 'getting-started'
          || this.$route.name == 'contact-information'
          || this.$route.name == 'loss-information'
          || this.$route.name == 'accident-information'
          || this.$route.name == 'property-loss-information'
          || this.$route.name == 'cargo-loss-information'
          || this.$route.name == 'liability-loss-information'
          || this.$route.name == 'other-party'
          || this.$route.name == 'additional-contacts'
          || this.$route.name == 'additional-information'
          || this.$route.name == 'review-and-submit')?_c('v-row',{staticClass:"pt-2",attrs:{"data-cy":"mobile-stepper"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#fff","height":"85px"}},[_c('v-list-item',{staticClass:"pl-9 pr-9 mt-n2",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"mt-3",attrs:{"size":"60","color":"transparent"}},[_c('v-progress-circular',{attrs:{"data-cy":"mobile-stepper-progress-circular","rotate":-90,"size":60,"width":5,"value":_vm.$route.name === 'getting-started' ? 14 :
                      _vm.$route.name === 'contact-information' ? 28 :
                      _vm.$route.name === 'loss-information' ? 42 :
                      _vm.$route.name === 'accident-information' ? 57 :
                      _vm.$route.name === 'cargo-loss-information' ?  57 :
                      _vm.$route.name === 'liability-loss-information' ? 57 :
                      _vm.$route.name === 'property-loss-information' ? 57 :
                      _vm.$route.name === 'other-party' ? 63 :
                      _vm.$route.name === 'additional-contacts' ? 72 :
                      _vm.$route.name === 'additional-information' ? 86 :
                      _vm.$route.name === 'review-and-submit' ? 100 : 0,"color":"#00ac5d"}},[_c('span',{staticClass:"stepper-numbers",attrs:{"data-cy":"mobile-stepper-page-number"}},[_vm._v("\n              "+_vm._s(_vm.$route.name === 'getting-started' ? '1 of ' +  this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'contact-information' ? '2 of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'loss-information' ? '3 of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'accident-information' ? '4 of ' + this.$store.state.totalNumberOfPages:
                _vm.$route.name === 'cargo-loss-information' ? '4 of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'liability-loss-information' ? '4 of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'property-loss-information' ? '4 of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'other-party' ? '5 of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'additional-contacts' ? this.$store.state.additionalContactsStepNumber + ' of ' + this.$store.state.totalNumberOfPages :
                _vm.$route.name === 'additional-information' ? this.$store.state.additionalInformationStepNumber + ' of ' + this.$store.state.totalNumberOfPages:
                _vm.$route.name === 'review-and-submit' ? this.$store.state.reviewStepNumber + ' of ' + this.$store.state.totalNumberOfPages : '')+"\n            ")])])],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"stepper-title",attrs:{"data-cy":"mobile-stepper-current-page"}},[_vm._v("\n            "+_vm._s(_vm.$route.name === 'getting-started' ? 'Getting Started' :
              _vm.$route.name === 'contact-information' ? 'Customer Information' :
              _vm.$route.name === 'loss-information' ? 'Loss Information' :
              _vm.$route.name === 'accident-information' ? 'Accident Information' :
              _vm.$route.name === 'property-loss-information' ? 'Property Information' :
              _vm.$route.name === 'cargo-loss-information' ? 'Cargo Information' :
              _vm.$route.name === 'liability-loss-information' ? 'Liability Information' :
              _vm.$route.name === 'other-party' ? 'Other Parties' :
              _vm.$route.name === 'additional-contacts' ? 'Additional Contacts' :
              _vm.$route.name === 'additional-information' ? 'Additional Information' :
              _vm.$route.name === 'review-and-submit' ? 'Review & Submit' : '')+"\n          ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"stepper-subtitle",attrs:{"data-cy":"mobile-stepper-next-page"}},[_vm._v("\n            "+_vm._s(_vm.$route.name === 'getting-started' ? 'Next: Customer Information' :
              _vm.$route.name === 'contact-information' ? 'Next: Loss Information' :
              _vm.$route.name === 'loss-information' ? 'Next: ' + this.getLossType() + ' Loss Type' :
              _vm.$route.name === 'accident-information' ? 'Next: Other Parties' :
              _vm.$route.name === 'property-loss-information' ? 'Next: Additional Contacts' :
              _vm.$route.name === 'cargo-loss-information' ? 'Next: Additional Contacts' :
              _vm.$route.name === 'liability-loss-information' ? 'Next: Additional Contacts' :
              _vm.$route.name === 'other-party' ? 'Next: Additional Contacts' :
              _vm.$route.name === 'additional-contacts' ? 'Next: Additional Information' :
              _vm.$route.name === 'additional-information' ? 'Next: Review & Submit' :
              _vm.$route.name === 'review-and-submit' ? '' : '')+"\n          ")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }