<template>
  <v-col data-cy="rs-vue" cols="12" style="padding: 0 !important;">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp" >
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>

      <v-col>  <!-- main column -->
        <v-row v-if="$vuetify.breakpoint.mdAndUp" style="display: flex; justify-content: space-around">
          <v-col>
            <div>
              <span data-cy="rs-main-title" class="pl-5 rs-title">Review & Submit</span>
            </div>
          </v-col>
          <v-col data-cy="rs-report-number-column" class="report-num-col">
            <span data-cy="rs-report-number-label" class="report-num-label">Report#: {{submission.reportNumber}}</span>
          </v-col>
        </v-row>

        <!-- GETTING STARTED SECTION -->
        <v-card elevation="0" class="card-background">
          <v-card-text class="py-0">
            <v-row class="rs-labels mt-2 pl-2">
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 pl-5 pr-0 rs-card-header' : 'pb-0 pr-0 rs-card-header'"
                     data-cy="rs-policy-number-label">POLICY NUMBER</v-col>
              <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown" class="rs-values">{{policySymbolAndNumber}}</v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4" class="pb-0 pr-0 rs-card-header" data-cy="rs-date-of-loss-label">DATE OF LOSS</v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 4" class="pb-0  rs-card-header" data-cy="rs-time-of-loss-label">TIME</v-col>
              <v-col v-if="$vuetify.breakpoint.smAndDown" class="rs-values">{{dateUtils.formatDateForDisplay(submission.gettingStarted.dateOfLoss)}}</v-col>
              <v-col v-if="$vuetify.breakpoint.smAndDown && submission.gettingStarted.timeOfLoss" class="rs-values">{{dateUtils.formatTimeForDisplay(submission.gettingStarted.dateOfLoss + " " +
                  submission.gettingStarted.timeOfLoss)}}</v-col>
            </v-row>
            <v-row class="rs-values pl-2" v-if="$vuetify.breakpoint.mdAndUp">
              <v-col class="pt-0 pl-5 pr-0" data-cy="rs-policy-number-value"> {{policySymbolAndNumber}}</v-col>
              <v-col class="pt-0 pr-0" data-cy="rs-date-of-loss-value">
                {{dateUtils.formatDateForDisplay(submission.gettingStarted.dateOfLoss)}}
              </v-col>
              <v-col class="pt-0">
                <div data-cy="rs-time-of-loss-value" v-if="submission.gettingStarted.timeOfLoss">
                  {{dateUtils.formatTimeForDisplay(submission.gettingStarted.dateOfLoss + " " +
                    submission.gettingStarted.timeOfLoss)}}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-expansion-panels flat multiple >
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:0.8em;margin-right:0.8em;' : ''" class="grey"></v-divider>
            </v-row >
            <v-expansion-panel :class="$vuetify.breakpoint.smAndDown ? 'px-6' : 'px-2'">
              <v-expansion-panel-header  data-cy="contact-information-section">
                <div class="rs-section-label">Contact Information</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ContactInformationReview/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:0.8em;margin-right:0.8em;' : ''" class="grey"></v-divider>
            </v-row>

            <v-expansion-panel :class="$vuetify.breakpoint.smAndDown ? 'px-6 mt-5' : 'px-2'">
              <v-expansion-panel-header data-cy="loss-information-section">
                <span class="rs-section-label">Loss Information</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <LossInformationReview/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:0.8em;margin-right:0.8em;' : ''" class="grey"></v-divider>
            </v-row>

            <v-expansion-panel v-if="submission.lossInformation.lossType" :class="$vuetify.breakpoint.smAndDown ? 'px-6 mt-5' : 'px-2'">
              <v-expansion-panel-header data-cy="loss-type-section">
                <span class="rs-section-label" v-if="submission.lossInformation.lossType === 'Auto'">Accident Information</span>
                <span class="rs-section-label" v-if="submission.lossInformation.lossType === 'Property'">Property Information</span>
                <span class="rs-section-label" v-if="submission.lossInformation.lossType === 'Cargo'">Cargo Information</span>
                <span class="rs-section-label" v-if="submission.lossInformation.lossType === 'Liability'">Liability Information</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <AccidentInformationReview v-if="submission.lossInformation.lossType === 'Auto'"/>
                <PropertyLossInformationReview v-if="submission.lossInformation.lossType === 'Property'"/>
                <CargoLossInformationReview v-if="submission.lossInformation.lossType === 'Cargo'"/>
                <LiabilityLossInformationReview v-if="submission.lossInformation.lossType === 'Liability'"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-row v-if="$vuetify.breakpoint.mdAndUp && submission.lossInformation.lossType === 'Auto'">
              <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:0.8em;margin-right:0.8em;' : ''" class="grey"></v-divider>
            </v-row>

            <v-expansion-panel v-if="submission.lossInformation.lossType === 'Auto'" :class="$vuetify.breakpoint.smAndDown ? 'px-6 mt-5' : 'px-2'">
              <v-expansion-panel-header data-cy="other-parties-section">
                <span class="rs-section-label">Other Parties</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <OtherPartyReview/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:0.8em;margin-right:0.8em;' : ''" class="grey"></v-divider>
            </v-row>

            <v-expansion-panel  :class="$vuetify.breakpoint.smAndDown ? 'px-6 mt-5' : 'px-2'">
              <v-expansion-panel-header data-cy="additional-contacts-section">
                <span class="rs-section-label">Additional Contacts</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <AdditionalContactsReview/>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:0.8em;margin-right:0.8em;' : ''" class="grey"></v-divider>
            </v-row>

            <v-expansion-panel :class="$vuetify.breakpoint.smAndDown ? 'px-6 mt-5' : 'px-2'">
              <v-expansion-panel-header data-cy="additional-information-section">
                <span class="rs-section-label">Additional Information</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <AdditionalInformationReview/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card>

        <v-row>
          <v-col>
            <v-card class="card-background" elevation="0">
              <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'" :class="$vuetify.breakpoint.smAndDown ? 'px-6 pb-0' : ''">
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="navigateTo('additional-information')" data-cy="rs-previous-button" outlined class="previous-button">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>

                <v-btn @click="navigateTo('confirmation')" :disabled=this.isButtonDisabled data-cy="rs-submit-button" class="next-button">
                  <span>Submit</span><v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-row>

              <v-row v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''" :class="$vuetify.breakpoint.smAndDown ? 'px-6' : ''">
                <v-btn @click="navigateTo('additional-information')" data-cy="previous-button" outlined class="previous-button" >
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>
              </v-row>

              <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''" :class="$vuetify.breakpoint.smAndDown ? 'px-6' : ''">
                <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                  <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>  <!-- main column -->
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission" v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import ContactInformationReview from "@/views/components/ContactInformationReview.vue";
import LossInformationReview from "@/views/components/LossInformationReview.vue";
import AccidentInformationReview from "@/views/components/AccidentInformationReview.vue";
import PropertyLossInformationReview from "@/views/components/PropertyLossInformationReview.vue";
import CargoLossInformationReview from "@/views/components/CargoLossInformationReview.vue";
import LiabilityLossInformationReview from "@/views/components/LiabilityLossInformationReview.vue";
import OtherPartyReview from "@/views/components/OtherPartyReview.vue";
import AdditionalInformationReview from "@/views/components/AdditionalInformationReview.vue";
import AdditionalContactsReview from "@/views/components/AdditionalContactsReview.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {DateUtils} from "@/utils/date-utils";
import {Status} from "@/models/typelists/Status";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";
import {ValidationUtils} from "@/utils/validation-utils";
import {UiUtils} from "@/utils/ui-utils";


@Component({
  components: {
    BrowserBackButtonModal,
    SideStepperComponent,
    CancelClaimModal,
    ContactInformationReview,
    LossInformationReview,
    AccidentInformationReview,
    PropertyLossInformationReview,
    CargoLossInformationReview,
    LiabilityLossInformationReview,
    OtherPartyReview,
    AdditionalContactsReview,
    AdditionalInformationReview,
    TimeoutModal}
})


export default class ReviewAndSubmit extends Vue {

  submission: FnolSubmission;
  dateUtils: DateUtils = new DateUtils();
  statuses: Array<String> = Object.values(Status);
  policySymbolAndNumber: string;
  validationUtils: ValidationUtils = new ValidationUtils();
  uiUtils= new UiUtils();
  isButtonDisabled: boolean = false;

  created() {
    this.submission = this.$store.state.submission;
    this.$store.state.showValidationSnackbar = false;

    // Check for "back button".
    if (this.submission.status === Status.SUBMITTED) {
      this.uiUtils.navigate('landing-page')
    }

    if(!this.validationUtils.validateRequiredFieldsOnEntireSubmission(this.submission)){
      this.$store.state.showValidationSnackbar = true
    }

    if (!this.validationUtils.validateRequiredFieldsOnEntireSubmission(this.submission) || this.submission.status === 'SUBMITTED'){
      this.isButtonDisabled = true;
    }

    if (this.submission.gettingStarted.policySymbol == null || this.submission.gettingStarted.policySymbol == 'undefined'){
      this.policySymbolAndNumber = this.submission.gettingStarted.policyNumber;
    }
    else {
      this.policySymbolAndNumber = this.submission.gettingStarted.policySymbol + " " + this.submission.gettingStarted.policyNumber;
    }
  }

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  applicationTimeoutTimer () {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  navigateTo(route: string) {
    if(route == 'confirmation') {
        this.submission.status = Status.SUBMITTED;
        window.onbeforeunload = function () {}   // remove leave page warning
    }

    this.uiUtils.navigateAndSaveSubmission(this.submission, route)
  }

  CancelClaimModalOnClick(){
    this.$store.state.showCancelClaimModal = true;
  }
}
</script>
