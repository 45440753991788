<template>
  <div data-cy="submission-loader-component"></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class SubmissionLoaderComponent extends Vue {

  uiUtils = new UiUtils();
  submission: FnolSubmission = new FnolSubmission()

  created(){

    this.initializeSubmission();

    this.$store.dispatch("startNewSubmission", this.submission).then(() => {
      this.uiUtils.navigate('getting-started');
      this.$store.state.customerInfoIsValid = false
      this.$store.state.lossInfoIsValid = false
      this.$store.state.cargoLossInformationPageValid = false
      this.$store.state.showAlertIconPropertyInfo = false
      this.$store.state.showAlertIconCargoInfo = false
    });

}

  initializeSubmission() {
    this.submission = new FnolSubmission()
    if(this.submission.commonData.userType == null) {
      this.submission.commonData.userType = this.$store.state.loggedInUser.userType
    }
    this.submission.policyDetails.policyBusinessUnit = this.$store.state.siteBrand.brandName;
    return this.submission
  }
}
</script>