import Vue from "vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {AplLoss} from "@/models/Loss/AplLoss";
import store from "@/store/store";

export class LossUtils extends Vue {

    getLossTypeCode(lossType: string) {
        switch(lossType) {
            case "Auto":
                return "AUTO"
            case "Cargo":
                return "INLANDMARINE"
            case "Liability":
                return "LIABILITY"
            case "Property":
                return "PROPERTY"
        }
    }

    getLossCauseCode(lossCause: string) {
        for (const aplLoss of store.state.lossCauseList) {
            if (aplLoss.cause.trim() === lossCause) {
                console.log("Found Code: " + aplLoss.causeCode + " for " + aplLoss.cause + ' and business unit ' + aplLoss.businessUnitCode)
                return aplLoss.causeCode
            }
        }
    }

    getLossCauseDetailCode(lossCauseDetail: string) {
        for (const aplLoss of store.state.lossCauseDetailList) {
            if (aplLoss.detail.trim() === lossCauseDetail) {
                console.log("Found Code: " + aplLoss.detailCode + " for " + aplLoss.detail + ' and business unit ' + aplLoss.businessUnitCode)
                return aplLoss.detailCode
            }
        }
    }

    async getLossCauseSelections(submission: FnolSubmission) {
        if (submission.lossInformation != null
            && submission.lossInformation.lossType != null
            && submission.policyDetails != null
            && submission.policyDetails.policyBusinessUnit != null) {

            const aplLoss = new AplLoss()
            aplLoss.lossType = submission.lossInformation.lossType
            aplLoss.businessUnitCode = this.shouldUseALTBU(submission.policyDetails.policyBusinessUnit, submission.lossInformation.lossType)
            await store.dispatch('getLossCause', aplLoss).then(() => {
                const lossCauseNameList = []
                store.state.lossCauseList.forEach((lossCause: AplLoss) => {
                    lossCauseNameList.push(lossCause.cause)
                })
                store.commit("setLossCauseNameList", lossCauseNameList)
            });
        } else {
            return []
        }
    }

    async getLossCauseDetailSelections(submission: FnolSubmission) {
        let lossCauseCode = null
        if (submission.lossInformation != null && submission.lossInformation.lossCause != null && submission.lossInformation.lossCauseCode == null) {
            lossCauseCode = this.getLossCauseCode(submission.lossInformation.lossCause)
        } else {
            lossCauseCode = submission.lossInformation.lossCauseCode
        }


        if (lossCauseCode != null && submission.policyDetails != null
            && submission.policyDetails.policyBusinessUnit != null) {
            const aplLoss = new AplLoss()
            aplLoss.lossType = submission.lossInformation.lossType
            aplLoss.businessUnitCode = this.shouldUseALTBU(submission.policyDetails.policyBusinessUnit, submission.lossInformation.lossType)
            aplLoss.causeCode = lossCauseCode

            await store.dispatch('getLossCauseDetail', aplLoss).then(() => {
                const lossCauseDetailNameList: Array<string> = []
                store.state.lossCauseDetailList.forEach((lossCause: AplLoss) => {
                    if (lossCause.detail != null && lossCause.detailCode != null) {
                        lossCauseDetailNameList.push(lossCause.detail)
                    }
                })
                store.commit("setLossCauseDetailNameList", lossCauseDetailNameList)
            });
        } else {
            return []
        }
    }

    shouldUseALTBU (bu: string, lt: string) : string {
        if ((bu == 'ALT' || bu == 'STRCMP') && lt == 'Cargo' ) {
            return "ALT"
        } else {
            return bu;
        }
    }
}