import {Contact} from "@/models/Contact";
import {Countries} from "@/models/typelists/Countries";

export class OtherPassenger extends Contact {

    injuriesSustainedFromAccident!: string;
    injuryInformationDescription: string;

    constructor() {
        super()
        this.location.country = Countries.US
        this.location.countryCode = 'US'
    }


}
