<template>
  <v-form
      autocomplete="off"
      v-model="$store.state.primaryContactComponentIsValid"
      data-cy="primary-contact-component">
    <v-row class="proximaNovaFont">
    <v-col>
      <v-card class="card-background" elevation="0">
        <v-row>
          <v-col>
            <div>
              <span data-cy="primary-contact-header" class="section-label berninaSansCompressedFont pl-8" >Primary Contact</span>
              <p data-cy="primary-contact-label" class="pl-8 helper-text">Who should we follow up with if we need more information about the loss after the claim is submitted?</p>
            </div>
          </v-col>
        </v-row>

        <v-row class="toggle-spacing">
          <v-col class="input-spacing">
            <v-row>
              <v-col cols="10" md="8" lg="6" class="input-spacing" data-cy="primary-contact-same-person-column">
                <v-row>
                  <v-switch
                      v-model="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter"
                      inset
                      class="v-switch-label"
                      label="Are you the primary contact?"
                      data-cy="primary-contact-same-person-switch"
                      dense
                      @change="contactCorrection.handleSameAsClaimReporterContact(submission.customerInformation)"/>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="3" lg="2" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-first-name-label" class="label-text">FIRST NAME<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-first-name-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-3" data-cy="primary-contact-first-name-text">{{ submission.customerInformation.claimReporter.firstName }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-first-name-input"
                      id="primary-contact-first-name"
                      v-model="submission.customerInformation.primaryContact.firstName"
                      dense
                      outlined
                      clearable
                      autocomplete="new-password"
                      background-color="white"
                      counter="30"
                      maxLength="30"
                      :rules="primaryFirstNameValidationRules"
                      @input="primaryFirstNameOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="3" lg="2" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-last-name-label"
                     class="label-text"
                  >LAST NAME<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-last-name-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-3" data-cy="primary-contact-last-name-text">{{ submission.customerInformation.primaryContact.lastName }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-last-name-input"
                      id="primary-contact-last-name"
                      v-model="submission.customerInformation.primaryContact.lastName"
                      dense
                      outlined
                      clearable
                      autocomplete="new-password"
                      background-color="white"
                      counter="30"
                      maxLength="30"
                      :rules="primaryLastNameValidationRules"
                      @input="primaryLastNameOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="2" lg="2" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-suffix-label" class="label-text">SUFFIX<span class="transparent-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-suffix-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-3" data-cy="primary-contact-suffix-text">{{ submission.customerInformation.primaryContact.suffix }}</div>
                  <v-autocomplete
                      v-else
                      data-cy="primary-contact-suffix-select"
                      v-model="submission.customerInformation.primaryContact.suffix"
                      :items="suffixesList"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="primaryContactSuffixOnSelect()"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-email-label"
                     class="label-text">EMAIL<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-email-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-3" data-cy="primary-contact-email-text">{{ submission.customerInformation.primaryContact.primaryEmail }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-email-input"
                      id="primary-contact-email"
                      v-model="submission.customerInformation.primaryContact.primaryEmail"
                      dense
                      outlined
                      clearable
                      autocomplete="new-password"
                      background-color="white"
                      counter="50"
                      maxLength="50"
                      :rules="primaryEmailValidationRules"
                      @input="primaryEmailValidationOnInput"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-phone-label" class="label-text">PHONE<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-phone-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-3" data-cy="primary-contact-phone-text">{{ submission.customerInformation.primaryContact.primaryPhone }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-phone-input"
                      id="primary-contact-phone"
                      v-model="submission.customerInformation.primaryContact.primaryPhone"
                      dense
                      outlined
                      clearable
                      autocomplete="new-password"
                      background-color="white"
                      v-mask="'(###) ###-####'"
                      :rules="primaryPhoneValidationRules"
                      @input="primaryPhoneValidationOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-phone-extension-label" class="label-text">EXTENSION<span class="transparent-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-phone-extension-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-3" data-cy="primary-contact-phone-extension-text">{{ submission.customerInformation.primaryContact.primaryPhoneExt }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-phone-extension-input"
                      id="primary-contact-phone-extension"
                      v-model="submission.customerInformation.primaryContact.primaryPhoneExt"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      v-mask="'####'"
                      @input="primaryContactPhoneExtOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        <v-row class="pl-8">
          <v-col cols="10" md="4" lg="3" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="primary-contact-relationship-label"
                   class="label-text">RELATIONSHIP TO INSURED</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing"
                     data-cy="primary-contact-relationship-column">
                <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter" class="rs-values pb-5" data-cy="primary-contact-relationship-text">{{ submission.customerInformation.primaryContact.relationToInsured }}</div>
                <v-autocomplete
                    v-else
                    data-cy="primary-contact-relationship-input"
                    v-model="submission.customerInformation.primaryContact.relationToInsured"
                    :items="relationToInsuredList"
                    autocomplete="new-password"
                    dense
                    outlined
                    clearable
                    background-color="white"
                    :rules="primaryRelationshipToInsurerValidationRules"
                    @input="primaryRelationToInsuredOnSelect"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="toggle-spacing" v-if="!submission.customerInformation.primaryContact.isSamePersonAsClaimReporter">
          <v-col class="input-spacing">
            <v-row>
              <v-col cols="10" md="8" lg="6" class="input-spacing"
                     data-cy="primary-contact-same-address-column">
                <v-row>
                  <v-switch
                      v-model="submission.customerInformation.primaryContact.isSameAddressAsInsured"
                      inset
                      class="v-switch-label"
                      label="Is the primary contact address the same as the Insured's?"
                      data-cy="primary-contact-same-address-switch"
                      dense
                      @change="contactCorrection.handleSamePrimaryContactAddressAsInsured(submission.customerInformation)"/>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter
                  || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-address-read-only">
          <v-row class="pl-8">
            <v-col cols="12">
              <div class="label-text" data-cy="primary-contact-address-label">PRIMARY CONTACT ADDRESS</div>
              <div class="rs-values" data-cy="primary-contact-address-line-1-text">{{ submission.customerInformation.primaryContact.location.addressLine1 }}</div>
              <div class="rs-values" data-cy="primary-contact-address-line-2-text">{{ submission.customerInformation.primaryContact.location.addressLine2 }}</div>
              <div class="rs-values" data-cy="primary-contact-city-state-zip-text">{{ submission.customerInformation.primaryContact.location.city }}, {{ submission.customerInformation.primaryContact.location.state }} {{ submission.customerInformation.primaryContact.location.postalCode }}</div>
              <div class="rs-values" data-cy="primary-contact-country-text">{{ submission.customerInformation.primaryContact.location.country }}</div>
            </v-col>
          </v-row>
        </div>

        <div v-else data-cy="primary-contact-address-user-inputs">

          <v-row class="pl-8">
            <v-col cols="10" md="8" lg="6" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-address-line-1-label"
                     class="label-text">ADDRESS LINE 1</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing"
                       data-cy="primary-contact-address-line-1-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter
                  || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-address-line-1-text">{{ submission.customerInformation.primaryContact.location.addressLine1 }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-address-line-1-input"
                      v-model="submission.customerInformation.primaryContact.location.addressLine1"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="primaryAddressLine1ValidationRules"
                      @input="primaryAddressLine1ValidationOnInput"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="8" lg="6" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-address-line-2-label"
                     class="label-text"
                  >ADDRESS LINE 2</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-address-line-2-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-address-line-2-text">{{ submission.customerInformation.primaryContact.location.addressLine2 }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-address-line-2-input"
                      v-model="submission.customerInformation.primaryContact.location.addressLine2"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="primaryAddressLine2ValidationRules"
                      @input="primaryAddressLine2ValidationOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-city-label"
                     class="label-text"
                  >CITY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-city-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-city-text">{{ submission.customerInformation.primaryContact.location.city }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-city-input"
                      v-model="submission.customerInformation.primaryContact.location.city"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="primaryCityValidationRules"
                      @input="primaryCityValidationOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-state-label"
                     class="label-text"
                  >STATE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-state-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-state-text">{{ submission.customerInformation.primaryContact.location.state }}</div>
                  <v-autocomplete
                      v-else
                      data-cy="primary-contact-state-select"
                      id="primary-contact-state-select"
                      v-model="submission.customerInformation.primaryContact.location.state"
                      autocomplete="new-password"
                      :items="statesList"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @blur="uiUtils.triggerEnterKeyPress('primary-contact-state-select')"
                      @input="primaryContactStateOnSelect"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-zip-code-label" class="label-text">ZIP CODE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-zip-code-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-zip-code-text">{{ submission.customerInformation.primaryContact.location.postalCode }}</div>
                  <v-text-field
                      v-else
                      data-cy="primary-contact-zip-code-input"
                      v-model="submission.customerInformation.primaryContact.location.postalCode"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      v-mask="['#####' ,'#####-####']"
                      :rules="primaryZipCodeValidationRules"
                      @input="primaryZipCodeValidationOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="primary-contact-country-label"
                     class="label-text"
                  >COUNTRY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="primary-contact-country-column">
                  <div v-if="submission.customerInformation.primaryContact.isSamePersonAsClaimReporter || submission.customerInformation.primaryContact.isSameAddressAsInsured" data-cy="primary-contact-country-text">{{ submission.customerInformation.primaryContact.location.country }}</div>
                  <v-autocomplete
                      v-else
                      data-cy="primary-contact-country-select"
                      v-model="submission.customerInformation.primaryContact.location.country"
                      autocomplete="new-password"
                      :items="countriesList"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="primaryContactCountryOnSelect"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {FrontEndValidation} from "./PageValidations/FrontEndValidation";
import {Countries} from "@/models/typelists/Countries";
import {States} from "@/models/typelists/States";
import {Suffixes} from "@/models/typelists/Suffixes";
import {UiUtils} from "@/utils/ui-utils";
import {ContactCorrectionUtil} from "@/utils/contact-correction-util";
import {RelationToInsured} from "@/models/typelists/RelationToInsured";

@Component
export default class PrimaryContactComponent extends Vue {

  submission: FnolSubmission;

  uiUtils = new UiUtils();
  contactCorrection = new ContactCorrectionUtil()
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  statesList: Array<string> =Object.values(States);
  suffixesList: Array<string> = Object.values(Suffixes);
  countriesList: Array<string> = Object.values(Countries);
  relationToInsuredList: Array<string> = Object.values(RelationToInsured);

  primaryFirstNameValidationRules: Array<string> = [];
  primaryLastNameValidationRules: Array<string> = [];
  primaryRelationshipToInsurerValidationRules: Array<string> = [];
  primaryAddressLine1ValidationRules: Array<string> = [];
  primaryAddressLine2ValidationRules: Array<string> = [];
  primaryCityValidationRules: Array<string> = [];
  primaryZipCodeValidationRules: Array<string> = [];
  primaryEmailValidationRules: Array<string> = [];
  primaryPhoneValidationRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
  }

  primaryFirstNameOnInput(){
    this.submission.customerInformation.primaryContact.firstName = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.firstName)
    this.primaryFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  primaryLastNameOnInput(){
    this.submission.customerInformation.primaryContact.lastName = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.lastName)
    this.primaryLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  primaryRelationToInsuredOnSelect() {
    this.submission.customerInformation.primaryContact.relationToInsured = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.relationToInsured)
    this.submission.customerInformation.primaryContact.relationToInsuredCode = this.uiUtils.getRelationToInsuredCode(this.submission.customerInformation.primaryContact.relationToInsured);
  }

  primaryAddressLine1ValidationOnInput(){
    this.submission.customerInformation.primaryContact.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.location.addressLine1)
    this.primaryAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  primaryAddressLine2ValidationOnInput(){
    this.submission.customerInformation.primaryContact.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.location.addressLine2)
    this.primaryAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  primaryCityValidationOnInput(){
    this.submission.customerInformation.primaryContact.location.city = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.location.city)
    this.primaryCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  primaryZipCodeValidationOnInput(){
    this.submission.customerInformation.primaryContact.location.postalCode = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.location.postalCode)
    this.primaryZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  primaryEmailValidationOnInput(){
    this.submission.customerInformation.primaryContact.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.primaryEmail)
    this.primaryEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  primaryPhoneValidationOnInput(){
    this.submission.customerInformation.primaryContact.primaryPhone = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.primaryPhone)
    this.primaryPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  primaryContactPhoneExtOnInput(){
    this.submission.customerInformation.primaryContact.primaryPhoneExt = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.primaryPhoneExt)
  }


  primaryContactSuffixOnSelect() {
    if (null != this.submission.customerInformation.primaryContact.suffix) {
      this.submission.customerInformation.primaryContact.suffixCode = this.uiUtils.getSuffixCode(this.submission.customerInformation.primaryContact.suffix);
    }
  }

  primaryContactCountryOnSelect() {
    this.submission.customerInformation.primaryContact.location.country = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.location.country)
    this.submission.customerInformation.primaryContact.location.countryCode = this.uiUtils.getCountryCode(this.submission.customerInformation.primaryContact.location.country);
  }

  primaryContactStateOnSelect() {
    this.submission.customerInformation.primaryContact.location.state = this.uiUtils.checkIfEmpty(this.submission.customerInformation.primaryContact.location.state)
    this.submission.customerInformation.primaryContact.location.stateCode = this.uiUtils.getStateCode(this.submission.customerInformation.primaryContact.location.state);
  }
}
</script>
