import { render, staticRenderFns } from "./CargoLossInformationReview.vue?vue&type=template&id=68383a49&scoped=true"
import script from "./CargoLossInformationReview.vue?vue&type=script&lang=ts"
export * from "./CargoLossInformationReview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68383a49",
  null
  
)

export default component.exports