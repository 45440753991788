<template>
  <div>
    <v-dialog v-model="this.$store.state.showBackButtonModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px">
      <v-card style="overflow-x: hidden" data-cy="browser-navigation-modal">
        <v-card-title class="py-3 modal-header-container">
          <span style="color: #003366" class="modal-header" data-cy="browser-navigation-header">Browser Navigation Warning</span>
        </v-card-title>
        <v-col cols="12" class="pa-0">
          <v-divider color="#E6E9ED" style="opacity: 50%"/>
        </v-col>
        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0 ml-3">
                <span class="modal-text" style="color: #003366" data-cy="browser-navigation-body">
                  Please use the 'Previous' button at the bottom of the page.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row justify="end" class="pr-8">
          <v-card-actions class="pb-4 pr-md-12">
            <v-btn depressed text color="white" class="ok-button" outlined data-cy="browser-modal-ok-btn" @click="okButton">OK</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>

  </div>
</template>

<script lang="ts">

import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";

@Component
export default class BrowserBackButtonModal extends Vue {

  created() {
    console.log("browser back button modal created")
  }

  okButton() {
    this.$store.state.showBackButtonModal = false;
    window.scrollTo(0,document.body.scrollHeight);
  }
}
</script>

<style lang="scss" scoped>

.ok-button {
  color: white;
  background-color: #00274E;
}

</style>
