<template>
  <div data-cy="other-party-review-vue">
  <!-- Other Party -->
<!--  <div class="divider" ><span class="px-4">Other Parties</span></div>-->
  <v-row data-cy="other-party-section">
    <v-btn outlined tile class="elevation-0 ml-3 mb-2">
              <span class="edit-section-label" data-cy="edit-other-party-cta" @click="editOtherPartyOnClick">
              <v-icon>mdi-pencil</v-icon> Go To Other Parties</span>
    </v-btn>
  </v-row>


    <v-row v-for="(party, index) in submission.otherParty.parties" :key="index">
      <v-col>

        <v-row>
          <v-col cols="auto" class="pb-0 mb-n2" :data-cy="'rs-other-party-header-label-' + index">
            <div class="rs-party-label">- Party {{index + 1}} -</div>
          </v-col>
        </v-row>

    <!-- Other Party Driver Information -->
    <v-row>
      <v-col cols="auto" class="pb-0 mb-n2" :data-cy="'rs-other-party-driver-information-label-' + index">
        <div class="sub-section-label">Driver</div>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row :data-cy="'rs-other-party-driver-first-name-label-' + index">
          FIRST NAME
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-first-name-value-' + index">
          {{party.driver.firstName}}
        </v-row>
      </v-col>
      <v-col class="pb-0" cols="8">
        <v-row :data-cy="'rs-other-party-driver-last-name-label-' + index">
          LAST NAME
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-last-name-value-' + index">
          {{party.driver.lastName}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row :data-cy="'rs-other-party-driver-email-label-' + index">
          EMAIL
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-email-value-' + index">
          {{party.driver.primaryEmail}}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0" >
        <v-row :data-cy="'rs-other-party-driver-phone-label-' + index">
          PHONE
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-phone-value-' + index">
          {{party.driver.primaryPhone}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row  :data-cy="'rs-other-party-driver-address-line-1-label-' + index">
          ADDRESS LINE 1
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-address-line-1-value-' + index">
          {{party.driver.location.addressLine1}}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0" >

        <v-row :data-cy="'rs-other-party-driver-address-line-2-label-' + index">
          ADDRESS LINE 2
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-address-line-2-value-' + index">
          {{party.driver.location.addressLine2}}
        </v-row>

      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row :data-cy="'rs-other-party-driver-city-label-' + index">
          CITY
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-city-value-' + index" >
          {{party.driver.location.city}}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0" >
        <v-row :data-cy="'rs-other-party-driver-state-label-' + index">
          STATE
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-state-value-' + index">
          {{party.driver.location.state}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row :data-cy="'rs-other-party-driver-zip-label-' + index">
          ZIP
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-zip-value-' + index">
          {{party.driver.location.postalCode}}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0" >
        <v-row :data-cy="'rs-other-party-driver-country-label-' + index">
          COUNTRY
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-country-value-' + index">
          {{party.driver.location.country}}
        </v-row>

      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" >
        <v-row :data-cy="'rs-other-party-driver-sustain-injury-label-' + index">
          DID THE DRIVER SUSTAIN INJURY IN THE ACCIDENT?
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-sustain-injury-value-' + index">
          {{party.driver.sustainInjury}}
        </v-row>
      </v-col>

    </v-row>

    <v-row class="rs-labels pl-3" v-if="isInjuryInformationVisible(party)">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row :data-cy="'rs-other-party-driver-injury-information-label-' + index">
          INJURY INFORMATION
        </v-row>
        <v-row v-if="party.hasDriverInfo" class="rs-values" :data-cy="'rs-other-party-driver-injury-information-value-' + index">
          {{party.driver.injury}}
        </v-row>
      </v-col>
    </v-row>

    <!-- Other Party Vehicle Information -->
    <v-row>
      <v-col cols="auto" class="pb-0 mb-n2" :data-cy="'other-party-vehicle-information-label-' + index">
        <div class="sub-section-label">Vehicle</div>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row :data-cy="'rs-other-party-vehicle-vin-label-' + index">VIN</v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-vin-value-' + index">
          {{party.vehicle.vin}}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0" >
        <v-row :data-cy="'rs-other-party-vehicle-plate-number-label-' + index">
          PLATE NUMBER
        </v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-plate-number-value-' + index">
          {{party.vehicle.plateNumber}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row :data-cy="'rs-other-party-vehicle-year-label-' + index">
          YEAR
        </v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-year-value-' + index">
          {{party.vehicle.year}}
        </v-row>
      </v-col>
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row :data-cy="'rs-other-party-vehicle-make-label-' + index">
          MAKE
        </v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-make-value-' + index">
          {{party.vehicle.make}}
        </v-row>
      </v-col>
      <v-col class="pb-0"  >
        <v-row :data-cy="'rs-other-party-vehicle-model-label-' + index">
          MODEL
        </v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-model-value-' + index">
          {{party.vehicle.model}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row :data-cy="'rs-other-party-vehicle-damage-info-label-' + index">
          VEHICLE DAMAGE
        </v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-damage-info-value-' + index">
          {{party.vehicle.damage}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row :data-cy="'rs-other-party-vehicle-location-label-' + index">
          CURRENT LOCATION
        </v-row>
        <v-row class="rs-values" :data-cy="'rs-other-party-vehicle-location-value-' + index">
          {{party.vehicle.location}}
        </v-row>
      </v-col>
    </v-row>

    <!-- Other Party Passengers -->
    <v-row v-if="party.otherPassengers.length > 0" class="pl-3">
      <v-col cols="auto" class="pb-0 mb-n2" >

        <v-row class="sub-section-label" :data-cy="'rs-other-party-passenger-information-label-' + index">
          Passengers
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3" v-for="(passenger, passengerIndex) in party.otherPassengers" :key="passengerIndex" data-cy="passenger-list">
      <v-col>
        <v-row >
          <v-col cols="auto" class="pb-0 mb-n2" >
            <v-row class="rs-passenger-label" :data-cy="'rs-other-party-' + index + '-passenger-header-label-' + passengerIndex">
              Passenger {{passengerIndex + 1}}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="rs-labels">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-first-name-label-'+ passengerIndex">
              FIRST NAME
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-first-name-value-'+ passengerIndex">
              {{passenger.firstName}}
            </v-row>
          </v-col>
          <v-col cols="8" class="pb-0" >
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-last-name-label-'+ passengerIndex">
              LAST NAME
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-last-name-value-'+passengerIndex">
              {{passenger.lastName}}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="rs-labels ">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-email-label-'+ passengerIndex">
              EMAIL
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-email-value-'+ passengerIndex">
              {{passenger.primaryEmail}}
            </v-row>
          </v-col>
          <v-col cols="8" class="pb-0">
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-phone-label-'+ passengerIndex">
              PHONE
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-phone-value-'+ passengerIndex">
              {{passenger.primaryPhone}}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="rs-labels ">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-address-line-1-label-'+ passengerIndex">
              ADDRESS LINE 1
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-address-line-1-value-'+ passengerIndex">
              {{passenger.location.addressLine1}}
            </v-row>

          </v-col>
          <v-col cols="8" class="pb-0" >
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-address-line-2-label-'+ passengerIndex">
              ADDRESS LINE 2
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-address-line-2-value-'+ passengerIndex">
              {{passenger.location.addressLine2}}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="rs-labels ">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-city-label-'+ passengerIndex">
              CITY
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-city-value-'+ passengerIndex">
              {{passenger.location.city}}
            </v-row>
          </v-col>
          <v-col cols="8" class="pb-0" >
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-state-label-'+ passengerIndex">
              STATE
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-state-value-'+ passengerIndex">
              {{passenger.location.state}}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="rs-labels ">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-zip-label-'+ passengerIndex">
              ZIP
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-zip-value-'+ passengerIndex">
              {{passenger.location.postalCode}}
            </v-row>
          </v-col>
          <v-col cols="8" class="pb-0" >
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-country-label-'+ passengerIndex">
              COUNTRY
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-country-value-'+ passengerIndex">
              {{passenger.location.country}}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="rs-labels">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-sustain-injury-label-'+ passengerIndex">
              DID THE PASSENGER SUSTAIN AN INJURY IN THE ACCIDENT?
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-sustain-injury-value-'+ passengerIndex">
              {{passenger.injuriesSustainedFromAccident}}
            </v-row>

          </v-col>

        </v-row>

        <v-row class="rs-labels " v-if="passenger.injuriesSustainedFromAccident">
          <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
            <v-row :data-cy="'rs-other-party-' + index + '-passenger-injury-information-label-'+ passengerIndex">
              INJURY INFORMATION
            </v-row>
            <v-row class="rs-values" :data-cy="'rs-other-party-' + index + '-passenger-injury-information-value-'+ passengerIndex">
              {{passenger.injuryInformationDescription}}
            </v-row>
          </v-col>
        </v-row>

        <v-divider v-if="passengerIndex != party.otherPassengers.length - 1"></v-divider>
      </v-col>
    </v-row>
        <v-divider v-if="index != submission.otherParty.parties.length - 1"></v-divider>
    </v-col>
  </v-row>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {UiUtils} from "@/utils/ui-utils";
import {FnolSubmission} from "@/models/FnolSubmission";
import {Party} from "@/models/Party";

@Component
export default class OtherPartyReview extends Vue {

  submission: FnolSubmission;
  uiUtils = new UiUtils()

  created() {
    console.log('Other Party Review component created')
    this.submission = this.$store.state.submission;
  }

  isInjuryInformationVisible(party: Party) {
    if(party.hasDriverInfo) {
      if(party.driver.sustainInjury === 'Yes') {
        return true
      }
    }
    return false;
}


  editOtherPartyOnClick() {
    this.uiUtils.navigate('other-party');
  }

}
</script>

<style scoped lang="scss">

</style>

