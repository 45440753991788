<template>
  <div data-cy="policy-reset-modal">
    <v-dialog v-model="this.$store.state.showPolicyResetModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px">
      <v-card style="overflow-x: hidden" data-cy="policy-reset-modal">
        <v-row justify="end">
          <v-col style="text-align: right" class="mr-4 py-0 col">
            <span class="exit-policy-reset-button" @click="cancelButton" data-cy="policy-reset-x">x</span>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-icon data-cy="policy-reset-icon" class="reset-icon">mdi-alert</v-icon>
        </v-row>
        <v-card-text class="pb-0 pt-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0">
                <span style="color: #003366" data-cy="policy-reset-body">
                  Updating the date of loss or policy will clear and reset all data entered previously for this claim.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row justify="end" class="pr-8">
          <v-card-actions class="pb-8">
            <v-btn depressed text color="primary" outlined data-cy="policy-reset-cancel" @click="cancelButton">Cancel</v-btn>
          </v-card-actions>
          <v-card-actions class="pb-8">
            <v-btn depressed color="primary" data-cy="policy-reset-proceed" @click="proceedButton">Proceed</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {CustomerInformation} from "@/models/CustomerInformation";
import {LossInformation} from "@/models/LossInformation";
import {OtherParty} from "@/models/OtherParty";
import {AdditionalContacts} from "@/models/AdditionalContacts";
import {AdditionalInformation} from "@/models/AdditionalInformation";

@Component
export default class PolicyResetModal extends Vue {

  @Prop()
  formattedDateOfLoss: string;

  @Prop()
  submission: FnolSubmission;

  created() {
    console.log("policy reset modal created")
  }

  proceedButton() {
    this.$store.state.showPolicyResetModal = false;
    this.$store.state.policyResetProceed = true;
    this.$store.state.hasBeenPassedGettingStarted = false;
    this.submission.customerInformation = new CustomerInformation()
    this.submission.lossInformation = new LossInformation()
    this.submission.otherParty = new OtherParty()
    this.submission.additionalContacts = new AdditionalContacts()
    this.submission.additionalInformation = new AdditionalInformation()
    this.$forceUpdate()
  }

  cancelButton() {
    this.$store.state.showPolicyResetModal = false;
    this.$store.state.policyResetProceed = false;
    this.submission.gettingStarted.dateOfLoss = this.$store.state.oldDateOfLoss;
  }
}
</script>

<style lang="scss" scoped>

.exit-policy-reset-button {
  background-color: white;
  font-size: 35px;
  font-weight: bold;
  color: #979797;
  border-radius: 30px;
  width: 50px;
  height: 50px;
}

.reset-icon {
  color: #FFB400;
  font-size: 36px;
}

</style>
