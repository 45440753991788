<template>
  <v-col data-cy="additional-contacts-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>

      <v-col class="px-8">
        <v-row v-if="$vuetify.breakpoint.mdAndUp" style="display: flex; justify-content: space-around">
          <v-col>
            <div>
              <span data-cy="additional-contacts-label" class="page-label berninaSansCompressedFont col-10">Additional Contacts</span>
            </div>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{submission.reportNumber}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-expansion-panels multiple accordion>
              <v-expansion-panel class="mobile-expansion-panel"  data-cy="witness-expansion-panel">
                <v-expansion-panel-header data-cy="witness-information-header">
                  <div class="berninaSansCompressedFont collapse-header">Witness Information</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content id="witness-expansion-panel-content" eager>
                      <AdditionalContactsWitnessComponent v-if="this.submission.additionalContacts.witnesses.length > 0"/>
                      <v-btn :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''" class="outlined-button" outlined data-cy="add-another-witness-cta" @click="addWitnessOnClick">
                        <span><v-icon>mdi-plus</v-icon>{{ submission.additionalContacts.witnesses.length > 0 ? 'Add Another Witness' : 'Add Witness' }}</span>
                      </v-btn>
                </v-expansion-panel-content>

              </v-expansion-panel>

              <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="medical-provider-expansion-panel">
                <v-expansion-panel-header data-cy="medical-provider-information-header">
                  <div class="berninaSansCompressedFont collapse-header">Medical Providers Information</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content id="medical-provider-expansion-panel-content" eager>
                      <MedicalProviderComponent v-if="this.submission.additionalContacts.medicalProviders.length > 0"/>
                      <v-btn :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''" class="outlined-button" outlined data-cy="add-another-medical-provider-cta" color="#00274e" @click="addProviderOnClick">
                        <span><v-icon>mdi-plus</v-icon>Add Medical Provider</span>
                      </v-btn>
                </v-expansion-panel-content>

              </v-expansion-panel>

              <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="police-expansion-panel">
                <v-expansion-panel-header data-cy="police-information-header">
                  <div class="berninaSansCompressedFont collapse-header">Fire/Police/EMT Information</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content id="police-expansion-panel-content" eager>
                  <FirePoliceEmtComponent v-if="this.submission.additionalContacts.firePoliceEMTs.length > 0"/>
                  <v-btn :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''" class="outlined-button" outlined data-cy="add-another-police-cta" @click="addPoliceOnClick">
                    <span><v-icon>mdi-plus</v-icon>Add Fire/Police/EMT</span>
                  </v-btn>
                </v-expansion-panel-content>

              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col :style="$vuetify.breakpoint.smAndDown ? 'padding: 0px .25em 12px .25em' : ''">
            <v-card class="card-background" elevation="0" >
              <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'" class="input-spacing">
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="navigateTo(getPreviousPageRoute())" data-cy="previous-button" outlined class="previous-button">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>

                <v-btn @click="navigateTo('additional-information')" data-cy="next-button" class="next-button">
                  <span>Next</span><v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-row>

              <v-row justify="center" v-if="$vuetify.breakpoint.smAndDown">
                <v-btn @click="navigateTo(getPreviousPageRoute())" data-cy="previous-button" outlined class="previous-button">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>
              </v-row>

              <v-row justify="center" v-if="$vuetify.breakpoint.smAndDown" >
                <v-btn data-cy="mobile-cancel-button" @click="CancelClaimModalOnClick" outlined class="previous-button">
                  <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission"
                      v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import AdditionalContactsWitnessComponent from "@/views/components/AdditionalContactsWitnessComponent.vue";
import {Witness} from "@/models/Witness";
import {UiUtils} from "@/utils/ui-utils";
import MedicalProviderComponent from "@/views/components/MedicalProviderComponent.vue";
import {MedicalProvider} from "@/models/MedicalProvider";
import {FirePoliceEMT} from "@/models/FirePoliceEMT";
import FirePoliceEmtComponent from "@/views/components/FirePoliceEmtComponent.vue";
import {ValidationUtils} from "@/utils/validation-utils";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";

@Component({
  components: {
    BrowserBackButtonModal,
    FirePoliceEmtComponent, MedicalProviderComponent, AdditionalContactsWitnessComponent, SideStepperComponent, TimeoutModal, CancelClaimModal
  }
})

export default class AdditionalContactsPage extends Vue {

  submission: FnolSubmission;
  uiUtils: UiUtils = new UiUtils();
  validationUtils: ValidationUtils = new ValidationUtils();

  created() {
    this.submission = this.$store.state.submission;
    console.log('additional contacts page created')
  }


  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  applicationTimeoutTimer () {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  addWitnessOnClick() {
    this.submission.additionalContacts.witnesses.push(new Witness());
    console.log("Witness added");
  }

  addProviderOnClick() {
    this.submission.additionalContacts.medicalProviders.push(new MedicalProvider());
    console.log("Provider added");
  }

  addPoliceOnClick() {
    this.submission.additionalContacts.firePoliceEMTs.push(new FirePoliceEMT());
    console.log("Fire/Police/EMT added");
  }

  async triggerNonRequiredValidations() {

    if (this.submission.additionalContacts.witnesses.length == 0 ){
      this.$store.state.isWitnessComponentValid = true;
    }else{
      for(let index = 0; index < this.submission.additionalContacts.witnesses.length; index++)
      {
        await this.validationUtils.triggerBlurs(["witness-first-name-input-" + index, "witness-last-name-input-" + index])
      }
    }

    if (this.submission.additionalContacts.medicalProviders.length == 0){
      this.$store.state.isMedicalComponentValid = true;
    }else{
      for(let index = 0; index < this.submission.additionalContacts.medicalProviders.length; index++)
      {
        await this.validationUtils.triggerBlurs(['medical-provider-fullname-input-' + index])
      }
    }

    if(this.submission.additionalContacts.firePoliceEMTs.length == 0){
      this.$store.state.isFirePoliceEMTComponentValid = true;
    }else{
       for (let index = 0; index < this.submission.additionalContacts.firePoliceEMTs.length; index++) {
         await this.validationUtils.triggerBlurs(['police-fullname-input-' + index])
         await this.validationUtils.triggerBlurs(['police-type-select-' + index])
       }
    }

    if (!this.$store.state.isWitnessComponentValid) {
      this.uiUtils.openPanelContent("witness-expansion-panel-content")
    }
    if (!this.$store.state.isMedicalComponentValid) {
      this.uiUtils.openPanelContent("medical-provider-expansion-panel-content")
    }
    if (!this.$store.state.isFirePoliceEMTComponentValid) {
      this.uiUtils.openPanelContent("police-expansion-panel-content")
    }
  }


  trimAdditionalContacts() {
    this.submission.additionalContacts.witnesses.forEach((witness) => {
      witness.firstName = this.uiUtils.testForTrim(witness.firstName)
      witness.lastName = this.uiUtils.testForTrim(witness.lastName)
      witness.primaryEmail = this.uiUtils.testForTrim(witness.primaryEmail)
      witness.location.addressLine1 = this.uiUtils.testForTrim(witness.location.addressLine1)
      witness.location.addressLine2 = this.uiUtils.testForTrim(witness.location.addressLine2)
      witness.location.city = this.uiUtils.testForTrim(witness.location.city)
      witness.notes = this.uiUtils.testForTrim(witness.notes)
    })
    this.submission.additionalContacts.medicalProviders.forEach((provider) => {
      provider.fullName = this.uiUtils.testForTrim(provider.fullName)
      provider.primaryEmail = this.uiUtils.testForTrim(provider.primaryEmail)
      provider.location.addressLine1 = this.uiUtils.testForTrim(provider.location.addressLine1)
      provider.location.addressLine2 = this.uiUtils.testForTrim(provider.location.addressLine2)
      provider.location.city = this.uiUtils.testForTrim(provider.location.city)
      provider.notes = this.uiUtils.testForTrim(provider.notes)
    })
    this.submission.additionalContacts.firePoliceEMTs.forEach((police) => {
      police.fullName = this.uiUtils.testForTrim(police.fullName)
      police.primaryEmail = this.uiUtils.testForTrim(police.primaryEmail)
      police.location.addressLine1 = this.uiUtils.testForTrim(police.location.addressLine1)
      police.location.addressLine2 = this.uiUtils.testForTrim(police.location.addressLine2)
      police.location.city = this.uiUtils.testForTrim(police.location.city)
      police.notes = this.uiUtils.testForTrim(police.notes)
      police.reportNumber = this.uiUtils.testForTrim(police.reportNumber)
    })
  }

  async navigateTo(route: string) {
    this.trimAdditionalContacts();
    await this.triggerNonRequiredValidations()
    if (this.$store.state.isWitnessComponentValid &&
        this.$store.state.isMedicalComponentValid &&
        this.$store.state.isFirePoliceEMTComponentValid) {

      this.$store.state.showValidationSnackbar = false
      this.$store.state.idleVue.idleTime = 1740000
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    } else {
      this.$store.state.showValidationSnackbar = true
    }
  }

  private getPreviousPageRoute(): string {
      let route: string;
      if (this.submission.lossInformation.lossType === 'Auto') {
        route = 'other-party'
      } else if (this.submission.lossInformation.lossType === 'Property') {
        route = 'property-loss-information'
      } else if (this.submission.lossInformation.lossType === 'Cargo') {
        route = 'cargo-loss-information'
      } else if (this.submission.lossInformation.lossType === 'Liability') {
        route = 'liability-loss-information'
      }
      else if (this.submission.lossInformation.lossType === null){
          route = 'loss-information'
      }
      return route;
    }

}
</script>

<style lang="scss" scoped>


</style>
