<template>
  <v-container class="failed-to-upload-modal-container" data-cy="failed-to-upload-modal-container">

    <v-dialog v-model="this.$store.state.showFailedToUploadModal" max-width="500" persistent overlay-opacity="0.7" overlay-color="#00172F">

      <!-- Start Over modal content -->
      <v-card data-cy="failed-to-upload-modal-content">
        <v-card-title class="py-3 modal-header-container">
          <span class="text-semi-bold fs-18 modal-header" data-cy="failed-to-upload-header">{{ failureReason }}</span>
        </v-card-title>
        <v-col cols="12" class="pa-0">
          <v-divider color="#E6E9ED" style="opacity: 50%"/>
        </v-col>
        <v-card-text class="pt-3">
          <p class="text-light fs-14 modal-text" data-cy="failed-to-upload-message">{{ getFailedReasonMessage() }}</p>
        </v-card-text>
        <!-- Modal buttons -->
          <v-btn
              class="next-button ok-button"
              @click="onModalClose"
              depressed outlined text
              data-cy="modal-close-button">OK
          </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script lang="ts">
import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class FailedToUploadModal extends Vue {

  @Prop()
  lastReasonOfUploadFailure: string

  failureReason: string;

  created() {
    this.failureReason = this.lastReasonOfUploadFailure;
  }

  onModalClose() {
    this.$store.state.showFailedToUploadModal = false
  }

  getFailedReasonMessage(): string {
    if (this.failureReason === 'File is Too Large') {
      return 'The selected file is too large. Selected file must be smaller than 8MB.'
    }

    if (this.failureReason === 'File Type Not Allowed') {
      return 'The file you are attempting to upload does not meet our allowed file types.'
    }

    if(this.failureReason === 'File Size Limit Reached') {
      return 'You have exceeded the file size limit. Please stay below 33MB.'
    }

    if(this.failureReason === 'File is Too Small') {
      return 'The selected file is invalid file (0KB). Selected need to be larger than 0KB and smaller than 8MB.'
    }
  }

}

</script>

<style lang="scss" scoped>

button.v-btn.v-size--default {

  letter-spacing: normal;
  text-transform: none;

  &.nav-button {
    font-weight: bold;
    font-size: 18px;
    min-width: 240px;
    height: 42px;
  }

  &.modal-button {
    font-weight: bold;
    font-size: 14px;
    height: 28px;
  }

  &.primary-button {
    color: white;
    float: right;
  }

  &.secondary-button {
    border-color: blue;
  }

  &.tertiary-button {
    border-color: gray;
  }
}

.button-row {
  padding: 16px 10%
}

.v-dialog > .v-card > .v-card__title {
  letter-spacing: normal;
  line-height: normal;
  padding: 23px 22px 5px 22px
}

.v-dialog > .v-card > .v-card__text {
  letter-spacing: normal;
  line-height: normal;
  padding: 0 22px
}

.remove-file-icon {
  float: inside;
  cursor: pointer;
}

.failed-to-upload-modal-container {
  display: inline;
}

</style>

