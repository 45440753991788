import {CustomerInformation} from "@/models/CustomerInformation";
import {UiUtils} from "./ui-utils"

const uiUtils = new UiUtils()

export class ContactCorrectionUtil {

    correctContacts(customerInformation: CustomerInformation) {
        const insured = customerInformation.insured;
        const claimReporter = customerInformation.claimReporter;
        const primaryContact = customerInformation.primaryContact;

            if (claimReporter && claimReporter.isSameAddressAsInsured) {
                uiUtils.addressSameAsToggle(claimReporter.location, insured.location, claimReporter.isSameAddressAsInsured)
            }

            if (primaryContact) {
                if (primaryContact.isSamePersonAsClaimReporter) {
                    uiUtils.contactSameAsToggle(primaryContact, claimReporter, primaryContact.isSamePersonAsClaimReporter)
                    primaryContact.isSameAddressAsInsured = false;
                    primaryContact.relationToInsured = claimReporter.relationToInsured
                    primaryContact.relationToInsuredCode = claimReporter.relationToInsuredCode
                }
                if (primaryContact.isSameAddressAsInsured) {
                    uiUtils.addressSameAsToggle(primaryContact.location, insured.location, primaryContact.isSameAddressAsInsured)
                    primaryContact.isSamePersonAsClaimReporter = false;
                }
            }

        customerInformation.claimReporter = claimReporter
        customerInformation.primaryContact = primaryContact
        return customerInformation
    }

    handleSameClaimReporterAddressAsInsured(customerInformation : CustomerInformation) {
        if (customerInformation.claimReporter.isSameAddressAsInsured) {
            this.correctContacts(customerInformation)
        } else {
            uiUtils.addressSameAsToggle(customerInformation.claimReporter.location, null, false)
            if (customerInformation.primaryContact.isSamePersonAsClaimReporter) {
                uiUtils.addressSameAsToggle(customerInformation.primaryContact.location, null, false)
            }
        }
    }

    handleSameAsClaimReporterContact(customerInformation : CustomerInformation){
        if (customerInformation.primaryContact.isSamePersonAsClaimReporter) {
            this.correctContacts(customerInformation)
        } else {
            customerInformation.primaryContact.relationToInsured = null
            customerInformation.primaryContact.relationToInsuredCode = null
            uiUtils.contactSameAsToggle(customerInformation.primaryContact, null, false)
        }
        return customerInformation
    }

    handleSamePrimaryContactAddressAsInsured(customerInformation : CustomerInformation){
        if (customerInformation.primaryContact.isSameAddressAsInsured) {
            this.correctContacts(customerInformation)
        } else {
            uiUtils.addressSameAsToggle(customerInformation.primaryContact.location, null, false)
        }
    }

}
