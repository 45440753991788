import axios, {AxiosError, AxiosResponse} from 'axios'
import {FileUploadResponse} from "@/documentUpload/FileUploadResponse";
import {axiosInstance} from "@/utils/axios-instance";


export class DocumentUploadClient {

    private API_FILE_UPLOAD_URL = '/fnol/files/upload'
    private API_FILE_REMOVE_URL = '/fnol/files/remove'


    async uploadFile(file: File, submissionId : string): Promise<FileUploadResponse | void> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("submissionId", submissionId)

        return await axiosInstance.request<FileUploadResponse>({
            timeout: 60000,
            method: 'put',
            url: this.API_FILE_UPLOAD_URL,
            data: formData,
            responseType: 'json',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response: AxiosResponse<FileUploadResponse>) => {

            if (response?.status == 200) {
                return response.data
            }

        }).catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data)
                //TODO HANDLE CLIENT UNEXPECTED ERRORS
            } else {
                console.error(error)
            }
            // For now, re-throw the same error
            // TODO: In the future, throw whatever error makes sense
            throw error
        });
    }

    async removeFile(filePath: string): Promise<string | void> {

        return await axiosInstance.request<string>({
            timeout: 10000,
            method: 'delete',
            url: this.API_FILE_REMOVE_URL,
            data: filePath,
            responseType: 'json',
            headers: {
                'Content-Type': 'text/plain'
            }
        }).then((response: AxiosResponse<string>) => {

            if (response?.status == 204) {
                return response.data
            }

        }).catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data)
                //TODO HANDLE CLIENT UNEXPECTED ERRORS
            } else {
                console.error(error)
            }
        });
    }

}
