<template>
        <v-row data-cy="property-owner-component-vue">
          <v-col>
            <v-card class="card-background" elevation="0" >

              <v-row>
                <v-col class="input-spacing">
                  <div>
                    <span data-cy="property-owner-label" class="section-label berninaSansCompressedFont pl-8">Property Owner</span >
                  </div>
                </v-col>
              </v-row>

              <v-col class="input-spacing pl-8">
                <v-row>
                <v-col class="input-spacing">
                  <p data-cy="have-property-owner-info-label" class="label-text">DO YOU HAVE THE PROPERTY OWNER'S INFORMATION?</p>
                </v-col>
              </v-row>
                <v-row class="pb-md-4">
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '11' : '8'" lg="6" class="input-spacing" data-cy="have-property-owner-info-column">
                  <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                 data-cy="have-property-owner-info-radio-group"
                                 class="radio-container"
                                 v-model="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo">
                      <v-radio
                          label="Yes"
                          :value=true
                          @change="onDoesHavePropertyOwnerInfo(true)"
                          data-cy="have-property-owner-info-radio-yes"
                      ></v-radio>
                      <v-radio
                          label="No"
                          :value=false
                          data-cy="have-property-owner-info-radio-no"
                          @change="onDoesHavePropertyOwnerInfo(false)"
                      ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

                <div v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo">
                <v-row class="toggle-spacing" v-if="submission.customerInformation.insured.fullName">
                  <v-col md="8" lg="6" class="input-spacing" data-cy="property-owner-same-as-insured-column">
                    <v-row>
                      <v-switch
                          inset
                          data-cy="property-owner-same-as-insured-switch"
                          class="v-switch-label"
                          label="Is the property owner the same as the insured?"
                          dense
                          v-model="submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured"
                          @change="propertyOwnerAddressSameAsInsuredOnChange()"/>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="toggle-spacing">
                  <v-col md="8" lg="6" class="input-spacing"  data-cy="property-owner-same-as-primary-contact-column">
                    <v-row>
                      <v-switch
                          inset
                          data-cy="property-owner-same-as-primary-contact-switch"
                          class="v-switch-label"
                          label="Is the property owner the same as the primary contact?"
                          dense
                          v-model="submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact"
                          @change="propertyOwnerAddressSameAsPrimaryContactOnChange()"/>
                    </v-row>
                  </v-col>
                </v-row>

                <v-form autocomplete="off" v-model="$store.state.isPropertyOwnerComponentValid">
                <v-row class="pl-8">
                  <v-col md="8" lg="6" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                      <p data-cy="property-owner-name-label" class="label-text">PROPERTY OWNER<span class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>
                    <v-row class="input-spacing" data-cy="property-owner-name-column">
                      <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing">
                      <div class="rs-values pb-3" data-cy="property-owner-name-text" v-if="submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured
                       || submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact"
                      >{{ submission.lossInformation.propertyLossInformation.propertyOwner.fullName }}</div>
                      <v-text-field
                          v-else
                          data-cy="property-owner-name-input"
                          id="property-owner-name-field"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          v-model="submission.lossInformation.propertyLossInformation.propertyOwner.fullName"
                          counter="100"
                          maxLength="100"
                          :rules="propertyOwnerFullNameValidationRules"
                          @input="propertyOwnerFullNameValidationOnInput"
                          @blur="propertyOwnerFullNameValidationOnInput"/>
                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col sm="12" md="4" lg="3" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                      <p data-cy="property-email-label" class="label-text">EMAIL</p>
                      </v-col>
                    </v-row>
                    <v-row class="input-spacing" data-cy="property-email-column">
                      <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing">
                      <div class="rs-values pb-5" data-cy="property-email-text" v-if="submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured
                      || submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact"
                      >{{ submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail }}</div>
                      <v-text-field
                          v-else
                          data-cy="property-email-input"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          counter="50"
                          maxLength="50"
                          v-model="submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail"
                          :rules="propertyOwnerEmailValidationRules"
                          @input="propertyOwnerEmailValidationOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" md="4" lg="3" class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                        <p data-cy="property-phone-label" class="label-text">PHONE</p>
                        </v-col>
                      </v-row>
                      <v-row class="input-spacing" data-cy="property-phone-column">
                        <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing">
                        <div class="rs-values" data-cy="property-phone-text" v-if="submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured
                         || submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact"
                        >{{ submission.lossInformation.propertyLossInformation.propertyOwner.primaryPhone }}</div>
                        <v-text-field
                            v-else
                            data-cy="property-phone-input"
                            dense
                            outlined
                            clearable
                            background-color="white"
                            v-mask="'(###) ###-####'"
                            v-model="submission.lossInformation.propertyLossInformation.propertyOwner.primaryPhone"
                            :rules="propertyOwnerPhoneValidationRules"
                            @input="propertyOwnerPhoneOnInput()"
                            @blur="propertyOwnerPhoneOnInput"/>
                        </v-col>
                      </v-row>
                  </v-col>
                </v-row>

                  <div v-if="submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured
                    || submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact" data-cy="property-owner-address-read-only">
                    <v-row class="pl-8">
                      <v-col cols="12">
                        <div class="label-text" data-cy="property-owner-address-label">PROPERTY OWNER ADDRESS</div>
                        <div class="rs-values" data-cy="property-owner-address-line-1-text">{{ submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1 }}</div>
                        <div class="rs-values" data-cy="property-owner-address-line-2-text">{{ submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2 }}</div>
                        <div class="rs-values" data-cy="property-owner-city-state-zip-text">{{ submission.lossInformation.propertyLossInformation.propertyOwner.location.city }}, {{ submission.lossInformation.propertyLossInformation.propertyOwner.location.state }} {{ submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode }}</div>
                        <div class="rs-values" data-cy="property-owner-country-text">{{ submission.lossInformation.propertyLossInformation.propertyOwner.location.country }}</div>
                      </v-col>
                    </v-row>
                  </div>


                  <div v-else>

                    <v-row class="pl-8">
                      <v-col md="8" lg="6" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                          <p data-cy="property-address-line-1-label" class="label-text">ADDRESS LINE 1</p>
                          </v-col>
                        </v-row>
                        <v-row class="input-spacing" >
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing" data-cy="property-address-line-1-column">
                          <v-text-field
                              data-cy="property-address-line-1-input"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              counter="60"
                              maxLength="60"
                              v-model="submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1"
                              :rules="propertyOwnerAddressLine1ValidationRules"
                              @input="propertyOwnerAddressLine1ValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="pl-8">
                      <v-col md="8" lg="6" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                          <p data-cy="property-address-line-2-label" class="label-text">ADDRESS LINE 2</p>
                          </v-col>
                        </v-row>
                        <v-row class="input-spacing">
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing" data-cy="property-address-line-2-column">
                          <v-text-field
                              data-cy="property-address-line-2-input"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              counter="60"
                              maxLength="60"
                              v-model="submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2"
                              :rules="propertyOwnerAddressLine2ValidationRules"
                              @input="propertyOwnerAddressLine2ValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="pl-8">
                      <v-col md="4" lg="3" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                          <p data-cy="property-city-label" class="label-text">CITY</p>
                          </v-col>
                        </v-row>
                        <v-row class="input-spacing" >
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing" data-cy="property-city-column">
                          <v-text-field
                              data-cy="property-city-input"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              counter="60"
                              maxLength="60"
                              v-model="submission.lossInformation.propertyLossInformation.propertyOwner.location.city"
                              :rules="propertyOwnerCityValidationRules"
                              @input="propertyOwnerCityValidationOnInput()"/>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="4" lg="3" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="property-state-label" class="label-text">STATE</p>
                          </v-col>
                        </v-row>
                        <v-row class="pt-0 pb-0">
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing" data-cy="property-state-column">
                          <v-autocomplete
                              data-cy="property-state-input"
                              id="property-state-input"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              :items="statesList"
                              @blur="uiUtils.triggerEnterKeyPress('property-state-input')"
                              @input="propertyOwnerStateOnSelect"
                              v-model="submission.lossInformation.propertyLossInformation.propertyOwner.location.state"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="pl-8">
                      <v-col md="4" lg="3" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="property-zip-label" class="label-text">ZIP CODE</p>
                          </v-col>
                        </v-row>
                        <v-row class="input-spacing" >
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing" data-cy="property-zip-column">
                          <v-text-field
                              data-cy="property-zip-input"
                              dense
                              outlined
                              v-mask="['#####' ,'#####-####']"
                              clearable
                              background-color="white"
                              v-model="submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode"
                              :rules="propertyOwnerZipCodeValidationRules"
                              @input="propertyOwnerZipCodeValidationOnInput()"/>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="4" lg="3" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="property-country-label" class="label-text">COUNTRY</p>
                          </v-col>
                        </v-row>
                        <v-row class="input-spacing">
                          <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing" data-cy="property-country-column">
                          <v-autocomplete
                              data-cy="property-country-input"
                              :items="countriesList"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              @input="propertyOwnerCountryOnSelect"
                              v-model="submission.lossInformation.propertyLossInformation.propertyOwner.location.country"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                  </div>
                </v-form>
                <!-- END -->

              </div>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {FnolSubmission} from "@/models/FnolSubmission";
  import {States} from "@/models/typelists/States";
  import {Countries} from "@/models/typelists/Countries";
  import {UiUtils} from "@/utils/ui-utils";
  import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
  import {PropertyOwner} from "@/models/PropertyOwner";

  @Component
export default class PropertyOwnerComponent extends Vue {

  submission: FnolSubmission;
  statesList: Array<string> =Object.values(States);
  countriesList: Array<string> =Object.values(Countries);
  uiUtils= new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  propertyOwnerEmailValidationRules: Array<string> = [];
  propertyOwnerPhoneValidationRules: Array<string> = [];
  propertyOwnerAddressLine1ValidationRules: Array<string> = [];
  propertyOwnerAddressLine2ValidationRules: Array<string> = [];
  propertyOwnerCityValidationRules: Array<string> = [];
  propertyOwnerZipCodeValidationRules: Array<string> = [];
  propertyOwnerFullNameValidationRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;

    if (this.submission.lossInformation.propertyLossInformation != null &&
        this.submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo == null) {
      this.submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo = false;
    }
    // Check for changes on PrimaryContact and Insured. Reload if necessary
    if (this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured) {
      if (this.submission.customerInformation.insured.fullName == null) {
        this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured = false
      }
        this.propertyOwnerAddressSameAsInsuredOnChange()
    }
    if(this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact) {
      this.propertyOwnerAddressSameAsPrimaryContactOnChange()
    }
  }

  propertyOwnerAddressSameAsInsuredOnChange() {
    if (this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured == true) {
      if (this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact) {
        this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact = false;
      }
      this.submission.lossInformation.propertyLossInformation.propertyOwner = new PropertyOwner();
      this.submission.lossInformation.propertyLossInformation.propertyOwner.fullName = this.submission.customerInformation.insured.fullName;
      this.uiUtils.addressSameAsToggle(this.submission.lossInformation.propertyLossInformation.propertyOwner.location,
          this.submission.customerInformation.insured.location,
          this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured)
    } else {
      if (this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact == false) {
        this.submission.lossInformation.propertyLossInformation.propertyOwner = new PropertyOwner();
      }
    }
  }

  propertyOwnerAddressSameAsPrimaryContactOnChange() {
    if(this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact == true) {
      console.log("setting PropertyOwner equal to Primary Contact")
      if(this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured) {
        this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured = false;
      }
      this.uiUtils.contactSameAsToggle(this.submission.lossInformation.propertyLossInformation.propertyOwner,
          this.submission.customerInformation.primaryContact,
          this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact)
      this.submission.lossInformation.propertyLossInformation.propertyOwner.fullName =
          `${ this.submission.lossInformation.propertyLossInformation.propertyOwner.firstName} ${ this.submission.lossInformation.propertyLossInformation.propertyOwner.lastName}`

    } else {
      if (!this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured) {
        console.log("Clearing Property Owner")
        this.submission.lossInformation.propertyLossInformation.propertyOwner = new PropertyOwner();
      }
    }
  }

  propertyOwnerCountryOnSelect(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.country)
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.country);
  }

  propertyOwnerStateOnSelect(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.state)
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.state);
  }

  propertyOwnerFullNameValidationOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.fullName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.propertyOwner.fullName)
    this.propertyOwnerFullNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false)
  }

  propertyOwnerEmailValidationOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail)
    this.propertyOwnerEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  propertyOwnerPhoneOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.primaryPhone = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.primaryPhone)
    this.propertyOwnerPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  propertyOwnerAddressLine1ValidationOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1)
    this.propertyOwnerAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  propertyOwnerAddressLine2ValidationOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2)
    this.propertyOwnerAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  propertyOwnerCityValidationOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.city =
        this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.city)
    this.propertyOwnerCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  propertyOwnerZipCodeValidationOnInput(){
    this.submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode =
        this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode)
    this.propertyOwnerZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  onDoesHavePropertyOwnerInfo(hasOwnerInfo: boolean) {
    if(hasOwnerInfo) {
      this.submission.lossInformation.propertyLossInformation.propertyOwner = new PropertyOwner();
      this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured = false
      this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact = false
    }else {
      if(this.submission.lossInformation.propertyLossInformation != null) {
        this.submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo = false;
        this.nullPropertyOwner();
      }
    }
  }

  nullPropertyOwner() {
    this.submission.lossInformation.propertyLossInformation.propertyOwner = null;
  }

}
</script>
<style>

</style>
