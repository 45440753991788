<template>
  <v-form v-model="$store.state.otherPartyPassengerInformationPageValid" autocomplete="off" class="card-background">
    <v-card :class="$vuetify.breakpoint.smAndDown ? 'px-2' : ''" class="card-background" elevation="0">
      <v-col v-for="(otherPassenger, index) in party.otherPassengers"
             :key="index"
             :data-cy="'passenger-vue-'+index">
        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col :data-cy="'passenger-label-'+index" class="berninaSansCompressedFont" cols="7" style="color: #036; font-size: 20px; font-weight: bold">
                Passenger {{ index + 1 }}
              </v-col>


            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'">
            <v-btn :id="'removeButton-'+index" :data-cy="'remove-passenger-button-' + index" class="outlined-button" style="width: 100%" outlined @click="showModal(index, party)">
              <span><v-icon>mdi-minus</v-icon>Remove Passenger</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-first-name-label-' + index" class="label-text">FIRST NAME<span class="req-asterisk">*</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-first-name-column-' + index" class="input-spacing">
                <v-text-field v-model="otherPassenger.firstName"
                              :data-cy="'passenger-first-name-input-' + index"
                              :rules="passengerFirstNameValidationRules"
                              background-color="white"
                              clearable
                              counter="30"
                              dense
                              maxLength="30"
                              outlined
                              @input="passengerFirstNameValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-last-name-label-' + index" class="label-text">LAST NAME<span class="req-asterisk">*</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-last-name-column-' + index" class="input-spacing">
                <v-text-field v-model="otherPassenger.lastName"
                              :data-cy="'passenger-last-name-input-' + index"
                              :rules="passengerLastNameValidationRules"
                              background-color="white"
                              clearable
                              counter="30"
                              dense
                              maxLength="30"
                              outlined
                              @input="passengerLastNameValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-email-label-' + index" class="label-text">EMAIL</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-email-column-' + index" class="input-spacing">
                <v-text-field
                    v-model="otherPassenger.primaryEmail"
                    :data-cy="'passenger-email-input-' + index"
                    :rules="passengerEmailValidationRules"
                    background-color="white"
                    clearable
                    counter="50"
                    dense
                    maxLength="50"
                    outlined
                    @input="passengerEmailValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-phone-label-' + index" class="label-text">PHONE</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-phone-column-' + index" class="input-spacing">
                <v-text-field
                    v-model="otherPassenger.primaryPhone"
                    v-mask="'(###) ###-####'"
                    :data-cy="'passenger-phone-input-' + index"
                    :rules="passengerPhoneValidationRules"
                    background-color="white"
                    clearable
                    dense
                    outlined
                    @input="passengerPhoneValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-home-address-1-label-' + index" class="label-text">ADDRESS LINE 1</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-home-address-1-column-' + index" class="input-spacing">
                <v-text-field v-model="otherPassenger.location.addressLine1"
                              :data-cy="'passenger-home-address-1-input-' + index"
                              :rules="passengerAddress1ValidationRules"
                              background-color="white"
                              clearable
                              counter="60"
                              dense
                              maxLength="60"
                              outlined
                              @input="passengerAddress1ValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-home-address-2-label-' + index" class="label-text">ADDRESS LINE 2</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-home-address-2-column-' + index" class="input-spacing">
                <v-text-field v-model="otherPassenger.location.addressLine2"
                              :data-cy="'passenger-home-address-2-input-' + index"
                              :rules="passengerAddress2ValidationRules"
                              background-color="white"
                              clearable
                              counter="60"
                              dense
                              maxLength="60"
                              outlined
                              @input="passengerAddress2ValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-city-label-' + index" class="label-text">CITY</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-city-column-' + index" class="input-spacing">
                <v-text-field
                    v-model="otherPassenger.location.city"
                    :data-cy="'passenger-city-input-' + index"
                    :rules="passengerCityValidationRules"
                    background-color="white"
                    clearable
                    counter="60"
                    dense
                    maxLength="60"
                    outlined
                    @input="passengerCityValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-state-label-' + index" class="label-text">STATE</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-state-column-' + index" class="input-spacing">
                <v-autocomplete
                    :id="'passenger-state-select-' + index"
                    v-model="otherPassenger.location.state"
                    :data-cy="'passenger-state-select-' + index"
                    :items="statesList"
                    autocomplete="new-password"
                    background-color="white"
                    dense
                    outlined
                    clearable
                    @blur="uiUtils.triggerEnterKeyPress('passenger-state-select-' + index)"
                    @input="otherPassengerStateOnSelect(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-zip-code-label-' + index" class="label-text">ZIP CODE</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-zip-code-column-' + index" class="input-spacing">
                <v-text-field
                    v-model="otherPassenger.location.postalCode"
                    v-mask="['#####' ,'#####-####']"
                    :data-cy="'passenger-zip-code-input-' + index"
                    :rules="passengerZipCodeValidationRules"
                    background-color="white"
                    clearable
                    dense
                    outlined
                    @input="passengerZipCodeValidationOnInput(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-country-label-' + index" class="label-text">COUNTRY</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-country-column-' + index" class="input-spacing">
                <v-autocomplete
                    v-model="otherPassenger.location.country"
                    :data-cy="'passenger-country-select-' + index"
                    :items="countriesList"
                    autocomplete="new-password"
                    background-color="white"
                    clearable
                    dense
                    outlined
                    @input="passengerCountryOnSelect(otherPassenger)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row data-cy="first vrow">
          <v-col data-cy="column">
            <v-row data-cy="second-v-row">
              <v-col class="input-spacing" data-cy="second-col">
                <p :data-cy="'passenger-injury-from-accident-label-' + index" class="label-text">DID THE PASSENGER SUSTAIN AN INJURY IN THE ACCIDENT?</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="third-col">
                <v-radio-group v-model="otherPassenger.injuriesSustainedFromAccident"
                               :data-cy="'passenger-injury-from-accident-radio-group-' + index"
                               :row="!$vuetify.breakpoint.smAndDown"
                               class="radio-container">
                  <v-radio
                      :data-cy="'passenger-sustain-injury-radio-yes-' + index"
                      label="Yes"
                      value="Yes"
                  ></v-radio>
                  <v-radio
                      :data-cy="'passenger-sustain-injury-radio-no-' + index"
                      label="No"
                      value="No"
                      @change="onNoInjuriesSustained(otherPassenger)"
                  ></v-radio>
                  <v-radio
                      :data-cy="'passenger-sustain-injury-radio-unsure-' + index"
                      label="I'm unsure"
                      value="I'm unsure"
                      @change="onNoInjuriesSustained(otherPassenger)"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="otherPassenger.injuriesSustainedFromAccident === 'Yes'">
          <v-col class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p :data-cy="'passenger-injury-information-label-' + index" class="label-text">INJURY INFORMATION</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :data-cy="'passenger-injury-information-column-' + index" class="input-spacing">
                <v-textarea id="injury-information"
                            v-model="otherPassenger.injuryInformationDescription"
                            :data-cy="'passenger-injury-information-input-' + index"
                            :maxLength="950"
                            :rules="passengerInjuryInformationRules"
                            auto-grow
                            autocomplete="off"
                            background-color="white"
                            clearable
                            counter="950"
                            outlined
                            @input="passengerInjuryInformationOnInput(otherPassenger)">
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- START:   Delete Passenger Dialog   -->
        <v-dialog v-model="$store.state.showRemovePassengerModal" :data-cy="'remove-passenger-dialog-'+index" max-width="500px" overlay-color="#00172F" overlay-opacity="0.7" persistent>
          <v-card v-if="$store.state.showRemovePassengerModal" :data-cy="'remove-passenger-card-'+index" color="#f2f4f6" style="overflow-x: hidden; border-radius: 12px">
            <v-row justify="end">
              <v-col class="mr-4 py-0 col" style="text-align: right">
                <span :data-cy="'remove-passenger-x-'+index" class="exit-remove-button" @click="noButton">x</span>
              </v-col>
            </v-row>
            <v-card-title class="py-0">
              <span :data-cy="'remove-passenger-header-'+index" class="headline" style="color: #003366; font-size: 24px; font-weight: 500">Remove Passenger</span>
            </v-card-title>
            <v-card-text class="pb-0 pt-0">
              <v-container>
                <v-row>
                  <v-col class="pb-0 pt-0" cols="12">
                    <p :data-cy="'remove-passenger-text-'+index" class="modal-text"
                    >Are you sure you want to remove the Passenger?<br>
                      <span style="font-size:18px">{{ $store.state.currentPassengerFullName }}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-row class="pr-8" justify="end">
              <v-card-actions class="pb-8">
                <v-btn :data-cy="'remove-passenger-yes-'+index" color="primary" depressed
                       @click="yesButton($store.state.currentPassengerIndex, $store.state.currentParty)">Yes
                </v-btn>
              </v-card-actions>
              <v-card-actions class="pb-8">
                <v-btn :data-cy="'remove-passenger-no-'+index" color="primary" depressed outlined text @click="noButton">No</v-btn>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- END:   Delete Passenger Dialog   -->

      </v-col>

      <v-row data-cy="add-passenger-column">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'">
          <v-btn class="previous-button ml-3" data-cy="add-passenger-button" outlined @click="addOtherPassenger(party)">
            <span><v-icon>mdi-plus</v-icon>{{ addPassengerText(party) }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-divider class="center-on-small-and-down"></v-divider>
      </v-row>


    </v-card>
  </v-form>

</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {States} from "@/models/typelists/States";
import {UiUtils} from "@/utils/ui-utils";
//import {OtherPassenger} from "@/models/OtherPassenger";
import {Countries} from "@/models/typelists/Countries";
import {Party} from "@/models/Party";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {OtherPassenger} from "@/models/OtherPassenger";

@Component({})
export default class OtherPartyPassengerComponent extends Vue {

  @Prop()
  party: Party;

  @Prop()
  partyIndex: number;

  uiUtils = new UiUtils();
  statesList: Array<string> = this.getStatesList();
  countriesList: Array<string> = this.getCountriesList();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();

  passengerInjuryInformationRules: Array<string> = [];
  passengerFirstNameValidationRules: Array<string> = [];
  passengerLastNameValidationRules: Array<string> = [];
  passengerEmailValidationRules: Array<string> = [];
  passengerPhoneValidationRules: Array<string> = [];
  passengerAddress1ValidationRules: Array<string> = [];
  passengerAddress2ValidationRules: Array<string> = [];
  passengerCityValidationRules: Array<string> = [];
  passengerZipCodeValidationRules: Array<string> = [];

  created() {
    this.$store.state.showRemovePassengerModal = false;
    this.$store.state.otherPartyPassengerInformationPageValid = false;
  }

  addOtherPassenger(party: Party) {
    party.otherPassengers.push(new OtherPassenger())
    this.$store.state.otherPartyPassengerInformationPageValid = false
  }

  addPassengerText(party: Party) {
    return party.otherPassengers.length > 0 ? 'Add Another Passenger' : 'Add A Passenger'
  }


  onNoInjuriesSustained(otherPassenger: OtherPassenger) {
    otherPassenger.injuryInformationDescription = null
  }

  showModal(theIndex: number, theParty: Party) {
    this.$store.state.currentPassengerIndex = theIndex
    this.$store.state.currentParty = theParty

    this.$store.state.currentPassengerFullName = ""
    if (theParty.otherPassengers[theIndex].firstName && theParty.otherPassengers[theIndex].lastName) {
      this.$store.state.currentPassengerFullName = theParty.otherPassengers[theIndex].firstName + " " + theParty.otherPassengers[theIndex].lastName
    }
    this.$store.state.showRemovePassengerModal = true;
  }

  yesButton(idx: number, pty: Party) {
    pty.otherPassengers.splice(idx, 1);
    if (pty.otherPassengers.length === 0) {
      this.$store.state.otherPartyPassengerInformationPageValid = true
    }
    this.$store.state.showRemovePassengerModal = false;
  }

  noButton() {
    this.$store.state.showRemovePassengerModal = false;
  }

  passengerFirstNameValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.firstName = this.uiUtils.checkIfEmpty(otherPassenger.firstName)
    this.passengerFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  passengerLastNameValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.lastName = this.uiUtils.checkIfEmpty(otherPassenger.lastName)
    this.passengerLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  passengerEmailValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.primaryEmail = this.uiUtils.checkIfEmpty(otherPassenger.primaryEmail)
    this.passengerEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  passengerPhoneValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.primaryPhone = this.uiUtils.testForTrim(otherPassenger.primaryPhone)
    this.passengerPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  passengerAddress1ValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.location.addressLine1 = this.uiUtils.checkIfEmpty(otherPassenger.location.addressLine1)
    this.passengerAddress1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  passengerAddress2ValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.location.addressLine2 = this.uiUtils.checkIfEmpty(otherPassenger.location.addressLine2)
    this.passengerAddress2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  passengerCityValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.location.city = this.uiUtils.checkIfEmpty(otherPassenger.location.city)
    this.passengerCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  passengerZipCodeValidationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.location.postalCode = this.uiUtils.testForTrim(otherPassenger.location.postalCode)
    this.passengerZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  getStatesList(): Array<string> {
    return Object.values(States);
  }

  getCountriesList(): Array<string> {
    return Object.values(Countries);
  }

  otherPassengerStateOnSelect(otherPassenger: OtherPassenger) {
    otherPassenger.location.state = this.uiUtils.testForTrim(otherPassenger.location.state);
    otherPassenger.location.stateCode = this.uiUtils.getStateCode(otherPassenger.location.state);
  }

  passengerCountryOnSelect(otherPassenger: OtherPassenger) {
    otherPassenger.location.country = this.uiUtils.testForTrim(otherPassenger.location.country);
    otherPassenger.location.countryCode = this.uiUtils.getCountryCode(otherPassenger.location.country);
  }

  passengerInjuryInformationOnInput(otherPassenger: OtherPassenger) {
    otherPassenger.injuryInformationDescription = this.uiUtils.checkIfEmpty(otherPassenger.injuryInformationDescription);
    this.passengerInjuryInformationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }

}

</script>

<style lang="scss" scoped>


</style>
