<template>
  <v-dialog
    v-model="$store.state.showTimeoutModalMessage"
    persistent
    width="600"
    data-cy="timeout-modal">
    <v-card>
      <v-card-title>
        <span>
          <label style="color: #00274e; padding-left: 12px" data-cy="timeout-modal-title" class="headline">Submission Timeout</label>
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <span data-cy="timeout-modal-text-1">Claim submission has been canceled due to inactivity.</span>
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-col cols="2">
              <v-btn depressed width="45" color="primary" @click="goToLandingPage" data-cy="timeout-ok-button">OK</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">

  import {Component, Prop, Vue} from "vue-property-decorator";
  import {FnolSubmission} from "@/models/FnolSubmission";
  import {Status} from "@/models/typelists/Status";
  import {UiUtils} from "@/utils/ui-utils";

  @Component
  export default class TimeoutModal extends Vue {

    @Prop()
    isIdle: boolean;

    @Prop()
    submission: FnolSubmission

    uiUtils= new UiUtils();
    saveSubmission = new FnolSubmission()

    async created() {
      this.saveSubmission = this.submission;
      if (this.saveSubmission != null && this.saveSubmission.submissionId != null) {
        this.$store.state.showTimeoutModalMessage = true;
        await this.$store.dispatch('saveSubmission', this.saveSubmission);
        console.log('Session timed out, submission ' + this.saveSubmission.reportNumber + ' has been saved')
      }
    }

    async goToLandingPage() {
      this.$store.state.showTimeoutModalMessage = false;
      if(this.submission.status != Status.SUBMITTED) {
        this.submission.status = Status.CANCELLED;
      }
      this.$store.dispatch('cancelSubmission', this.$store.state.submission).then(() => {
        this.uiUtils.navigate('landing-page');
      });
    }
  }
</script>
