<template>
  <v-col data-cy="liability-loss-information-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>
      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div>
              <span data-cy="liability-loss-information-label" class="page-label berninaSansCompressedFont">Liability Information</span>
            </div>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{submission.reportNumber}}</span>
          </v-col>
        </v-row>

        <v-form autocomplete="off" v-model="$store.state.liabilityLossInformationPageValid" >
          <v-row>
            <v-col>
              <v-card class="card-background" elevation="0">
                <v-row class="pl-8">
                  <v-card-title class="section-label berninaSansCompressedFont" data-cy="liability-details-label">Incident Details</v-card-title>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="liability-incident-on-property-label" class="label-text">DID THE INCIDENT OCCUR ON THE INSURED'S PROPERTY?</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-radio-group data-cy="liability-incident-on-property-radio-group"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-buttons-mobile-background"
                                     v-model="submission.lossInformation.liabilityLossInformation.incidentOccurOnInsuredProperty">
                        <v-radio
                            class="radio-buttons-loss-info-mobile-yes"
                            label="Yes"
                            :value=true
                            data-cy="liability-incident-on-property-radio-yes">
                        </v-radio>
                        <v-radio
                            class="radio-buttons-loss-info-mobile-no"
                            label="No"
                            :value=false
                            data-cy="liability-incident-on-property-radio-no"
                        ></v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">

                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="liability-incident-report-label" class="label-text">WAS THERE AN INCIDENT REPORT FILED WITH THE INSURED?</p>
                      </v-col>
                    </v-row>

                    <v-row >
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-radio-group data-cy="liability-incident-report-radio-group"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-buttons-mobile-background"
                                     v-model="submission.lossInformation.liabilityLossInformation.incidentReportFiledWithInsured">
                        <v-radio
                            class="radio-buttons-loss-info-mobile-yes"
                            label="Yes"
                            :value=true
                            data-cy="liability-incident-report-radio-yes"
                        ></v-radio>
                        <v-radio
                            class="radio-buttons-loss-info-mobile-no"
                            label="No"
                            :value=false
                            data-cy="liability-incident-report-radio-no"
                        ></v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="liability-incident-vehicles-label" class="label-text">WERE ANY VEHICLES OR EQUIPMENT INVOLVED IN THE INCIDENT?</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-radio-group data-cy="liability-incident-vehicles-radio-group"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-buttons-mobile-background"
                                     v-model="submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved">
                        <v-radio
                            class="radio-buttons-loss-info-mobile-yes"
                            label="Yes"
                            :value=true
                            data-cy="liability-incident-vehicles-radio-yes">
                        </v-radio>
                        <v-radio
                            class="radio-buttons-loss-info-mobile-no"
                            label="No"
                            :value=false
                            data-cy="liability-incident-vehicles-radio-no"
                            @change="onVehiclesOrEquipmentInvolvedNo"
                        ></v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8" v-if="submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved">
                  <v-col cols="8" lg="6" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="liability-equipment-label" class="label-text">PLEASE DESCRIBE THE VEHICLES OR EQUIPMENT INVOLVED</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col data-cy="liability-equipment-column" class="input-spacing">
                      <v-textarea
                          v-model="submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment"
                          data-cy="liability-equipment-input"
                          dense
                          outlined
                          clearable
                          auto-grow
                          background-color="white"
                          counter="950"
                          :maxLength="950"
                          :rules="descriptionOfVehiclesAndEquipRules"
                          @input="descriptionOfVehiclesAndEquipOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="liability-incident-witnesses-label" class="label-text">WERE THERE ANY WITNESSES TO THE INCIDENT?</p>
                      </v-col>
                    </v-row>

                    <v-row >
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-radio-group data-cy="liability-incident-witnesses-radio-group"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-buttons-mobile-background"
                                     v-model="submission.lossInformation.liabilityLossInformation.witnessOfIncident">
                        <v-radio
                            class="radio-buttons-loss-info-mobile-yes"
                            label="Yes"
                            :value=true
                            data-cy="liability-incident-witnesses-radio-yes">
                        </v-radio>
                        <v-radio
                            class="radio-buttons-loss-info-mobile-no"
                            label="No"
                            :value=false
                            data-cy="liability-incident-witnesses-radio-no">
                        </v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8 alert-spacing" v-if="submission.lossInformation.liabilityLossInformation.witnessOfIncident">
                  <v-col cols="8" lg="6" class="input-spacing">
                    <v-row class="alert-row">
                      <v-col class="input-spacing" >
                        <p data-cy="liability-incident-witnesses-info-label" class="alert-label">
                        <v-icon>mdi-alert-circle-outline</v-icon> Please enter witness details on the Additional Contacts page.</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">
                    <v-row>
                      <v-col class="input-spacing">
                            <p data-cy="liability-incident-police-label" class="label-text">WERE ANY POLICE, FIRE, OR EMT CALLED?</p>
                      </v-col>
                    </v-row>

                    <v-row >
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-radio-group data-cy="liability-incident-police-radio-group"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-buttons-mobile-background"
                                     v-model="submission.lossInformation.liabilityLossInformation.policeFireEmtCalled">
                        <v-radio
                            class="radio-buttons-loss-info-mobile-yes"
                            label="Yes"
                            :value=true
                            data-cy="liability-incident-police-radio-yes"
                        ></v-radio>
                        <v-radio
                            class="radio-buttons-loss-info-mobile-no"
                            label="No"
                            :value=false
                            data-cy="liability-incident-police-radio-no"
                        ></v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8 alert-spacing" v-if="submission.lossInformation.liabilityLossInformation.policeFireEmtCalled">
                  <v-col cols="8" lg="6" class="input-spacing">
                    <v-row class="alert-row">
                      <v-col class="input-spacing" >
                        <p data-cy="liability-incident-police-info-label" class="alert-label">
                          <v-icon>mdi-alert-circle-outline</v-icon> Please enter police, fire or EMT details on the Additional Contacts page.</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="liability-transported-label" class="label-text">WAS ANYONE TRANSPORTED TO THE HOSPITAL?</p>
                      </v-col>
                    </v-row>

                    <v-row  >
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-radio-group data-cy="liability-transported-radio-group"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-buttons-mobile-background"
                                     v-model="submission.lossInformation.liabilityLossInformation.anyoneToHospital">
                        <v-radio
                            class="radio-buttons-loss-info-mobile-yes"
                            label="Yes"
                            :value=true
                            data-cy="liability-transported-radio-yes"
                        ></v-radio>
                        <v-radio
                            class="radio-buttons-loss-info-mobile-no"
                            label="No"
                            :value=false
                            data-cy="liability-transported-radio-no"
                        ></v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col >
              <v-card class="card-background" elevation="0" >
                <v-row class="pl-8">
                  <v-card-title class="section-label berninaSansCompressedFont" data-cy="liability-owner-label">Injured Person(s)</v-card-title>
                </v-row>

                <v-row class="pl-8">
                  <v-col md="8" lg="6">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="liability-injured-persons-amount-label" class="label-text">HOW MANY INDIVIDUALS WERE INJURED?</p>
                        </v-col>
                      </v-row>

                    <v-row>
                      <v-col cols="8" md="3" lg="3" class="input-spacing" data-cy="liability-injured-persons-amount-column">
                      <v-text-field
                          v-model="submission.lossInformation.liabilityLossInformation.injuredPersonsAmount"
                          data-cy="liability-injured-persons-amount-input"
                          counter="3"
                          maxLength="3"
                          v-mask="'###'"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          :rules="injuredPersonsAmountValidationRules"
                          @input="injuredPersonsAmountValidationOnInput"
                          @blur="injuredPersonsAmountValidationOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <div v-if="submission.lossInformation.liabilityLossInformation.injuredPersonsAmount > 0">
                  <v-row class="pl-8">
                    <v-col class="input-spacing">
                      <p data-cy="liability-injured-persons-info-label" class="label-text">PLEASE ENTER ANY INFORMATION ON THE INJURED PERSONS THAT YOU MAY HAVE.</p>
                    </v-col>
                  </v-row>

                  <InjuredPersonComponent v-if="submission.lossInformation.liabilityLossInformation.injuredPersons.length > 0"></InjuredPersonComponent>


                  <v-row class="pl-8 pb-4">
                    <v-col>
                      <v-btn class="outlined-button" outlined data-cy="add-another-injured-person-cta" @click="addInjuredPersonOnClick">
                        <span><v-icon>mdi-plus</v-icon>Add Injured Person</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
            </v-card>
          </v-col>
        </v-row>

              <DamagedPropertyComponent data-cy="damaged-property-component"/>

          <v-row>
            <v-col>
              <v-card class="card-background pl-7 pr-7" elevation="0">
                <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
                  <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined class="previous-button"
                         v-if="$vuetify.breakpoint.mdAndUp">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>
                  <v-btn @click="navigateTo('additional-contacts')" color="#00274e" data-cy="next-button"
                     class="next-button"><span style="color: white; padding-right: 4px">Next</span>
                      <v-icon color="white" style="padding-left: 8px">mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-row>

                <v-row  v-if="$vuetify.breakpoint.smAndDown" justify="center">
                  <v-btn @click="navigateTo('loss-information')" data-cy="mobile-previous-button" outlined class="previous-button" v-if="$vuetify.breakpoint.smAndDown">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>
                </v-row>

                <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" justify="center">
                  <v-btn data-cy="mobile-cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                    <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                  </v-btn>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission" v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>


  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import InjuredPersonComponent from "@/views/components/InjuredPersonComponent.vue";
import {InjuredPerson} from '@/models/InjuredPerson';
import {ValidationUtils} from "@/utils/validation-utils";
import DamagedPropertyComponent from "@/views/components/DamagedPropertyComponent.vue";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";


@Component({
  components: {
    BrowserBackButtonModal,
    InjuredPersonComponent, DamagedPropertyComponent, SideStepperComponent, TimeoutModal, CancelClaimModal}
})


export default class LiabilityLossInformation extends Vue {

    submission: FnolSubmission;
    uiUtils = new UiUtils();
    statesList: Array<string> = this.getStatesList();
    countriesList: Array<string> = this.getCountriesList();

    aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
    validationUtils: ValidationUtils = new ValidationUtils();
    injuredPersonsAmountValidationRules: Array<string> = [];
    descriptionOfVehiclesAndEquipRules: Array<string> = [];


    created() {
    this.submission = this.$store.state.submission;
    console.log('Liability Loss Information page created')

    this.uiUtils.instantiateLossObject(this.submission)

    }

    // How often the user is checked for inactivity (1 second = 1000)
    timeout = setInterval(this.applicationTimeoutTimer, 1000);

    get isIdle() {
      if (this.$store.state.idleVue.isIdle) {
        this.$store.state.showTimeoutModalMessage = true;
      }
      return this.$store.state.idleVue.isIdle;
    }

    applicationTimeoutTimer () {
      // CONTINUES TO RUN THROUGHOUT THE APPLICATION
      if (this.$store.state.idleVue.isIdle) {
        this.$store.state.showTimeoutModalMessage = true;
      }
    }


    trimLiabilityLossInfoObject() {
      if(this.submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved) {
        this.submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment)
      }
      if(this.submission.lossInformation.liabilityLossInformation.hasPropertyDamaged) {
        this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription)
        if(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.hasPropertyOwner) {
          this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName)
          this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryEmail = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryEmail)
          this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1)
          this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2)
          this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city)
        }
      }

      this.submission.lossInformation.liabilityLossInformation.injuredPersons.forEach((injuredPerson) => {
        injuredPerson.firstName = this.uiUtils.testForTrim(injuredPerson.firstName)
        injuredPerson.lastName = this.uiUtils.testForTrim(injuredPerson.lastName)
        injuredPerson.primaryEmail = this.uiUtils.testForTrim(injuredPerson.primaryEmail)
        injuredPerson.location.addressLine1 = this.uiUtils.testForTrim(injuredPerson.location.addressLine1)
        injuredPerson.location.addressLine2 = this.uiUtils.testForTrim(injuredPerson.location.addressLine2)
        injuredPerson.location.city = this.uiUtils.testForTrim(injuredPerson.location.city)
        injuredPerson.injuryDescription = this.uiUtils.testForTrim(injuredPerson.injuryDescription)
        if(injuredPerson.relationshipToInsured === 'Other') {
          injuredPerson.describeOtherRelationship = this.uiUtils.testForTrim(injuredPerson.describeOtherRelationship)
        }
      });
    }

    addInjuredPersonOnClick() {
      this.submission.lossInformation.liabilityLossInformation.injuredPersons.push(new InjuredPerson());
      console.log("Injured Person added");
    }

    injuredPersonsAmountValidationOnInput() {
      this.submission.lossInformation.liabilityLossInformation.injuredPersonsAmount = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.injuredPersonsAmount)

      if(parseInt(this.submission.lossInformation.liabilityLossInformation.injuredPersonsAmount) == 0
          || this.uiUtils.isNullBlankUndefined(this.submission.lossInformation.liabilityLossInformation.injuredPersonsAmount)
) {
        this.submission.lossInformation.liabilityLossInformation.injuredPersons = [];
      }
      this.injuredPersonsAmountValidationRules = this.aplFrontEndValidation.runInjuredPersonsAmountValidationRules();
    }

  onVehiclesOrEquipmentInvolvedNo(){
    this.submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment = null
  }

  getStatesList(): Array<string> {
    return Object.values(States);
  }

  getCountriesList(): Array<string> {
    return Object.values(Countries);
  }

  hasInjuredPeople(): boolean{
     let hasInjured = false;

    if(!isNaN(parseInt(this.submission.lossInformation.liabilityLossInformation.injuredPersonsAmount))
            && parseInt(this.submission.lossInformation.liabilityLossInformation.injuredPersonsAmount) > 0
              && this.submission.lossInformation.liabilityLossInformation.injuredPersons.length > 0){

              // Injured Persons Amount contains a number, and Injured Persons Array contains a person
              hasInjured = true;
       }
    return hasInjured;
  }
  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  descriptionOfVehiclesAndEquipOnInput() {
    this.submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment = this.uiUtils.checkIfEmpty(this.submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment)
    this.descriptionOfVehiclesAndEquipRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  async navigateTo(route: string) {
    this.trimLiabilityLossInfoObject();

    if (this.$store.state.liabilityLossInformationPageValid && this.$store.state.isDamagedPropertyComponentValid) {
      this.$store.state.showValidationSnackbar = false
      if (this.hasInjuredPeople()) {
        if (this.$store.state.injuredPersonComponentIsValid) {
          this.$store.state.showValidationSnackbar = false
          this.uiUtils.navigateAndSaveSubmission(this.submission, route)
        } else {
          this.$store.state.showValidationSnackbar = true
          window.scrollTo(0, 0);
        }
      } else {
        this.uiUtils.navigateAndSaveSubmission(this.submission, route)
      }
    }else{
      this.$store.state.showValidationSnackbar = true
      window.scrollTo(0, 0);
    }
  }

}
</script>
