<template>
  <v-col data-cy="other-party-vue">
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>

      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-8">
          <v-col cols="10">
            <span class="other-party-title berninaSansCompressedFont" data-cy="other-party-label">Other Parties</span>
            <div class="hint-text" data-cy="add-parties-text">If you have information on any other parties involved in the accident, please provide them here.</div>
          </v-col>

          <v-col class="report-num-col" cols="2" data-cy="report-number-column">
            <span class="report-num-label" data-cy="report-number-label">Report#: {{ submission.reportNumber }}</span>
          </v-col>
        </v-row>

        <v-form autocomplete="off">

          <v-row data-cy="add-party-column" justify="center">
            <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'">
                  <v-btn :disabled="isAddPartyButtonDisabled()" class="outlined-button" data-cy="add-party-btn" outlined @click="addPartyOnClick">
                    <span><v-icon left>mdi-plus</v-icon>Add Another Party</span>
                  </v-btn>
              </v-col>
          </v-row>

          <v-row>
            <v-col data-cy="tabs-col">
              <v-tabs v-model="tab" :center-active="$vuetify.breakpoint.smAndDown" next-icon="mdi-arrow-right-bold-box-outline"
                      prev-icon="mdi-arrow-left-bold-box-outline"
                      show-arrows
                      @change="onTabsChange">
                <v-tab v-for="(party, index) in submission.otherParty.parties" :id="'tab-' + index" :key="index"
                       :data-cy="'tab-' + index" active-class="active" class="party-tab">
                  <div class="tab-content">
                    <span :data-cy="'tab-name-'+index" class="tab-name"> {{ tabName(party) }}</span>
                    <span :class="{active: isActiveTab(index)}" :data-cy="'tab-button-'+index"
                          class="ml-3 remove-party-button" @click="deletePartyOnClick(index)">x</span>
                  </div>
                </v-tab>
                <v-tab-item v-for="(party, index) in submission.otherParty.parties" :key="index">
                  <v-form autocomplete="off">

                    <v-expansion-panels multiple>

                      <v-expansion-panel class="card-background" data-cy="driver-expansion-panel">
                        <v-expansion-panel-header data-cy="driver-expansion-panel-header">
                          <div class="berninaSansCompressedFont collapse-header" data-cy="other-driver-information-label">Driver</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="op-driver-content" eager>
                          <OtherPartyDriverInformation :data-cy="'driver-' + index" :index="index" :party="party" @update-tab-names="$forceUpdate()"/>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel class="mt-5 card-background" data-cy="vehicle-expansion-panel">
                        <v-expansion-panel-header data-cy="vehicle-expansion-panel-header">
                          <div class="berninaSansCompressedFont collapse-header"
                               data-cy="other-vehicle-information-label">Vehicle
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="op-vehicle-content" eager>
                          <OtherPartyVehicleInformation :data-cy="'vehicle-' + index" :vehicle="party.vehicle" class="card-background"
                                                        elevation="0"/>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel class="mt-5 card-background" data-cy="passenger-expansion-panel">
                        <v-expansion-panel-header data-cy="passenger-expansion-panel-header">
                          <div class="berninaSansCompressedFont collapse-header" data-cy="other-passenger-information-label">Passengers</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="op-passenger-content" eager>
                          <OtherPartyPassengerComponent :party="party" :partyIndex="index"/>

                        </v-expansion-panel-content>
                      </v-expansion-panel>

                    </v-expansion-panels>
                  </v-form>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <RemovePartyModal v-if="$store.state.showRemovePartyModal" :index="otherPartyIndex" :submission="submission"></RemovePartyModal>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="card-background px-7" elevation="0">
              <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">

                  <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="previous-button" data-cy="previous-button"
                         outlined @click="navigateTo('accident-information')">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>

                <v-btn class="next-button" data-cy="next-button" @click="navigateTo('additional-contacts')">
                  <span>Next</span>
                  <v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-row>

              <v-row v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                <v-btn class="previous-button" data-cy="previous-button" outlined
                       @click="navigateTo('accident-information')">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>
              </v-row>

              <v-row v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''" class="pb-5 mt-5">
                <v-btn class="outlined-button" data-cy="cancel-button" outlined @click="CancelClaimModalOnClick">
                  <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                </v-btn>
              </v-row>

            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"
                      :submission="submission"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {Party} from '@/models/Party';
import OtherPartyDriverInformation from "@/views/components/OtherPartyDriverInformation.vue";
import OtherPartyVehicleInformation from "@/views/components/OtherPartyVehicleInformation.vue";
import OtherPartyPassengerComponent from "@/views/components/OtherPartyPassengerComponent.vue";
import RemovePartyModal from "@/views/modals/RemovePartyModal.vue";
import {UiUtils} from "@/utils/ui-utils";
import {ValidationUtils} from "@/utils/validation-utils";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";
import { OtherParty } from '@/models/OtherParty';

@Component({
  components: {
    BrowserBackButtonModal,
    RemovePartyModal,
    OtherPartyPassengerComponent,
    OtherPartyVehicleInformation,
    OtherPartyDriverInformation,
    SideStepperComponent,
    TimeoutModal,
    CancelClaimModal
  }
})

export default class OtherPartyPage extends Vue {
  validationUtils: ValidationUtils = new ValidationUtils();
  uiUtils = new UiUtils();
  submission: FnolSubmission;
  tab = 0;
  otherPartyIndex: number
  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  created() {
    this.submission = this.$store.state.submission;

      if (!this.submission.otherParty){
          console.log("create OtherParty")
          this.submission.otherParty = new OtherParty()
      }

    if (!this.submission.otherParty.parties) {
      this.submission.otherParty.parties = new Array<Party>();
    }
    if (this.submission.otherParty.parties.length) {
      for (let i = 0; i < this.submission.otherParty.parties.length; i++) {
        if (this.submission.otherParty.parties[i].hasDriverInfo && this.submission.otherParty.parties[i].driver.isSameAsPrimaryContact) {
          this.uiUtils.contactSameAsToggle(this.submission.otherParty.parties[i].driver,
              this.submission.customerInformation.primaryContact,
              this.submission.otherParty.parties[i].driver.isSameAsPrimaryContact)
        }
      }
    }
  }

  async onTabsChange() {
    if (this.submission.otherParty.parties.length > 0) {
        if (this.$store.state.otherPartyPassengerInformationPageValid &&
            this.$store.state.otherPartyDriverInformationPageValid &&
            this.$store.state.otherPartyVehicleInformationPageValid) {
          this.$store.state.currentTab = this.tab
        } else {
          this.tab = this.$store.state.currentTab
        }
    }
  }

  applicationTimeoutTimer() {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  isActiveTab(tabIndex: number): boolean {
    return this.tab === tabIndex
  }

  tabName(party: Party): string {
    if (party.driver != null) {
      if (party.driver.firstName && party.driver.lastName) {
        return party.driver.firstName + ' ' + party.driver.lastName;
      }
    }
    return 'New Party'
  }

  async addPartyOnClick() {
    if (this.$store.state.otherPartyDriverInformationPageValid &&
        this.$store.state.otherPartyVehicleInformationPageValid &&
        this.$store.state.otherPartyPassengerInformationPageValid) {
      this.submission.otherParty.parties.push(new Party());
      this.tab = this.submission.otherParty.parties.length - 1;
      this.$store.state.currentTab = this.tab;
    }
  }

  deletePartyOnClick(partyIndex: number) {
    this.$store.state.showRemovePartyModal = true;
    if (this.submission.otherParty.parties[partyIndex].driver && this.submission.otherParty.parties[partyIndex].driver.isSameAsPrimaryContact) {
      this.$store.state.primaryContactAlreadyEnabled = false;
    }
    this.otherPartyIndex = partyIndex;
    this.$store.state.otherPartyVehicleInformationPageValid = true;
    this.$store.state.otherPartyDriverInformationPageValid = true;
    this.$store.state.otherPartyPassengerInformationPageValid = true;
  }

  trimOtherPartyObject() {
    this.submission.otherParty.parties.forEach((party) => {
      //driver
      if (party.hasDriverInfo) {
        party.driver.firstName = this.uiUtils.testForTrim(party.driver.firstName)
        party.driver.lastName = this.uiUtils.testForTrim(party.driver.lastName)
        party.driver.primaryEmail = this.uiUtils.testForTrim(party.driver.primaryEmail)
        party.driver.location.addressLine1 = this.uiUtils.testForTrim(party.driver.location.addressLine1)
        party.driver.location.addressLine2 = this.uiUtils.testForTrim(party.driver.location.addressLine2)
        party.driver.location.city = this.uiUtils.testForTrim(party.driver.location.city)
        if (party.driver.sustainInjury) {
          party.driver.injury = this.uiUtils.testForTrim(party.driver.injury)
        }
      }
      //vehicle
      party.vehicle.vin = this.uiUtils.testForTrim(party.vehicle.vin)
      party.vehicle.plateNumber = this.uiUtils.testForTrim(party.vehicle.plateNumber)
      party.vehicle.make = this.uiUtils.testForTrim(party.vehicle.make)
      party.vehicle.model = this.uiUtils.testForTrim(party.vehicle.model)
      party.vehicle.damage = this.uiUtils.testForTrim(party.vehicle.damage)
      party.vehicle.location = this.uiUtils.testForTrim(party.vehicle.location)
      //passenger
      party.otherPassengers.forEach((passenger) => {
        passenger.firstName = this.uiUtils.testForTrim(passenger.firstName)
        passenger.lastName = this.uiUtils.testForTrim(passenger.lastName)
        passenger.primaryEmail = this.uiUtils.testForTrim(passenger.primaryEmail)
        passenger.location.addressLine1 = this.uiUtils.testForTrim(passenger.location.addressLine1)
        passenger.location.addressLine2 = this.uiUtils.testForTrim(passenger.location.addressLine2)
        passenger.location.city = this.uiUtils.testForTrim(passenger.location.city)
        if (passenger.injuriesSustainedFromAccident) {
          passenger.injuryInformationDescription = this.uiUtils.testForTrim(passenger.injuryInformationDescription)
        }
      })

    })
  }

  async navigateTo(route: string) {
    if (this.submission.otherParty.parties.length > 0) {
      if (this.$store.state.otherPartyDriverInformationPageValid &&
          this.$store.state.otherPartyPassengerInformationPageValid &&
          this.$store.state.otherPartyVehicleInformationPageValid) {
        this.$store.state.showValidationSnackbar = false
        this.uiUtils.navigateAndSaveSubmission(this.submission, route)
      } else {
        this.$store.state.showValidationSnackbar = true
        window.scrollTo(0, 0);
        if (!this.$store.state.otherPartyVehicleInformationPageValid) {
          this.uiUtils.openPanelContent('op-vehicle-content')
        }
        if (!this.$store.state.otherPartyPassengerInformationPageValid) {
          this.uiUtils.openPanelContent('op-passenger-content')
        }
      }
    } else {
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    }
  }

  isAddPartyButtonDisabled(): boolean {
    if (this.submission.otherParty.parties.length > 0) {
      if (!this.$store.state.otherPartyDriverInformationPageValid ||
          !this.$store.state.otherPartyVehicleInformationPageValid ||
          !this.$store.state.otherPartyPassengerInformationPageValid) {
        return true;
      }
    }
    return false;
  }

  isCurrentTabDisabled(index: number): boolean {
    if (this.submission.otherParty.parties.length > 0) {
      if (index == this.$store.state.currentTab && (!this.$store.state.otherPartyDriverInformationPageValid ||
          !this.$store.state.otherPartyVehicleInformationPageValid ||
          !this.$store.state.otherPartyPassengerInformationPageValid)) {
        return true;
      }
    }
    return false;
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

}
</script>

<style lang="scss" scoped>

.other-party-title {
  color: #00274E;
  font-size: 32px;
  font-weight: bold;
}

.hint-text {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #264867;
}

.party-tab {
  border: 1px solid gray;
  border-radius: 12px 12px 0 0;
  background-color: #9bb7d4;
  color: white;

  &.active {
    background-color: white;
    color: #00274e;
  }
}

.tab-content {
  display: flex;
  align-items: center;
}

.remove-party-button {
  background-color: white;
  color: #979797;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  padding: 3px 0 0 2px;

  &.active {
    border: 1px solid black;
    padding: 2px 0 0 1px;
  }
}

:deep.theme--light.v-tabs > .v-tabs-bar {
  background-color: #f4f5f7;
}

.v-expansion-panels {
  background-color: #f4f5f7;
}

</style>
