<template>
  <div data-cy="liability-loss-information-review-vue">
    <!-- Liability Loss Information -->
<!--    <div class="divider" "><span-->
<!--        class="px-4">Liability Information</span></div>-->
    <v-row data-cy="liability-loss-information-section">
      <v-btn outlined tile class="elevation-0 ml-3">
              <span class="edit-section-label" data-cy="edit-liability-loss-information-cta"
                    @click="editLiabilityLossInformationOnClick">
              <v-icon>mdi-pencil</v-icon> Go To Liability Information</span>
      </v-btn>
    </v-row>

    <v-row class="sub-section-label">
      <v-col class="pb-0" data-cy="incident-details-header-section">Incident Details</v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-incident-details-insured-property-label">DID THE INCIDENT OCCUR ON THE INSURED'S PROPERTY?</v-col>
    </v-row>

    <v-row class="rs-values">
      <v-col class="pt-0 pb-0" data-cy="rs-incident-details-insured-property-value"
             v-if="submission.lossInformation.liabilityLossInformation.incidentOccurOnInsuredProperty != null">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.incidentOccurOnInsuredProperty) }}
      </v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-incident-details-filed-report-label">WAS THERE AN INCIDENT REPORT FILED WITH THE INSURED?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0 pb-0" data-cy="rs-incident-details-filed-report-value"
             v-if="submission.lossInformation.liabilityLossInformation.incidentReportFiledWithInsured != null">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.incidentReportFiledWithInsured) }}
      </v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-incident-vehicles-label">WERE ANY VEHICLES OR EQUIPMENT INVOLVED IN THE INCIDENT?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0 pb-0" data-cy="rs-incident-vehicles-value"
             v-if="submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved != null">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved) }}
      </v-col>
    </v-row>

    <v-row class="rs-labels" v-if="submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved &&
                                     submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment != null">
      <v-col class="pb-0" data-cy="rs-incident-vehicles-description-label">PLEASE DESCRIBE THE VEHICLES OR EQUIPMENT INVOLVED</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0 pb-0" data-cy="rs-incident-vehicles-description-value"
             v-if="submission.lossInformation.liabilityLossInformation.vehiclesOrEquipmentInvolved">
        {{ submission.lossInformation.liabilityLossInformation.descriptionOfVehiclesAndEquipment }}
      </v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-incident-witnesses-label">WERE THERE ANY WITNESSES TO THE INCIDENT?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0 pb-0" data-cy="rs-incident-witnesses-value"
             v-if="submission.lossInformation.liabilityLossInformation.witnessOfIncident != null">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.witnessOfIncident) }}
      </v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-incident-police-label">WERE ANY POLICE, FIRE, OR EMT CALLED?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0" data-cy="rs-incident-police-value"
             v-if="submission.lossInformation.liabilityLossInformation.policeFireEmtCalled != null">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.policeFireEmtCalled) }}
      </v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-transported-to-hospital-label">WAS ANYONE TRANSPORTED TO THE HOSPITAL?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0" data-cy="rs-transported-to-hospital-value"
             v-if="submission.lossInformation.liabilityLossInformation.anyoneToHospital != null">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.anyoneToHospital) }}
      </v-col>
    </v-row>

    <v-row class="sub-section-label">
      <v-col class="pb-0" data-cy="injured-persons-header-section">Injured Person(s)</v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-injured-persons-amount-label">HOW MANY INDIVIDUALS WERE INJURED?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0" data-cy="rs-injured-persons-amount-value"
             v-if="submission.lossInformation.liabilityLossInformation.injuredPersonsAmount != null">
        {{ submission.lossInformation.liabilityLossInformation.injuredPersonsAmount }}
      </v-col>
    </v-row>

    <div class="pl-3" v-for="(injuredPerson, index) in submission.lossInformation.liabilityLossInformation.injuredPersons"
         :key="index" data-cy="injured-persons-review-list" :injuredPerson="injuredPerson">
      <v-row class="sub-section-label">
        <v-col class="pb-0 pl-0" :data-cy="'injured-person-header-section-' + index">Injured Person {{ index + 1 }}</v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <v-row :data-cy="'rs-injured-person-first-name-label-' + index">FIRST NAME</v-row>
          <v-row class="rs-values rs-wrapping-text" v-if="injuredPerson.firstName != null" :data-cy="'rs-injured-person-first-name-value-' + index">{{ injuredPerson.firstName }}</v-row>
        </v-col>

        <v-col class="pb-0" cols="4">
          <v-row :data-cy="'rs-injured-person-last-name-label-' + index">LAST NAME</v-row>
          <v-row class="rs-values rs-wrapping-text" v-if="injuredPerson.lastName != null" :data-cy="'rs-injured-person-last-name-value-' + index">{{ injuredPerson.lastName }}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
          <v-row :data-cy="'rs-injured-person-email-label-' + index">EMAIL</v-row>
          <v-row class="rs-values rs-wrapping-text" v-if="injuredPerson.primaryEmail != null" :data-cy="'rs-injured-person-email-value-' + index">{{ injuredPerson.primaryEmail }}</v-row>
        </v-col>

        <v-col class="pb-0" cols="4">
          <v-row :data-cy="'rs-injured-person-phone-label-' + index">PHONE</v-row>
          <v-row class="rs-values" v-if="injuredPerson.primaryPhone != null" :data-cy="'rs-injured-person-phone-value-' + index">{{ injuredPerson.primaryPhone }}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <v-row :data-cy="'rs-injured-person-address-line-1-label-' + index">ADDRESS LINE 1</v-row>
          <v-row class="rs-values rs-wrapping-text" v-if="injuredPerson.location.addressLine1 != null" :data-cy="'rs-injured-person-address-line-1-value-' + index">{{ injuredPerson.location.addressLine1 }}</v-row>
        </v-col>

        <v-col class="pb-0" cols="4">
          <v-row :data-cy="'rs-injured-person-address-line-2-label-' + index">ADDRESS LINE 2</v-row>
          <v-row class="rs-values rs-wrapping-text" v-if="injuredPerson.location.addressLine2 != null" :data-cy="'rs-injured-person-address-line-2-value-' + index">{{ injuredPerson.location.addressLine2 }}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <v-row :data-cy="'rs-injured-person-city-label-' + index">CITY</v-row>
          <v-row class="rs-values rs-wrapping-text" v-if="injuredPerson.location.city != null" :data-cy="'rs-injured-person-city-value-' + index">{{ injuredPerson.location.city }}</v-row>
        </v-col>

        <v-col class="pb-0" cols="4">
          <v-row :data-cy="'rs-injured-person-state-label-' + index">STATE</v-row>
          <v-row class="rs-values" v-if="injuredPerson.location.state != null" :data-cy="'rs-injured-person-state-value-' + index">{{ injuredPerson.location.state }}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <v-row :data-cy="'rs-injured-person-zip-label-' + index">ZIP</v-row>
          <v-row class="rs-values" v-if="injuredPerson.location.postalCode != null" :data-cy="'rs-injured-person-zip-value-' + index">{{ injuredPerson.location.postalCode }}</v-row>
        </v-col>

        <v-col class="pb-0" cols="4">
          <v-row :data-cy="'rs-injured-person-country-label-' + index">COUNTRY</v-row>
          <v-row class="rs-values" v-if="injuredPerson.location.country != null" :data-cy="'rs-injured-person-country-value-' + index">{{ injuredPerson.location.country }}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <v-row :data-cy="'rs-injured-person-relationship-to-insured-label-' + index">RELATIONSHIP TO INSURED</v-row>
          <v-row class="rs-values" v-if="injuredPerson.relationshipToInsured != null" :data-cy="'rs-injured-person-relationship-to-insured-value-' + index">{{ injuredPerson.relationshipToInsured }}</v-row>
        </v-col>

        <v-col class="pb-0" cols="4" v-if="injuredPerson.relationshipToInsured === 'Other'">
          <v-row :data-cy="'rs-injured-person-relationship-description-label-' + index">DESCRIBE OTHER RELATIONSHIP</v-row>
          <v-row class="rs-values" v-if="injuredPerson.describeOtherRelationship != null" :data-cy="'rs-injured-person-relationship-description-value-' + index">{{ injuredPerson.describeOtherRelationship }}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" cols="8">
          <v-row :data-cy="'rs-injured-description-label-' + index">PLEASE DESCRIBE THE INJURY</v-row>
          <v-row class="rs-values" v-if="injuredPerson.injuryDescription != null" :data-cy="'rs-injured-description-value-' + index">{{ injuredPerson.injuryDescription }}</v-row>
        </v-col>
      </v-row>

    </div>

    <v-row class="sub-section-label">
      <v-col class="pb-0" data-cy="damaged-property-header-section">Damaged Property</v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-has-damaged-property-label">WAS THERE ANY DAMAGE TO PROPERTY?</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0 pb-0" data-cy="rs-has-damaged-property-value">
        {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.liabilityLossInformation.hasPropertyDamaged) }}
      </v-col>
    </v-row>

    <v-row class="rs-labels">
      <v-col class="pb-0" data-cy="rs-damaged-property-description-label"
             v-if="submission.lossInformation.liabilityLossInformation.hasPropertyDamaged">PLEASE DESCRIBE THE DAMAGED PROPERTY / EQUIPMENT</v-col>
    </v-row>
    <v-row class="rs-values" v-if="submission.lossInformation.liabilityLossInformation.hasPropertyDamaged">
      <v-col class="pt-0 pb-0" data-cy="rs-damaged-property-description-value"
             v-if="submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription != null">
        {{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.damagedPropertyDescription }}
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0"  :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
        <v-row data-cy="rs-property-owner-name-label">PROPERTY OWNER NAME</v-row>
        <v-row class="rs-values rs-wrapping-text" data-cy="rs-property-owner-name-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.fullName }}</v-row>
      </v-col>
    </v-row>


    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.mdAndDown ? 12 : ''">
        <v-row data-cy="rs-property-owner-email-label">EMAIL</v-row>
        <v-row class="rs-values" data-cy="rs-property-owner-email-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryEmail }}</v-row>
      </v-col>
      <v-col cols="8" class="pb-0">
        <v-row data-cy="rs-property-owner-phone-label">PHONE</v-row>
        <v-row class="rs-values" data-cy="rs-property-owner-phone-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.primaryPhone }}</v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row data-cy="rs-property-owner-address-line-1-label">ADDRESS LINE 1</v-row>
        <v-row class="rs-values rs-wrapping-text" data-cy="rs-property-owner-address-line-1-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine1 }}</v-row>
      </v-col>
      <v-col cols="4" class="pb-0">
        <v-row data-cy="rs-property-owner-address-line-2-label">ADDRESS LINE 2</v-row>
        <v-row class="rs-values rs-wrapping-text" data-cy="rs-property-owner-address-line-2-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.addressLine2 }}</v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row data-cy="rs-property-owner-city-label">CITY</v-row>
        <v-row class="rs-values rs-wrapping-text" data-cy="rs-property-owner-city-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.city }}</v-row>
      </v-col>
      <v-col cols="4" class="pb-0">
        <v-row data-cy="rs-property-owner-state-label">STATE</v-row>
        <v-row class="rs-values" data-cy="rs-property-owner-state-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.state }}</v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-property-owner-zip-label">ZIP</v-row>
        <v-row class="rs-values" data-cy="rs-property-owner-zip-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.postalCode }}</v-row>
      </v-col>
      <v-col cols="8" class="pb-0">
        <v-row data-cy="rs-property-owner-country-label">COUNTRY</v-row>
        <v-row class="rs-values" data-cy="rs-property-owner-country-value" v-if="propertyOwnerExists">{{ submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.propertyOwner.location.country }}</v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class LiabilityLossInformationReview extends Vue {

  submission: FnolSubmission;
  uiUtils = new UiUtils();
  propertyOwnerExists: boolean = false;

  created() {
    console.log('Loss Information Review component created')
    this.submission = this.$store.state.submission;
    if(this.submission.lossInformation.liabilityLossInformation.hasPropertyDamaged) {
      if(this.submission.lossInformation.liabilityLossInformation.damagedPropertyInformation.hasPropertyOwner){
        this.propertyOwnerExists = true;
      }
    }
  }

  editLiabilityLossInformationOnClick() {
    this.uiUtils.navigate('liability-loss-information');
  }

}
</script>

<style scoped lang="scss">

</style>

