import {Vue} from "vue-property-decorator";
import {ValidationUtils} from "@/utils/validation-utils";
import {IsoValidation} from "@/utils/iso-validation";
import store from "@/store/store";

export class FrontEndValidation extends Vue {

  validationUtils: ValidationUtils = new ValidationUtils();
  isoValidationUtils: IsoValidation = new IsoValidation();

  runPolicyNumberValidationRules(): Array<any> {

    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.validationUtils.validateStringNoSpacesOnlyNumbersAndLetters(v) || 'No special characters or spaces',
      (v: any) => this.validationUtils.validateMinLength(v, 7) || 'Must be 7 characters'];

    //  Remove 7 character rule if MCG
    if(store.state.siteBrand.brandName === "MCG") {
      rules.splice(2, 1)
    }

    return rules;
  }

  runDateValidationRules_isRequired(isRequired: boolean): Array<any> {
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.validationUtils.validateDateNotInFuture(v) || 'Date cannot be a future date',
      (v: any) => this.validationUtils.validateOptionalDate(v) || 'Date must be in MM/DD/YYYY format',
      (v: any) => this.validationUtils.validateDateNotBeforeAnotherDate(v, "01/01/1900") || "Date cannot be prior to 1900"];
    return this.checkIfRequired(isRequired, rules);
  }

  runAlphaNumWithSpaceRules_isRequired(isRequired: boolean): Array<any>{
    const rules = [
        (v: any) => !!v || 'Please enter required field',
        (v: any) => this.validationUtils.validateAlphaNumWithSpaces(v) || 'Invalid characters entered'];

    return this.checkIfRequired(isRequired, rules)
  }

  runPlateValidationRules_isRequired(isRequired: boolean): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.validationUtils.validatePlateCharacters(v) || 'Invalid characters entered'];

    return this.checkIfRequired(isRequired, rules)
  }

  runNameValidationRules_isRequired(isRequired : boolean): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.isoValidationUtils.isValid(v) || 'The name entered is invalid',
      (v: any) => this.validationUtils.validateFirstOrLastNameInput(v) || 'Please enter a valid name'
    ];
      return this.checkIfRequired(isRequired, rules)
  }

  runFullNameValidationRules_isRequired(isRequired : boolean): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.validationUtils.validateFullNameInput(v) || 'Please enter a valid name',
      (v: any) => this.isoValidationUtils.isValid(v) || 'The name entered is invalid'
    ];

    return this.checkIfRequired(isRequired, rules)
  }

  runTextInputValidationRules_isRequired(isRequired : boolean): Array<any>{
    const rules = [
        (v: any) => !!v || 'Please enter required field',
        (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered'];

    return this.checkIfRequired(isRequired, rules)
  }


  runTextAreaValidationRules_isRequired(isRequired : boolean): Array<any>{
    const rules = [
        (v: any) => !!v || 'Please enter required field',
        (v: any) => this.validationUtils.validateTextArea(v) || 'Invalid characters entered',]

    return this.checkIfRequired(isRequired, rules)
  }

  runCityValidationRules_isRequired( isRequired : boolean): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.isoValidationUtils.isValid(v) || 'The city entered is invalid',
      (v: any) => this.validationUtils.validateStringOnlyLettersPeriodsAndHyphens(v) || 'Please enter a valid city'
  ];

    return this.checkIfRequired(isRequired, rules)
  }

  runStateValidationRules(stateCode: string): Array<any> {
    const rules = [
      (v: any) => this.validationUtils.validateStateisValidState(v, stateCode) || 'Please enter a valid State']
    return rules;
  }

  runLossTypeValidationRules(lossType: string): Array<any> {
    const rules = [
      (v: any) => this.validationUtils.validateLossTypeHasValidLossTypeCode(v, lossType) || 'Please enter a valid loss type']
    return rules;
  }

  runRequiredValidationRules(): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field']
    return rules;
  }

  runZipCodeValidationRules(): Array<any>{
    const rules = [
      (v: any) => this.validationUtils.validateUsZipIsValidLength(v) || 'Please enter a valid Zip Code']
    return rules;
  }

  runZipCodeValidationRules_isRequired(isRequired: boolean): Array<any>{
     const rules = [
        (v: any) => !!v || 'Please enter required field',
        (v: any) => this.validationUtils.validateUsZipIsValidLength(v) || 'Please enter a valid Zip Code'
      ]
    return this.checkIfRequired(isRequired, rules)
  }

  runYearValidationRules(): Array<any> {
    const rules = [
      (v: any) => this.validationUtils.validateYearIsValidLength(v) || 'Please enter a valid year'
    ]
    return rules;
  }

  runYearRangeValidationRules(): Array<any> {
    const rules = [
      (v: any) => this.validationUtils.validateYearRange(v) || 'Please enter a valid year'
    ]
    return rules;
  }


  runEmailValidationRules_isRequired(isRequired: boolean): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.validationUtils.validateEmailIsValidFormat(v) || 'Please enter a valid email address'
    ]
    return this.checkIfRequired(isRequired, rules)
  }

  //This has to stay parameterized because the additional info page needs to loop through and send each email through this
  runEmailArrayValidationRules(email: string): Array<any>{
    const rules = [
      (v: any) => this.validationUtils.validateEmailIsValidFormat(email) || 'Please enter a valid email address'
    ]
    return rules;
  }

  runPhoneValidationRules_isRequired(isRequired: boolean): Array<any>{
    const rules = [
      (v: any) => !!v || 'Please enter required field',
      (v: any) => this.validationUtils.validateMinLength(v, 10) || 'Must be 10 characters',
      (v: any) => this.validationUtils.validatePhoneNumberIsNotAllSameNumbers(v) || 'Please enter a valid phone number',
      (v: any) => this.validationUtils.validatePhoneNumberDoesNotStartWith0or1(v) || 'Phone number cannot start with 0 or 1',
    ]
    return this.checkIfRequired(isRequired, rules)
  }


  runVinValidationRules(): Array<any>{
    const rules = [
      (v: any) => this.validationUtils.validateAlphaNumNoSpaces(v) || 'Invalid characters entered'
    ]

    return rules;
  }

  runCargoPickupDateValidationRules(): Array<any>{
    const rules = [
      (v: any) => this.validationUtils.validateDateNotInFuture(v) || 'Date cannot be a future date',
      (v: any) => this.validationUtils.validateOptionalDate(v) || 'Please enter a valid date',
    ]
    return rules;
  }

  runCargoDeliveryDateValidationRules(): Array<any>{
    const rules = [
      (v: any) => this.validationUtils.validateOptionalDate(v) || 'Please enter a valid date',
    ]
    return rules;
  }

  runAddressValidationRules(): Array<any>{
    const rules = [
      (v: any) => this.isoValidationUtils.isValid(v) || 'The address entered is invalid',
      (v: any) => this.validationUtils.validateAddress(v) || 'Please enter a valid address'
    ]
    return rules;
  }

  runInjuredPersonsAmountValidationRules(): Array<any> {
    const rules = [
      (v: any) => this.validationUtils.validateOptionalStringOnlyNumbers(v) || 'Please enter a only numbers'
    ]
    return rules;
  }

  checkIfRequired(isRequired: boolean, rulesArray: Array<any>): Array<any>{
    if(isRequired) {
      return rulesArray
    }else{
      rulesArray.splice(0, 1)
      return rulesArray
    }
  }
}

// @ts-ignore