<template>
    <div data-cy="accident-information-review-vue">
        <!-- Accident Information -->
        <v-row data-cy="accident-information-section">
            <v-btn outlined tile class="elevation-0 ml-3">
              <span class="edit-section-label" data-cy="edit-accident-information-cta"
                    @click="editAccidentInformationOnClick">
              <v-icon>mdi-pencil</v-icon> Go To Accident Information</span>
            </v-btn>
        </v-row>

        <!-- Insured Driver Information -->
        <v-row>
            <v-col cols="auto" class="pb-0 mb-n2" data-cy="insured-driver-information-label">
                <div class="sub-section-label">Insured Driver</div>
            </v-col>
        </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="rs-insured-driver-first-name-label">FIRST NAME</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-first-name-value">
              {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.firstName : ""}}
          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0" >
          <v-row data-cy="rs-insured-driver-last-name-label">LAST NAME</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-last-name-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.lastName : ""}}          </v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="rs-insured-driver-email-label">EMAIL</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-email-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.primaryEmail : ""}}          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="rs-insured-driver-phone-label">PHONE</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-phone-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.primaryPhone : ""}}          </v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="rs-insured-driver-address-line-1-label">ADDRESS LINE 1</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-address-line-1-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.location.addressLine1 : ""}}          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
           <v-row data-cy="rs-insured-driver-address-line-2-label">ADDRESS LINE 2</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-address-line-2-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.location.addressLine2 : ""}}          </v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="rs-insured-driver-city-label">CITY</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-city-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.location.city : ""}}          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0" >
          <v-row data-cy="rs-insured-driver-state-label">STATE</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-state-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.location.state : ""}}          </v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row class="rs-labels" data-cy="rs-insured-driver-zip-label">ZIP</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-zip-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.location.postalCode : ""}}          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="rs-insured-driver-country-label">COUNTRY</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-country-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.location.country : ""}}          </v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="rs-insured-driver-employee-label">IS THE DRIVER AN EMPLOYEE?</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-employee-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              uiUtils.convertBooleanToYesNo(submission.lossInformation.accidentInformation.driver.insuredEmployee) : ""}}
          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="rs-insured-driver-sustain-injury-label">DID THE DRIVER SUSTAIN INJURY IN THE ACCIDENT?</v-row>
          <v-row class="rs-values" data-cy="rs-insured-driver-sustain-injury-value">
            {{submission.lossInformation.accidentInformation.hasDriverInfo ?
              submission.lossInformation.accidentInformation.driver.sustainInjury : ""}}
          </v-row>
        </v-col>
      </v-row>

        <v-row data-cy="tempInjury" class="rs-labels"
               v-if="submission.lossInformation.accidentInformation.hasDriverInfo
                && submission.lossInformation.accidentInformation.driver.sustainInjury === 'Yes'">
            <v-col class="pb-0" data-cy="rs-insured-driver-injury-information-label">INJURY INFORMATION</v-col>

        </v-row>
        <v-row class="rs-values" v-if="submission.lossInformation.accidentInformation.hasDriverInfo
        &&submission.lossInformation.accidentInformation.driver.sustainInjury === 'Yes'">
            <v-col class="pt-0" data-cy="rs-insured-driver-injury-information-value">
                {{submission.lossInformation.accidentInformation.hasDriverInfo ?
                submission.lossInformation.accidentInformation.driver.injury : ""}}
            </v-col>
        </v-row>

        <!-- Vehicle Information -->
        <v-row>
            <v-col cols="auto" class="pb-0 mb-n2" data-cy="insured-vehicle-information-label">
                <div class="sub-section-label">Insured Vehicle</div>
            </v-col>
        </v-row>

      <v-row class="rs-labels pl-3">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="rs-insured-vehicle-vin-label">VIN</v-row>
          <v-row class="rs-values" data-cy="rs-insured-vehicle-vin-value">
            {{submission.lossInformation.accidentInformation.vehicleInformation.vin}}
          </v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="rs-insured-vehicle-plate-number-label">PLATE NUMBER</v-row>
          <v-row class="rs-values" data-cy="rs-insured-vehicle-plate-number-value">
            {{submission.lossInformation.accidentInformation.vehicleInformation.plateNumber}}
          </v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels pl-3">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" class="pb-0" >
          <v-row data-cy="rs-insured-vehicle-year-label">YEAR</v-row>
          <v-row class="rs-values" data-cy="rs-insured-vehicle-year-value">
            {{submission.lossInformation.accidentInformation.vehicleInformation.year}}
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0">
          <v-row data-cy="rs-insured-vehicle-make-label">MAKE</v-row>
          <v-row class="rs-values" data-cy="rs-insured-vehicle-make-value">
            {{submission.lossInformation.accidentInformation.vehicleInformation.make}}
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0">
          <v-row data-cy="rs-insured-vehicle-model-label">MODEL</v-row>
          <v-row class="rs-values" data-cy="rs-insured-vehicle-model-value">
            {{submission.lossInformation.accidentInformation.vehicleInformation.model}}
          </v-row>
        </v-col>
      </v-row>

        <v-row class="rs-labels">
            <v-col class="pb-0" data-cy="rs-insured-vehicle-damage-label">VEHICLE DAMAGE</v-col>
        </v-row>
        <v-row class="rs-values">
            <v-col cols="8" class="pt-0 pb-0" data-cy="rs-insured-vehicle-damage-value">
                {{submission.lossInformation.accidentInformation.vehicleInformation.damage}}
            </v-col>
        </v-row>

        <v-row class="rs-labels">
            <v-col class="pb-0" data-cy="rs-insured-vehicle-location-label">VEHICLE CURRENT LOCATION</v-col>
        </v-row>
        <v-row class="rs-values">
            <v-col cols="8" class="pt-0 pb-0" data-cy="rs-insured-vehicle-location-value">
                {{submission.lossInformation.accidentInformation.vehicleInformation.location}}
            </v-col>
        </v-row>

        <v-row class="rs-labels">
            <v-col class="pb-0" data-cy="rs-insured-vehicle-owner-same-as-insured-label">DOES THE INSURED OWN THE VEHICLE?</v-col>
        </v-row>
        <v-row class="rs-values">
            <v-col class="pt-0 pb-0" data-cy="rs-insured-vehicle-owner-same-as-insured-value">
                {{uiUtils.convertBooleanToYesNo(submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured)}}
            </v-col>
        </v-row>

        <v-row class="rs-labels"
               v-if="!submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured">
            <v-col class="pb-0" data-cy="rs-insured-vehicle-comment-label">COMMENT</v-col>
        </v-row>
        <v-row class="rs-values"
               v-if="!submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured">
            <v-col class="pt-0 pb-0" data-cy="rs-insured-vehicle-comment-value">
                {{submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured}}
            </v-col>
        </v-row>

        <!-- Insured Vehicle Passengers -->
        <v-row>
            <v-col cols="auto" class="pb-0 mb-n2" data-cy="insured-vehicle-passenger-information-label">
                <div class="sub-section-label">Insured Vehicle Passengers</div>
            </v-col>
        </v-row>

        <v-row v-for="(passenger, index) in submission.lossInformation.accidentInformation.passengers" :key="index"
               data-cy="passenger-list">

            <v-col>
                <v-row>
                    <v-col cols="auto" class="pb-0 mb-n2" :data-cy="'rs-insured-vehicle-passenger-' + index + '-label'">
                        <div class="rs-passenger-label">Passenger {{index + 1}}</div>
                    </v-col>
                </v-row>

              <v-row class="rs-labels pl-3">
                <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
                  <v-row class="rs-labels" :data-cy="'rs-insured-vehicle-passenger-first-name-label-'+ index">FIRST NAME</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-first-name-value-'+ index">
                    {{passenger.firstName}}
                  </v-row>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-last-name-label-'+ index">LAST NAME</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-last-name-value-'+index">
                    {{passenger.lastName}}
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="rs-labels pl-3">
                <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
                  <v-row class="rs-labels" :data-cy="'rs-insured-vehicle-passenger-email-label-'+ index">EMAIL</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-email-value-'+ index">
                      {{passenger.primaryEmail}}
                  </v-row>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-phone-label-'+ index">PHONE</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-phone-value-'+ index">
                    {{passenger.primaryPhone}}
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="rs-labels pl-3">
                <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
                  <v-row class="rs-labels" :data-cy="'rs-insured-vehicle-passenger-address-line-1-label-'+ index">ADDRESS LINE 1</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-address-line-1-value-'+ index">
                      {{passenger.location.addressLine1}}
                  </v-row>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-address-line-2-label-'+ index">ADDRESS LINE 2</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-address-line-2-value-'+ index">
                    {{passenger.location.addressLine2}}
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="rs-labels pl-3">
                <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-city-label-'+ index">CITY</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-city-value-'+ index">
                      {{passenger.location.city}}
                  </v-row>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-state-label-'+ index">STATE</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-state-value-'+ index">
                    {{passenger.location.state}}
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="rs-labels pl-3">
                <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-zip-label-'+ index">ZIP</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-zip-value-'+ index">
                      {{passenger.location.postalCode}}
                  </v-row>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-country-label-'+ index">COUNTRY</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-country-value-'+ index">
                    {{passenger.location.country}}
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="rs-labels pl-3">
                <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-employee-label-'+ index">IS THE PASSENGER AN EMPLOYEE?</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-employee-value-'+ index">
                      {{uiUtils.convertBooleanToYesNo(passenger.isInsuredEmployee)}}
                  </v-row>
                </v-col>
                <v-col cols="8" class="pb-0">
                  <v-row :data-cy="'rs-insured-vehicle-passenger-sustain-injury-label-'+ index">DID THE PASSENGER SUSTAIN AN INJURY IN THE ACCIDENT?</v-row>
                  <v-row class="rs-values" :data-cy="'rs-insured-vehicle-passenger-sustain-injury-value-'+ index">
                    {{passenger.isInjured}}
                  </v-row>
                </v-col>
              </v-row>

                <v-row class="rs-labels" v-if="passenger.isInjured === 'Yes'">
                    <v-col class="pb-0" :data-cy="'rs-insured-vehicle-passenger-injury-information-label-'+ index">INJURY INFORMATION</v-col>

                </v-row>
                <v-row class="rs-values" v-if="passenger.isInjured === 'Yes'">
                    <v-col class="pt-0" :data-cy="'rs-insured-vehicle-passenger-injury-information-value-'+ index">{{passenger.injuryInformationDescription}}</v-col>
                </v-row>

                <v-divider v-if="index != submission.lossInformation.accidentInformation.passengers.length - 1"></v-divider>
            </v-col>
        </v-row>

    </div>
</template>

<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import {UiUtils} from "@/utils/ui-utils";
    import {FnolSubmission} from "@/models/FnolSubmission";


    @Component
    export default class AccidentInformationReview extends Vue {

        submission: FnolSubmission;
        uiUtils = new UiUtils()

        created() {
            console.log('Loss Information Review component created')
            this.submission = this.$store.state.submission;
        }

        editAccidentInformationOnClick() {
          this.uiUtils.navigate('accident-information');
        }

    }
</script>

<style scoped lang="scss">

</style>

