<template>
  <v-dialog v-model="show" max-width="1000px" persistent data-cy="display-aa-dialog">
    <v-card style="overflow-x: hidden" data-cy="display-aa-card">
      <v-card-title>
        <span class="aa-label" data-cy="aa-label">Access Agreement</span>
        <span data-cy="aa-close-x" class="aa-close-x" @click="closeModal">X</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-row data-cy="agreement-box-row-wrapper" justify="center">
        <v-col cols="11" class="aa-text">
          <v-row data-cy="agreement-box-row">
            <v-col data-cy="agreement-box-column">
              <p data-cy="agreement-start">This is the access agreement for the systems (the “Systems”) hosted by Great American Insurance Company and its affiliates (collectively, the “Company”).  Proprietary and confidential information of the Company is stored, transmitted, and processed by the Systems. By accessing the Systems you, on behalf of Yourself and any organization or company you may represent (“You”, “Your” or similar reference) acknowledge that You have read the Systems terms and conditions of use below (“Access Agreement”) and agree to accept and be bound by the terms of this Access Agreement. If You do not agree to the Access Agreement, You may not use the Systems. The Company may update the Access Agreement in its discretion, and it is Your responsibility to review the Access Agreement before using the Systems. Your continued use of the Systems constitutes Your acceptance of the Access Agreement.</p>
              <p data-cy="agreement-instructions">The Systems are offered and available to users who are 18 years of age or older and reside in the United States. By using the Systems, You represent and warrant that You are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If You do not meet all of these requirements, You must not access or use the Systems. In exchange for the right to access and use the Systems, You agree to the following terms and conditions:</p>
              <p data-cy="agreement-one"><span>1.</span>If You access the Systems on behalf of another person, organization or company, You bind that person, organization or company to these terms and You affirm that You have the legal authority to do so.</p>
              <p data-cy="agreement-two"><span>2.</span>To access or use the Systems, You may be asked to submit or enter certain information. You agree that the Company has the right to control all information submitted, entered into, contained in, or provided by the Systems (collectively the “Data”). You agree that the Data is Company’s confidential information, which may include nonpublic personal information (“NPI”). For the purposes of this Access Agreement, “NPI” is defined as nonpublic personal information of You, Your company’s, or the Company’s contract holders, insureds, customers, consumers, employees, and third-party service providers. NPI may include but is not limited to claims information, employee information, and health information.</p>
              <p data-cy="agreement-three"><span>3.</span>If You access Data, You shall preserve and protect the confidentiality of all Data with at least the same standard of care with which You protect Your own confidential information and in no event less than a commercially reasonable standard of care. You agree to comply fully with all laws and regulations governing the collection, use, and disclosure of Data (“Privacy Laws”).</p>
              <p data-cy="agreement-four"><span>4.</span>You agree not to disclose any Data that You access through the Systems, except as permitted by applicable Privacy Laws and this Access Agreement.</p>
              <p data-cy="agreement-five"><span>5.</span>In the event of any unauthorized access to or use of the Systems or Data, You shall notify the Company immediately and shall reasonably cooperate with the Company’s investigation of the incident.</p>
              <p data-cy="agreement-six"><span>6.</span>You agree to the terms and conditions of Company’s privacy policy which may be found <a href="https://www.greatamericaninsurancegroup.com/contact/privacy" target="_blank" data-cy="company-privacy-url">here</a>. The Company may update the terms and conditions of the privacy policy at any time without notice. Your continued use of the Systems constitutes Your acceptance of the privacy policy.</p>
              <p data-cy="agreement-seven"><span>7.</span>You may access and use the Systems and Data only for the purposes of administering an insurance transaction, which includes the review and/or servicing of insurance policies and claims, and the reporting of claims to the Company (“Permitted Purpose”).</p>
              <p data-cy="agreement-eight"><span>8.</span>You will not (i) use and access the Systems and Data for any purpose that is unlawful; (ii) use the Systems for any purpose other than the Permitted Purpose; or (iii) copy, distribute, use, share, or publish any Data for any purpose other than the Permitted Purpose.</p>
              <p data-cy="agreement-nine"><span>9.</span>You agree that all Data You provide on the Systems is correct, current, and complete. You will not knowingly submit or enter false, incomplete, or misleading Data into the Systems.</p>
              <p data-cy="agreement-ten"><span>10.</span>To the extent Company makes third-party products or services available to You through the Systems (“Third-Party Services”), You are responsible for complying with the Third-Party Services’ terms of use and privacy practices. In addition to Company’s disclaimer elsewhere in this Access Agreement, Company makes no representation, warranty, or assurance with respect to Third-Party Services and disclaims all liability that may be based on Third-Party Services.</p>
              <p data-cy="agreement-eleven"><span>11.</span>THE COMPANY MAKES NO REPRESENTATION, WARRANTY, OR ASSURANCE UNDER THIS ACCESS AGREEMENT AS TO THE ACCURACY, CONDITION, COMPLETENESS, MERCHANTABILITY, FITNESS FOR ANY PURPOSE, OR THE ABSENCE OF ANY CONFLICT OR INFRINGEMENT OF THE PATENTS, INTELLECTUAL PROPERTY, OR OTHER RIGHTS OF OTHER PARTIES AND DISCLAIMS ANY AND ALL LIABILITY THAT MAY BE BASED ON THE SYSTEMS AND DATA, ERRORS THEREIN, OR OMISSIONS THEREFROM.</p>
              <p data-cy="agreement-twelve"><span>12.</span>You agree to indemnify, hold harmless, and defend the Company against any loss, damage, or expense, including reasonable attorney fees, incurred by the Company as a result of (i) Your negligent or more culpable act or omission arising from use of the Systems or Data; and/or (ii) Your breach of any of the terms of this Access Agreement.</p>
              <p data-cy="agreement-thirteen"><span>13.</span>Your access to the Systems does not confer a license to use the Systems and the Company may terminate this Access Agreement and Your access to the Systems, in whole or in part, at any time, with or without cause.</p>
              <p data-cy="agreement-fourteen"><span>14.</span>This Access Agreement shall be governed, construed, and enforced in accordance with the laws of the State of Ohio without regard to conflict of law provisions.</p>
              <p data-cy="agreement-fifteen"><span>15.</span>In the event of a conflict in terms between this Access Agreement and any other agreement governing access to the Systems, this Access Agreement shall control. However, if any other agreement between the Company and You governing access to the Systems contains any obligations of Yours that are in addition to or are more restrictive on You than those set forth in this Access Agreement, such additional or more restrictive obligations shall control.</p>
              <p data-cy="agreement-sixteen"><span>16.</span>You shall send any notice required to be provided to the Company under this Access Agreement to: Great American Insurance Company, Attn: General Counsel, 301 East Fourth Street, Cincinnati, Ohio 45202</p>
              <p data-cy="agreement-validation">To indicate that You agree to this Access Agreement, click "Agree". If You do not agree to this Access Agreement, click "Disagree" and You will not be permitted to access the System.</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <button class="mr-5 mb-5 close-modal" data-cy="aa-close-button" type="button" @click="closeModal">CLOSE</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class AccessAgreementComponent extends Vue {

  show = true;

  closeModal() {
    this.$emit('clicked', false)
    this.show = false
  }
}
</script>

<style lang="scss" scoped>

.dialog-space{
  padding: 0 1.5em;
}

.aa-label {
  display: flex;
  float: left;
  font-size: 40px;
  font-weight: bold;
  color: #00274e;
  font-family: jaf-bernina-sans-comp, sans-serif;
}

.aa-info {
  color: #00274e;
  font-size: 18px;
  font-family: proxima-nova, serif;
  padding: 20px 0 0 36px;
  font-weight: 400;
}

.aa-close-x {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  flex: auto;
  text-align: right;
  white-space: nowrap;
}

.close-modal {
  background-color: #00274e;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 10px 12px 12px 10px;
  border-radius: 4px;
}


</style>
