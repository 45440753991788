import {Vue} from "vue-property-decorator";
import dayjs from "dayjs";

export class DateUtils extends Vue {

  // DATA MODEL TO UI
  // Returns as MM/DD/YYYYY
  formatDateForDisplay(date: string): string {
    return date ? dayjs(date).format('MM/DD/YYYY') : null;
  }

  // UI TO DATA MODEL
  // Returns as YYYY-MM-DD
  parseFormattedDate(date: string) {
    return date ? dayjs(date).format('YYYY-MM-DD') : null;
  }

  formatTimeForDisplay(dateTimeString: string): string {
    return dateTimeString ? dayjs(dateTimeString).format('h:mm A') : ''
  }
}
