<template>
  <v-form
      autocomplete="off"
      data-cy="witness-form"
      v-model="$store.state.isWitnessComponentValid">
   <v-col :data-cy="'witness-vue-' + index" v-for="(witness, index) in submission.additionalContacts.witnesses" :key="index" style="padding: 0 0 12px 0">
        <v-card class="card-background" elevation="0" >
          <v-row>
            <v-col md="4" >
              <div>
                <span :data-cy="'witness-label-'+ index" class="section-label berninaSansCompressedFont">Witness {{ index + 1 }}</span>
              </div>
            </v-col>
            <v-col md="4" v-if="$vuetify.breakpoint.mdAndDown"  class="text-right">
              <v-btn  @click="removeWitness(index)" :data-cy="'remove-witness-button-' + index" outlined class="mobile-remove-button">
                <span style="color: #00274e;"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="3" lg="2" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-first-name-label-' + index"
                     class="label-text">FIRST NAME<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-first-name-column-' + index">
                  <v-text-field
                      :data-cy="'witness-first-name-input-' + index"
                      :id="'witness-first-name-input-' + index"
                      v-model="witness.firstName"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="30"
                      maxLength="30"
                      :rules="witnessFirstNameValidationRules"
                      @input="runWitnessFirstNameOnInput(witness)"
                      @blur="runWitnessFirstNameOnInput(witness)"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col md="3" lg="2" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-last-name-label-' + index"
                     class="label-text">LAST NAME<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-last-name-column-' + index">
                  <v-text-field
                      :data-cy="'witness-last-name-input-' + index"
                      :id="'witness-last-name-input-' + index"
                      v-model="witness.lastName"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="30"
                      maxLength="30"
                      :rules="witnessLastNameValidationRules"
                      @input="runWitnessLastNameOnInput(witness)"
                      @blur="runWitnessLastNameOnInput(witness)"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="2" lg="2" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-suffix-label-' + index"
                     class="label-text"
                  >SUFFIX<span class="transparent-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-suffix-column-' + index">
                  <v-autocomplete
                      :data-cy="'witness-suffix-select-' + index"
                      v-model="witness.suffix"
                      :items="suffixesList"
                      dense
                      outlined
                      clearable
                      @input="witnessSuffixOnSelect(witness)"
                      background-color="white"/>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp" class="pl-8 pt-7 input-spacing" md="4" lg="3">

              <v-btn @click="removeWitness(index)" color="#00274e" :data-cy="'remove-witness-button-' + index" outlined class="outlined-button">
                <span style="color: #00274e"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove Witness</span>
              </v-btn>

            </v-col>
          </v-row>

          <v-row>
            <v-col md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-email-label-' + index"
                     class="label-text"
                  >EMAIL</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-email-column-' + index">
                  <v-text-field
                      :data-cy="'witness-email-input-' + index"
                      :id="'witness-email-input-' + index"
                      v-model="witness.primaryEmail"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      counter="50"
                      maxLength="50"
                      background-color="white"
                      :rules="witnessEmailValidationRules"
                      @input="runWitnessEmailOnClick(witness)"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-phone-label-' + index"
                     class="label-text"
                  >PHONE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-phone-column-' + index">
                  <v-text-field v-model="witness.primaryPhone"
                                :data-cy="'witness-phone-input-' + index"
                                :id="'witness-phone-input-' + index"
                                outlined dense
                                clearable
                                autocomplete="off"
                                background-color="white"
                                v-mask="'(###) ###-####'"
                                :rules="witnessPhoneValidationRules"
                                @input="runWitnessPhoneOnInput(witness)">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="8" lg="6" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-address-line-1-label-' + index"
                     class="label-text"
                  >ADDRESS LINE 1</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-address-line-1-column-' + index">
                  <v-text-field
                      :data-cy="'witness-address-line-1-input-' + index"
                      v-model="witness.location.addressLine1"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxlength="60"
                      :rules="witnessAddressLine1ValidationRules"
                      @input="runWitnessAddressLine1OnInput(witness)"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="8" lg="6" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-address-line-2-label-' + index"
                     class="label-text"
                  >ADDRESS LINE 2</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-address-line-2-column-' + index">
                  <v-text-field
                      :data-cy="'witness-address-line-2-input-' + index"
                      v-model="witness.location.addressLine2"
                      dense
                      outlined
                      clearable
                      counter="60"
                      maxLength="60"
                      background-color="white"
                      :rules="witnessAddressLine2ValidationRules"
                      @input="runWitnessAddressLine2OnInput(witness)"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-city-label-' + index"
                     class="label-text"
                  >CITY</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-city-column-' + index">
                  <v-text-field
                      :data-cy="'witness-city-input-' + index"
                      :id="'witness-city-input-' + index"
                      v-model="witness.location.city"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      :rules="witnessCityValidationRules"
                      @input="runWitnessCityOnInput(witness)"
                      counter="60"
                      maxLength="60"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-state-label-' + index"
                     class="label-text"
                  >STATE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-state-column-' + index">
                  <v-autocomplete v-model="witness.location.state"
                                  :data-cy="'witness-state-select-' + index"
                                  :id="'witness-state-' + index"
                                  autocomplete="new-password"
                                  :items="statesList"
                                  outlined dense
                                  clearable
                                  @blur="uiUtils.triggerEnterKeyPress('witness-state-' + index)"
                                  @input="witnessStateOnSelect(witness)"
                                  background-color="white"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-zip-code-label-' + index"
                     class="label-text"
                  >ZIP CODE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-zip-code-column-' + index">
                  <v-text-field
                      :data-cy="'witness-zip-code-input-' + index"
                      v-model="witness.location.postalCode"
                      v-mask="['#####' ,'#####-####']"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      :rules="witnessZipValidationRules"
                      @input="runWitnessZipOnInput(witness)"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" lg="3" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-country-label-' + index"
                     class="label-text"
                  >COUNTRY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-country-column-' + index">
                  <v-autocomplete v-model="witness.location.country"
                                  :data-cy="'witness-country-select-' + index"
                                  :items="countriesList"
                                  clearable
                                  outlined
                                  dense
                                  @input="witnessCountryOnSelect(witness)"
                                  background-color="white"
                                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="8" lg="6" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p :data-cy="'witness-notes-label-' + index"
                     class="label-text"
                  >NOTES</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" :data-cy="'witness-notes-column-' + index">
                  <v-textarea
                      :data-cy="'witness-notes-input-' + index"
                      v-model="witness.notes"
                      dense
                      outlined
                      auto-grow
                      clearable
                      counter="950"
                      maxLength="950"
                      background-color="white"
                      :rules="witnessNotesValidationRules"
                      @input="witnessNotesOnInput(witness)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:1.7em;' : ''"/>

        </v-card>

        <!-- START:   Delete Additional Witness Dialog   -->
        <v-dialog v-model="$store.state.showRemoveWitnessModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px" :data-cy="'remove-additional-witness-dialog-'+index">
          <v-card v-if="$store.state.showRemoveWitnessModal" style="overflow-x: hidden; border-radius: 12px" :data-cy="'remove-additional-witness-card-'+index" color="#f2f4f6" >
            <v-row justify="end">
              <v-col style="text-align: right" class="mr-4 py-0 col">
                <span class="exit-remove-button" @click="noButton" :data-cy="'remove-additional-witness-exit-'+index">x</span>
              </v-col>
            </v-row>
            <v-card-title class="py-0">
              <span style="color: #003366; font-size: 24px; font-weight: 500" class="headline" :data-cy="'remove-additional-witness-header-'+index">Remove Witness</span>
            </v-card-title>
            <v-card-text class="pb-0 pt-0">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0 pt-0">
                    <p class="modal-text" :data-cy="'remove-additional-witness-text-'+index">
                      Are you sure you want to remove witness {{$store.state.currentWitnessFullName}}?
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-row justify="end" class="pr-8">
              <v-card-actions class="pb-8">
                <v-btn depressed color="primary" :data-cy="'remove-additional-witness-yes-'+index"
                       @click="yesButton($store.state.currentWitnessIndex)">Yes</v-btn>
              </v-card-actions>
              <v-card-actions class="pb-8">
                <v-btn depressed text color="primary" outlined :data-cy="'remove-additional-witness-no-'+index" @click="noButton">No</v-btn>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- END:   Delete Witness Dialog   -->

  </v-col>
  </v-form>
</template>


<script lang="ts">
import {States} from "../../models/typelists/States";
import {Countries} from "../../models/typelists/Countries";
import {Suffixes} from "../../models/typelists/Suffixes";
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "../../models/FnolSubmission";
import {UiUtils} from "../../utils/ui-utils";
import {Witness} from '@/models/Witness';
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";

@Component
export default class AdditionalContactsWitnessComponent extends Vue {

  submission: FnolSubmission;
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  uiUtils = new UiUtils();
  suffixesList: Array<string> = Object.values(Suffixes);
  statesList: Array<string> = Object.values(States);
  countriesList: Array<string> = Object.values(Countries);

  witnessEmailValidationRules: Array<string> = [];
  witnessCityValidationRules: Array<string> = [];
  witnessZipValidationRules: Array<string> = [];
  witnessFirstNameValidationRules: Array<string> = [];
  witnessLastNameValidationRules: Array<string> = [];
  witnessAddressLine1ValidationRules: Array<string> = [];
  witnessAddressLine2ValidationRules: Array<string> = [];
  witnessPhoneValidationRules: Array<string> = [];
  witnessNotesValidationRules: Array<string> = [];

  created() {
    console.log("Witness component created")
    this.submission = this.$store.state.submission;
    this.$store.state.showRemoveWitnessModal = false;
  }

  runWitnessFirstNameOnInput(witness: Witness){
    witness.firstName = this.uiUtils.checkIfEmpty(witness.firstName)
    this.witnessFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false);
  }

  runWitnessLastNameOnInput(witness: Witness){
    witness.lastName = this.uiUtils.checkIfEmpty(witness.lastName)
    this.witnessLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false);
  }

  runWitnessEmailOnClick(witness: Witness){
     witness.primaryEmail = this.uiUtils.checkIfEmpty(witness.primaryEmail);
     this.witnessEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  runWitnessPhoneOnInput(witness: Witness){
    witness.primaryPhone = this.uiUtils.testForTrim(witness.primaryPhone)
    this.witnessPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  runWitnessAddressLine1OnInput(witness: Witness){
    witness.location.addressLine1 = this.uiUtils.checkIfEmpty(witness.location.addressLine1)
    this.witnessAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  runWitnessAddressLine2OnInput(witness: Witness){
    witness.location.addressLine2 = this.uiUtils.checkIfEmpty(witness.location.addressLine2)
    this.witnessAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  runWitnessCityOnInput(witness: Witness){
    witness.location.city = this.uiUtils.checkIfEmpty(witness.location.city);
    this.witnessCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false);
  }

  runWitnessZipOnInput(witness: Witness){
    witness.location.postalCode = this.uiUtils.testForTrim(witness.location.postalCode);
    this.witnessZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  removeWitness(idx: number) {
    this.$store.state.currentWitnessIndex = idx
    this.$store.state.currentWitnessFullName = ""

    if(this.submission.additionalContacts.witnesses[idx].firstName && this.submission.additionalContacts.witnesses[idx].lastName){
      this.$store.state.currentWitnessFullName = this.submission.additionalContacts.witnesses[idx].firstName + " " + this.submission.additionalContacts.witnesses[idx].lastName
    }
    this.$store.state.showRemoveWitnessModal = true;
  }

  yesButton(Index: number){
    this.submission.additionalContacts.witnesses.splice(Index, 1);
    this.$store.state.showRemoveWitnessModal = false;
  }

  noButton(){
    this.$store.state.showRemoveWitnessModal = false;
  }

  witnessStateOnSelect(witness: Witness) {
    witness.location.state = this.uiUtils.testForTrim(witness.location.state);
    witness.location.stateCode = this.uiUtils.getStateCode(witness.location.state);
  }

  witnessCountryOnSelect(witness: Witness) {
    witness.location.country = this.uiUtils.testForTrim(witness.location.country);
    witness.location.countryCode = this.uiUtils.getCountryCode(witness.location.country);
  }

  witnessSuffixOnSelect(witness: Witness) {
    witness.suffixCode= this.uiUtils.getSuffixCode(witness.suffix);
  }

  witnessNotesOnInput(witness: Witness){
    witness.notes = this.uiUtils.checkIfEmpty(witness.notes);
    this.witnessNotesValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }
}


</script>
<style scoped lang="scss">

</style>
