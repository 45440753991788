import {Vue} from "vue-property-decorator";
import {States} from "@/models/typelists/States";
import {LossTypes} from "@/models/typelists/LossTypes";
import {RegexUtils} from "@/utils/regex-utils";
import {FnolSubmission} from "@/models/FnolSubmission";
import {Insured} from "@/models/Insured";
import {LossInformation} from "@/models/LossInformation";
import {Contact} from "@/models/Contact";
import {LossUtils} from "@/utils/loss-utils";
import store from '@/store/store'
import dayjs from "dayjs";
import {AccidentInformation} from "@/models/AccidentInformation";
import {Party} from "@/models/Party";
import {LiabilityLossInformation} from "@/models/LiabilityLossInformation";
import {AdditionalContacts} from "@/models/AdditionalContacts";

export class ValidationUtils extends Vue {

  validateStateisValidState(v: string, stateCode: string): boolean {
    let result = false;

    if (v != null && stateCode != null) {
      for (const state in States) {
        if (state == stateCode) {
          result = true;
        }
      }
    }
    return result;
  }

  validateLossTypeHasValidLossTypeCode(v: string, lossTypeCode: string): boolean {
    let result = false;
    if (v != null && lossTypeCode != null) {
      for (const lossType in LossTypes) {
        if (lossTypeCode == lossType) {
          result = true;
        }
      }
    }
    return result;
  }

  validateDateNotInFuture(selectedDate: string): boolean {
    const selectedDateInput = new Date(selectedDate);
    const currentDate = new Date();
    return !(selectedDateInput != null && selectedDateInput > currentDate);
  }

  validateDateNotBeforeAnotherDate(selectedDate: string, cutOffDate: string): boolean {
    const selectedDateInput = new Date(selectedDate);
    const cutOffDateInput = new Date(cutOffDate);
    return !(selectedDateInput != null && selectedDateInput < cutOffDateInput);
  }

  validateEmailIsValidFormat(email: string): boolean {
    if (email == null || email == '') {
      return true;
    }
    return RegexUtils.EMAIL.test(email)
  }

  validateUsZipIsValidLength(zip: string): boolean {
    if (zip == null || zip == '') {
      return true;
    }

    // Check if zip ends with a hyphen
    if (zip.endsWith("-")) {
      return false;
    }

    const parsedZip = zip.replace("-", "");

    return (parsedZip.length > 1 && parsedZip.length == 5) || (parsedZip.length > 1 && parsedZip.length == 9);
  }

  validateYearIsValidLength(year: string): boolean {
    if (year == null || year == '') {
      return true;
    }

    return year.length == 4;
  }

  validateYearRange(year: string): boolean {
    if (year == null || year == '') {
      return true;
    } else {
      return RegexUtils.YYYY_CHECK.test(year)
    }
  }

  validateMinLength(string: string, length: number): boolean {
    return string == null || string == '' || string.length >= length;
  }

  validatePhoneNumberIsNotAllSameNumbers(phoneNumber: string): boolean {
    if (phoneNumber == null || phoneNumber == '') {
      return true;
    }
    const parsedPhone = phoneNumber
      .replace('(', "")
      .replace('-', "")
      .replace(')', "")
      .replace(" ", "");
    if (parsedPhone.length == 10) {
      return RegexUtils.PHONE_US_ARE_NOT_ALL_SAME_NUMBERS.test(parsedPhone)
    }
  }

  validatePhoneNumberDoesNotStartWith0or1(phoneNumber: string): boolean {
    if (phoneNumber == null || phoneNumber == '') {
      return true;
    }
    const parsedPhone = phoneNumber.replace('(', "")
      .replace('-', "").replace(')', "")
      .replace(" ", "");

    if (parsedPhone.length > 1) {
      return RegexUtils.PHONE_US_DOES_NOT_START_WITH_1_OR_0.test(parsedPhone)
    } else {
      return true;
    }
  }

  validateOptionalDate(dateInput: string): boolean {
    if (dateInput == null || dateInput == '') {
      return true;
    }
    return dayjs(dateInput, ["YYYY-MM-DD", "MM/DD/YYYY"], true).isValid();
  }

  validateAddress(fieldInput: string): boolean {
    return RegexUtils.ADDRESS.test(fieldInput)
  }
  validateAlphaNumWithSpaces(fieldInput: string): boolean {
    return RegexUtils.ALPHA_NUM_WITH_SPACE.test(fieldInput)
  }

  validatePlateCharacters(fieldInput: string): boolean {
    return RegexUtils.VEHICLE_PLATE_NUMBER_CHARACTERS.test(fieldInput)
  }

  validateAlphaNumNoSpaces(fieldInput: string): boolean {
    return RegexUtils.ALPHA_NUM_NO_SPACE.test(fieldInput)
  }

  validateRequiredFieldsOnEntireSubmission(submission: FnolSubmission): boolean {
    if (!submission) {
      return false;
    }

    if ((!this.validateRequiredFieldsOnInsured(submission.customerInformation.insured) && (!submission.gettingStarted.knowPolicyNumberInfo || submission.policyDetails.policyBusinessUnit == 'PIM'))) {
      return false;
    }

    if (!this.validateContactRequiredFields(submission.customerInformation.claimReporter)) {
      return false;
    }

    if (!this.validateContactRequiredFields(submission.customerInformation.primaryContact)) {
      return false;
    }

    if (!this.validateRequiredFieldsOnLossInfo(submission)) {
      return false;
    }

    if (submission.lossInformation.lossType == 'Cargo')
    {
      if (!this.validateRequiredFieldsOnCargo(submission)) {
        return false;
      }
    }

    if (submission.lossInformation.lossType == 'Property') {
      if (!this.validateRequiredFieldsOnProperty(submission)) {
        return false;
      }
    }

    if (submission.lossInformation.lossType == 'Liability') {
      if (!this.validateRequiredFieldsOnLiability(submission.lossInformation.liabilityLossInformation)) {
        return false;
      }
    }

    if (submission.lossInformation.lossType == "Auto"){
      if (submission.lossInformation.accidentInformation.hasDriverInfo) {
        if (!this.validateRequiredFieldsOnInsuredDriver(submission.lossInformation.accidentInformation)) {
          return false;
        }
      }

      if (submission.lossInformation.accidentInformation.passengers != null &&
          submission.lossInformation.accidentInformation.passengers.length > 0) {
        if (!this.validateRequiredFieldsOnInsuredPassengers(submission.lossInformation.accidentInformation)) {
          return false;
        }
      }

      for (const party of submission.otherParty?.parties) {
        if (party?.hasDriverInfo) {
          if (!this.validateRequiredFieldsOnOtherPartyDriver(party)) {
            return false;
          }
        }
        if(party?.otherPassengers.length > 0){
          if(!this.validateRequiredFieldsOnOtherPartyPassenger(party)){
            return false
          }
        }
      }
    }

    if(submission.additionalContacts){
      if(!this.validateRequiredFieldsOnAdditionalContactsWitness(submission.additionalContacts)){
        return false;
      }
      if(!this.validateRequiredFieldsOnAdditionalContactsMedicalProviders(submission.additionalContacts)){
        return false;
      }
      if(!this.validateRequiredFieldsOnAdditionalContactsFirePoliceEMT(submission.additionalContacts)){
        return false;
      }
    }

    return true;
  }

  validateRequiredFieldsOnInsured(insured: Insured): boolean {
    if (!insured) {
      return false;
    }

    if (insured.isFullNameRequired) {
      if (!insured.fullName) {
        return false
      }
    }

    return true;
  }

  validateContactRequiredFields(contact: Contact): boolean {
    if (!contact) {
      return false;
    }

    return !(!contact.firstName || !contact.lastName || !contact.primaryEmail || !contact.primaryPhone);


  }

  validateRequiredFieldsOnLossInfo(submission: FnolSubmission): boolean {
    if (!submission.lossInformation) {
      return false;
    }

    if (!this.validateConventionalLossInfo(submission.lossInformation)) {
      return false;
    }

    return this.validateDetailLossCauseOnLossInfo(submission);

  }

  validateDetailLossCauseOnLossInfo(submission: FnolSubmission): boolean {
    const lossUtils = new LossUtils();

    lossUtils.getLossCauseDetailSelections(submission)
    if (store.state.lossCauseDetailList.length > 1) {
      if (!submission.lossInformation.detailedLossCause || !submission.lossInformation.detailedLossCauseCode) {
        return false;
      }
    }
    return true;
  }

  validateConventionalLossInfo(lossInfo: LossInformation): boolean {
    if (!lossInfo.lossType || !lossInfo.lossCause || !lossInfo.lossCauseCode || !lossInfo.lossDescription) {
      return false
    }

    if (!lossInfo?.location?.state) {
      return false
    }

    if (lossInfo.lossCause == "All Other" || lossInfo.lossCauseCode == "allother") {
      if (!lossInfo.otherDescription) {
        return false;
      }
    }

    return true;
  }

  validateRequiredFieldsOnCargo(submission: FnolSubmission): boolean {
    if (!submission.lossInformation.cargoLossInformation) {
      return false;
    }

    if (submission.policyDetails?.policyBusinessUnit == 'PIM'){
      if (!submission?.lossInformation?.cargoLossInformation?.lossEstimateAmount) {
        return false;
      }
    }

    if(submission.lossInformation.cargoLossInformation.isShipperInfoAvailable){
      if(!submission.lossInformation.cargoLossInformation.shipper?.fullName){
        return false;
      }
    }

    if(submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable){
      if(!submission.lossInformation.cargoLossInformation.consignee?.fullName){
        return false;
      }
    }

    if(submission.lossInformation.cargoLossInformation.wasTowCompanyUsed){
      if(!submission.lossInformation.cargoLossInformation.towCompany?.fullName){
        return false;
      }
    }


    return true;
  }

  validateRequiredFieldsOnProperty(submission: FnolSubmission): boolean {
    if (!submission.lossInformation?.propertyLossInformation) {
      return false;
    }

    if (submission.policyDetails?.policyBusinessUnit == 'PIM'){
      if (!submission.lossInformation?.propertyLossInformation.estimateOfLoss) {
        return false;
      }
    }
    if(submission.lossInformation?.propertyLossInformation?.propertyOwner) {
      if (!submission.lossInformation?.propertyLossInformation?.propertyOwner?.fullName) {
        return false;
      }
    }

    return true;
  }

  validateRequiredFieldsOnLiability(liability: LiabilityLossInformation): boolean {
    if (liability?.injuredPersons) {
      for (const injuredPerson of liability.injuredPersons) {
        if (!injuredPerson.firstName) {
          return false;
        }
        if (!injuredPerson.lastName) {
          return false;
        }
      }
    }

    return !(liability?.damagedPropertyInformation?.hasPropertyOwner
        && !liability?.damagedPropertyInformation?.propertyOwner?.fullName);


  }

  validateRequiredFieldsOnInsuredDriver(accident: AccidentInformation): boolean {
    if (accident?.driver) {
      if (!accident?.driver.firstName || !accident?.driver.lastName) {
        return false
      }
    }
    return true;
  }

  validateRequiredFieldsOnOtherPartyDriver(party: Party): boolean {
    if (party?.driver) {
      if (!party?.driver?.firstName || !party?.driver?.lastName) {
        return false
      }
    }
    return true;
  }

  validateRequiredFieldsOnInsuredPassengers(accident: AccidentInformation): boolean {
    if (accident && accident.passengers) {
      for (const passenger of accident.passengers) {
        if (!passenger.firstName || !passenger.lastName) {
          return false
        }
      }
    }
    return true;
  }

  validateRequiredFieldsOnOtherPartyPassenger(party: Party): boolean {
    if (party?.otherPassengers) {
      for (const passenger of party?.otherPassengers)
        if (!passenger?.firstName || !passenger?.lastName) {
          return false
        }
    }
    return true
  }

  validateRequiredFieldsOnAdditionalContactsWitness(additionalContact: AdditionalContacts): boolean {
    if (additionalContact?.witnesses) {
      for (const witness of additionalContact?.witnesses)
        if (!witness?.firstName || !witness?.lastName) {
          return false
        }
    }
    return true
  }

  validateRequiredFieldsOnAdditionalContactsMedicalProviders(additionalContact: AdditionalContacts): boolean {
    if (additionalContact?.medicalProviders) {
      for (const medicalProvider of additionalContact?.medicalProviders)
        if (!medicalProvider?.fullName) {
          return false
        }
    }
    return true
  }

  validateRequiredFieldsOnAdditionalContactsFirePoliceEMT(additionalContact: AdditionalContacts): boolean {
    if (additionalContact?.firePoliceEMTs) {
      for (const firePoliceEMT of additionalContact?.firePoliceEMTs)
        if (!firePoliceEMT?.fullName || !firePoliceEMT?.type) {
          return false
        }
    }
    return true
  }
  validateFirstOrLastNameInput(fieldInput: string): boolean {
    return RegexUtils.ALPHA_WITH_ACCENT_ALPHA_CHARACTERS.test(fieldInput)
  }

  validateFullNameInput(fieldInput: string): boolean {
    return RegexUtils.FULL_NAME_OR_COMPANY_NAME.test(fieldInput)
  }

  validateTextArea(fieldInput: string): boolean {
    return RegexUtils.TEXT_AREA.test(fieldInput)
  }

  validateStringOnlyLettersPeriodsAndHyphens(fieldInput: string): boolean {
    return RegexUtils.LETTERS_PERIODS_HYPHENS.test(fieldInput)
  }

  validateOptionalStringOnlyNumbers(fieldInput: string): boolean {
    if (fieldInput == null || fieldInput == '') {
      return true;
    }
    const regEx = /^[0-9\s]+$/
    return regEx.test(fieldInput);
  }
  validateStringNoSpacesOnlyNumbersAndLetters(inputString: string) {
    const regEx = /^[a-zA-Z0-9]+([a-zA-Z0-9]+)*$/;
    return regEx.test(inputString);
  }

  async triggerBlurs(fields: Array<string>) {
    for (const fieldName of fields) {
      await this.focusById(fieldName);
      await this.blurById(fieldName);
    }
  }

  async triggerBlur(id: string) {
    await this.focusById(id);
    await this.blurById(id);
  }

  async focusById(id: string) {
    document.getElementById(id).focus();
  }

  async blurById(id: string) {
    document.getElementById(id).blur();
  }

  setToUpperCase(value: string) {
    if (value) {
      return value.toUpperCase();
    }
  }


}
