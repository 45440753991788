<template>
  <v-form v-model="$store.state.insuredComponentIsValid" data-cy="insured-component">
    <v-row class="proximaNovaFont">
      <v-col>
        <v-card class="card-background" elevation="0">
          <v-row>
            <v-col>
              <div>
                <span class="section-label berninaSansCompressedFont pl-8" data-cy="insured-information-header">Insured</span>
              </div>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col class="input-spacing" cols="10" lg="6" md="8">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text" data-cy="insured-name-label">INSURED NAME<span v-if="submission.gettingStarted.knowPolicyNumberInfo == false" class="req-asterisk">*</span>
                  </p>
                </v-col>
                <v-col class="hidden-sm-and-down"/>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="insured-name-column">
                  <v-text-field
                      id="insured-name"
                      v-model="submission.customerInformation.insured.fullName"
                      :rules="fullNameValidationRules"
                      autocomplete="new-password"
                      background-color="white"
                      clearable
                      counter="100"
                      data-cy="insured-name-input"
                      dense
                      maxLength="100"
                      outlined
                      @input="fullNameValidationOnInput"
                      @keydown="fullNameValidationOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col class="input-spacing" cols="10" lg="6" md="8">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text" data-cy="insured-address-line-1-label">ADDRESS LINE 1</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="insured-address-line-1-column">
                  <v-text-field
                      v-model="submission.customerInformation.insured.location.addressLine1"
                      :rules="insuredAddressLine1ValidationRules"
                      background-color="white"
                      clearable
                      counter="60"
                      data-cy="insured-address-line-1-input"
                      dense
                      maxlength="60"
                      outlined
                      @input="insuredAddressLine1ValidationOnInput()"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col class="input-spacing" cols="10" lg="6" md="8">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text"
                     data-cy="insured-address-line-2-label"
                  >ADDRESS LINE 2
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="insured-address-line-2-column">
                  <v-text-field
                      v-model="submission.customerInformation.insured.location.addressLine2"
                      :rules="insuredAddressLine2ValidationRules"
                      background-color="white"
                      clearable
                      counter="60"
                      data-cy="insured-address-line-2-input"
                      dense
                      maxLength="60"
                      outlined
                      @input="insuredAddressLine2ValidationOnInput()"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col class="input-spacing" cols="10" lg="3" md="4">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text"
                     data-cy="insured-city-label"
                  >CITY
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing" data-cy="insured-city-column">
                  <v-text-field
                      v-model="submission.customerInformation.insured.location.city"
                      :rules="insuredCityValidationRules"
                      background-color="white"
                      clearable
                      counter="60"
                      data-cy="insured-city-input"
                      dense
                      maxLength="60"
                      outlined
                      @input="insuredCityValidationOnInput()"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="input-spacing" cols="10" lg="3" md="4">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text"
                     data-cy="insured-state-label"
                  >STATE
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="insured-state-column">
                  <v-autocomplete id="insured-state-select"
                                  v-model="submission.customerInformation.insured.location.state"
                                  :items="statesList"
                                  autocomplete="new-password"
                                  background-color="white"
                                  clearable
                                  data-cy="insured-state-select"
                                  dense
                                  outlined
                                  @blur="uiUtils.triggerEnterKeyPress('insured-state-select')"
                                  @input="insuredStateOnSelect"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col class="input-spacing" cols="10" lg="3" md="4">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text"
                     data-cy="insured-zip-code-label"
                  >ZIP CODE
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="insured-zip-code-column">
                  <v-text-field
                      v-model="submission.customerInformation.insured.location.postalCode"
                      v-mask="['#####' ,'#####-####']"
                      :rules="insuredZipCodeValidationRules"
                      background-color="white"
                      clearable
                      data-cy="insured-zip-code-input"
                      dense
                      outlined
                      @input="insuredZipCodeValidationOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col class="input-spacing" cols="10" lg="3" md="4">
              <v-row>
                <v-col class="input-spacing">
                  <p class="label-text"
                     data-cy="insured-country-label"
                  >COUNTRY
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="insured-country-column">
                  <v-autocomplete v-model="submission.customerInformation.insured.location.country"
                                  :items="countriesList"
                                  background-color="white"
                                  data-cy="insured-country-select" dense
                                  outlined
                                  @input="insuredCountryOnSelect">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {Insured} from "@/models/Insured";
import {ContactCorrectionUtil} from "@/utils/contact-correction-util";

@Component
export default class InsuredComponent extends Vue {

  submission: FnolSubmission;

  uiUtils = new UiUtils();
  contactCorrectionUtil = new ContactCorrectionUtil()

  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  fullNameValidationRules: Array<string> = [];
  insuredAddressLine1ValidationRules: Array<string> = [];
  insuredAddressLine2ValidationRules: Array<string> = [];
  insuredCityValidationRules: Array<string> = [];
  insuredZipCodeValidationRules: Array<string> = [];

  statesList: Array<string> = Object.values(States);
  countriesList: Array<string> = Object.values(Countries);

  created() {
    this.submission = this.$store.state.submission;
    if (!this.submission.customerInformation.insured) {
      this.submission.customerInformation.insured = new Insured();
    } else {
      this.submission.customerInformation.insured.isFullNameRequired = this.submission.policyDetails.policyBusinessUnit == 'PIM' || !this.submission.gettingStarted.knowPolicyNumberInfo;
    }
  }

  fullNameValidationOnInput() {
    this.submission.customerInformation.insured.fullName = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.fullName)
    this.fullNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false)
  }

  insuredAddressLine1ValidationOnInput() {
    this.setSameAsInsured();
    this.submission.customerInformation.insured.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.location.addressLine1)
    this.insuredAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  insuredAddressLine2ValidationOnInput() {
    this.setSameAsInsured();
    this.submission.customerInformation.insured.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.location.addressLine2)
    this.insuredAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  insuredCityValidationOnInput() {
    this.setSameAsInsured();
    this.submission.customerInformation.insured.location.city = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.location.city)
    this.insuredCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  insuredZipCodeValidationOnInput() {
    this.setSameAsInsured();
    this.submission.customerInformation.insured.location.postalCode = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.location.postalCode)
    this.insuredZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  insuredStateOnSelect() {
    this.submission.customerInformation.insured.location.state = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.location.state)
    this.submission.customerInformation.insured.location.stateCode = this.uiUtils.getStateCode(this.submission.customerInformation.insured.location.state);
    this.setSameAsInsured();
  }

  insuredCountryOnSelect() {
    this.submission.customerInformation.insured.location.country = this.uiUtils.checkIfEmpty(this.submission.customerInformation.insured.location.country)
    this.submission.customerInformation.insured.location.countryCode = this.uiUtils.getCountryCode(this.submission.customerInformation.insured.location.country);
    this.setSameAsInsured();
  }

  setSameAsInsured() {
    this.submission.customerInformation = this.contactCorrectionUtil.correctContacts(this.submission.customerInformation)
  }


}


</script>
<style lang="scss" scoped>

</style>
