<template>
  <div data-cy="cargo-loss-information-review-vue">
  <!-- Cargo Loss Information -->
<!--  <div class="divider"><span data-cy="cargo-loss-information-section" class="px-4">Cargo Information</span></div>-->

    <v-row class="mx-0">
      <v-btn :block="$vuetify.breakpoint.smAndDown" outlined tile class="elevation-0">
                <span class="edit-section-label" data-cy="edit-cargo-loss-information-cta" @click="editCargoLossInformationOnClick">
                <v-icon>mdi-pencil</v-icon> Go To Cargo Information</span>
      </v-btn>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-estimate-of-cost-label">WHAT IS THE ESTIMATE OF THE LOSS?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-estimate-of-cost-value" v-if="submission.lossInformation.cargoLossInformation.lossEstimateAmount != null">
            {{submission.lossInformation.cargoLossInformation.lossEstimateAmount}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-date-cargo-picked-up-label">WHAT DATE WAS THE CARGO PICKED UP?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-date-cargo-picked-up-value" v-if="submission.lossInformation.cargoLossInformation.cargoPickupDate != null">
            {{dateUtils.formatDateForDisplay(submission.lossInformation.cargoLossInformation.cargoPickupDate)}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-state-cargo-picked-up-label">WHAT STATE WAS THE CARGO PICKED UP FROM?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-state-cargo-picked-up-value" v-if="submission.lossInformation.cargoLossInformation.cargoStatePickedUpFrom != null">
            {{submission.lossInformation.cargoLossInformation.cargoStatePickedUpFrom}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-date-cargo-supposed-to-be-delivered-label">WHAT DATE WAS THE CARGO SUPPOSED TO BE DELIVERED?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-date-cargo-supposed-to-be-delivered-value" v-if="submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate != null">
            {{dateUtils.formatDateForDisplay(submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate)}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-state-cargo-delivered-label">WHAT STATE WAS THE CARGO SUPPOSED TO BE DELIVERED TO?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-state-cargo-delivered-value" v-if="submission.lossInformation.cargoLossInformation.cargoExpectedStateDeliveredTo != null">
            {{submission.lossInformation.cargoLossInformation.cargoExpectedStateDeliveredTo}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="sub-section-label">
          <div data-cy="cargo-items-information-section">Cargo Items</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-damage-to-cargo-label">WAS THERE DAMAGE TO THE CARGO?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0 pb-2" data-cy="rs-damage-to-cargo-value" v-if="submission.lossInformation.cargoLossInformation.wasThereDamageToCargo"
            >{{submission.lossInformation.cargoLossInformation.wasThereDamageToCargo}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0 pt-0">
        <v-row class="rs-labels">
          <div data-cy="rs-cargo-items-label">CARGO ITEMS</div>
        </v-row>
        <v-row class="rs-values"  v-for="(cargoItem, index) in submission.lossInformation.cargoLossInformation.cargoList" :key="index">
          <div class="pt-0" :data-cy="'rs-cargo-items-' + index + '-value'">
            {{cargoItem.itemName}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="sub-section-label">
          <div data-cy="shipper-information-section">Shipper</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0 pt-0">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-info-available-label">DO YOU HAVE THE SHIPPER'S INFORMATION?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-shipper-info-available-value" v-if="submission.lossInformation.cargoLossInformation.isShipperInfoAvailable != null">
            {{this.uiUtils.convertBooleanToYesNo(submission.lossInformation.cargoLossInformation.isShipperInfoAvailable)}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-full-name-label">SHIPPER NAME</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-shipper-full-name-value" v-if="submission.lossInformation.cargoLossInformation.shipper.fullName != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.fullName}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-address-line-1-label">ADDRESS LINE 1</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-shipper-address-line-1-value" v-if="submission.lossInformation.cargoLossInformation.shipper.location.addressLine1 != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.location.addressLine1}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-address-line-2-label">ADDRESS LINE 2</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-shipper-address-line-2-value" v-if="submission.lossInformation.cargoLossInformation.shipper.location.addressLine2 != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.location.addressLine2}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-city-label">CITY</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-shipper-city-value" v-if="submission.lossInformation.cargoLossInformation.shipper.location.city != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.location.city}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-state-label">STATE</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-shipper-state-value" v-if="submission.lossInformation.cargoLossInformation.shipper.location.state != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.location.state}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-zip-label">ZIP</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-shipper-zip-value" v-if="submission.lossInformation.cargoLossInformation.shipper.location.postalCode != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.location.postalCode}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-country-label">COUNTRY</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-shipper-country-value" v-if="submission.lossInformation.cargoLossInformation.shipper.location.country != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.location.country}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-email-label">EMAIL</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0 rs-wrapping-text" data-cy="rs-shipper-email-value" v-if="submission.lossInformation.cargoLossInformation.shipper.primaryEmail != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.primaryEmail}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-shipper-phone-label">PHONE</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-shipper-phone-value" v-if="submission.lossInformation.cargoLossInformation.shipper.primaryPhone != null && submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.shipper.primaryPhone}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="sub-section-label">
          <div data-cy="consignee-information-section">Consignee</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0 pt-0">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-info-available-label">DO YOU HAVE THE CONSIGNEE'S INFORMATION?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-consignee-info-available-value" v-if="submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable != null">
            {{this.uiUtils.convertBooleanToYesNo(submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable)}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-full-name-label">CONSIGNEE NAME</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-consignee-full-name-value" v-if="submission.lossInformation.cargoLossInformation.consignee.fullName != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.fullName}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-address-line-1-label">ADDRESS LINE 1</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-consignee-address-line-1-value" v-if="submission.lossInformation.cargoLossInformation.consignee.location.addressLine1 != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.location.addressLine1}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-address-line-2-label">ADDRESS LINE 2</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-consignee-address-line-2-value" v-if="submission.lossInformation.cargoLossInformation.consignee.location.addressLine2 != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.location.addressLine2}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-city-label">CITY</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-consignee-city-value" v-if="submission.lossInformation.cargoLossInformation.consignee.location.city != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.location.city}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-state-label">STATE</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-consignee-state-value" v-if="submission.lossInformation.cargoLossInformation.consignee.location.state != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.location.state}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-zip-label">ZIP</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-consignee-zip-value" v-if="submission.lossInformation.cargoLossInformation.consignee.location.postalCode != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.location.postalCode}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-country-label">COUNTRY</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-consignee-country-value" v-if="submission.lossInformation.cargoLossInformation.consignee.location.country != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.location.country}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-email-label">EMAIL</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0 rs-wrapping-text" data-cy="rs-consignee-email-value" v-if="submission.lossInformation.cargoLossInformation.consignee.primaryEmail != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.primaryEmail}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-consignee-phone-label">PHONE</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-consignee-phone-value" v-if="submission.lossInformation.cargoLossInformation.consignee.primaryPhone != null && submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
            {{submission.lossInformation.cargoLossInformation.consignee.primaryPhone}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="sub-section-label">
          <div data-cy="tow-company-information-section">Wrecker/Tow Company</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0 pt-0">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-info-available-label">DO YOU HAVE THE WRECKER/TOW COMPANY'S INFORMATION?</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-tow-company-info-available-value" v-if="submission.lossInformation.cargoLossInformation.wasTowCompanyUsed != null"
          >{{this.uiUtils.convertBooleanToYesNo(submission.lossInformation.cargoLossInformation.wasTowCompanyUsed)}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0">
        <v-row class="rs-labels" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
          <div data-cy="rs-tow-company-full-name-label">WRECKER/TOW COMPANY NAME</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-tow-company-full-name-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.fullName != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.fullName}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-address-line-1-label">ADDRESS LINE 1</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-tow-company-address-line-1-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1 != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-address-line-2-label">ADDRESS LINE 2</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-tow-company-address-line-2-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2 != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-city-label">CITY</div>
        </v-row>
        <v-row class="rs-values rs-wrapping-text">
          <div class="pt-0" data-cy="rs-tow-company-city-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.location.city != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.location.city}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-state-label">STATE</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-tow-company-state-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.location.state != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.location.state}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-zip-label">ZIP</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-tow-company-zip-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.location.postalCode != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.location.postalCode}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-country-label">COUNTRY</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-tow-company-country-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.location.country != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.location.country}}</div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-email-label">EMAIL</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0 rs-wrapping-text" data-cy="rs-tow-company-email-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.primaryEmail != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
            {{submission.lossInformation.cargoLossInformation.towCompany.primaryEmail}}</div>
        </v-row>
      </v-col>

      <v-col class="pb-0" cols="4">
        <v-row class="rs-labels">
          <div data-cy="rs-tow-company-phone-label">PHONE</div>
        </v-row>
        <v-row class="rs-values">
          <div class="pt-0" data-cy="rs-tow-company-phone-value" v-if="submission.lossInformation.cargoLossInformation.towCompany.primaryPhone != null && submission.lossInformation.cargoLossInformation.wasTowCompanyUsed"
          >{{submission.lossInformation.cargoLossInformation.towCompany.primaryPhone}}</div>
        </v-row>
      </v-col>
    </v-row>


  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {DateUtils} from "@/utils/date-utils";
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class CargoLossInformationReview extends Vue {

  submission: FnolSubmission;
  dateUtils: DateUtils = new DateUtils();
  uiUtils: UiUtils = new UiUtils();
  created() {
    console.log('Loss Information Review component created')
    this.submission = this.$store.state.submission;
  }

  editCargoLossInformationOnClick() {
    this.uiUtils.navigate('cargo-loss-information')
  }

}
</script>

<style scoped lang="scss">

</style>

