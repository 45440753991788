<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" data-cy="content-card" fluid>
    <slot></slot>
  </v-container>
  <div v-else>
    <slot></slot>
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'

@Component
export default class ContentCard extends Vue {}

</script>

<style lang="scss" scoped>


</style>
