<template>
  <v-col data-cy="getting-started-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"/>
      </v-col>

      <v-col>
        <v-row style="display: flex; justify-content: space-around" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div>
              <span data-cy="getting-started-label" class="page-label berninaSansCompressedFont col-10">Getting Started</span>
            </div>
          </v-col>

          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{submission.reportNumber}}</span>
          </v-col>

        </v-row>

        <v-form
            autocomplete="off"
            v-model="gettingStartedPageValid"
            class="form-content">
          <v-row>
            <v-col>
              <v-card class="card-background" elevation="0" >
                <v-row class="pl-10 pb-0 pr-6" v-if="$store.state.hasBeenPassedGettingStarted" :justify-content="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn data-cy="edit-button" @click="$store.state.showPolicyResetModal=true" outlined class="previous-button pr-6 ma-3">
                    <span><v-icon>mdi-pencil</v-icon>Edit</span>
                  </v-btn>
                </v-row>

                <v-row class="pl-10 pb-3 pt-7 proximaNovaFont">

<!-- May be needed in the future? -->
<!--                  <v-row class="pl-6 pb-5" >-->
<!--                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 11" class="liability-loss-type-container pa-0">-->
<!--                      <v-col class="liability-loss-type-helper-text" cols="auto">-->
<!--                        <v-icon color="#00274E" data-cy="liability-loss-type-helper-icon">mdi-alert</v-icon>-->
<!--                      </v-col>-->
<!--                      <v-col class="liability-loss-type-helper-text" cols="auto">-->
<!--                        <div data-cy="workers-comp-warning">Workers' Compensation claims should not be reported as Liability claims. If you are trying to-->
<!--                          report a WC claim, please call 1-844-GAIG NOW / 1-844-424-4669.</div>-->
<!--                      </v-col>-->
<!--                    </v-col>-->
<!--                  </v-row>-->

                  <v-col md="8">
                    <v-form class="px-0 py-0" v-model="dateOfLossIsValid">
                      <v-row class="proximaNovaFont">
                          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 11" class="input-spacing">
                            <p data-cy="date-of-loss-label" class="label-text">DATE OF LOSS<span class="req-asterisk">*</span></p>
                            <v-col class="pa-0" data-cy="date-of-loss-column">
                              <v-menu
                                  v-model="dateOfLossPicker"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="150px">
                                <template v-slot:activator="{ off }">
                                  <v-text-field
                                      autocomplete="off"
                                      data-cy="date-of-loss-input"
                                      id="date-of-loss"
                                      v-model="formattedDateOfLoss"
                                      v-mask="'##/##/####'"
                                      append-icon="mdi-calendar"
                                      @click:append="dateOfLossPicker = true"
                                      @click:clear="submission.gettingStarted.dateOfLoss = null"
                                      clearable
                                      outlined
                                      :disabled="$store.state.hasBeenPassedGettingStarted"
                                      v-on="off"
                                      dense
                                      background-color="white"
                                      :rules="dateOfLossValidationRules"
                                      @input="validateDateOfLossTextField"
                                      @blur="validateDateOfLossTextField">
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                    data-cy="date-of-loss-picker"
                                    @input="dateOfLossOnPickerSelect"
                                    v-model="submission.gettingStarted.dateOfLoss"
                                    id="gs-date-picker">
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-col>
                          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 11" class="input-spacing" style="padding-top: 0.37em">
                                <p data-cy="time-of-loss-label" class="label-text">TIME</p>
                              <v-col class="pa-0" data-cy="time-of-loss-column">
                                <v-text-field
                                    autocomplete="off"
                                    data-cy="time-of-loss-input"
                                    v-model="submission.gettingStarted.timeOfLoss"
                                    @click:clear="submission.gettingStarted.timeOfLoss = null"
                                    clearable
                                    type="time"
                                    outlined
                                    :disabled="$store.state.hasBeenPassedGettingStarted"
                                    dense
                                    background-color="white">
                                </v-text-field>
                              </v-col>
                          </v-col>
                        </v-row>
                    </v-form>

                    <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="know-policy-number-label" class="label-text">DO YOU KNOW THE POLICY NUMBER?<span class="req-asterisk">*</span></p>
                        </v-col>
                    </v-row>
                    <v-row style="background-color: #f4f5f7" >
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 11 : 8" class="input-spacing" data-cy="know-policy-number-radio-group-column">
                          <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                           data-cy="know-policy-number-radio-group"
                                           class="radio-container"
                                           v-model="submission.gettingStarted.knowPolicyNumberInfo"
                                           :error-messages="knowPolicyNumberInfoErrorMessage()"
                                           :disabled="shouldKnowPolicyInfoBeDisabled()"
                          >
                              <v-radio
                                  label="Yes"
                                  :value=true
                                  data-cy="know-policy-number-radio-yes"
                                  @change="onKnownPolicyNumber"
                              ></v-radio>
                              <v-radio
                                  label="No"
                                  :value=false
                                  data-cy="know-policy-number-radio-no"
                                  @change="onNoKnowPolicyNumber()"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                  </v-col>
                </v-row>

<!--                NEW NON_INTERNAL POLICY NUMBER SELECT-->
                <v-row class="pl-10 proximaNovaFont" v-if="showNonInternalPolicySelect()" data-cy="non-internal-policy-column">
                  <v-col md="8">
                    <v-row class="proximaNovaFont pr-md-2">
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 11" class="input-spacing">
                        <p data-cy="non-internal-policy-label" class="label-text pb-0">POLICY<span class="req-asterisk">*</span></p>
                          <div v-if="showNonInsuredDropdownLoader" class="pt-2">
                              <v-row class="mb-2">
                                <v-progress-circular
                                        data-cy="non-internal-dropdown-loader"
                                        class="ml-4"
                                        indeterminate
                                        color="#00274E"></v-progress-circular>
                                <p data-cy="non-insured-dropdown-loader-notice" class="label-text pl-4 pt-2 px-0">Loading policies...</p>
                              </v-row>
                          </div>
                          <div v-else>
                              <p data-cy="search-by-label-insured" class="label-text py-0 px-0" v-if="$store.state.isUserAnInsured">You can search by Policy Number</p>
                              <p data-cy="search-by-label" class="label-text py-0 px-0" v-else>You can search by Insured Name or Policy Number</p>
                              <v-col class="pa-0 input-spacing" >
                                  <v-autocomplete data-cy="non-internal-policy-select"
                                      v-model="$store.state.selectedPolicyInfo"
                                      dense
                                      return-object
                                      outlined
                                      clearable
                                      background-color="white"
                                      @input="setNonInternalPolicyData()"
                                      @change="shouldClearPolicyInfoWhenManualEntry"
                                      :error-messages="nonInternalPolicyNumberValidationRules"
                                      :items="$store.state.userPolicies"
                                      :item-text="policy => policy.formattedPolicyString"
                                      :disabled="$store.state.hasBeenPassedGettingStarted">
                                      <template v-slot:selection="{ item }">
                                          <span v-if="item.insuredName" class="font-weight-bold pr-2" :class="$store.state.hasBeenPassedGettingStarted ? '' : 'agent-text'">{{ item.insuredName }}</span>
                                          <span class="font-weight-bold"  :class="$store.state.hasBeenPassedGettingStarted ? '' : 'agent-text'">{{ item.policySymbol }}{{ item.policyNumber }}</span>
                                          <span v-if="item.policyEffectiveDate" class="pl-2" :class="$store.state.hasBeenPassedGettingStarted ? '' : 'agent-text'">{{ dateUtils.formatDateForDisplay(item.policyEffectiveDate) }} - {{ dateUtils.formatDateForDisplay(item.policyExpirationDate) }}</span>
                                      </template>
                                      <template v-slot:item="{ item }">
                                          <v-list-item-content>
                                              <v-list-item-title>
                                                  <span v-if="item.insuredName" class="font-weight-bold agent-text pr-1">{{ item.insuredName }}</span>
                                                  <span class="font-weight-bold agent-text" :class="item.policyNumber ? 'px-1' : ''">{{ item.policySymbol }}{{ item.policyNumber }}</span>
                                                  <span v-if="item.policyEffectiveDate" class="agent-text pl-1">{{ dateUtils.formatDateForDisplay(item.policyEffectiveDate) }} - {{ dateUtils.formatDateForDisplay(item.policyExpirationDate) }}</span>
                                              </v-list-item-title>
                                          </v-list-item-content>
                                      </template>
                                  </v-autocomplete>
                              </v-col>
                          </div>
                  </v-col>
                    </v-row>
                  </v-col>
                </v-row>

<!--                SYMBOL-->
                <v-row class="pl-10 proximaNovaFont" v-if="showInsuredPolicyNumberInput()">
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 11" :class="$vuetify.breakpoint.mdAndUp ? 'policy-number-input-spacing-authenticated' : 'policy-number-input-spacing'">
                    <p data-cy="policy-symbol-label" class="label-text">POLICY SYMBOL</p>
                    <v-col class="pa-0" data-cy="policy-symbol-column">
                      <v-text-field data-cy="policy-symbol-input"
                                    id="policy-symbol"
                                    v-model="submission.gettingStarted.policySymbol"
                                    dense
                                    counter="3"
                                    :maxLength="3"
                                    v-mask="'X'.repeat(3)"
                                    outlined
                                    clearable
                                    :disabled="$store.state.hasBeenPassedGettingStarted"
                                    background-color="white"
                                    @input="policySymbolOnInput()"/>
                      <span v-if="$vuetify.breakpoint.smAndDown" v-html="$store.state.siteBrand.policySymbolMobileHelper"/>
                    </v-col>
                  </v-col>
<!--              This empty column helps with spacing of the policy helpers-->
                  <v-col v-if="$store.state.isPolicySearchVisible" cols="1"></v-col>
                  <v-col>
                    <div class="policy-symbol-desktop-helper-div" v-if='$vuetify.breakpoint.mdAndUp' v-html="$store.state.siteBrand.policySymbolDesktopHelper"></div>
                  </v-col>
                </v-row>

              <!-- POLICY NUMBER INPUT-->
                <v-row class="pl-10 proximaNovaFont" v-if="showInsuredPolicyNumberInput()">
                  <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 11" :class="$store.state.isPolicySearchVisible === false ? 'policy-number-input-spacing' : 'policy-number-input-spacing-authenticated'">
                    <p data-cy="policy-number-label" class="label-text no-wrap">POLICY NUMBER<span class="req-asterisk">*</span>
                      <span data-cy="mcg-desktop-policy-number-helper-text" v-if='$vuetify.breakpoint.mdAndUp' class="label-text" style="text-decoration: underline">{{$store.state.siteBrand.policyNumberHelperText}}</span>
                      <span data-cy="mcg-mobile-policy-number-helper-text" v-else>{{$store.state.siteBrand.policyNumberHelperText}}</span>
                    </p>
                      <v-col class="pa-0 policy-number-column" data-cy="policy-number-column">
                        <v-text-field data-cy="policy-number-input"
                                      id="policy-number"
                                      v-model="submission.gettingStarted.policyNumber"
                                      dense
                                      counter="7"
                                      :maxLength="7"
                                      v-mask="'X'.repeat(7)"
                                      outlined
                                      clearable
                                      background-color="white"
                                      :disabled="$store.state.hasBeenPassedGettingStarted"
                                      :rules="policyNumberValidationRules"
                                      @input="policyNumberOnInput()"
                                      v-on:keypress.enter="!isPolicySearchDisabled() ? policySearchOnClick() : ''"
                                      @blur="policyNumberOnInput()"/>
                        <span class="pl-0" v-if="$vuetify.breakpoint.smAndDown" v-html="$store.state.siteBrand.policyNumberMobileHelper"></span>
                      </v-col>
                  </v-col>
                  <v-col cols="1" class="px-0 pt-7 policy-search-button-column" v-if="$store.state.isPolicySearchVisible && $store.state.loggedInUser.isAuthenticated">
                    <v-btn data-cy="policy-search-button" :disabled="isPolicySearchDisabled()" @click="policySearchOnClick()" class="policy-search-button">
                      <v-icon color="#00274e">mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <div class="policy-number-desktop-helper-div" v-if='$vuetify.breakpoint.mdAndUp' v-html="$store.state.siteBrand.policyNumberDesktopHelper"></div>
                  </v-col>
                </v-row>

                <v-row class="pl-10">
                  <v-col class="pt-0 mt-n8 proximaNovaFont">
                    <span v-if="$store.state.wasPolicySearchSuccessful === true &&
                                                                    gettingStartedPageValid &&
                                                                    submission.policyDetails != null &&
                                                                     submission.policyDetails.insuredName"
                                                                    data-cy="policyholder-name-label"
                                                                      :class="$store.state.hasBeenPassedGettingStarted ? 'policyholder-disabled' : 'successful-search-message'">
                                                                      <b>Policyholder found:</b><br/>{{submission.policyDetails.insuredName}}</span>
                    <span class="policy-not-found-message"
                            v-if="$store.state.wasPolicySearchSuccessful === false
                                    && gettingStartedPageValid === true
                                    && submission.gettingStarted.knowPolicyNumberInfo === true
                                    && submission.gettingStarted.policyNumber != null
                                    && submission.gettingStarted.policyNumber !== ''
                                    && $store.state.selectedPolicyInfo == null"

                            data-cy="policy-not-found-label">
                      <b>Policy not found</b>
                    </span>
                    <span v-if="submission.gettingStarted.policySymbol == null &&
                                submission.gettingStarted.policyNumber == null &&
                                submission.policyDetails.insuredName == null" data-cy="policyholder-name-hide-label"><b></b><br/>
                    </span>

                  </v-col>
                </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="card-background" elevation="0">
              <v-row class="pl-10 pb-5 pr-8 pr-sm-10" :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
                <v-btn @click="navigateTo('contact-information')" data-cy="next-button" class="next-button" :disabled="showNonInsuredDropdownLoader">
                  <span>Next</span><v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-row>

              <v-row class="pl-10 pb-5 pr-8 pr-sm-10" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                    <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                  </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        </v-form>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission" v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <PolicyResetModal :formattedDateOfLoss="formattedDateOfLoss" :submission="submission" v-if="$store.state.showPolicyResetModal"/>

    <WcPolicyModal v-if="showWcModal" :showModal="showWcModal" @close="showWcModal = false"/>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {DateUtils} from "@/utils/date-utils";
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation"
import {ValidationUtils} from "@/utils/validation-utils";
import {UiUtils} from "@/utils/ui-utils";
import MobileStepperComponent from "@/views/components/MobileStepperComponent.vue";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import {PolicyDetails} from '@/models/PolicyDetails';
import {CommonData} from "@/models/CommonData";
import {CustomerInformation} from "@/models/CustomerInformation";
import {LossInformation} from "@/models/LossInformation";
import {AdditionalContacts} from "@/models/AdditionalContacts";
import {AdditionalInformation} from "@/models/AdditionalInformation";
import PolicyResetModal from "@/views/modals/PolicyResetModal.vue";
import WcPolicyModal from "@/views/modals/WcPolicyModal.vue";

@Component({
  components: {WcPolicyModal, PolicyResetModal, MobileStepperComponent, SideStepperComponent, TimeoutModal, CancelClaimModal}
})
export default class GettingStartedPage extends Vue {

  submission: FnolSubmission;
  dateUtils = new DateUtils();
  uiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  validationUtils: ValidationUtils = new ValidationUtils();

  showNonInsuredDropdownLoader = false;
  gettingStartedPageValid: boolean = false;
  dateOfLossIsValid: boolean = false;
  policyNumberValidationRules: Array<string> = [];
  nonInternalPolicyNumberValidationRules: Array<string> = [];
  dateOfLossValidationRules: Array<string> = [];
  dateOfLossPicker: Boolean = false;
  formattedDateOfLoss: string = null;
  nextButtonClicked: boolean = false;

  showWcModal: boolean = false;

  oldPolicyNumber: string = null;
  oldDateOfLoss: string = null;

  created() {
    this.submission = this.$store.state.submission;
    if (this.submission.gettingStarted.policyNumber) {
      this.oldPolicyNumber = this.submission.gettingStarted.policyNumber
    }
    if (this.submission.gettingStarted.dateOfLoss) {
      this.oldDateOfLoss = this.submission.gettingStarted.dateOfLoss
    }

    this.formatDateOnload();
    if (this.submission.policyDetails && this.submission.policyDetails.policyTermKey) {
      this.$store.state.oldPolicyKey = this.submission.policyDetails.policyTermKey;
    }

    window.onbeforeunload = function (e) {
      e.returnValue = "Exit";
    }
  }

  shouldKnowPolicyInfoBeDisabled(): boolean{
    if (this.$store.state.hasBeenPassedGettingStarted){
      return true;
    }

    if (!this.dateOfLossIsValid || this.submission.gettingStarted.dateOfLoss == null){
      this.submission.gettingStarted.knowPolicyNumberInfo = null
      this.submission.gettingStarted.policySymbol = null
      this.submission.gettingStarted.policyNumber = null
      this.submission.policyDetails.insuredName = null
      this.$store.state.selectedPolicyInfo = null;
      return true;
    }
    return false;
  }

  shouldClearInsuredNameWhenPolicyNumberChanges() : boolean{
    if (this.oldPolicyNumber != this.submission.gettingStarted.policyNumber){
      this.submission.policyDetails.insuredName = null
      return true;
    }
  }

  // This asynchronously updates policy location from the Retrieve call
  @Watch('$store.state.policyResetProceed')
  ifUserClicksProceed(): void {
    if (this.$store.state.policyResetProceed) {
      this.$store.state.policyResetProceed = false;
      this.$store.state.hasBeenPassedGettingStarted = false;
    }
  }

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  applicationTimeoutTimer() {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  formatDateOnload() {
    if (this.submission.gettingStarted.dateOfLoss != null) {
      this.formattedDateOfLoss = this.dateUtils.formatDateForDisplay(this.submission.gettingStarted.dateOfLoss)
    }
  }

  policySymbolOnInput() {
    this.submission.gettingStarted.policySymbol = this.validationUtils.setToUpperCase(this.uiUtils.testForTrim(this.submission.gettingStarted.policySymbol))
  }

  policyNumberOnInput() {
    this.submission.gettingStarted.policyNumber = this.validationUtils.setToUpperCase(this.submission.gettingStarted.policyNumber)
    this.policyNumberValidationRules = this.aplFrontEndValidation.runPolicyNumberValidationRules();
    this.clearPolicySearchResponse();
    this.shouldClearInsuredNameWhenPolicyNumberChanges()
  }


//Since this is a bound error messages to the radio group,
//Any time the UI is updated or interacted with, this method will run
  knowPolicyNumberInfoErrorMessage() {
    if (this.nextButtonClicked == true) {
      if (this.submission.gettingStarted.knowPolicyNumberInfo === null || this.submission.gettingStarted.knowPolicyNumberInfo === undefined) {
        return 'Please enter required field';
      } else {
        return null;
      }
    }
    return null;
  }

 async mcgAddZeros(){
    if(this.$store.state.siteBrand.brandName === "MCG" && this.submission.gettingStarted.knowPolicyNumberInfo){
      while (this.submission.gettingStarted.policyNumber.length < 7) {
        this.submission.gettingStarted.policyNumber = '0' + this.submission.gettingStarted.policyNumber;
      }
    }
  }

  async navigateTo(route: string) {
    this.showWcModal = this.submission.gettingStarted.policySymbol == 'WC'
    if (this.showWcModal) return;
    await this.mcgAddZeros()
    this.nextButtonClicked = true
    this.submission.gettingStarted.dateOfLoss = this.dateUtils.parseFormattedDate(this.formattedDateOfLoss)
    await this.triggerNonRequiredValidations();
    if (this.gettingStartedPageValid) {
      this.$store.state.showValidationSnackbar = false
      if (this.shouldClearSubmission()) {
        this.clearSubmission()
      }
      if ((this.$store.state.isPolicySearchVisible && this.submission.gettingStarted.knowPolicyNumberInfo)
          || (this.$store.state.selectedPolicyInfo != null && this.$store.state.selectedPolicyInfo.policySymbol != "Manual Entry"
              && (this.$store.state.isUserAgent || this.$store.state.isUserAnInsured))) {
        await this.gatherPolicyInformation();
      }
      this.$store.state.idleVue.idleTime = 1740000

      if(this.submission.commonData.userType == null) {
          this.submission.commonData.userType = this.$store.state.loggedInUser.userType
      }
          this.uiUtils.navigateAndSaveSubmission(this.submission, route)

    } else {
      this.$store.state.showValidationSnackbar = true;
    }
  }

  async triggerNonRequiredValidations() {
    await this.validationUtils.triggerBlur('date-of-loss');
    this.knowPolicyNumberInfoErrorMessage()
    if (this.submission.gettingStarted.knowPolicyNumberInfo == true && this.showInsuredPolicyNumberInput()) {
      await this.validationUtils.triggerBlur('policy-number');
    }

    if (this.submission.gettingStarted.knowPolicyNumberInfo == true && this.showNonInternalPolicySelect()){
      await this.setNonInternalPolicyData()
    }
  }

  validateDateOfLossTextField() {
    this.submission.gettingStarted.dateOfLoss = this.dateUtils.parseFormattedDate(this.formattedDateOfLoss)
    this.$store.state.submission.gettingStarted.dateOfLoss = this.submission.gettingStarted.dateOfLoss
    this.dateOfLossValidationRules = this.aplFrontEndValidation.runDateValidationRules_isRequired(true);
    this.clearPolicySearchResponse();
  }

  dateOfLossOnPickerSelect() {
    this.formattedDateOfLoss = this.dateUtils.formatDateForDisplay(this.submission.gettingStarted.dateOfLoss);

    this.submission.gettingStarted.dateOfLoss = this.dateUtils.parseFormattedDate(this.formattedDateOfLoss)
    this.dateOfLossPicker = false;
    this.dateOfLossValidationRules = this.aplFrontEndValidation.runDateValidationRules_isRequired(true);
    this.clearPolicySearchResponse();
  }

  async onKnownPolicyNumber() {
    if (this.$store.state.isUserAgent) {
        this.showNonInsuredDropdownLoader = true
        console.log("Firing an Agent Search")

        await this.$store.dispatch('getAgentPolicies', this.submission.gettingStarted.dateOfLoss).then(async () => {
        }).catch((e: any) => {
          this.$store.commit('resetFoundPoliciesOnError', e)
      })
      this.showNonInsuredDropdownLoader = false
    }
  }

  onNoKnowPolicyNumber() {
    this.submission.gettingStarted.policySymbol = null
    this.submission.gettingStarted.policyNumber = null
    this.submission.policyDetails = new PolicyDetails();
    this.submission.policyDetails.policyBusinessUnit = this.$store.state.siteBrand.brandName
    this.$store.state.policyAddresses = [];
    this.$store.state.policyVehicles = [];
    this.$store.state.wasPolicyRetrieveSuccessful = false;
    if (this.$store.state.wasPolicySearchSuccessful && this.submission.customerInformation && this.submission.customerInformation.insured) {
      this.submission.customerInformation.insured = null
    }
    this.$store.state.wasPolicySearchSuccessful = undefined
    this.$store.commit('clearLossCauseInformation', this.submission.lossInformation)
    this.$store.state.selectedPolicyInfo = null
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  async policySearchOnClick() {
    console.log("Time to search PolicySearch!")
    await this.$store.dispatch('doPolicySearch', this.submission)
    console.log("PolicySearch call finished")
  }

  shouldClearSubmission(): boolean {
    return (this.$store.state.isAuthenticated && (this.oldDateOfLoss != this.submission.gettingStarted.dateOfLoss || this.oldPolicyNumber != this.submission.gettingStarted.policyNumber))
  }

  clearSubmission() {
    this.submission.commonData = new CommonData()
    this.submission.policyDetails = new PolicyDetails();
    //This is the default business unit for un-verified policies
    this.submission.policyDetails.policyBusinessUnit = this.$store.state.siteBrand.brandName
    this.submission.customerInformation = new CustomerInformation()
    this.submission.lossInformation = new LossInformation()
    this.submission.otherParty = null;
    this.submission.additionalContacts = new AdditionalContacts()
    this.submission.additionalInformation = new AdditionalInformation()
    this.$store.commit('resetOldLossType')
    this.$store.commit('resetOldPolicyKey')
    //Clear data that might be placed in the store (so old policy information doesn't persist)
    this.clearSelectedPolicyRisks();
  }

  async gatherPolicyInformation() {
    this.$store.state.wasPolicyRetrieveSuccessful = false
    this.$store.state.loadingRetrieveCall = true

    await this.$store.dispatch('doPolicySearch', this.submission)
    console.log("PolicySearch search finished")
    if (this.$store.state.wasPolicySearchSuccessful) {
      this.submission.gettingStarted.policySymbol = this.submission.policyDetails.policySymbol;
    }
    // Call 'doPolicyRetrieve' always, regardless of whether not the policy was actually changed.
      //DO NOT AWAIT THIS CALL
      this.$store.dispatch('doPolicyRetrieve', this.submission).then(() => {
        this.$store.state.loadingRetrieveCall = false
      });

    //do the FDWRetrieve call here to grab the policy's BU
    if (this.$store.state.wasPolicySearchSuccessful) {
      console.log("Getting BU")
      await this.$store.dispatch('getBusinessUnit', this.submission);
    }
  }

  isPolicySearchDisabled() {
    if (this.submission.gettingStarted.policyNumber && this.submission.gettingStarted.dateOfLoss) {
      if (this.gettingStartedPageValid && !this.$store.state.hasBeenPassedGettingStarted) {
        return false;
      }
    }
    return true;
  }

  clearPolicySearchResponse() {
    if (this.$store.state.policySearchResponse != null) {
      this.$store.state.policySearchResponse = null;
    }
    if (this.$store.state.wasPolicySearchSuccessful === false) {
      this.$store.state.wasPolicySearchSuccessful = null;
    }
  }

  clearSelectedPolicyRisks() {
    this.$store.commit('clearAccidentInfoSelectedVehicle')
    this.$store.commit('clearLossCauseLocation');
  }

  showNonInternalPolicySelect(): boolean {
    return this.submission.gettingStarted.knowPolicyNumberInfo
        && (this.$store.state.isUserAgent
            || (this.$store.state.isUserAnInsured && this.$store.state.userPolicies.length > 0));

  }

  shouldClearPolicyInfoWhenManualEntry(){
    if(this.$store.state.selectedPolicyInfo) {
      if (this.$store.state.selectedPolicyInfo.policySymbol == 'Manual Entry') {
        this.submission.gettingStarted.policySymbol = null
        this.submission.gettingStarted.policyNumber = null
        this.submission.policyDetails = new PolicyDetails()
      } else {
        this.submission.gettingStarted.policySymbol = this.$store.state.selectedPolicyInfo.policySymbol
        this.submission.gettingStarted.policyNumber = this.$store.state.selectedPolicyInfo.policyNumber
      }
    }
  }

  async setNonInternalPolicyData(){
    this.nonInternalPolicyNumberValidationRules = []

    if (this.$store.state.selectedPolicyInfo == null) {
      this.nonInternalPolicyNumberValidationRules.push("Please enter required field")
      return
    }

    if(this.$store.state.selectedPolicyInfo.policySymbol != 'Manual Entry') {
      this.submission.gettingStarted.policySymbol = this.$store.state.selectedPolicyInfo.policySymbol
      this.submission.gettingStarted.policyNumber = this.$store.state.selectedPolicyInfo.policyNumber
      await this.policySearchOnClick();
    } else {
      this.$store.state.isPolicySearchVisible = false;
      this.$store.state.wasPolicySearchSuccessful = false;
      this.$store.state.wasPolicyRetrieveSuccessful = false;
    }
  }

  showInsuredPolicyNumberInput(){
    if (this.submission.gettingStarted.knowPolicyNumberInfo && !this.$store.state.isUserAgent
        && (!this.$store.state.isUserAnInsured || (this.$store.state.isUserAnInsured && this.$store.state.userPolicies.length < 1))) {
      return true
    }

    return this.submission.gettingStarted.knowPolicyNumberInfo && (this.$store.state.isUserAgent || this.$store.state.isUserAnInsured) &&
        this.$store.state.selectedPolicyInfo && this.$store.state.selectedPolicyInfo.policySymbol == "Manual Entry";



  }

}
</script>

<style scoped lang="scss">

@import '~vuetify/src/styles/settings/_variables';

.form-content {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0 0 0 0;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 69px 0 63px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.agent-text {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00274e;
}

.policyholder-disabled {
  color: gray;
  font-size: 13px;
}

.successful-search-message{
  color: #00274e;
  font-size: 13px;
}

.policy-not-found-message {
  color: #cc0000;
  font-size: 13px;
}

</style>



