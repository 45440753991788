<template>
  <v-form
      autocomplete="off"
      v-model="$store.state.claimReporterComponentIsValid" data-cy="claim-reporter-component">
    <v-row class="proximaNovaFont">
    <v-col>
      <v-card class="card-background" elevation="0">
        <v-row>
          <v-col>
            <div>
              <span data-cy="claim-reporter-header" class="section-label berninaSansCompressedFont pl-8">Claim Reporter</span>
              <p data-cy="claim-reporter-label" class="pl-8 helper-text">Who is reporting this new claim?</p>
            </div>
          </v-col>
        </v-row>

        <v-row class="pl-8">
          <v-col cols="10" md="3" lg="2" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-first-name-label"
                   class="label-text"
                >FIRST NAME<span class="req-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="claim-reporter-first-name-column">
                <v-text-field
                    data-cy="claim-reporter-first-name-input"
                    id="claim-reporter-first-name"
                    v-model="submission.customerInformation.claimReporter.firstName"
                    dense
                    autocomplete="new-password"
                    outlined
                    clearable
                    background-color="white"
                    counter="30"
                    maxLength="30"
                    :rules="claimReporterFirstNameValidationRules"
                    @input="claimReporterFirstNameValidationOnInput"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="10" md="3" lg="2" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-last-name-label"
                   class="label-text"
                >LAST NAME<span class="req-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="claim-reporter-last-name-column">
                <v-text-field
                    data-cy="claim-reporter-last-name-input"
                    id="claim-reporter-last-name"
                    v-model="submission.customerInformation.claimReporter.lastName"
                    dense
                    outlined
                    clearable
                    autocomplete="new-password"
                    background-color="white"
                    counter="30"
                    maxLength="30"
                    :rules="claimReporterLastNameValidationRules"
                    @input="claimReporterLastNameValidationOnInput"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="10" md="2" lg="2" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-suffix-label" class="label-text">SUFFIX<span class="transparent-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="claim-reporter-suffix-column">
                <v-autocomplete
                    data-cy="claim-reporter-suffix-select"
                    v-model="submission.customerInformation.claimReporter.suffix"
                    :items="suffixesList"
                    dense
                    outlined
                    clearable
                    background-color="white"
                    @input="claimReporterSuffixOnSelect()"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pl-8">
          <v-col cols="10" md="4" lg="3" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-email-label"
                   class="label-text">EMAIL<span class="req-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="claim-reporter-email-column">
                <v-text-field
                    data-cy="claim-reporter-email-input"
                    id="claim-reporter-email"
                    v-model="submission.customerInformation.claimReporter.primaryEmail"
                    dense
                    outlined
                    clearable
                    autocomplete="new-password"
                    background-color="white"
                    counter="50"
                    maxLength="50"
                    :rules="claimReporterEmailValidationRules"
                    @input="claimReporterEmailValidationOnInput"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pl-8">
          <v-col cols="10" md="4" lg="3" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-phone-label"
                   class="label-text">PHONE<span class="req-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="claim-reporter-phone-column">
                <v-text-field v-model="submission.customerInformation.claimReporter.primaryPhone"
                              autocomplete="new-password"
                              data-cy="claim-reporter-phone-input"
                              id="claim-reporter-phone"
                              dense outlined clearable
                              background-color="white"
                              v-mask="'(###) ###-####'"
                              :rules="claimReporterPhoneValidationRules"
                              @input="claimReporterPhoneOnInput()">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="10" md="4" lg="3" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-phone-extension-label" class="label-text">EXTENSION<span class="transparent-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="claim-reporter-phone-extension-column">
                <v-text-field
                    data-cy="claim-reporter-phone-extension-input"
                    id="claim-reporter-phone-extension"
                    v-model="submission.customerInformation.claimReporter.primaryPhoneExt"
                    dense
                    outlined
                    clearable
                    background-color="white"
                    v-mask="'####'"
                    @input="claimReporterPhoneExtOnInput"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pl-8">
          <v-col cols="10" md="4" lg="3" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="claim-reporter-relationship-label"
                   class="label-text">RELATIONSHIP TO INSURED</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing"
                     data-cy="claim-reporter-relationship-column">
                <v-autocomplete
                    data-cy="claim-reporter-relationship-input"
                    v-model="submission.customerInformation.claimReporter.relationToInsured"
                    :items="relationToInsuredList"
                    autocomplete="new-password"
                    dense
                    outlined
                    clearable
                    background-color="white"
                    :rules="claimReporterRelationshipToInsurerValidationRules"
                    @input="claimReporterRelationToInsuredOnSelect"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="toggle-spacing">
          <v-col class="input-spacing">
            <v-row>
              <v-col cols="10" md="8" lg="6" class="input-spacing"
                     data-cy="claim-reporter-same-address-column">
                <v-row>
                  <v-switch
                      v-model="submission.customerInformation.claimReporter.isSameAddressAsInsured"
                      inset
                      class="v-switch-label"
                      label="Is your address the same as the Insured's?"
                      data-cy="claim-reporter-same-address-switch"
                      dense
                      @change="contactCorrectionUtils.handleSameClaimReporterAddressAsInsured(submission.customerInformation)"/>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <div v-if="submission.customerInformation.claimReporter.isSameAddressAsInsured" data-cy="claim-reporter-address-read-only">
          <v-row class="pl-8">
            <v-col cols="12">
              <div class="label-text" data-cy="claim-reporter-address-label">REPORTER ADDRESS</div>
              <div class="rs-values" data-cy="claim-reporter-address-line-1-text">{{ submission.customerInformation.claimReporter.location.addressLine1 }}</div>
              <div class="rs-values" data-cy="claim-reporter-address-line-2-text">{{ submission.customerInformation.claimReporter.location.addressLine2 }}</div>
              <div class="rs-values" data-cy="claim-reporter-city-text">{{ submission.customerInformation.claimReporter.location.city }}, {{ submission.customerInformation.claimReporter.location.state }} {{ submission.customerInformation.claimReporter.location.postalCode }}</div>
              <div class="rs-values" data-cy="claim-reporter-country-text">{{ submission.customerInformation.claimReporter.location.country }}</div>
            </v-col>
          </v-row>
        </div>

        <div v-else data-cy="claim-reporter-address-user-inputs">
          <v-row class="pl-8">
            <v-col cols="10" md="8" lg="6" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="claim-reporter-address-line-1-label"
                     class="label-text">ADDRESS LINE 1</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing"
                       data-cy="claim-reporter-address-line-1-column">
                  <v-text-field
                      data-cy="claim-reporter-address-line-1-input"
                      v-model="submission.customerInformation.claimReporter.location.addressLine1"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="claimReporterAddressLine1ValidationRules"
                      @input="claimReporterAddressLine1ValidationOnInput"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="8" lg="6" class="input-spacing">
              <v-row v-if="!submission.customerInformation.claimReporter.isSameAddressAsInsured">
                <v-col class="input-spacing">
                  <p data-cy="claim-reporter-address-line-2-label"
                     class="label-text">ADDRESS LINE 2</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing"
                       data-cy="claim-reporter-address-line-2-column">
                  <v-text-field
                      data-cy="claim-reporter-address-line-2-input"
                      v-model="submission.customerInformation.claimReporter.location.addressLine2"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="claimReporterAddressLine2ValidationRules"
                      @input="claimReporterAddressLine2ValidationOnInput"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row v-if="!submission.customerInformation.claimReporter.isSameAddressAsInsured">
                <v-col class="input-spacing">
                  <p data-cy="claim-reporter-city-label" class="label-text">CITY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="claim-reporter-city-column">
                  <v-text-field
                      data-cy="claim-reporter-city-input"
                      v-model="submission.customerInformation.claimReporter.location.city"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="claimReporterCityValidationRules"
                      @input="claimReporterCityValidationOnInput()"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row v-if="!submission.customerInformation.claimReporter.isSameAddressAsInsured">
                <v-col class="input-spacing">
                  <p data-cy="claim-reporter-state-label" class="label-text">STATE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="claim-reporter-state-column">
                  <v-autocomplete
                      data-cy="claim-reporter-state-select"
                      id="claim-reporter-state-select"
                      v-model="submission.customerInformation.claimReporter.location.state"
                      autocomplete="new-password"
                      :items="statesList"
                      dense
                      outlined
                      background-color="white"
                      @blur="uiUtils.triggerEnterKeyPress('claim-reporter-state-select')"
                      @input="claimReporterStateOnSelect"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-8">
            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row v-if="!submission.customerInformation.claimReporter.isSameAddressAsInsured">
                <v-col class="input-spacing">
                  <p data-cy="claim-reporter-zip-code-label"
                     class="label-text">ZIP CODE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="claim-reporter-zip-code-column">
                  <v-text-field
                      data-cy="claim-reporter-zip-code-input"
                      v-model="submission.customerInformation.claimReporter.location.postalCode"
                      dense
                      outlined
                      v-mask="['#####' ,'#####-####']"
                      clearable
                      background-color="white"
                      :rules="claimReporterZipCodeValidationRules"
                      @input="claimReporterZipCodeValidationOnInput()"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="10" md="4" lg="3" class="input-spacing">
              <v-row v-if="!submission.customerInformation.claimReporter.isSameAddressAsInsured">
                <v-col class="input-spacing">
                  <p data-cy="claim-reporter-country-label" class="label-text">COUNTRY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="claim-reporter-country-column">
                  <v-autocomplete
                      data-cy="claim-reporter-country-select"
                      v-model="submission.customerInformation.claimReporter.location.country"
                      :items="countriesList"
                      autocomplete="new-password"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="claimReporterCountryOnSelect"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {Suffixes} from "@/models/typelists/Suffixes";
import {RelationToInsured} from "@/models/typelists/RelationToInsured";
import {UiUtils} from "@/utils/ui-utils";
import {FnolSubmission} from "@/models/FnolSubmission";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {ContactCorrectionUtil} from "@/utils/contact-correction-util";

@Component
export default class ClaimReporterComponent extends Vue {

  submission: FnolSubmission;
  contactCorrectionUtils = new ContactCorrectionUtil()
  uiUtils = new UiUtils();
  statesList: Array<string> = Object.values(States);
  suffixesList: Array<string> = Object.values(Suffixes);
  countriesList: Array<string> = Object.values(Countries);
  relationToInsuredList: Array<string> = Object.values(RelationToInsured);

  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  claimReporterFirstNameValidationRules: Array<string> = [];
  claimReporterLastNameValidationRules: Array<string> = [];
  claimReporterAddressLine1ValidationRules: Array<string> = [];
  claimReporterAddressLine2ValidationRules: Array<string> = [];
  claimReporterCityValidationRules: Array<string> = [];
  claimReporterZipCodeValidationRules: Array<string> = [];
  claimReporterEmailValidationRules: Array<string> = [];
  claimReporterPhoneValidationRules: Array<string> = [];
  claimReporterRelationshipToInsurerValidationRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
    this.doAuthenticatedTasks()
  }

  setPrimaryContactSameAsClaimReporter() {
    if (this.submission.customerInformation.primaryContact.isSamePersonAsClaimReporter) {
      this.submission.customerInformation = this.contactCorrectionUtils.correctContacts(this.submission.customerInformation)
    }
  }

  //  Start name block
  claimReporterFirstNameValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.firstName = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.firstName)
    this.claimReporterFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  claimReporterLastNameValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
      this.submission.customerInformation.claimReporter.lastName = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.lastName)
      this.claimReporterLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  claimReporterSuffixOnSelect() {
    this.setPrimaryContactSameAsClaimReporter();
    if (null != this.submission.customerInformation.claimReporter.suffix) {
      this.submission.customerInformation.claimReporter.suffixCode = this.uiUtils.getSuffixCode(this.submission.customerInformation.claimReporter.suffix);
    }
    this.setPrimaryContactSameAsClaimReporter();
  }

  claimReporterEmailValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.primaryEmail)
    this.claimReporterEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  claimReporterPhoneOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.primaryPhone = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.primaryPhone)
    this.claimReporterPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false);
  }

  claimReporterPhoneExtOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.primaryPhoneExt = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.primaryPhoneExt)
  }

  // Start Address block
  claimReporterAddressLine1ValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.location.addressLine1)
    this.claimReporterAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  claimReporterAddressLine2ValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.location.addressLine2)
    this.claimReporterAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  claimReporterCityValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.location.city = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.location.city)
    this.claimReporterCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  claimReporterStateOnSelect() {
    this.submission.customerInformation.claimReporter.location.state = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.location.state)
    this.submission.customerInformation.claimReporter.location.stateCode = this.uiUtils.getStateCode(this.submission.customerInformation.claimReporter.location.state);
    this.setPrimaryContactSameAsClaimReporter();
  }

  claimReporterZipCodeValidationOnInput(){
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.location.postalCode = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.location.postalCode)
    this.claimReporterZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  claimReporterCountryOnSelect() {
    this.submission.customerInformation.claimReporter.location.country = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.location.country)
    this.submission.customerInformation.claimReporter.location.countryCode = this.uiUtils.getCountryCode(this.submission.customerInformation.claimReporter.location.country);
    this.setPrimaryContactSameAsClaimReporter();
  }

  claimReporterRelationToInsuredOnSelect() {
    this.setPrimaryContactSameAsClaimReporter();
    this.submission.customerInformation.claimReporter.relationToInsured = this.uiUtils.checkIfEmpty(this.submission.customerInformation.claimReporter.relationToInsured)
    this.submission.customerInformation.claimReporter.relationToInsuredCode = this.uiUtils.getRelationToInsuredCode(this.submission.customerInformation.claimReporter.relationToInsured);
  }

  doAuthenticatedTasks() {
    if(this.$store.state.loggedInUser.isAuthenticated) {
      this.setClaimReporterFromLoggedInUser()
    }
  }

  setClaimReporterFromLoggedInUser()  {
    if (!this.submission.customerInformation.claimReporter.firstName) {
      this.submission.customerInformation.claimReporter.firstName = this.$store.state.loggedInUser.firstName;
      this.submission.commonData.claimPreparer.firstName = this.$store.state.loggedInUser.firstName;
    }
    if (!this.submission.customerInformation.claimReporter.lastName) {
      this.submission.customerInformation.claimReporter.lastName = this.$store.state.loggedInUser.lastName;
      this.submission.commonData.claimPreparer.lastName = this.$store.state.loggedInUser.lastName;
    }
    if (!this.submission.customerInformation.claimReporter.primaryEmail) {
      this.submission.customerInformation.claimReporter.primaryEmail = this.$store.state.loggedInUser.emailAddress;
      this.submission.commonData.claimPreparer.emailAddress = this.$store.state.loggedInUser.emailAddress;
    }
    //Todo Need to set claim preparer's phone with okta phone, which is not yet retrievable.
    // if (!this.submission.customerInformation.claimReporter.primaryPhone) {
    //   this.submission.customerInformation.claimReporter.primaryPhone = this.$store.state.loggedInUser.phoneNumber;
    // }
  }
}
</script>
