
export class RegexUtils {

    //  EMAIL
    // Must have between 1 and 64 char before @  (email address)
    // Must have between 1 and 253 char after the @, and before the . (domain name)
    // Must have between 2 and 64 char after the .  (domain extension)
    static EMAIL = /^[a-zA-Z0-9!#$%&'*=?^`{|._+-]{1,64}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

    //  PHONE
    static PHONE_US_ARE_NOT_ALL_SAME_NUMBERS = /^(\d)(?!\1+$)\d{9}$/
    static PHONE_US_DOES_NOT_START_WITH_1_OR_0 = /^[2-9][0-9]*$/

    //  TEXT
    static ADDRESS = /^[a-zA-Z0-9\-\s,.#&()!/\\']*[a-zA-Z]+[a-zA-Z0-9\-\s,.#&()'!/\\]*$/

    static LETTERS_NUM_SPACES_SOME_SC = /^[a-zA-Z0-9\s\-,.#&']+( [a-zA-Z0-9\s\-,.#&']+)*$/

    static LETTERS_NUM_SPACES_SOME_SC_COMMAS_HYPHENS = /^[a-zA-Z0-9\s\-,.]+( [a-zA-Z0-9\s\-,.]+)*$/

    static LETTERS_PERIODS_HYPHENS = /^[A-Za-z\s.\-’‘']+$/

    static ALPHA_NUM_WITH_SPACE = /^[a-zA-Z0-9\s]+([a-zA-Z0-9\s]+)*$/

    static ALPHA_NUM_NO_SPACE = /^[a-zA-Z0-9]+([a-zA-Z0-9]+)*$/

    static YYYY_CHECK = /^(19|20)\d{2}$/

    static ALPHA_NUM_WITH_SPACE_CHARS_AND_SELECT_SPEC_CHARS = /^[a-zA-Z0-9À-ÿ\s\\/"\-,.#&'_!’]+( [a-zA-Z0-9\s\\/"\-,.#&'_!’]+)*$/

    static TEXT_AREA = /^[a-zA-Z0-9À-ÿ ?$@;#\\/()"'!,+\-=_:.&€£*%’\s]+$/

    static FULL_NAME_OR_COMPANY_NAME = /^[A-ZÀ-ÿa-z0-9 ?!“”"#$%&’‘()*+,\-./\\:;@{}[\]~_^`|'\s]+$/

    static ALPHA_WITH_ACCENT_ALPHA_CHARACTERS = /^[A-ZÀ-ÿa-z0-9\\\-,.'’\s]+$/

    static VEHICLE_PLATE_NUMBER_CHARACTERS = /^[a-zA-Z0-9]+[a-zA-Z0-9\s=*+@!:.\-,'"&#?$/]+$/
}
