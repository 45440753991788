<template>
  <v-container data-cy="wc-policy-modal">

    <v-dialog v-model="showModal" max-width="500" persistent overlay-opacity="0.7" overlay-color="#00172F">

      <!-- Start Over modal content -->
      <v-card data-cy="wc-policy-modal-content">
        <v-card-title class="py-3 modal-header-container">
          <span class="text-semi-bold fs-18 modal-header" data-cy="wc-policy-header">Is this a Workers' Comp claim?</span>
        </v-card-title>
        <v-col cols="12" class="pa-0">
          <v-divider color="#E6E9ED" style="opacity: 50%"/>
        </v-col>
        <v-card-text class="pt-3">
          <p class="text-light fs-14 modal-text" data-cy="wc-policy-body">This app does not currently support reporting Workers' Compensation claims. If you are trying to submit a Workers' Compensation claim, please contact 1-844-GAIG NOW / 1-844-424-4669</p>
        </v-card-text>
        <!-- Modal buttons -->
        <v-btn
            class="next-button ok-button"
            @click="onModalClose"
            depressed outlined text
            data-cy="modal-close-button">OK
        </v-btn>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script lang="ts">
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({})
export default class WcPolicyModal extends Vue {

  @Prop()
  showModal: boolean;

  onModalClose() {
    this.$emit('close')
  }

}
</script>

<style scoped lang="scss">

</style>