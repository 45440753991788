<template>
  <v-form data-cy="policy-found-insured-component" v-model="$store.state.insuredComponentIsValid">
    <v-row class="proximaNovaFont">
      <v-col>
        <v-card class="card-background" elevation="0">
          <v-row>
            <v-col>
              <div>
                <span data-cy="insured-information-header" class="section-label berninaSansCompressedFont pl-8">Insured</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="pl-8">
            <v-col cols="12">
              <div class="label-text">POLICYHOLDER</div>
              <div class="rs-values" data-cy="policy-holder-name">{{submission.policyDetails.insuredName}}</div>
            </v-col>
            <v-col cols="12">
              <div class="label-text">PRIMARY ADDRESS</div>
              <div class="rs-values" data-cy="policy-holder-address">{{submission.policyDetails.insuredAddress.streetAddress1}}</div>
              <div class="rs-values" data-cy="policy-holder-location">{{submission.policyDetails.insuredAddress.city}}, {{submission.policyDetails.insuredAddress.state}} {{submission.policyDetails.insuredAddress.postalCode}}</div>
              <div class="rs-values" data-cy="policy-holder-country">United States of America</div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";

@Component
export default class PolicyFoundInsuredComponent extends Vue {

  submission: FnolSubmission;
  uiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();

  created() {
    this.submission = this.$store.state.submission;
    if (this.submission.customerInformation.insured) {
      this.submission.customerInformation.insured.isFullNameRequired = !this.submission.gettingStarted.knowPolicyNumberInfo;
    }
    this.$store.state.insuredComponentIsValid = true;
  }
}
</script>
