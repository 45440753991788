<template>
  <v-form autocomplete="off" data-cy="consignee-form" v-model="$store.state.isConsigneeComponentValid">
    <v-row>
      <v-col>
    <v-card elevation="0" class="card-background">
      <v-row>
        <v-col>
          <div>
              <span data-cy="consignee-header" class="section-label berninaSansCompressedFont pl-8">Consignee</span>
          </div>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8 pb-8': 'px-8 pb-8'">
        <v-col class="input-spacing" md="8">
              <p data-cy="is-consignee-info-available-label" class="label-text">DO YOU HAVE THE CONSIGNEE'S INFORMATION?</p>
              <v-radio-group data-cy="is-consignee-info-available-radio-group"
                             :row="$vuetify.breakpoint.mdAndUp"
                             v-model="submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable"
                             class="radio-container">
                <v-radio
                    label="Yes"
                    :value=true
                    data-cy="is-consignee-info-available-radio-yes"
                ></v-radio>
                <v-radio
                    label="No"
                    :value=false
                    @change="clearConsignee"
                    data-cy="is-consignee-info-available-radio-no"
                ></v-radio>
              </v-radio-group>
        </v-col>
      </v-row>


      <v-row v-if="submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable">
        <v-col class="input-spacing">
          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="8" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-name-label" class="label-text">CONSIGNEE NAME<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-name-column">
                  <v-text-field
                      data-cy="consignee-name-input"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.fullName"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="100"
                      maxLength="100"
                      :rules="consigneeNameValidationRules"
                      @input="consigneeNameOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-email-label" class="label-text">EMAIL</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-email-column">
                  <v-text-field
                      data-cy="consignee-email-input"
                      id="consignee-email"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.primaryEmail"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="50"
                      maxLength="50"
                      :rules="consigneeEmailValidationRules"
                      @input="consigneeEmailOnInput"
                      @blur="consigneeEmailOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-phone-label" class="label-text">PHONE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-phone-column">
                  <v-text-field v-model="submission.lossInformation.cargoLossInformation.consignee.primaryPhone"
                                data-cy="consignee-phone-input"
                                id="consignee-phone"
                                dense outlined clearable
                                autocomplete="off"
                                background-color="white"
                                :rules="consigneePhoneValidationRules"
                                v-mask="'(###) ###-####'"
                                @input="consigneePhoneOnInput"
                                @blur="consigneePhoneOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="toggle-spacing">
            <v-col class="input-spacing">
              <v-row>
                <v-col md="8" lg="6" class="input-spacing" data-cy="consignee-address-same-as-loss-location-address-column">
                  <v-row>
                    <v-switch
                        v-model="submission.lossInformation.cargoLossInformation.isConsigneeAddressSameAsLossLocationAddress"
                        inset
                        class="v-switch-label"
                        data-cy="consignee-address-same-as-loss-location-address-switch"
                        label="Is the consignee address the same as the loss location address?"
                        dense
                        @change="consigneeAddressSameAsLossLocationAddressSwitch"/>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div v-if="submission.lossInformation.cargoLossInformation.isConsigneeAddressSameAsLossLocationAddress" data-cy="consignee-address-read-only">
            <v-row class="pl-8">
              <v-col cols="12">
                <div class="label-text" data-cy="consignee-address-label">CONSIGNEE ADDRESS</div>
                <div class="rs-values" data-cy="consignee-address-line-1-text">{{ submission.lossInformation.cargoLossInformation.consignee.location.addressLine1 }}</div>
                <div class="rs-values" data-cy="consignee-address-line-2-text">{{ submission.lossInformation.cargoLossInformation.consignee.location.addressLine2 }}</div>
                <div class="rs-values" data-cy="consignee-address-city-state-zip-text">{{ submission.lossInformation.cargoLossInformation.consignee.location.city }}, {{ submission.lossInformation.cargoLossInformation.consignee.location.stateCode }} {{ submission.lossInformation.cargoLossInformation.consignee.location.postalCode }}</div>
                <div class="rs-values" data-cy="consignee-address-country-text">{{ submission.lossInformation.cargoLossInformation.consignee.location.country }}</div>
              </v-col>
            </v-row>
          </div>

          <div v-else data-cy="consignee-address-inputs">
          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="8" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-address-line-1-label" class="label-text">ADDRESS LINE 1</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-address-line-1-column">
                  <v-text-field
                      data-cy="consignee-address-line-1-input"
                      id="consignee-address1"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.location.addressLine1"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="consigneeAddressLine1ValidationRules"
                      @input="consigneeAddressLine1OnInput"
                      @blur="consigneeAddressLine1OnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="8" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-address-line-2-label" class="label-text">ADDRESS LINE 2</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-address-line-2-column">
                  <v-text-field
                      data-cy="consignee-address-line-2-input"
                      id="consignee-address2"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.location.addressLine2"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="consigneeAddressLine2ValidationRules"
                      @input="consigneeAddressLine2OnInput"
                      @blur="consigneeAddressLine2OnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-city-label" class="label-text">CITY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-city-column">
                  <v-text-field
                      data-cy="consignee-city-input"
                      id="consignee-city"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.location.city"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="consigneeCityValidationRules"
                      @input="consigneeCityOnInput"
                      @blur="consigneeCityOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-state-label" class="label-text">STATE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-state-column">
                  <v-autocomplete
                      data-cy="consignee-state-select"
                      id="consignee-state-select"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.location.state"
                      autocomplete="new-password"
                      :items="states"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="consigneeStateOnSelect"
                      @blur="consigneeStateOnSelect; uiUtils.triggerEnterKeyPress('consignee-state-select')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-zip-code-label" class="label-text">ZIP CODE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-zip-code-column">
                  <v-text-field
                      data-cy="consignee-zip-code-input"
                      id="consignee-zip"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.location.postalCode"
                      dense
                      outlined
                      autocomplete="off"
                      v-mask="['#####' ,'#####-####']"
                      clearable
                      background-color="white"
                      :rules="consigneeZipValidationRules"
                      @input="consigneeZipOnInput"
                      @blur="consigneeZipOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="consignee-country-label" class="label-text">COUNTRY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="consignee-country-column">
                  <v-autocomplete
                      data-cy="consignee-country-select"
                      v-model="submission.lossInformation.cargoLossInformation.consignee.location.country"
                      :items="countries"
                      autocomplete="new-password"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="consigneeCountryOnInput"
                      @blur="consigneeCountryOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card>
      </v-col>
    </v-row>
  </v-form>

</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {ValidationUtils} from "@/utils/validation-utils";
import {Consignee} from "@/models/Consignee";
import {Locus} from "@/models/Locus";

@Component
export default class ConsigneeComponent extends Vue {

  submission: FnolSubmission;
  validationUtils: ValidationUtils = new ValidationUtils();
  states: Array<string> = [];
  countries: Array<string> = [];

  uiUtils: UiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();

  consigneeNameValidationRules: Array<string> = [];
  consigneeEmailValidationRules: Array<string> = [];
  consigneePhoneValidationRules: Array<string> = [];
  consigneeAddressLine1ValidationRules: Array<string> = [];
  consigneeAddressLine2ValidationRules: Array<string> = [];
  consigneeCityValidationRules: Array<string> = [];
  consigneeZipValidationRules: Array<string> = [];

  created() {
    console.log('ConsigneeComponent created')
    this.submission = this.$store.state.submission;
    this.states = Object.values(States)
    this.countries = Object.values(Countries)
    if (this.submission.lossInformation.cargoLossInformation.isConsigneeAddressSameAsLossLocationAddress) {
      this.consigneeAddressSameAsLossLocationAddressSwitch()
    }
  }

  clearConsignee(){
    this.submission.lossInformation.cargoLossInformation.consignee = new Consignee();
    if (this.submission.lossInformation.cargoLossInformation.isConsigneeAddressSameAsLossLocationAddress) {
      this.submission.lossInformation.cargoLossInformation.isConsigneeAddressSameAsLossLocationAddress = false;
      this.submission.lossInformation.cargoLossInformation.consignee.location = new Locus();
    }
  }

  consigneeNameOnInput() {
      this.submission.lossInformation.cargoLossInformation.consignee.fullName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.consignee.fullName)
      this.consigneeNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false);
    }

  consigneeEmailOnInput() {
    this.submission.lossInformation.cargoLossInformation.consignee.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.consignee.primaryEmail)
      this.consigneeEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)

  }

  consigneePhoneOnInput() {
      this.submission.lossInformation.cargoLossInformation.consignee.primaryPhone = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.primaryPhone)
      this.consigneePhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false);
  }

  consigneeAddressLine1OnInput() {
    this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine1)
    this.consigneeAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  consigneeAddressLine2OnInput() {
    this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine2)
    this.consigneeAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  consigneeCityOnInput() {
      this.submission.lossInformation.cargoLossInformation.consignee.location.city = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.consignee.location.city)
      this.consigneeCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  consigneeZipOnInput() {
      this.submission.lossInformation.cargoLossInformation.consignee.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.location.postalCode)
      this.consigneeZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules();
  }


  consigneeStateOnSelect() {
    this.submission.lossInformation.cargoLossInformation.consignee.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.location.state)
    this.submission.lossInformation.cargoLossInformation.consignee.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.cargoLossInformation.consignee.location.state);
  }

  consigneeCountryOnInput() {
    this.submission.lossInformation.cargoLossInformation.consignee.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.location.country)
    this.submission.lossInformation.cargoLossInformation.consignee.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.cargoLossInformation.consignee.location.country);
  }

  consigneeAddressSameAsLossLocationAddressSwitch() {
    this.$forceUpdate()
    this.uiUtils.addressSameAsToggle(this.submission.lossInformation.cargoLossInformation.consignee.location,
        this.submission.lossInformation.location,
        this.submission.lossInformation.cargoLossInformation.isConsigneeAddressSameAsLossLocationAddress)
  }

}
</script>

<style>

</style>
