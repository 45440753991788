<template>
  <v-form autocomplete="off" data-cy="tow-company-form" v-model="$store.state.isTowCompanyComponentValid">
    <v-row>
      <v-col>
    <v-card elevation="0" class="card-background">
      <v-row>
        <v-col>
          <div>
              <span data-cy="tow-company-header" class="section-label berninaSansCompressedFont pl-8">Wrecker/Tow Company</span>
          </div>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8 pb-8':'px-8 pb-8'">
        <v-col class="input-spacing" md="8">
          <p data-cy="was-tow-company-used-label" class="label-text">WAS A WRECKER/TOW COMPANY USED?</p>
          <v-radio-group data-cy="was-tow-company-used-radio-group"
                         :row="$vuetify.breakpoint.mdAndUp"
                         v-model="submission.lossInformation.cargoLossInformation.wasTowCompanyUsed"
                         class="radio-container">
            <v-radio
                label="Yes"
                :value=true
                data-cy="was-tow-company-used-radio-yes"
            ></v-radio>
            <v-radio
                label="No"
                :value=false
                @change="clearTowCompany"
                data-cy="was-tow-company-used-radio-no"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row v-if="submission.lossInformation.cargoLossInformation.wasTowCompanyUsed">
        <v-col class="input-spacing">
          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="8" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-name-label" class="label-text">WRECKER/TOW COMPANY NAME<span class="req-asterisk">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-name-column">
                  <v-text-field
                      data-cy="tow-company-name-input"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.fullName"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      counter="100"
                      maxLength="100"
                      :rules="towCompanyNameValidationRules"
                      @input="towCompanyNameOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-email-label" class="label-text">EMAIL</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-email-column">
                  <v-text-field
                      data-cy="tow-company-email-input"
                      id="tow-company-email"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.primaryEmail"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="50"
                      maxLength="50"
                      :rules="towCompanyEmailValidationRules"
                      @input="towCompanyEmailOnInput"
                      @blur="towCompanyEmailOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-phone-label" class="label-text">PHONE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-phone-column">
                  <v-text-field v-model="submission.lossInformation.cargoLossInformation.towCompany.primaryPhone"
                                data-cy="tow-company-phone-input"
                                id="tow-company-phone"
                                dense outlined clearable
                                autocomplete="off"
                                background-color="white"
                                v-mask="'(###) ###-####'"
                                :rules="towCompanyPhoneValidationRules"
                                @input="towCompanyPhoneOnInput"
                                @blur="towCompanyPhoneOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="8" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-address-line-1-label" class="label-text">ADDRESS LINE 1</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-address-line-1-column">
                  <v-text-field
                      data-cy="tow-company-address-line-1-input"
                      id="tow-company-address1"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="towCompanyAddressLine1ValidationRules"
                      @input="towCompanyAddressLine1OnInput"
                      @blur="towCompanyAddressLine1OnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="8" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-address-line-2-label" class="label-text">ADDRESS LINE 2</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-address-line-2-column">
                  <v-text-field
                      data-cy="tow-company-address-line-2-input"
                      id="tow-company-address2"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="towCompanyAddressLine2ValidationRules"
                      @input="towCompanyAddressLine2OnInput"
                      @blur="towCompanyAddressLine2OnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-city-label" class="label-text">CITY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-city-column">
                  <v-text-field
                      data-cy="tow-company-city-input"
                      id="tow-company-city"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.location.city"
                      dense
                      outlined
                      clearable
                      autocomplete="off"
                      background-color="white"
                      counter="60"
                      maxLength="60"
                      :rules="towCompanyCityValidationRules"
                      @input="towCompanyCityOnInput"
                      @blur="towCompanyCityOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-state-label" class="label-text">STATE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-state-column">
                  <v-autocomplete
                      data-cy="tow-company-state-select"
                      id="tow-company-state-select"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.location.state"
                      autocomplete="new-password"
                      :items="states"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="towCompanyStateOnSelect"
                      @blur="towCompanyStateOnSelect; uiUtils.triggerEnterKeyPress('tow-company-state-select')"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-zip-code-label" class="label-text">ZIP CODE</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-zip-code-column">
                  <v-text-field
                      data-cy="tow-company-zip-code-input"
                      id="tow-company-zip"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.location.postalCode"
                      dense
                      outlined
                      v-mask="['#####' ,'#####-####']"
                      clearable
                      autocomplete="off"
                      :rules="towCompanyZipValidationRules"
                      @input="towCompanyZipOnInput"
                      background-color="white"
                      @blur="towCompanyZipOnInput"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4" cols="12" class="input-spacing">
              <v-row>
                <v-col class="input-spacing">
                  <p data-cy="tow-company-country-label" class="label-text">COUNTRY</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing" data-cy="tow-company-country-column">
                  <v-autocomplete
                      data-cy="tow-company-country-select"
                      v-model="submission.lossInformation.cargoLossInformation.towCompany.location.country"
                      :items="countries"
                      autocomplete="new-password"
                      dense
                      outlined
                      clearable
                      background-color="white"
                      @input="towCompanyCountryOnInput"
                      @blur="towCompanyCountryOnInput"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-card>
      </v-col>
    </v-row>

  </v-form>

</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {TowCompany} from '@/models/TowCompany';

@Component
export default class TowCompanyComponent extends Vue {

  submission: FnolSubmission;
  states: Array<String> = [];
  countries: Array<String> = [];

  uiUtils: UiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();

  towCompanyNameValidationRules: Array<string> = [];
  towCompanyEmailValidationRules: Array<string> = [];
  towCompanyPhoneValidationRules: Array<string> = [];
  towCompanyAddressLine1ValidationRules: Array<string> = [];
  towCompanyAddressLine2ValidationRules: Array<string> = [];
  towCompanyCityValidationRules: Array<string> = [];
  towCompanyZipValidationRules: Array<string> = [];

  created() {
    console.log('TowCompanyComponent created')
    this.submission = this.$store.state.submission;
    this.states = Object.values(States)
    this.countries = Object.values(Countries)
  }

  clearTowCompany(){
    this.submission.lossInformation.cargoLossInformation.towCompany = new TowCompany();
  }

  towCompanyNameOnInput() {
      this.submission.lossInformation.cargoLossInformation.towCompany.fullName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.towCompany.fullName)
      this.towCompanyNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false);
    }

  towCompanyEmailOnInput() {
    this.submission.lossInformation.cargoLossInformation.towCompany.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.towCompany.primaryEmail)
    this.towCompanyEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  towCompanyPhoneOnInput() {
      this.submission.lossInformation.cargoLossInformation.towCompany.primaryPhone = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.primaryPhone)
      this.towCompanyPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false);
  }

  towCompanyAddressLine1OnInput() {
    this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1)
    this.towCompanyAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  towCompanyAddressLine2OnInput() {
    this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2)
    this.towCompanyAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  towCompanyCityOnInput() {
    this.submission.lossInformation.cargoLossInformation.towCompany.location.city = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.towCompany.location.city);
    this.towCompanyCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false);
  }

  towCompanyZipOnInput() {
    this.submission.lossInformation.cargoLossInformation.towCompany.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.location.postalCode);
    this.towCompanyZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules();
  }


  towCompanyStateOnSelect() {
    this.submission.lossInformation.cargoLossInformation.towCompany.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.location.state);
    this.submission.lossInformation.cargoLossInformation.towCompany.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.cargoLossInformation.towCompany.location.state);
  }

  towCompanyCountryOnInput() {
    this.submission.lossInformation.cargoLossInformation.towCompany.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.location.country);
    this.submission.lossInformation.cargoLossInformation.towCompany.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.cargoLossInformation.towCompany.location.country);
  }

}
</script>

<style>

</style>
