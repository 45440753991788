<template>
  <v-col>
    <v-row data-cy="cargo-loss-information-vue">
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>
      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div>
              <span data-cy="cargo-loss-information-label" class="page-label berninaSansCompressedFont">Cargo Information</span>
            </div>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{ submission.reportNumber }}</span>
          </v-col>
        </v-row>

        <v-form autocomplete="off" v-model="$store.state.cargoLossInformationPageValid">
          <v-row>
            <v-col>
              <v-card class="card-background" elevation="0">
                <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
                  <v-col cols="12" md="8"  class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="loss-estimate-label" class="label-text">WHAT IS THE ESTIMATE OF THE LOSS?<span v-if="businessUnitPIM()" class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="8" class="input-spacing" data-cy="loss-estimate-column">
                        <v-autocomplete v-model="submission.lossInformation.cargoLossInformation.lossEstimateAmount"
                                        data-cy="loss-estimate-select"
                                        :items="lossEstimateList"
                                        outlined
                                        dense
                                        background-color="white"
                                        data-lpignore="true"
                                        :error-messages="cargoLossEstimateValidationRules"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'pr-8 pl-8'">
                  <v-col cols="12" md="8" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="cargo-pickup-date-label" class="label-text">WHAT DATE WAS THE CARGO PICKED UP?</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="8" class="input-spacing" data-cy="cargo-pickup-date-column">
                        <v-menu
                            v-model="cargoPickupDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="150px">
                          <template v-slot:activator="{ off }">
                            <v-text-field
                                autocomplete="off"
                                data-cy="cargo-pickup-date-input"
                                id="cargo-pickup-date"
                                v-model="formattedCargoPickupDate"
                                v-mask="'##/##/####'"
                                append-icon="mdi-calendar"
                                @click:append="cargoPickupDatePicker = true"
                                @click:clear="cargoPickupDate = null"
                                clearable
                                outlined
                                v-on="off"
                                dense
                                background-color="white"
                                :rules="cargoPickupDateValidationRules"
                                @input="enteredCargoPickupDateOnInput"
                                @blur="enteredCargoPickupDateOnInput">
                            </v-text-field>
                          </template>
                          <v-date-picker data-cy="cargo-pickup-date-picker"
                                         @input="cargoPickupDateOnInput"
                                         @blur="cargoPickupDateOnInput"
                                         v-model="submission.lossInformation.cargoLossInformation.cargoPickupDate">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'pr-8 pl-8'">
                  <v-col cols="12" md="8" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="cargo-pickup-state-label" class="label-text">WHAT STATE WAS THE CARGO PICKED UP FROM?</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="8" class="input-spacing" data-cy="cargo-pickup-state-column">
                        <v-autocomplete v-model="submission.lossInformation.cargoLossInformation.cargoStatePickedUpFrom"
                                        data-cy="cargo-pickup-state-select"
                                        id="cargo-pickup-state-select"
                                        :items="states"
                                        outlined
                                        dense
                                        clearable
                                        @blur="uiUtils.triggerEnterKeyPress('cargo-pickup-state-select')"
                                        @input="cargoPickupStateOnSelect"
                                        background-color="white"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
                  <v-col cols="12" md="8" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="cargo-delivery-date-label" class="label-text">WHAT DATE WAS THE CARGO SUPPOSED TO BE DELIVERED?</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="8" class="input-spacing" data-cy="cargo-delivery-date-column">
                        <v-menu
                            v-model="cargoDeliveryDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="150px">
                          <template v-slot:activator="{ off }">
                            <v-text-field
                                autocomplete="off"
                                data-cy="cargo-delivery-date-input"
                                id="cargo-delivery-date"
                                v-model="formattedCargoDeliveryDate"
                                v-mask="'##/##/####'"
                                append-icon="mdi-calendar"
                                @click:append="cargoDeliveryDatePicker = true"
                                @click:clear="cargoDeliveryDate = null"
                                clearable
                                outlined
                                v-on="off"
                                dense
                                background-color="white"
                                :rules="cargoDeliveryDateValidationRules"
                                @input="enteredCargoDeliveryDateOnInput"
                                @blur="enteredCargoDeliveryDateOnInput"/>
                          </template>
                          <v-date-picker data-cy="cargo-delivery-date-picker"
                                         @input="cargoDeliveryDateOnInput"
                                         @blur="cargoDeliveryDateOnInput"
                                         v-model="submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'pr-8 pl-8'">
                  <v-col cols="12" md="8" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="cargo-delivery-state-label" class="label-text">WHAT STATE WAS THE CARGO SUPPOSED TO BE DELIVERED TO?</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="8" class="input-spacing" data-cy="cargo-delivery-state-column">
                        <v-autocomplete
                            v-model="submission.lossInformation.cargoLossInformation.cargoExpectedStateDeliveredTo"
                            data-cy="cargo-delivery-state-select"
                            id="cargo-delivery-state-select"
                            :items="states"
                            outlined
                            dense
                            background-color="white"
                            @blur="uiUtils.triggerEnterKeyPress('cargo-delivery-state-select')"
                            @input="cargoDeliveryStateOnSelect"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="card-background" elevation="0">
                <v-row>
                  <v-col>
                    <div>
                      <span data-cy="cargo-header"
                            class="section-label berninaSansCompressedFont pl-8">Cargo Items</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8': 'px-8'">
                  <v-col cols="12" md="8" class="input-spacing" data-cy="cargo-list-column">

                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="damage-to-cargo-label" class="label-text">WAS THERE DAMAGE TO THE CARGO?</p>
                      <v-radio-group data-cy="damage-to-cargo-radio-group"
                                     class="pt-0 mt-1 mb-5 radio-buttons-mobile-background"
                                     :class="$vuetify.breakpoint.mdAndUp? 'pl-4': ''"
                                     v-model="submission.lossInformation.cargoLossInformation.wasThereDamageToCargo">
                        <v-radio
                            label="Yes"
                            class="radio-buttons-mobile-yes pt-3"
                            value="Yes"
                            data-cy="damage-to-cargo-radio-yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            class="radio-buttons-mobile-yes"
                            value="No"
                            data-cy="damage-to-cargo-radio-no"
                        ></v-radio>
                        <v-radio
                            label="I'm unsure"
                            class="radio-buttons-mobile-no"
                            value="I'm unsure"
                            data-cy="damage-to-cargo-radio-unsure"
                        ></v-radio>
                      </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row v-for="(item, index) in submission.lossInformation.cargoLossInformation.cargoList"
                           :key="index">
                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p :data-cy="'cargo-item-label-' + index" class="label-text">CARGO ITEM {{ index + 1 }}</p>
                          </v-col>
                        </v-row>
                        <v-row :class="$vuetify.breakpoint.mdAndUp? 'input-spacing':'pr-12'">
                          <v-col cols="11" md="8" :data-cy="'cargo-item-column-' + index">
                            <v-text-field
                                :data-cy="'cargo-item-input-' + index"
                                v-model="item.itemName"
                                dense
                                id="cargo-item"
                                outlined
                                clearable
                                counter="50"
                                maxLength="50"
                                background-color="white"
                                :rules="cargoItemsValidationRules"
                                @input="cargoItemOnInput(item)"/>
                          </v-col>
                          <v-col cols="1" md="4" :data-cy="'remove-cargo-item-column-' + index">
                              <v-btn class="white-bg outlined-button" outlined :data-cy="'remove-cargo-item-button-' + index"
                                   @click="removeCargoItem(index)">
                              <v-icon class="">mdi-minus</v-icon><span class="hidden-sm-and-down">Remove Cargo Item</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" data-cy="add-cargo-item-column">
                        <v-btn class="white-bg outlined-button" :width="$vuetify.breakpoint.smAndDown? '100%':''" outlined data-cy="add-cargo-item-button"
                               @click="addNewCargoItem">
                          <span><v-icon>mdi-plus</v-icon>Add New Cargo Item</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <ShipperComponent/>
          <ConsigneeComponent/>
          <TowCompanyComponent/>

        </v-form>

      </v-col>
    </v-row>


    <v-row>
      <v-col md="9" offset-md="3">
        <v-card class="card-background px-7" elevation="0">
            <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
              <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined class="previous-button"
                     v-if="$vuetify.breakpoint.mdAndUp">
                <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
              </v-btn>

              <v-btn @click="navigateTo('additional-contacts')" data-cy="next-button" class="next-button">
                <span>Next</span>
                <v-icon>mdi-arrow-right-thick</v-icon>
              </v-btn>
            </v-row>

          <v-row class="" v-if="$vuetify.breakpoint.smAndDown" justify="center">
            <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined class="previous-button"
                   v-if="$vuetify.breakpoint.smAndDown">
              <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
            </v-btn>
          </v-row>

          <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown"
                 :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
            <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
              <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>
    <CancelClaimModal :submission="submission" v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>
    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {DateUtils} from "@/utils/date-utils";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {UiUtils} from "@/utils/ui-utils";
import {CargoItem} from '@/models/CargoItem';
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {ValidationUtils} from "@/utils/validation-utils";
import ConsigneeComponent from "@/views/components/ConsigneeComponent.vue";
import TowCompanyComponent from "@/views/components/TowCompanyComponent.vue";
import ShipperComponent from "@/views/components/ShipperComponent.vue";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";

@Component({
  components: {
    BrowserBackButtonModal,
    ShipperComponent, TowCompanyComponent, ConsigneeComponent, SideStepperComponent, TimeoutModal,
    CancelClaimModal
  }
})


export default class CargoLossInformation extends Vue {

  submission: FnolSubmission;
  dateUtils = new DateUtils();
  uiUtils: UiUtils = new UiUtils();
  validationUtils: ValidationUtils = new ValidationUtils();
  lossEstimateList = ['Less than $10,000', '$10,001-$25,000', '$25,001-$50,000', 'More than $50,000', 'Unknown']

  cargoDeliveryDatePicker: Boolean = false;
  cargoPickupDatePicker: Boolean = false;

  cargoPickupDate: Date
  formattedCargoPickupDate: string = null;

  cargoDeliveryDate: Date
  formattedCargoDeliveryDate: string = null;

  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  cargoLossEstimateValidationRules: Array<string> = [];
  cargoPickupDateValidationRules: Array<string> = [];
  cargoDeliveryDateValidationRules: Array<string> = [];
  cargoItemsValidationRules: Array<string> = [];
  states: Array<String> = [];
  countries: Array<String> = [];

  created() {
    this.submission = this.$store.state.submission;
    this.uiUtils.instantiateLossObject(this.submission)
    this.formatDatesOnload()
    this.states = Object.values(States)
    this.countries = Object.values(Countries)
    console.log('Cargo Loss Information page created')
  }


  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  businessUnitPIM(): boolean {
    return this.submission.policyDetails.policyBusinessUnit === 'PIM'
  }

  applicationTimeoutTimer() {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  formatDatesOnload() {
    if (this.submission.lossInformation.cargoLossInformation.cargoPickupDate != null) {
      this.formattedCargoPickupDate = this.dateUtils.formatDateForDisplay(this.submission.lossInformation.cargoLossInformation.cargoPickupDate)
    }
    if (this.submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate != null) {
      this.formattedCargoDeliveryDate = this.dateUtils.formatDateForDisplay(this.submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate)
    }
  }

  cargoPickupDateOnInput() {
    if (this.submission.lossInformation.cargoLossInformation.cargoPickupDate.trim() == "") {
      this.submission.lossInformation.cargoLossInformation.cargoPickupDate = null;
    }
    this.formattedCargoPickupDate = this.dateUtils.formatDateForDisplay(this.submission.lossInformation.cargoLossInformation.cargoPickupDate);
    this.cargoPickupDatePicker = false;
    this.cargoPickupDateValidationRules = this.aplFrontEndValidation.runCargoPickupDateValidationRules()
  }

  enteredCargoPickupDateOnInput() {
    this.submission.lossInformation.cargoLossInformation.cargoPickupDate = this.dateUtils.parseFormattedDate(this.formattedCargoPickupDate);
    this.cargoPickupDatePicker = false;
    this.cargoPickupDateValidationRules = this.aplFrontEndValidation.runCargoPickupDateValidationRules()
  }

  cargoPickupStateOnSelect() {
    this.submission.lossInformation.cargoLossInformation.cargoStatePickedUpFrom = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.cargoStatePickedUpFrom);
  }

  cargoDeliveryStateOnSelect() {
    this.submission.lossInformation.cargoLossInformation.cargoExpectedStateDeliveredTo = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.cargoExpectedStateDeliveredTo);
  }

  cargoDeliveryDateOnInput() {
    this.formattedCargoDeliveryDate = this.dateUtils.formatDateForDisplay(this.submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate);
    this.cargoDeliveryDatePicker = false;
    this.cargoDeliveryDateValidationRules = this.aplFrontEndValidation.runCargoDeliveryDateValidationRules()
  }

  enteredCargoDeliveryDateOnInput() {
    this.submission.lossInformation.cargoLossInformation.cargoExpectedDeliveryDate = this.dateUtils.parseFormattedDate(this.formattedCargoDeliveryDate);
    this.cargoDeliveryDatePicker = false;
    this.cargoDeliveryDateValidationRules = this.aplFrontEndValidation.runCargoDeliveryDateValidationRules()
  }

  addNewCargoItem() {
    this.submission.lossInformation.cargoLossInformation.cargoList.push(new CargoItem());
  }

  removeCargoItem(index: number) {
    this.submission.lossInformation.cargoLossInformation.cargoList.splice(index, 1)

  }

  cargoItemOnInput(item: CargoItem) {
    item.itemName = this.uiUtils.checkIfEmpty(item.itemName)
    // Need to clear rules array if item is NULL.
    this.cargoItemsValidationRules = item.itemName != null ? this.aplFrontEndValidation.runTextInputValidationRules_isRequired(false) : [];
  }

  removeEmptyCargoItems() {
    for (let i = 0; i < this.submission.lossInformation.cargoLossInformation.cargoList.length; i++) {
      if (!this.submission.lossInformation.cargoLossInformation.cargoList[i].itemName) {
        this.removeCargoItem(i);
        i = i - 1;
      }
    }
  }


  async triggerNonRequiredValidations() {
    await this.validationUtils.triggerBlurs(['cargo-pickup-date', 'cargo-delivery-date']);
  }

  trimCargoInfoObject() {
    this.submission.lossInformation.cargoLossInformation.cargoList.forEach((item) => {
      item.itemName = this.uiUtils.testForTrim(item.itemName)
    })
    if (this.submission.lossInformation.cargoLossInformation.isShipperInfoAvailable) {
      this.submission.lossInformation.cargoLossInformation.shipper.fullName = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.fullName)
      this.submission.lossInformation.cargoLossInformation.shipper.primaryEmail = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.primaryEmail)
      this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine1)
      this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine2)
      this.submission.lossInformation.cargoLossInformation.shipper.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.location.city)
    }
    if (this.submission.lossInformation.cargoLossInformation.isConsigneeInfoAvailable) {
      this.submission.lossInformation.cargoLossInformation.consignee.fullName = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.fullName)
      this.submission.lossInformation.cargoLossInformation.consignee.primaryEmail = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.primaryEmail)
      this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine1)
      this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.location.addressLine2)
      this.submission.lossInformation.cargoLossInformation.consignee.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.consignee.location.city)
    }
    if (this.submission.lossInformation.cargoLossInformation.wasTowCompanyUsed) {
      this.submission.lossInformation.cargoLossInformation.towCompany.fullName = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.fullName)
      this.submission.lossInformation.cargoLossInformation.towCompany.primaryEmail = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.primaryEmail)
      this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine1)
      this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.location.addressLine2)
      this.submission.lossInformation.cargoLossInformation.towCompany.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.towCompany.location.city)
    }
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  shouldShowCargoInfoAlertIcon(): boolean{
      if (this.submission.lossInformation?.lossType == "Cargo") {
        return !this.validationUtils.validateRequiredFieldsOnCargo(this.submission);
      }
  }

  async navigateTo(route: string) {
    this.$store.state.showAlertIconCargoInfo = this.shouldShowCargoInfoAlertIcon();
    this.trimCargoInfoObject()
    await this.triggerNonRequiredValidations();
      this.removeEmptyCargoItems();
      if (this.$store.state.cargoLossInformationPageValid &&
            this.$store.state.isConsigneeComponentValid &&
              this.$store.state.isShipperComponentValid &&
                this.$store.state.isTowCompanyComponentValid)
      {
                this.$store.state.showValidationSnackbar = false
                this.$store.state.idleVue.idleTime = 1740000
                this.uiUtils.navigateAndSaveSubmission(this.submission, route)

      } else {
        this.$store.state.showValidationSnackbar = true
      }
    }
}
</script>

<style>

.white-bg {
  background-color: white !important;
  color: white;
}
</style>
