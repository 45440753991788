<template>
  <v-col>
    <v-row>
      <div data-cy="confirmation-vue">
      </div>
    </v-row>

    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <v-card data-cy="confirmation-card">
          <div data-cy="container" class="container">
            <img data-cy="confirmation-img" class="confirmation-image" src="../assets/apl_submitted.png">

            <p class="confirmation-text" data-cy="confirmation-message-insured-name">Your claim for <span style="font-weight: bold" data-cy="insured-name">{{ submission.customerInformation.insured.fullName }}</span> has been successfully submitted and is in the process of being reviewed and assigned to a claim professional who will contact you{{this.$store.state.siteBrand.confirmationContacted}}</p>

            <p class="confirmation-text" cols="3" data-cy="confirmation-message-email-sent-to">A confirmation email will be sent to:
              <span style="font-weight: bold" data-cy="insured-email" v-if='!this.formattedEmails'>{{ submission.customerInformation.claimReporter.primaryEmail }}</span>
              <span style="font-weight: bold" data-cy="additional-email" v-else>{{ submission.customerInformation.claimReporter.primaryEmail }}, {{ formattedEmails }}</span>
            </p>
            <p class="confirmation-text" data-cy="confirmation-message-report-number">The report number for this claim is <span style="font-weight: bold" data-cy="report-number-text">{{ submission.reportNumber }}</span></p>
            <p class="confirmation-text" data-cy="confirmation-message-questions">If you have any questions regarding this claim please contact us at:</p>
            <v-row>
              <v-icon small class="pl-3 pr-1" color="#036fe6" data-cy="phone-icon">mdi-phone</v-icon>
              <p class="contact-info" data-cy="contact-info-phone">{{this.$store.state.siteBrand.confirmationNumber}}</p>
            </v-row>
            <v-row>
              <v-icon small class="pl-3 pr-1 pb-5" color="#036fe6" data-cy="mail-icon">mdi-mail</v-icon>
              <p class="contact-info pb-5" data-cy="contact-info-email">
                <a :href="`mailto:${this.$store.state.siteBrand.confirmationEmail}`" data-cy="contact-email-link" target="_blank">{{this.$store.state.siteBrand.confirmationEmail}}</a></p>
            </v-row>
          </div>
        </v-card>

        <!-- Should probably remove these when removing button -->
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="navigateTo('landing-page')" data-cy="file-another-claim-button">FILE ANOTHER CLAIM</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class Confirmation extends Vue {

  uiUtils: UiUtils = new UiUtils();

  submission: FnolSubmission;
  formattedEmails: string;

  created() {
    this.submission = this.$store.state.submission;
    this.handleAdditionalEmails();
    console.log('confirmation page created')
  }

  navigateTo(route: string) {
    this.$store.dispatch('resetStore').then(() => {
      if (route == 'landing-page'){
        this.$store.state.submission = new FnolSubmission()
      }
        this.uiUtils.navigate(route)
    })
  }

  handleAdditionalEmails() {
    let emailsArray = []
    let additionalCopiesArray = []
    const claimReporterEmail = this.submission.customerInformation.claimReporter.primaryEmail;
    const primaryContactEmail = this.submission.customerInformation.primaryContact.primaryEmail;

    if (claimReporterEmail.localeCompare(primaryContactEmail) != 0) {
        emailsArray.push(this.submission.customerInformation.primaryContact.primaryEmail);
    }

    if(this.submission.additionalInformation.additionalCopies) {
        additionalCopiesArray = this.submission.additionalInformation.additionalCopies.split(";")
    }
      additionalCopiesArray.forEach((email) => {
        if (email.localeCompare(primaryContactEmail) != 0) {
              emailsArray.push(email)
        }
      })
          this.formattedEmails = emailsArray.join(", ")
  }

}
</script>

<style scoped lang="scss">

.container {
  max-width: fit-content !important;
  //margin-left: 8%;
  //margin-right: 8%;
}

.confirmation-text {
  color: #00274e;
  font-size: 16px;
  font-family: proxima-nova;
  margin-bottom: 16px;
  //margin-left: 8%;
  //margin-right: 8%;
  //text-align: center;
}

.contact-info {
  color: #036fe6;
  font-family: Poppins;
  font-size: 14px;
  //margin-left: 12%;
  margin-bottom: 0;
  margin-top: 0;
}

.confirmation-image {
  margin: 4% 4% 4% 4%;
}

.icon {

}

</style>

<style scoped lang="scss">

.container {
  max-width: fit-content !important;
  //margin-left: 8%;
  //margin-right: 8%;
}

.confirmation-text {
  color: #00274e;
  font-size: 16px;
  font-family: proxima-nova;
  margin-bottom: 16px;
  //margin-left: 8%;
  //margin-right: 8%;
  //text-align: center;
}

.contact-info {
  color: #036fe6;
  font-family: Poppins;
  font-size: 14px;
  //margin-left: 12%;
  margin-bottom: 0;
  margin-top: 0;
}

.confirmation-image {
  margin: 4% 4% 4% 4%;
}

.icon {

}

</style>
