<template>
  <div data-cy="loss-information-review-vue">
  <!-- Loss Information -->
<!--  <div class="divider" data-cy="rs-loss-information-section"><span class="px-4">Loss Information</span></div>-->
    <v-row data-cy="rs-loss-information-section">
      <v-btn tile outlined class="ml-3 elevation-0">
        <span class="edit-section-label" data-cy="rs-edit-loss-information-cta" @click="editLossInformationOnClick">
          <v-icon>mdi-pencil</v-icon> Go To Loss Information</span>
      </v-btn>
    </v-row>

    <v-row>
      <v-col cols="auto" class="pb-0 mb-n2" data-cy="rs-loss-details-label">
        <div class="sub-section-label" data-cy="rs-loss-details-sub-section-label">Loss Details</div>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''" >
        <v-row data-cy="rs-loss-info-loss-type-label">
          LOSS TYPE
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-loss-type-value">
          {{submission.lossInformation.lossType}}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0" >
        <v-row data-cy="rs-loss-info-loss-cause-label">
          LOSS CAUSE
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-loss-cause-value">
          {{submission.lossInformation.lossCause}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3" v-if="submission.lossInformation.detailedLossCause!=null && submission.lossInformation.detailedLossCauseCode!=null">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-loss-info-detail-loss-cause-label">
          DETAILED LOSS CAUSE
        </v-row>
        <v-row class="rs-values" v-if="submission.lossInformation.detailedLossCause!=null && submission.lossInformation.detailedLossCauseCode!=null" data-cy="rs-loss-info-detail-loss-cause-value">
          {{submission.lossInformation.detailedLossCause}}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3" v-if="submission.lossInformation.otherDescription!=null">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-loss-info-other-description-label">
          OTHER DESCRIPTION
        </v-row>
        <v-row class="rs-values" v-if="submission.lossInformation.otherDescription!=null" data-cy="rs-loss-info-other-description-value">
          {{ submission.lossInformation.otherDescription }}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-loss-info-loss-description-label">
          LOSS DESCRIPTION
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-loss-description-value">
          {{ submission.lossInformation.lossDescription }}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-loss-info-weather-related-label">
          WEATHER RELATED
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-weather-related-value">
          {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.weatherRelated) }}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="rs-labels pl-3" v-if="submission.lossInformation.lossType == 'Liability'">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-loss-info-in-litigation-label">
          IS THIS IN LITIGATION?
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-in-litigation-value">
          {{ uiUtils.convertBooleanToYesNo(submission.lossInformation.inLitigation) }}
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto" class="pb-0 mb-n2" data-cy="rs-loss-location-label">
        <div class="sub-section-label" data-cy="rs-loss-location-sub-section-label">Loss Location</div>
      </v-col>
    </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-loss-info-address-line-1-label">
        ADDRESS LINE 1
      </v-row>
      <v-row class="rs-values" data-cy="rs-loss-info-address-line-1-value">
        {{ submission.lossInformation.location.addressLine1 }}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0">
      <v-row data-cy="rs-loss-info-address-line-2-label">
        ADDRESS LINE 2
      </v-row>
      <v-row class="rs-values" data-cy="rs-loss-info-address-line-2-value">
        {{ submission.lossInformation.location.addressLine2 }}
      </v-row>
    </v-col>
  </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
        <v-row data-cy="rs-loss-info-city-label">
          CITY
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-city-value">
          {{ submission.lossInformation.location.city }}
        </v-row>
      </v-col>
      <v-col cols="8" class="pb-0">
        <v-row data-cy="rs-loss-info-state-label">
          STATE
        </v-row>
        <v-row class="rs-values" data-cy="rs-loss-info-state-value">
          {{ submission.lossInformation.location.state }}
        </v-row>
      </v-col>
    </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
      <v-row data-cy="rs-loss-info-zip-label">
        ZIP
      </v-row>
      <v-row class="rs-values" data-cy="rs-loss-info-zip-value">
        {{submission.lossInformation.location.postalCode}}
      </v-row>
    </v-col>
    <v-col cols="8" class="pb-0" >
      <v-row data-cy="rs-loss-info-country-label">
        COUNTRY
      </v-row>
      <v-row class="rs-values" data-cy="rs-loss-info-country-value">
        {{submission.lossInformation.location.country}}
      </v-row>
    </v-col>
  </v-row>

    <v-row class="rs-labels pt-2">
      <v-col class="pb-0" data-cy="rs-loss-info-location-details-label">LOSS LOCATION DETAILS</v-col>
    </v-row>
    <v-row class="rs-values">
      <v-col class="pt-0" data-cy="rs-loss-info-location-details-value"> {{submission.lossInformation.lossLocationDetails}}</v-col>
    </v-row>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";


@Component
export default class LossInformationReview extends Vue {

  submission: FnolSubmission;
  uiUtils = new UiUtils()

  created() {
    console.log('Loss Information Review component created')
    this.submission = this.$store.state.submission;
  }

  editLossInformationOnClick() {
    this.uiUtils.navigate('loss-information')
  }

}
</script>

<style scoped lang="scss">

</style>

