
export class AplLoss{

    id: number;

    businessUnit: string;

    businessUnitCode: string;

    lossType: string;

    lossTypeCode: string;

    cause: string;

    causeCode: string;

    detail: string;

    detailCode: string;

}