<template>
  <v-col data-cy="contact-information-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"/>
      </v-col>

      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div>
              <span data-cy="contact-information-label"
                    class="page-label berninaSansCompressedFont">Contact Information</span>
            </div>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{ submission.reportNumber }}</span>
          </v-col>
        </v-row>

        <PolicyFoundInsuredComponent v-if="this.$store.state.loggedInUser.isAuthenticated && $store.state.wasPolicySearchSuccessful"/>
        <InsuredComponent v-else/>

        <ClaimReporterComponent/>

        <PrimaryContactComponent/>

        <v-row>
          <v-col>
            <v-card class="card-background px-7" elevation="0">

              <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
                <v-btn @click="navigateTo('getting-started')" data-cy="previous-button" outlined class="previous-button"
                       v-if="$vuetify.breakpoint.mdAndUp">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>

                <v-btn @click="navigateTo('loss-information')" data-cy="next-button" class="next-button">
                  <span>Next</span><v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </v-row>

              <v-row v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                <v-btn @click="navigateTo('getting-started')" data-cy="previous-button" outlined class="previous-button" v-if="$vuetify.breakpoint.smAndDown">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>
              </v-row>

              <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                  <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                </v-btn>
              </v-row>

            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission"
                      v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {States} from "@/models/typelists/States";
import {UiUtils} from "@/utils/ui-utils";
import {Countries} from "@/models/typelists/Countries";
import {RelationToInsured} from "@/models/typelists/RelationToInsured";
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import ClaimReporterComponent from "@/views/components/ClaimReporterComponent.vue";
import PrimaryContactComponent from "@/views/components/PrimaryContactComponent.vue";
import InsuredComponent from "@/views/components/InsuredComponent.vue";
import {ValidationUtils} from "@/utils/validation-utils";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import PolicyFoundInsuredComponent from "@/views/components/PolicyFoundInsuredComponent.vue";
import {ClaimReporter} from "@/models/ClaimReporter";
import {CommonContact} from "@/models/CommonContact";
import {PrimaryContact} from "@/models/PrimaryContact";
import {ContactCorrectionUtil} from "@/utils/contact-correction-util";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";

@Component({
  components: {
    BrowserBackButtonModal,
    PolicyFoundInsuredComponent,
    InsuredComponent,
    PrimaryContactComponent,
    ClaimReporterComponent,
    SideStepperComponent,
    TimeoutModal,
    CancelClaimModal
  }
})

export default class CustomerInformation extends Vue {
  uiUtils = new UiUtils();

  submission = new FnolSubmission();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  validationUtils: ValidationUtils = new ValidationUtils();
  contactUtil: ContactCorrectionUtil = new ContactCorrectionUtil()

  statesList: Array<string> = Object.values(States);
  countriesList: Array<string> = Object.values(Countries);
  relationToInsuredList: Array<string> = Object.values(RelationToInsured);

  created() {
    console.log('customer information page created')
    this.submission = this.$store.state.submission;

    if(!this.submission.customerInformation.claimReporter) {
      this.submission.customerInformation.claimReporter = new ClaimReporter();
    }
    if(!this.submission.commonData.claimReporter) {
      this.$store.state.submission.commonData.claimReporter = new CommonContact();
    }
    if(!this.submission.commonData.claimPreparer) {
      this.$store.state.submission.commonData.claimPreparer = new CommonContact();
    }
    if(!this.submission.customerInformation.primaryContact) {
      this.submission.customerInformation.primaryContact = new PrimaryContact();
    }
    this.submission.customerInformation = this.contactUtil.correctContacts(this.submission.customerInformation)

    this.$store.state.hasBeenPassedGettingStarted = true;

  }

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  applicationTimeoutTimer() {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  //TODO This needs to include each field that is user input-able
  trimCustomerInfoObject() {
    this.submission.customerInformation.insured.fullName = this.uiUtils.testForTrim(this.submission.customerInformation.insured.fullName)
    this.submission.customerInformation.insured.location.addressLine1 = this.uiUtils.testForTrim(this.submission.customerInformation.insured.location.addressLine1)
    this.submission.customerInformation.insured.location.addressLine2 = this.uiUtils.testForTrim(this.submission.customerInformation.insured.location.addressLine2)
    this.submission.customerInformation.insured.location.city = this.uiUtils.testForTrim(this.submission.customerInformation.insured.location.city)
    this.submission.customerInformation.claimReporter.firstName = this.uiUtils.testForTrim(this.submission.customerInformation.claimReporter.firstName)
    this.submission.customerInformation.claimReporter.lastName = this.uiUtils.testForTrim(this.submission.customerInformation.claimReporter.lastName)
    this.submission.customerInformation.claimReporter.primaryEmail = this.uiUtils.testForTrim(this.submission.customerInformation.claimReporter.primaryEmail)
    this.submission.customerInformation.claimReporter.location.addressLine1 = this.uiUtils.testForTrim(this.submission.customerInformation.claimReporter.location.addressLine1)
    this.submission.customerInformation.claimReporter.location.addressLine2 = this.uiUtils.testForTrim(this.submission.customerInformation.claimReporter.location.addressLine2)
    this.submission.customerInformation.claimReporter.location.city = this.uiUtils.testForTrim(this.submission.customerInformation.claimReporter.location.city)
    this.submission.customerInformation.primaryContact.firstName = this.uiUtils.testForTrim(this.submission.customerInformation.primaryContact.firstName)
    this.submission.customerInformation.primaryContact.lastName = this.uiUtils.testForTrim(this.submission.customerInformation.primaryContact.lastName)
    this.submission.customerInformation.primaryContact.primaryEmail = this.uiUtils.testForTrim(this.submission.customerInformation.primaryContact.primaryEmail)
    this.submission.customerInformation.primaryContact.location.addressLine1 = this.uiUtils.testForTrim(this.submission.customerInformation.primaryContact.location.addressLine1)
    this.submission.customerInformation.primaryContact.location.addressLine2 = this.uiUtils.testForTrim(this.submission.customerInformation.primaryContact.location.addressLine2)
    this.submission.customerInformation.primaryContact.location.city = this.uiUtils.testForTrim(this.submission.customerInformation.primaryContact.location.city)
  }

  saveCommonData() {
    this.submission.commonData.claimReporter.firstName = this.submission.customerInformation.claimReporter.firstName;
    this.submission.commonData.claimReporter.lastName = this.submission.customerInformation.claimReporter.lastName;
    this.submission.commonData.claimReporter.emailAddress = this.submission.customerInformation.claimReporter.primaryEmail;
    this.submission.commonData.claimReporter.phone = this.submission.customerInformation.claimReporter.primaryPhone;
    if (!this.$store.state.loggedInUser.isAuthenticated) {
      this.submission.commonData.claimPreparer.firstName = this.submission.customerInformation.claimReporter.firstName;
      this.submission.commonData.claimPreparer.lastName = this.submission.customerInformation.claimReporter.lastName;
      this.submission.commonData.claimPreparer.phone = this.submission.customerInformation.claimReporter.primaryPhone;
      this.submission.commonData.claimPreparer.emailAddress = this.submission.customerInformation.claimReporter.primaryEmail;
    }
  }

  validateRequiredFields() {
    if (!this.validationUtils.validateRequiredFieldsOnInsured(this.submission.customerInformation.insured) ||
    !this.validationUtils.validateContactRequiredFields(this.submission.customerInformation.claimReporter) ||
    !this.validationUtils.validateContactRequiredFields(this.submission.customerInformation.primaryContact)){
      this.$store.state.customerInfoIsValid = true
      window.scrollTo(0, 0)
    } else{
      this.$store.state.customerInfoIsValid = true
      this.submission.customerInformation.insured.isFullNameRequired = true
    }
  }

  async navigateTo(route: string) {
    this.trimCustomerInfoObject()
    this.validateRequiredFields()

    if (this.$store.state.claimReporterComponentIsValid &&
        this.$store.state.primaryContactComponentIsValid &&
        this.$store.state.insuredComponentIsValid) {
      this.$store.state.idleVue.idleTime = 1740000

      this.saveCommonData()
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    } else {
      this.$store.state.showValidationSnackbar = true
      window.scrollTo(0, 0);
    }

  }
}
</script>

<style scoped lang="scss">

</style>
