import Vue from 'vue'
import App from './App.vue'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import "@/styles/global.scss";
import '@mdi/font/css/materialdesignicons.css'
// @ts-ignore
import IdleVue from 'idle-vue';
// @ts-ignore
import VueTheMask from 'vue-the-mask';
import dayjs from "dayjs";

Vue.use(VueTheMask)

Vue.config.productionTip = false

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1740000, // Timeout length when inactive (math): 1740000 milliseconds = 29 min.
  startAtIdle: false,
  dayjs
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
