<!-- Document Upload Data -->
<template>
  <v-form class="form-content px-0" data-cy="supporting-documents-form" ref="form">
    <content-card class="pl-0 pr-0">
      <v-col data-cy="document-upload-vue" class="pa-0">
        <v-row>
          <v-col>
            <v-card class="card-background" elevation="0" data-cy="documents-upload-v-card">
              <v-row class="pl-6">
                <v-card-title class="section-label berninaSansCompressedFont" data-cy="documents-label">Documents</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="12" md="7" class="input-spacing">
                  <v-row class="pb-2 pl-8">
                    <v-col class="input-spacing">
                      <p class="label-text" data-cy="documents-photos-text">HAVE DOCUMENTS OR PHOTOS RELATED TO THE ACCIDENT?<span class="file-mb"> (Max 8MB each, Max 33MB total)</span></p>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 pl-10">
                    <v-col class="pa-0" style="display: flex">
                      <v-col cols="5" class="pa-0">
                        <v-icon color="#00247e" data-cy="supported-types-info-icon" @click="showSupportedTypesModal = !showSupportedTypesModal">mdi-information</v-icon>
                        <span data-cy="supported-types-text" class="disclaimer-text" @click="showSupportedTypesModal = !showSupportedTypesModal"
                              style="cursor: pointer">Accepted File Types</span>
                      </v-col>

                      <v-col class="pa-0" cols="1">
                        <v-icon style="color: #FFB400" data-cy="password-warning-icon">mdi-alert</v-icon>
                      </v-col>
                      <v-col class="pa-0">
                        <span data-cy="password-warning-text" class="disclaimer-text">Password protected files cannot be uploaded</span>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 pl-8">
                    <v-col class="file-mb" data-cy="totalMB-count">{{ this.totalMB }}MB of 33MB</v-col>
                  </v-row>

                  <v-row v-for="(fileStatus, index) in uploadedFileStatuses" :key="index" :data-cy="'document-row-' + index">
                    <v-col cols="12" class="pl-10 py-2" style="display: inherit">


                      <div v-if="fileStatus.status === 'success'">
                        <v-icon class="upload-success">mdi-paperclip</v-icon>
                        <span class="upload-success file-name" :data-cy="'file-name-'+index">{{ fileStatus.fileName }}</span><span :data-cy="'file-status-'+index" class="file-mb ml-3">({{ computeFileMB(fileStatus) }}MB)</span>
                        <delete-file-modal :submission="submission" :file-status="fileStatus" :index="index" :fileInput="fileInput"></delete-file-modal>
                      </div>

                      <div v-else-if="fileStatus.status === 'in progress'">
                        <v-icon class="file-name">mdi-paperclip</v-icon>
                        <span class="file-name" :data-cy="'file-name-'+index">{{ fileStatus.fileName }}</span><span :data-cy="'file-status-'+index" class="file-mb ml-3">({{ computeFileMB(fileStatus) }}MB)</span>
                        <v-progress-circular v-if="fileStatus.status === 'in progress'" indeterminate color="tundora2" size="24" width="2" class="right" data-cy="upload-spinner"></v-progress-circular>
                        <div class="scan-box" v-if="fileStatus.status === 'in progress'" data-cy="scan-for-virus-message">We ask you for your patience while we scan your files for viruses.</div>
                      </div>

                      <div v-else>
                        <v-icon color="red" class="mr-1">mdi-alert-circle</v-icon>
                        <span class="file-name" :data-cy="'file-name-'+index">{{ fileStatus.fileName }}</span><span :data-cy="'file-status-'+index" class="file-mb ml-3">({{ computeFileMB(fileStatus) }}MB)</span>
                        <span :data-cy="'upload-status-message-' + index" class="upload-status-message failure">{{ fileStatus.fileUploadFailureReason }}</span>
                      </div>

                    </v-col>
                  </v-row>

                  <v-row class="add-file-row" justify="start">
                    <v-col cols="11" class="add-file-col" :class="$vuetify.breakpoint.mdAndUp ? 'pl-9 pb-8' : 'pl-0'">
                      <v-btn
                          class="add-file-button"
                          @click="onAddFileClick"
                          depressed
                          :disabled="totalMB >= 33.0"
                          data-cy="add-file-button">
                        <v-icon color="#000247e" size="30" data-cy="add-file-icon">mdi-plus</v-icon>
                        <span class="add-file-text" data-cy="add-file-text">{{ getAddFileButtonText() }}</span>
                        <input type="file" @change="onFileInputChange" class="file-input" ref="fileInput" data-cy="file-input">
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <FailedToUploadModal :lastReasonOfUploadFailure="lastReasonOfUploadFailure" v-if="this.$store.state.showFailedToUploadModal"/>

        <v-dialog v-model="showSupportedTypesModal" persistent max-width="750px">
          <v-card data-cy="supported-types-modal">

            <v-card-title class="py-0" style="display: flex">
              <v-row>
                <v-col cols="8">
                  <span class="modal-header" style="word-break: break-word" data-cy="accepted-file-types-title">Accepted File Types</span>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col style="text-align: right" class="mr-4 pb-0 pt-1" cols="2">
                  <v-icon data-cy="supported-types-cancel-x" style="color: #00274e"
                          @click="showSupportedTypesModal = !showSupportedTypesModal">mdi-close
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-title>

            <v-col cols="12" class="py-0">
              <v-divider color="#E6E9ED" style="opacity: 50%"/>
            </v-col>
            <v-card-text class="px-3 py-0">
              <v-container>

                <v-row>
                  <v-col data-cy="accepted-types-column" class="modal-text" style="color: #00274e">
                    <span data-cy="accepted-types-text">The following file types are supported for upload to accompany the current claim submission:</span><br/>
                    <v-icon style="color: #FFB400" data-cy="warning-icon">mdi-alert</v-icon>
                    <span data-cy="password-protected-files-message" style="font-size: 14px">Password protected files cannot be uploaded</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="modal-text" style="color: #00274e">
                      <span data-cy="document-file-types-title" style="font-weight: bold">Document file types supported:</span> <br/> <span data-cy="document-file-types-text">{{ getDocFilesList() }}</span> <br/> <br/>
                      <span data-cy="image-file-types-title" style="font-weight: bold">Image file types supported:</span> <br/><span data-cy="image-file-types-text">{{ getImageFilesList() }}</span><br/><br/>
                      <span data-cy="audio-file-types-title" style="font-weight: bold">Audio file types supported:</span> <br/><span data-cy="audio-file-types-text">{{ getAudioFilesList() }}</span><br/><br/>
                      <span data-cy="video-file-types-title" style="font-weight: bold">Video file types supported:</span> <br/><span data-cy="video-file-types-text">{{ getVideoFilesList() }}</span>

                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="next-button" depressed rounded small @click="showSupportedTypesModal = !showSupportedTypesModal"
                     style="border-radius: 4px; margin: 0 20px 0 0" data-cy="accepted-file-types-close-btn">
                <span style="color: white">Close</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </content-card>
  </v-form>
</template>

<script lang="ts">
import {Prop, Vue} from 'vue-property-decorator';
import Component from "vue-class-component";
import ContentCard from "@/documentUpload/ContentCard.vue";
import UploadedFileStatus from "@/documentUpload/UploadedFileStatus";
import {FnolSubmission} from "@/models/FnolSubmission";
import DeleteFileModal from "@/documentUpload/DeleteFileModal.vue";
import {DocumentUploadClient} from "@/documentUpload/document-upload-client";
import {AxiosError} from "axios";
import FailedToUploadModal from "@/documentUpload/FailedToUploadModal.vue";

@Component({
  components: {FailedToUploadModal, ContentCard, DeleteFileModal}
})
export default class DocumentUploadComponent extends Vue {

  @Prop()
  submission: FnolSubmission;

  showSupportedTypesModal: boolean = false;
  totalMB: number = 0;
  lastReasonOfUploadFailure = "";


  docUploadClient = new DocumentUploadClient();

// TODO: Can we add these methods and properties to the Vue TS typing?
  fileInput: Vue & { click: () => void, files: FileList, value: any }

  created() {
    this.computeTotalMB();
  }

  mounted() {
    this.fileInput = this.$refs.fileInput as Vue & { click: () => void, files: FileList, value: any }
  }

  // When the Add File button is clicked, trigger the file dialog to open
  onAddFileClick() {
    if (this.fileInput) {
      this.fileInput.click()
    }
  }

  async onFileInputChange(): Promise<void> {
    // Only dispatch the uploadFile action if the user actually selects a file (they could cancel the dialog)
    this.computeTotalMB();


    let theFile = this.fileInput.files[0]

    console.log("LENGTH: " + this.fileInput.files.length + " FILE ADDED: " + theFile.name)

    if (this.fileInput.files.length > 0) {
      console.log("File Size: " + theFile.size / 1000000)
      const uploadableFile: UploadedFileStatus = new UploadedFileStatus(theFile.name, theFile.size, 'in progress')
      this.submission.additionalInformation.uploadedFileStatuses.push(uploadableFile);

      if (this.fileCanBeUploaded(uploadableFile)) {
          await this.docUploadClient.uploadFile(theFile, this.submission.submissionId).then(fileUploadResponse => {
            if (fileUploadResponse) {
              if (fileUploadResponse.uploaded || fileUploadResponse.updated) {
                uploadableFile.status = 'success'
                uploadableFile.filePath = fileUploadResponse.filePath
                this.$store.dispatch('saveSubmission', this.submission)
              } else if (fileUploadResponse.infected) {
                uploadableFile.status = 'infected'
                uploadableFile.fileUploadFailureReason = "File failed virus scan"
              } else if (fileUploadResponse.encrypted){
                uploadableFile.status = 'failure'
                uploadableFile.fileUploadFailureReason = "PDF file is password protected"
              } else {
                uploadableFile.status = 'failure'
                uploadableFile.fileUploadFailureReason = "File failed to upload"
              }
            }
          }).catch((error) => {
            if (error instanceof AxiosError) {
              uploadableFile.status = 'failure'
              uploadableFile.fileUploadFailureReason = "File failed to upload"
            } else {
              uploadableFile.status = 'failure'
              uploadableFile.fileUploadFailureReason = "File failed to upload"
            }
          }).finally(() => {
            console.log("FINALLY: " + uploadableFile.status)
          })
        }
    }
    this.computeTotalMB();
  }

  computeFileMB(fileStatus: UploadedFileStatus): number {
    return Number((fileStatus.fileSize / 1000000).toFixed(3));
  }

  computeTotalMB() {
    this.totalMB = 0;
    if (this.submission.additionalInformation.uploadedFileStatuses != null && this.submission.additionalInformation.uploadedFileStatuses.length > 0) {
      let sumMB = 0;
      Array.from(this.submission.additionalInformation.uploadedFileStatuses).forEach(file => {
        if (file.status === 'success' || file.status === "in progress") {
          sumMB += this.computeFileMB(file)
        }
      })
      this.totalMB = parseFloat((this.totalMB + sumMB).toFixed(3))
    }
  }

  get uploadedFileStatuses(): UploadedFileStatus[] {
    this.computeTotalMB()
    return this.submission.additionalInformation.uploadedFileStatuses;
  }

  getAddFileButtonText(): string {
    return !this.submission.additionalInformation.uploadedFileStatuses.length ? "Add File" : "Add Another File";
  }

  fileCanBeUploaded(file: UploadedFileStatus): boolean {
    if(!this.fileIsValidType(file)) {
      file.status = 'failure'
      this.fileInput.value = null
      this.lastReasonOfUploadFailure = "File Type Not Allowed"
      this.$store.state.showFailedToUploadModal = true
      this.uploadedFileStatuses.pop()
      return false;
    }

    if (this.isFileSizeTooSmall(file)) {
      file.status = 'failure'
      this.fileInput.value = null
      this.lastReasonOfUploadFailure = "File is Too Small"
      this.$store.state.showFailedToUploadModal = true
      this.uploadedFileStatuses.pop()
      return false;
    }

    if(!this.isFileLessThan8MB(file)) {
      file.status = 'failure'
      this.fileInput.value = null
      this.lastReasonOfUploadFailure = "File is Too Large"
      this.$store.state.showFailedToUploadModal = true
      this.uploadedFileStatuses.pop()
      return false;
    }

    if(!this.isFileListBelowSizeLimit(file)) {
      file.status = 'failure'
      this.fileInput.value = null
      this.lastReasonOfUploadFailure = "File Size Limit Reached"
      this.$store.state.showFailedToUploadModal = true
      this.uploadedFileStatuses.pop()
      return false;
    }

    return true;
  }

  isFileLessThan8MB(file: UploadedFileStatus): boolean {
      return this.computeFileMB(file) < 8.0;
  }

  isFileListBelowSizeLimit(file: UploadedFileStatus): boolean {
    return (Number((this.totalMB + this.computeFileMB(file)).toFixed(3)) < 33.0)
  }

  isFileSizeTooSmall(file: UploadedFileStatus): boolean {
    return (file.fileSize == 0);
  }

  fileIsValidType(file: UploadedFileStatus): boolean {
    let isFileValidType = false;
    let fileExtension = this.getFileTypeExtension(file);
    let validFileTypes = this.getDocFilesList() + this.getImageFilesList() + this.getAudioFilesList() + this.getVideoFilesList();

    if(validFileTypes.toLowerCase().includes(fileExtension.toLowerCase())) {
      isFileValidType = true;
    }
    return isFileValidType;
  }

  getFileTypeExtension(file: UploadedFileStatus): string {
    return file.fileName.substring(file.fileName.lastIndexOf("."))
  }

  getDocFilesList() {
    return '.doc, .docm, .docx, .dot, .htm, .html, .rtf, .tif, .tiff, .txt, .pdf, .xls, .xlsx';
  }

  getImageFilesList() {
    return '.jpeg/.jpg, .png, .gif, .bmp'
  }

  getAudioFilesList() {
    return '.aac, .flac, .m4a, .mp3, .mp4, .wav, .wma'
  }

  getVideoFilesList() {
    return '.3g2, .3gp, .3gpp, .avi, .dv, .m4v, .mov, .mp4, .mp4v, .mpe, .mpeg, .mpeg1, .mpeg2, .mpeg4, .mpg4'
  }

}
</script>

<style lang="scss">

@import '~vuetify/src/styles/settings/_variables';

button.v-btn.v-size--default {
  font-size: 18px;
  height: 42px;

  &.button-primary {
    color: white
  }

  &.button-secondary {
    border-color: blue;
  }

  &.button-tertiary {
    border-color: gray;
  }

  &.add-file-button {
    background-color: white;
    color: #00274e;
    border: thin solid #00274e;
  }
}

.info-icon1 {
  display: inherit;
}

.add-file-row {
  justify-content: center;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: left;
  }
}

.add-file-col {
  text-align: center;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-left: 10px;
    text-align: left;
  }
}

.add-file-text {
  font-size: 16px;
  font-weight: bold;
  color: #00274e;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
}

.form-content {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0 19px;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 0 69px 0 63px;
  }
}

.upload-status-message {
  font-weight: bold;
  font-size: 13px;
  color: green;

  &.failure {
    color: red;
  }
}

.file-input {
  opacity: 0;
  max-width: 0;
}

.file-mb {
  color: gray;
}

.max-file-upload {
  color: red;
}

.right {
  float: right;
}

.file-name {
  color: silver;

  &.upload-success {
    color: #00274E;
  }
}

div:deep.content-card {
  padding: 10px 68px 55px 68px;
}

.scan-box {
  margin-top: 5px;
  padding: 12px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

</style>

