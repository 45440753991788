<template>
  <v-col :data-cy="'passenger-vue'">
    <v-row>
      <v-col class="pt-0">
        <v-card color="card-background" elevation="0">
          <v-form
              autocomplete="off"
              v-model="$store.state.accidentInformationPassengersComponentIsValid">
            <div v-for="(passenger, passengerArrayIndex) in submission.lossInformation.accidentInformation.passengers" :key="passengerArrayIndex"
                 data-cy="passenger-list">
              <v-row>
                <v-col md="8" class="input-spacing">
                  <v-row>
                    <v-col class="pt-0 pb-4">
                      <div>
                        <span :data-cy="'passenger-label-'+passengerArrayIndex" class="berninaSansCompressedFont"
                              style="color: #036; font-size: 20px; font-weight: bold">Passenger {{ passengerArrayIndex + 1 }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'">
                  <v-btn @click="showModal(passengerArrayIndex)" color="#00274e" :data-cy="'remove-passenger-button-' + passengerArrayIndex"
                         outlined class="outlined-button" width="100%"><span
                      style="color: #00274e"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove Passenger</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-first-name-label-' + passengerArrayIndex" class="label-text">FIRST NAME<span class="req-asterisk">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-first-name-column-' + passengerArrayIndex">
                      <v-text-field :data-cy="'passenger-first-name-input-' + passengerArrayIndex"
                                    v-model="passenger.firstName"
                                    dense
                                    outlined
                                    clearable
                                    counter="30"
                                    maxLength="30"
                                    background-color="white"
                                    :rules="passengerFirstNameRules"
                                    @input="passengerFirstNameValidationOnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-last-name-label-' + passengerArrayIndex" class="label-text">LAST NAME<span class="req-asterisk">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-last-name-column-' + passengerArrayIndex">
                      <v-text-field :data-cy="'passenger-last-name-input-' + passengerArrayIndex"
                                    v-model="passenger.lastName"
                                    dense
                                    outlined
                                    clearable
                                    counter="30"
                                    maxLength="30"
                                    background-color="white"
                                    :rules="passengerLastNameRules"
                                    @input="passengerLastNameValidationOnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-email-label-' + passengerArrayIndex"
                         class="label-text">
                        EMAIL</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-email-column-' + passengerArrayIndex">
                      <v-text-field
                          :data-cy="'passenger-email-input-' + passengerArrayIndex"
                          v-model="passenger.primaryEmail"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          :maxLength="50"
                          counter="50"
                          :rules="passengerEmailValidationRules"
                          @input="passengerEmailValidationOnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-phone-label-' + passengerArrayIndex"
                         class="label-text"
                      >PHONE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-phone-column-' + passengerArrayIndex">
                      <v-text-field
                          :data-cy="'passenger-phone-input-' + passengerArrayIndex"
                          v-model="passenger.primaryPhone"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          v-mask="'(###) ###-####'"
                          :rules="passengerPhoneValidationRules"
                          @input="passengerPhoneValidationOnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-home-address-1-label-' + passengerArrayIndex" class="label-text">ADDRESS LINE 1</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-home-address-1-column-' + passengerArrayIndex">
                      <v-text-field :data-cy="'passenger-home-address-1-input-' + passengerArrayIndex"
                                    v-model="passenger.location.addressLine1"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="passengerAddressLine1Rules"
                                    @input="passengerAddressLine1OnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-home-address-2-label-' + passengerArrayIndex" class="label-text">ADDRESS LINE 2</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-home-address-2-column-' + passengerArrayIndex">
                      <v-text-field :data-cy="'passenger-home-address-2-input-' + passengerArrayIndex"
                                    v-model="passenger.location.addressLine2"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="passengerAddressLine2Rules"
                                    @input="passengerAddressLine2OnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-city-label-' + passengerArrayIndex"
                         class="label-text"
                      >CITY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-city-column-' + passengerArrayIndex">
                      <v-text-field
                          :data-cy="'passenger-city-input-' + passengerArrayIndex"
                          v-model="passenger.location.city"
                          dense
                          outlined
                          clearable
                          counter="60"
                          maxLength="60"
                          background-color="white"
                          :rules="passengerCityValidationRules"
                          @input="passengerCityValidationOnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-state-label-' + passengerArrayIndex"
                         class="label-text"
                      >STATE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-state-column-' + passengerArrayIndex">
                      <v-autocomplete
                          :data-cy="'passenger-state-select-' + passengerArrayIndex"
                          :id="'passenger-state-select-' + passengerArrayIndex"
                          v-model="passenger.location.state"
                          autocomplete="new-password"
                          :items="statesList"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @blur="uiUtils.triggerEnterKeyPress('passenger-state-select-' + passengerArrayIndex)"
                          @input="passengerStateOnSelect(passenger)"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-zip-code-label-' + passengerArrayIndex"
                         class="label-text"
                      >ZIP CODE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-zip-code-column-' + passengerArrayIndex">
                      <v-text-field
                          :data-cy="'passenger-zip-code-input-' + passengerArrayIndex"
                          v-model="passenger.location.postalCode"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          v-mask="['#####' ,'#####-####']"
                          :rules="passengerZipCodeRules"
                          @input="passengerZipCodeValidationOnInput(passenger)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'passenger-country-label-'+ passengerArrayIndex" class="label-text">COUNTRY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'passenger-country-column-'+ passengerArrayIndex">
                      <v-autocomplete
                          :data-cy="'passenger-country-select-'+ passengerArrayIndex"
                          v-model="passenger.location.country"
                          autocomplete="new-password"
                          :items="countriesList"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @input="passengerCountryOnSelect(passenger)"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col md="8" lg="6" class="input-spacing">
                      <p :data-cy="'passenger-insured-employee-label-' + passengerArrayIndex" class="label-text">IS THE PASSENGER AN EMPLOYEE?</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing">
                      <v-radio-group :data-cy="'passenger-insured-employee-radio-group-' + passengerArrayIndex"
                                     v-model="passenger.isInsuredEmployee"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-container">
                        <v-radio
                            label="Yes"
                            :value="true"
                            :data-cy="'passenger-insured-employee-radio-yes-' + passengerArrayIndex"
                        ></v-radio>
                        <v-radio
                            label="No"
                            :value="false"
                            :data-cy="'passenger-insured-employee-radio-no-' + passengerArrayIndex"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-row>
                    <v-col md="8" lg="6" class="input-spacing">
                      <p :data-cy="'passenger-sustain-injury-label-' + passengerArrayIndex" class="label-text">DID THE PASSENGER SUSTAIN AN INJURY IN THE ACCIDENT?</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing">
                      <v-radio-group :data-cy="'passenger-sustain-injury-radio-group-' + passengerArrayIndex"
                                     v-model="passenger.isInjured"
                                     :row="!$vuetify.breakpoint.smAndDown"
                                     class="radio-container">
                        <v-radio
                            label="Yes"
                            value="Yes"
                            :data-cy="'passenger-sustain-injury-radio-yes-' + passengerArrayIndex"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                            @change="onNoIsInjured(passenger)"
                            :data-cy="'passenger-sustain-injury-radio-no-' + passengerArrayIndex"
                        ></v-radio>
                        <v-radio
                            label="I'm unsure"
                            value="I'm unsure"
                            @change="onNoIsInjured(passenger)"
                            :data-cy="'passenger-sustain-injury-radio-unsure-' + passengerArrayIndex"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="passenger.isInjured === 'Yes'">
                <v-col>
                  <p :data-cy="'passenger-injury-information-label-' + passengerArrayIndex" class="label-text">INJURY INFORMATION</p>
                  <v-row class="ml-0" cols="8">
                    <v-col class="pl-0 pt-0 pb-0" :data-cy="'passenger-injury-information-column-' + passengerArrayIndex">
                      <v-textarea v-model="passenger.injuryInformationDescription"
                                  autocomplete="off"
                                  auto-grow
                                  background-color="white"
                                  clearable
                                  counter="950"
                                  maxLength="950"
                                  :data-cy="'passenger-injury-information-input-' + passengerArrayIndex"
                                  id="injury-information"
                                  outlined
                                  :rules="passengerInjuryInformationValidationRules"
                                  @input="passengerInjuryInformationValidationOnInput(passenger)">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <v-row>
            <v-divider style="margin-left:0.7em;"></v-divider>
          </v-row>
        </v-card>
      </v-col>

      <!-- START:   Delete Passenger Dialog   -->
      <v-dialog v-model="$store.state.showAccidentInfoRemovePassengerModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px" :data-cy="'remove-passenger-dialog'">
        <v-card v-if="$store.state.showAccidentInfoRemovePassengerModal" style="overflow-x: hidden; border-radius: 12px" :data-cy="'remove-passenger-card'" color="#f2f4f6">
          <v-row justify="end">
            <v-col style="text-align: right" class="mr-4 py-0 col">
              <span class="exit-remove-button" @click="noButton" :data-cy="'remove-passenger-x'">x</span>
            </v-col>
          </v-row>
          <v-card-title class="py-0">
            <span style="color: #003366; font-size: 24px; font-weight: 500" class="headline" :data-cy="'remove-passenger-header'">Remove Passenger</span>
          </v-card-title>
          <v-card-text class="input-spacing">
            <v-container>
              <v-row>
                <v-col cols="12" class="input-spacing">
                  <p class="modal-text" :data-cy="'remove-passenger-text'">
                    Are you sure you want to remove the Passenger? {{ $store.state.currentAccidentInfoPassengerFullName }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-row justify="end" class="pr-8">
            <v-card-actions class="pb-8">
              <v-btn depressed color="primary" :data-cy="'remove-passenger-yes'"
                     @click="yesButton($store.state.currentAccidentInfoPassengerIndex)">Yes</v-btn>
            </v-card-actions>
            <v-card-actions class="pb-8">
              <v-btn depressed text color="primary" outlined :data-cy="'remove-passenger-no'" @click="noButton">No</v-btn>
            </v-card-actions>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- END:   Delete Passenger Dialog   -->

    </v-row>
  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {States} from "@/models/typelists/States";
import {UiUtils} from "@/utils/ui-utils";
import {Passenger} from "@/models/Passenger";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {Countries} from "@/models/typelists/Countries";

@Component
export default class PassengerInformationComponent extends Vue {


  submission: FnolSubmission;
  uiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  statesList: Array<string> = this.getStatesList();
  countriesList: Array<string> = this.getCountriesList();

  passengerFirstNameRules: Array<string> = [];
  passengerLastNameRules: Array<string> = [];
  passengerAddressLine1Rules: Array<string> = [];
  passengerAddressLine2Rules: Array<string> = [];
  passengerCityValidationRules: Array<string> = [];
  passengerZipCodeRules: Array<string> = [];
  passengerEmailValidationRules: Array<string> = [];
  passengerInjuryInformationValidationRules: Array<string> = [];
  passengerPhoneValidationRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
    this.$store.state.showAccidentInfoRemovePassengerModal = false;
  }

  showModal(index: number) {
    this.$store.state.currentAccidentInfoPassengerIndex = index
    this.$store.state.currentAccidentInfoPassengerFullName = ""

    if (this.submission.lossInformation.accidentInformation.passengers[index].firstName && this.submission.lossInformation.accidentInformation.passengers[index].lastName) {
      this.$store.state.currentAccidentInfoPassengerFullName = this.submission.lossInformation.accidentInformation.passengers[index].firstName + " " + this.submission.lossInformation.accidentInformation.passengers[index].lastName
    }
    this.$store.state.showAccidentInfoRemovePassengerModal = true;
  }

  yesButton(index: number) {
    this.submission.lossInformation.accidentInformation.passengers.splice(index, 1);
    this.$store.state.showAccidentInfoRemovePassengerModal = false;
  }

  noButton() {
    this.$store.state.showAccidentInfoRemovePassengerModal = false;
  }

  getStatesList(): Array<string> {
    return Object.values(States);
  }

  passengerStateOnSelect(passenger: Passenger) {
    passenger.location.state = this.uiUtils.testForTrim(passenger.location.state);
    passenger.location.stateCode = this.uiUtils.getStateCode(passenger.location.state);
  }

  getCountriesList(): Array<string> {
    return Object.values(Countries);
  }

  passengerCountryOnSelect(passenger: Passenger) {
    passenger.location.country = this.uiUtils.testForTrim(passenger.location.country);
    passenger.location.countryCode = this.uiUtils.getCountryCode(passenger.location.country);
  }

  passengerFirstNameValidationOnInput(passenger: Passenger) {
    passenger.firstName = this.uiUtils.checkIfEmpty(passenger.firstName)
    this.passengerFirstNameRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  passengerLastNameValidationOnInput(passenger: Passenger) {
    passenger.lastName = this.uiUtils.checkIfEmpty(passenger.lastName)
    this.passengerLastNameRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  passengerAddressLine1OnInput(passenger: Passenger) {
    passenger.location.addressLine1 = this.uiUtils.checkIfEmpty(passenger.location.addressLine1)
    this.passengerAddressLine1Rules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  passengerAddressLine2OnInput(passenger: Passenger) {
    passenger.location.addressLine2 = this.uiUtils.checkIfEmpty(passenger.location.addressLine2)
    this.passengerAddressLine2Rules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  passengerCityValidationOnInput(passenger: Passenger) {
    passenger.location.city = this.uiUtils.checkIfEmpty(passenger.location.city);
    this.passengerCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  passengerZipCodeValidationOnInput(passenger: Passenger) {
    passenger.location.postalCode = this.uiUtils.testForTrim(passenger.location.postalCode)
    this.passengerZipCodeRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  passengerEmailValidationOnInput(passenger: Passenger) {
    passenger.primaryEmail = this.uiUtils.checkIfEmpty(passenger.primaryEmail)
    this.passengerEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  passengerInjuryInformationValidationOnInput(passenger: Passenger) {
    passenger.injuryInformationDescription = this.uiUtils.checkIfEmpty(passenger.injuryInformationDescription)
    this.passengerInjuryInformationValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  passengerPhoneValidationOnInput(passenger: Passenger) {
    passenger.primaryPhone = this.uiUtils.testForTrim(passenger.primaryPhone)
    this.passengerPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  onNoIsInjured(passenger: Passenger) {
    passenger.injuryInformationDescription = null
  }

}

</script>

<style>


</style>
