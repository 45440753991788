<template>
  <v-col data-cy="landing-page-vue" class="customLandingPageBackground" :style="$vuetify.breakpoint.smAndDown ? 'padding-bottom: 0' : 'padding-bottom: 60px'" >

    <v-row class="mb-4">
      <v-col cols="2" class="pl-md-7">
        <img data-cy="header-logo" :src="require(`../assets/${this.$store.state.siteBrand.companyLogoHeader}`)" alt="Company Logo">
      </v-col>
      <v-col cols="7" class="mt-10 claim-start-now-img hidden-sm-and-down" >
        <img data-cy="claim-start-now-logo" style="max-width: 70%"  src="../assets/ClaimStartNow_WM.png" alt="GAIG Desktop Logo">

      </v-col>
      <v-col cols="7" class="mt-10 hidden-md-and-up" style="display: flex;justify-content: end;">
        <img data-cy="claim-start-now-logo" style="max-width: 70%"  src="../assets/ClaimStartNow_WM.png" alt="GAIG Mobile Logo">
      </v-col>

      <v-col cols="3">
        <div data-cy="faq-link" class="subtitle-2 landing-page-faq-link" @click="showFaqModalOnClick">FAQ</div>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="1" />
      <v-col class="pl-0 pb-0">
        <v-row>
          <v-col :class="$vuetify.breakpoint.smAndDown?'text-center':''">
            <label data-cy="claim-reporting-label" style="color: white; font-size: 22px">CLAIM REPORTING MADE EASY!</label>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span data-cy="claim-reporting-message" style="color: white; font-size: 18px">Report your claims from virtually anywhere 24/7 using our online claim reporting platform. Here is the information that you will need to submit your claim.</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <ul>
              <li style="color: white; font-size: 18px; padding-bottom: .5em" data-cy="claim-reporting-info-li1">Policy Number</li>
              <li style="color: white; font-size: 18px; padding-bottom: .5em" data-cy="claim-reporting-info-li2">Insured and Claimant Contact Information</li>
              <li style="color: white; font-size: 18px; padding-bottom: .5em" data-cy="claim-reporting-info-li3">Date and approximate time of the loss</li>
              <li style="color: white; font-size: 18px; padding-bottom: .5em" data-cy="claim-reporting-info-li4">A description of what happened</li>
            </ul>
          </v-col>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <span style="color: white; font-size: 18px" data-cy="claim-reporting-process-span">
             The process to report a claim should take less than 10 minutes. After submitting your claim, a claims professional will contact you{{this.$store.state.siteBrand.reportingProcessContacted}}
            </span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" class="login-vcard">
        <v-card :tile="$vuetify.breakpoint.smAndDown">
          <v-card-text >
          <v-row class="login-group">
            <v-col>
              <v-btn-toggle
                  id="loginToggle"
                  data-cy="login-btn-toggle"
                  v-model="noLogin"
                  mandatory
                  color="#00274e">
                  <v-btn data-cy="have-login-btn">I have a Login</v-btn>
                  <v-btn data-cy="no-login-btn">I don't have a Login</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row class="justify-center mt-5" v-if="!noLogin">
            <v-btn @click="doLogin" :width="$vuetify.breakpoint.smAndDown? '80%':''" class="login-button elevation-0 mb-5" data-cy="card-login-btn">Login Here</v-btn>
          </v-row>

            <div data-cy="placeholder" v-if="!noLogin">
              <v-row class="mt-2 mb-0 justify-center">
                <div style="font-size: 18px; padding: 0 12px;" class="login-question" data-cy="access-agreement-login">
                  By clicking “Login Here” you are accepting the<br>terms of use in the <span class="access-agreement-text" @click="showAccessAgreementModalOnClick">Access Agreement</span></div>
                <br>
              </v-row>
            </div>

            <!-- No Login Recaptcha   -->
          <v-row class="recaptcha-container mt-3" justify="center"  v-if="noLogin">
            <v-col cols="10">
              <v-row class="mt-2" data-cy="recaptcha-widget" v-if="loadRecaptcha === true" justify="center">
                <vue-recaptcha
                    ref="recaptcha"
                    :loadRecaptchaScript="true"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    :sitekey="this.$store.state.siteKey">
                </vue-recaptcha>
              </v-row>

              <v-row class="mt-5 pb-0">
                <v-col style="text-align: center;">
                  <v-btn :disabled="!isHuman"
                         :class="!isHuman ? 'outlined-button custom-disabled' : 'login-button'"
                         @click="navigateTo('submission-loader')"
                         data-cy="start-claim-button"
                         width="100%">
                    Start A Claim
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

            <div data-cy="placeholder" v-if="noLogin">
              <v-row class="mt-2 mb-0 justify-center">
                <p style="font-size: 18px; padding: 0 12px;" class="login-question" data-cy="access-agreement-noLogin">
                  By clicking “Submit a Claim” you are accepting the<br>terms of use in the <span class="access-agreement-text" @click="showAccessAgreementModalOnClick">Access Agreement</span></p>
                <br>
              </v-row>
            </div>

            <div data-cy="placeholder">
              <v-row class="mt-2 mb-0 justify-center">
                <p style="font-size: 18px; padding: 0 12px;" class="login-question" data-cy="contact-us-span">
                  If you need to speak to someone, please call us at:</p>
                <br>
              </v-row>

              <v-row class="mt-0 justify-center">
                <div class="contact-us-info" v-html="this.$store.state.siteBrand.contactInfo"></div>
              </v-row>
            </div>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <FaqComponent @clicked="onFaqClose" v-if="this.showFaqModal" data-cy="faq-component"/>
    <AccessAgreementAPLComponent @clicked="onAccessAgreementClose" v-if="this.showAccessAgreementModal" data-cy="access-agreement-component"/>
  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha'
import {UiUtils} from "@/utils/ui-utils";
import FaqComponent from './components/faq/FaqComponent.vue';
import AccessAgreementAPLComponent from "@/views/components/AccessAgreementComponent.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import store from "@/store/store";


@Component({
  computed: {
    store() {
      return store
    }
  },
  components: {
    AccessAgreementAPLComponent,
    FaqComponent,
    'vue-recaptcha': VueRecaptcha
  }
})
export default class LandingPage extends Vue {

  uiUtils = new UiUtils();
  submission: FnolSubmission = new FnolSubmission()

  loadRecaptcha = false;
  isHuman:boolean = false;
  isInternal = false;
  showFaqModal = false;
  noLogin = true;
  showAccessAgreementModal = false;


  async created() {
    this.$store.commit('setIsAccessInternal', true)
    await this.$store.dispatch('getIsInternal').then(() => {
      if (this.$store.state.isAccessInternal == true) {
        this.isInternal = true;
      }
    })

    this.$store.commit('setIsEnvDevOrLocal', false)
    await this.$store.dispatch('getIsEnvDevOrLocal')

    await this.retrieveLoggedInUser()

      if (this.$store.state.loggedInUser.isAuthenticated) {
        this.uiUtils.navigate('submission-loader');
      }

    await this.setRecaptcha();
    window.onbeforeunload = function () {}   // remove leave page warning
  }

  async setRecaptcha() {
    this.$store.commit('setIsRecaptchaTokenValid', false)
    await this.$store.dispatch('getKey').then(() => {
      if (this.$store.state.siteKey == "ACCEPTANCE-TEST-867-5309") {
        console.log("Recaptcha disabled for acceptance tests")
        this.loadRecaptcha = false;
        this.isHuman = true;
      } else {
        this.loadRecaptcha = true;
      }
    })
  }

  showFaqModalOnClick(){
    this.showFaqModal = !this.showFaqModal
  }

  onFaqClose(reset){
    this.showFaqModal = reset;
  }

  showAccessAgreementModalOnClick(){
    this.showAccessAgreementModal = !this.showAccessAgreementModal
  }

  onAccessAgreementClose(reset){
    this.showAccessAgreementModal = reset;
  }

  async onCaptchaVerified(recaptchaToken: string) {
    await this.$store.dispatch('verifyToken', recaptchaToken).then(() => {
      if (this.$store.state.isRecaptchaTokenValid == true) {
        this.isHuman = true;
      }
    });
  }

  onCaptchaExpired() {
    this.isHuman = false;
  }

  navigateTo(route: string) {
    this.$store.state.idleVue.idleTime = 1740000
    this.$store.state.showTimeoutModalMessage = false;
    window.scrollTo(0, 0);
    this.uiUtils.navigate(route);
  }

  async doLogin() {
    if (!this.$store.state.loggedInUser.isAuthenticated) {
      window.location.replace("/login")
    }
  }

  async retrieveLoggedInUser() {
    await this.$store.dispatch('getLoggedInUser').then(() => {
      let user = this.$store.state.loggedInUser;
      if (user.firstName != null && user.firstName != '') {
        this.$store.state.displayUserInitials = user.firstName!.substr(0, 1);

        if (user.lastName != null && user.lastName != '') {
          this.$store.state.displayUserInitials += user.lastName!.substr(0, 1);
        }
      } else {
        this.$store.state.displayUserInitials = null;
      }
    });
  }
}
</script>

<style lang="scss" scoped>

.landing-page-faq-link{
  color: white;
  cursor: pointer;
  font-weight: bold;
  flex: auto;
  text-align: right;
  padding: 3em 5em 0 0
}

.user-name-align{
  display: block;
  margin-block: auto;
}

.access-agreement-text{
  text-decoration: underline;
  cursor: pointer;
}

</style>
