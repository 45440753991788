import {Contact} from "@/models/Contact";

export class MedicalProvider extends Contact {
    treatmentDate!: string;
    notes!: string;

    //These are required for the date picker to function
    treatmentDateForDisplay: string = null;
    treatmentDatePicker: Boolean = false;
}
