<template>
  <v-col data-cy="accident-information-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent  @navigate="(route) => navigateTo(route)"></SideStepperComponent>
      </v-col>

      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div>
              <span data-cy="accident-information-label" class="page-label berninaSansCompressedFont">Accident Information</span>
            </div>
          </v-col>

          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{ submission.reportNumber }}</span>
          </v-col>
        </v-row>

        <v-expansion-panels>
          <v-expansion-panel class="card-background" data-cy="driver-expansion-panel">
            <v-expansion-panel-header data-cy="driver-expansion-panel-header">
              <div data-cy="driver-information-label" class="section-label berninaSansCompressedFont collapse-header">Insured Driver</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager id="driver-content">
              <v-card class="card-background" :class="$vuetify.breakpoint.smAndDown ? 'pl-8 pr-8' : ''" elevation="0">

                <v-form
                    autocomplete="off"
                    v-model="$store.state.accidentInformationDriverIsValid">
                  <v-row>
                    <v-col class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="have-driver-info-label" class="label-text">DO YOU HAVE THE INSURED DRIVER'S INFORMATION?</p>
                        </v-col>
                      </v-row>

                      <v-row cols="12">
                        <v-col class="input-spacing" data-cy="has-driver-info-radio-column">
                          <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                         data-cy="have-driver-info-radio-group"
                                         class="radio-container"
                                         v-model="submission.lossInformation.accidentInformation.hasDriverInfo">
                            <v-radio
                                label="Yes"
                                :value=true
                                @change="onDoesHaveDriverInfo(true)"
                                data-cy="have-driver-info-radio-yes"
                            ></v-radio>
                            <v-radio
                                label="No"
                                :value=false
                                data-cy="have-driver-info-radio-no"
                                @change="onDoesHaveDriverInfo(false)"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <div v-if="submission.lossInformation.accidentInformation.hasDriverInfo">

                    <v-col class="input-spacing">
                      <v-row>
                        <v-col v-if="!uiUtils.areAnyOtherPartyDriversSameAsPrimaryContact(submission.otherParty)" md="8" lg="6" class="input-spacing" data-cy="driver-same-as-primary-contact-column">
                          <v-row>
                            <v-switch
                                v-model="submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact"
                                inset
                                data-cy="driver-same-as-primary-contact-switch"
                                label="Is the driver the same as the primary contact?"
                                class="v-switch-label"
                                dense
                                @change="driverSameAsPrimaryContactOnSwitch"/>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-row>
                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-first-name-label" class="label-text">FIRST NAME<span class="req-asterisk">*</span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-first-name-column">
                            <div data-cy="driver-first-name-text" v-if="submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact">{{ submission.customerInformation.primaryContact.firstName }}</div>
                            <v-text-field
                                v-else
                                data-cy="driver-first-name-input"
                                v-model="submission.lossInformation.accidentInformation.driver.firstName"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                :maxLength="30"
                                counter="30"
                                @input="driverFirstNameValidationOnInput"
                                :rules="driverFirstNameValidationRules"/>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-last-name-label" class="label-text">LAST NAME<span class="req-asterisk">*</span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-last-name-column">
                            <div data-cy="driver-last-name-text" v-if="submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact">{{ submission.customerInformation.primaryContact.lastName }}</div>
                            <v-text-field
                                v-else
                                data-cy="driver-last-name-input"
                                v-model="submission.lossInformation.accidentInformation.driver.lastName"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                :maxLength="30"
                                counter="30"
                                @input="driverLastNameValidationOnInput"
                                :rules="driverLastNameValidationRules"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-email-label"
                               class="label-text">EMAIL</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-email-column">
                            <div data-cy="driver-email-text" v-if="submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact">{{ submission.customerInformation.primaryContact.primaryEmail }}</div>
                            <v-text-field
                                v-else
                                data-cy="driver-email-input"
                                v-model="submission.lossInformation.accidentInformation.driver.primaryEmail"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                :maxLength="50"
                                counter="50"
                                :rules="emailValidationRules"
                                @input="emailValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-phone-label"
                               class="label-text">PHONE</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-phone-column">
                            <div data-cy="driver-phone-text" v-if="submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact">{{ submission.customerInformation.primaryContact.primaryPhone }}</div>
                            <v-text-field
                                v-else
                                data-cy="driver-phone-input"
                                v-model="submission.lossInformation.accidentInformation.driver.primaryPhone"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                v-mask="'(###) ###-####'"
                                :rules="driverPhoneValidationRules"
                                @input="driverPhoneValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-col class="input-spacing">
                      <v-row>
                        <v-col md="8" lg="6" class="input-spacing" data-cy="driver-address-same-as-insured-column"
                               v-if="!submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact">
                          <v-row>
                            <v-switch
                                v-model="submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured"
                                inset
                                data-cy="driver-address-same-as-insured-switch"
                                class="v-switch-label"
                                label="Is the driver's address the same as the insured's?"
                                dense
                                @change="driverAddressSameAsInsuredOnSwitch"/>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>

                    <div v-if="submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact ||
                                         submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured" data-cy="driver-address-read-only">
                      <v-row>
                        <v-col cols="12">
                          <div class="label-text" data-cy="driver-address-label">DRIVER ADDRESS</div>
                          <div class="rs-values" data-cy="driver-address-line-1-text">{{ submission.lossInformation.accidentInformation.driver.location.addressLine1 }}</div>
                          <div class="rs-values" data-cy="driver-address-line-2-text">{{ submission.lossInformation.accidentInformation.driver.location.addressLine2 }}</div>
                          <div class="rs-values" data-cy="driver-city-state-zip-text">{{ submission.lossInformation.accidentInformation.driver.location.city }}, {{ submission.lossInformation.accidentInformation.driver.location.state }} {{ submission.lossInformation.accidentInformation.driver.location.postalCode }}</div>
                          <div class="rs-values" data-cy="driver-country-text">{{ submission.lossInformation.accidentInformation.driver.location.country }}</div>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-else>
                      <v-row>
                      <v-col  class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-address-1-label" class="label-text">ADDRESS LINE 1</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-address-1-column">
                            <v-text-field data-cy="driver-address-1-input"
                                          v-model="submission.lossInformation.accidentInformation.driver.location.addressLine1"
                                          dense
                                          outlined
                                          clearable
                                          background-color="white"
                                          :maxLength="60"
                                          counter="60"
                                          :rules="driverAddressLine1ValidationRules"
                                          @input="driverAddressLine1ValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                      <v-row>
                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-address-2-label" class="label-text">ADDRESS LINE 2</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-address-2-column">
                            <v-text-field data-cy="driver-address-2-input"
                                          v-model="submission.lossInformation.accidentInformation.driver.location.addressLine2"
                                          dense
                                          outlined
                                          clearable
                                          background-color="white"
                                          :maxLength="60"
                                          counter="60"
                                          :rules="driverAddressLine2ValidationRules"
                                          @input="driverAddressLine2ValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                      <v-row>
                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-city-label"
                               class="label-text">CITY</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-city-column">
                            <v-text-field
                                data-cy="driver-city-input"
                                v-model="submission.lossInformation.accidentInformation.driver.location.city"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                :maxLength="60"
                                counter="60"
                                :rules="cityValidationRules"
                                @input="driverCityValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-state-label"
                               class="label-text">STATE</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-state-column">
                            <v-autocomplete
                                data-cy="driver-state-select"
                                id="driver-state-select"
                                v-model="submission.lossInformation.accidentInformation.driver.location.state"
                                autocomplete="new-password"
                                :items="statesList"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                @blur="uiUtils.triggerEnterKeyPress('driver-state-select')"
                                @input="driverStateOnSelect"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                      <v-row>
                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-zip-code-label"
                               class="label-text">ZIP CODE</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-zip-code-column">
                            <v-text-field
                                data-cy="driver-zip-code-input"
                                v-model="submission.lossInformation.accidentInformation.driver.location.postalCode"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                v-mask="['#####' ,'#####-####']"
                                :rules="driverZipCodeValidationRules"
                                @input="driverZipCodeValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-country-label"
                               class="label-text">COUNTRY</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing" data-cy="driver-country-column">
                            <v-autocomplete
                                data-cy="driver-country-select"
                                v-model="submission.lossInformation.accidentInformation.driver.location.country"
                                autocomplete="new-password"
                                :items="countriesList"
                                dense
                                outlined
                                clearable
                                background-color="white"
                                @input="driverCountryOnSelect"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    </div>

                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="driver-insured-employee-label" class="label-text">IS THE DRIVER AN EMPLOYEE?</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col  class="input-spacing">
                            <v-radio-group data-cy="driver-insured-employee-radio-group"
                                           v-model="submission.lossInformation.accidentInformation.driver.insuredEmployee"
                                           class="radio-container"
                                           :row="!$vuetify.breakpoint.smAndDown">
                              <v-radio
                                  label="Yes"
                                  :value=true
                                  data-cy="driver-insured-employee-radio-yes"
                              ></v-radio>
                              <v-radio
                                  label="No"
                                  :value=false
                                  data-cy="driver-insured-employee-radio-no">
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col class="input-spacing" >
                            <p data-cy="driver-sustain-injury-label" class="label-text">DID THE DRIVER SUSTAIN AN INJURY IN THE ACCIDENT?</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="input-spacing">
                            <v-radio-group data-cy="driver-sustain-injury-radio-group"
                                           v-model="submission.lossInformation.accidentInformation.driver.sustainInjury"
                                           :row="!$vuetify.breakpoint.smAndDown"
                                           class="radio-container">
                              <v-radio
                                  label="Yes"
                                  value="Yes"
                                  data-cy="driver-sustain-injury-radio-yes"
                                  @change="onYesIsInjured"
                              ></v-radio>
                              <v-radio
                                  label="No"
                                  value="No"
                                  @change="onNoIsInjured"
                                  data-cy="driver-sustain-injury-radio-no"

                              ></v-radio>
                              <v-radio
                                  label="I'm unsure"
                                  value="I'm unsure"
                                  @change="onNoIsInjured"
                                  data-cy="driver-sustain-injury-radio-unsure"

                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row v-if="submission.lossInformation.accidentInformation.driver.sustainInjury === 'Yes'">
                      <v-col class="pb-0">
                        <v-row>
                          <v-col class="pb-0">
                            <p data-cy="driver-injury-label" class="label-text">INJURY INFORMATION</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="pt-0 pb-0" data-cy="driver-injury-column">
                            <v-textarea data-cy="driver-injury-input"
                                          id="driver-injury-info"
                                          v-model="submission.lossInformation.accidentInformation.driver.injury"
                                          dense
                                          outlined
                                          clearable
                                          auto-grow
                                          background-color="white"
                                          :maxLength="950"
                                          counter="950"
                                          :rules="driverInjuryInfoValidationRules"
                                          @input="driverInjuryInfoValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="mt-5 card-background" data-cy="vehicle-expansion-panel">
            <v-expansion-panel-header data-cy="vehicle-expansion-panel-header">
              <div data-cy="vehicle-information-label" class="berninaSansCompressedFont collapse-header">Insured Vehicle</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager id="vehicle-content">
              <v-card color="card-background" :class="$vuetify.breakpoint.smAndDown ? 'pl-8 pr-8' : ''" elevation="0">
                <v-form
                    v-model="$store.state.accidentInformationVehicleIsValid"
                    autocomplete="off">

                  <v-row v-if="shouldShowVehicleSearch()" class="pl-3 pr-8 py-4">
                    <v-col class="input-spacing vehicle-search-box" data-cy="vehicle-search-row" md="10" lg="8">
                      <v-row class="py-1 px-4">
                        <p class="label-text" data-cy="vehicle-label" style="color: #00274e">VEHICLE SEARCH (VIN, Year, Make, Model)</p>
                      </v-row>
                      <v-row class="py-1 px-4">
                        <p class="label-text" data-cy="vehicle-not-listed-label" style="color: #00274e">If vehicle is not listed in dropdown, please enter the vehicle information manually</p>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" cols="10" data-cy="vehicle-select-column">
                          <v-autocomplete
                              v-model="$store.state.selectedVehicle"
                              :item-text="vehicle => getVehicleName(vehicle)"
                              :items="$store.state.policyVehicles"
                              autocomplete="off"
                              background-color="white"
                              data-cy="vehicle-select"
                              dense
                              outlined
                              return-object>
                            <template v-slot:selection="{ item }">
                              <span class="autocomplete-blue-text pr-2">{{ item.vin }}</span>
                              <span class="autocomplete-text pl-1">{{ item.vehicleYear }}</span>
                              <span class="autocomplete-text pl-1">{{ item.vehicleMake }}</span>
                              <span class="autocomplete-text pl-1">{{ item.vehicleModel }}</span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content @click="setVehicleInfo(item)">
                                <v-list-item-title>
                                  <span class="autocomplete-blue-text pr-2">{{ item.vin }}</span>
                                  <span class="autocomplete-text">{{ item.vehicleYear }}</span>
                                  <span class="autocomplete-text">{{ item.vehicleMake }}</span>
                                  <span class="autocomplete-text">{{ item.vehicleModel }}</span>
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col class="px-0 pt-2 pb-0" cols="2" @click="reset">
                          <v-icon color="#003366" data-cy="autocomplete-reset-icon" style="cursor: pointer">
                            mdi-restore
                          </v-icon>
                          <span class="reset-button-label px-0" data-cy="autocomplete-reset-span"
                                style="cursor: pointer">Reset</span>
                        </v-col>
                      </v-row>
                      <v-overlay
                          absolute
                          data-cy="retrieve-loading-overlay"
                          v-if="$store.state.loadingRetrieveCall">
                        <div data-cy="retrieve-loader">
                          <label data-cy="retrieve-loading-text">Gathering your vehicles...</label>
                          <v-progress-linear data-cy="retrieve-loading-bar" indeterminate color="white" class="mb-0"/>
                        </div>
                      </v-overlay>

                      <v-overlay
                          absolute
                          data-cy="no-results-overlay"
                          v-if="$store.state.wasPolicyRetrieveSuccessful && this.$store.state.policyVehicles.length === 0">
                        <v-col style="text-align: center; align-items: center" data-cy="no-results">
                          <label data-cy="no-results-text" >No vehicles were returned. Please enter vehicle information below.</label>
                        </v-col>
                      </v-overlay>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="vin-lookup-label" class="label-text">VIN</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="vin-lookup-column">
                          <div data-cy="vin-lookup-text"
                               v-if="this.usingSearch"><p data-cy="vin-text" class="read-only">{{ submission.lossInformation.accidentInformation.vehicleInformation.vin }}</p>
                          </div>
                          <v-text-field v-model="submission.lossInformation.accidentInformation.vehicleInformation.vin"
                                        v-else
                                        :maxLength="17"
                                        :rules="vinLookupValidationRules"
                                        background-color="white"
                                        clearable
                                        outlined
                                        counter="17"
                                        data-cy="vin-lookup-input"
                                        dense
                                        @input="vinLookupValidationOnInput"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="4" class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="year-label" class="label-text">YEAR</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="year-column">
                          <div data-cy="year-text"
                               v-if="this.usingSearch"><p class="read-only">{{ submission.lossInformation.accidentInformation.vehicleInformation.year }}</p>
                          </div>
                          <v-text-field v-model="submission.lossInformation.accidentInformation.vehicleInformation.year"
                                        v-else
                                        v-mask="'####'"
                                        :rules="yearValidationRules"
                                        background-color="white"
                                        clearable
                                        outlined
                                        data-cy="year-input"
                                        dense
                                        maxLength="4"
                                        @input="yearValidationOnInput"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="4" class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="make-label" class="label-text">MAKE</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="make-column">
                          <div
                               v-if="this.usingSearch"><p data-cy="make-text" class="read-only">{{ submission.lossInformation.accidentInformation.vehicleInformation.make }}</p>
                          </div>
                          <v-text-field v-model="submission.lossInformation.accidentInformation.vehicleInformation.make"
                                        v-else
                                        :maxLength="60"
                                        :rules="makeValidationRules"
                                        background-color="white"
                                        clearable
                                        outlined
                                        counter="60"
                                        data-cy="make-input"
                                        dense
                                        @input="makeValidationOnInput"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="4" class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="model-label" class="label-text">MODEL</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="model-column">
                          <div data-cy="model-text"
                               v-if="this.usingSearch"><p class="read-only">{{ submission.lossInformation.accidentInformation.vehicleInformation.model }}</p>
                          </div>
                          <v-text-field v-model="submission.lossInformation.accidentInformation.vehicleInformation.model"
                                        :maxLength="60"
                                        v-else
                                        :rules="modelValidationRules"
                                        background-color="white"
                                        clearable
                                        outlined
                                        counter="60"
                                        data-cy="model-input"
                                        dense
                                        @input="modelValidationOnInput"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p class="label-text" data-cy="plate-number-label">PLATE NUMBER</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="plate-number-column">
                          <v-text-field v-model="submission.lossInformation.accidentInformation.vehicleInformation.plateNumber"
                                        :maxLength="10"
                                        :rules="plateNumberValidationRules"
                                        background-color="white"
                                        clearable
                                        counter="10"
                                        data-cy="plate-number-input"
                                        dense
                                        outlined
                                        @input="plateNumberOnInput"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p class="label-text" data-cy="vehicle-damage-label">VEHICLE DAMAGE</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="vehicle-damage-column">
                          <v-textarea id="vehicle-damage"
                                      v-model="submission.lossInformation.accidentInformation.vehicleInformation.damage"
                                      :maxLength="950"
                                      :rules="vehicleDamageValidationRules"
                                      background-color="white"
                                      clearable
                                      auto-grow
                                      counter="950"
                                      data-cy="vehicle-damage-input"
                                      dense
                                      outlined
                                      @input="vehicleDamageOnInput"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="input-spacing">
                      <v-row>
                        <v-col class="input-spacing">
                          <p data-cy="vehicle-location-label" class="label-text">VEHICLE CURRENT LOCATION</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing" data-cy="vehicle-location-column">
                          <v-textarea data-cy="vehicle-location-input"
                                      v-model="submission.lossInformation.accidentInformation.vehicleInformation.location"
                                      dense
                                      outlined
                                      clearable
                                      auto-grow
                                      background-color="white"
                                      :maxLength="950"
                                      counter="950"
                                      :rules="vehicleCurrentLocationRules"
                                      @input="vehicleCurrentLocationOnInput"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col md="8" class="input-spacing">
                          <p data-cy="owner-same-as-insured-label" class="label-text">DOES THE INSURED OWN THE VEHICLE?</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="input-spacing">
                          <v-radio-group data-cy="owner-same-as-insured-radio-group"
                                         v-model="submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured"
                                         :row="!$vuetify.breakpoint.smAndDown"
                                         class="radio-container"
                          >
                            <v-radio
                                label="Yes"
                                :value=true
                                @change="onSameAsInsuredIsYes"
                                data-cy="owner-same-as-insured-radio-yes"
                            ></v-radio>
                            <v-radio
                                label="No"
                                :value=false
                                data-cy="owner-same-as-insured-radio-no"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured == false">
                    <v-col data-cy="owner-not-same-as-insured-input-column" class="pb-0">
                      <p data-cy="owner-not-same-as-insured-label" class="label-text">COMMENT</p>
                      <v-row>
                        <v-col class="input-spacing" data-cy="owner-not-same-as-insured-column">
                          <v-text-field data-cy="owner-not-same-as-insured-input"
                                        v-model="submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured"
                                        dense
                                        outlined
                                        clearable
                                        background-color="white"
                                        maxLength="30"
                                        counter="30"
                                        :rules="ownerNotSameAsInsuredRules"
                                        @input="ownerNotSameAsInsuredOnInput"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="mt-5 card-background" data-cy="passenger-expansion-panel">
            <v-expansion-panel-header data-cy="passenger-expansion-panel-header" id="passenger-expansion-panel-header">
              <div data-cy="passenger-information-label" class="berninaSansCompressedFont collapse-header">Insured Vehicle Passengers</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager id="passenger-content">
              <v-card color="card-background" :class="$vuetify.breakpoint.smAndDown ? 'pl-6' : ''" elevation="0">
                <v-row v-if="submission.lossInformation.accidentInformation.passengers.length < 1" :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'">
                    <v-btn class="outlined-button" outlined @click="addNewPassenger" data-cy="add-passenger-button">
                      <span><v-icon>mdi-plus</v-icon>Add A Passenger</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mr-3">
                  <v-col class="input-spacing" data-cy="passenger-list-column">
                      <PassengerInformationComponent :data-cy="'passenger-component'"/>
                  </v-col>
                </v-row>

                <v-row v-if="submission.lossInformation.accidentInformation.passengers.length > 0" data-cy="add-passenger-column">
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'">
                    <v-btn class="previous-button ml-3" outlined width="75%" @click="addNewPassenger" data-cy="add-another-passenger-button">
                      <span><v-icon>mdi-plus</v-icon>Add Another Passenger</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>

        <v-row>
          <v-col>
            <v-card color="card-background px-7" elevation="0">
              <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
                  <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined
                         class="previous-button" v-if="$vuetify.breakpoint.mdAndUp">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>

                <v-btn @click="navigateTo('other-party')" data-cy="next-button" class="next-button">
                  <span>Next</span>
                  <v-icon>mdi-arrow-right-thick</v-icon>
                </v-btn>

              </v-row>
              <v-row v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined class="previous-button" v-if="$vuetify.breakpoint.smAndDown">
                  <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                </v-btn>
              </v-row>

              <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                  <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                </v-btn>
              </v-row>

            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission"
                      v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import PassengerInformationComponent from "@/views/components/PassengerInformationComponent.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {UiUtils} from "@/utils/ui-utils";
import {Passenger} from "@/models/Passenger";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {ValidationUtils} from "@/utils/validation-utils";
import MobileStepperComponent from "@/views/components/MobileStepperComponent.vue";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import {Driver} from '@/models/Driver';
import {PsarVehicle} from "@/models/PolicyData/PsarVehicle";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";

@Component({
  components: {
    BrowserBackButtonModal,
    MobileStepperComponent,
    SideStepperComponent,
    PassengerInformationComponent,
    TimeoutModal,
    CancelClaimModal
  }
})

export default class AccidentInformationPage extends Vue {

  uiUtils = new UiUtils();
  aplFrontEndValidation = new FrontEndValidation();
  validationUtils: ValidationUtils = new ValidationUtils();

  submission: FnolSubmission;
  statesList: Array<string> = this.getStatesList();
  countriesList: Array<string> = this.getCountriesList();

  driverFirstNameValidationRules: Array<string> = [];
  driverLastNameValidationRules: Array<string> = [];

  driverAddressLine1ValidationRules: Array<string> = [];
  driverAddressLine2ValidationRules: Array<string> = [];
  vehicleDamageValidationRules: Array<string> = [];
  vehicleCurrentLocationRules: Array<string> = [];
  ownerNotSameAsInsuredRules: Array<string> = [];

  vinLookupValidationRules: Array<string> = [];
  yearValidationRules: Array<string> = [];
  plateNumberValidationRules: Array<string> = [];
  makeValidationRules: Array<string> = [];
  modelValidationRules: Array<string> = [];
  cityValidationRules: Array<string> = [];
  driverZipCodeValidationRules: Array<string> = [];
  emailValidationRules: Array<string> = [];
  driverPhoneValidationRules: Array<string> = [];
  driverInjuryInfoValidationRules: Array<string> = [];
  usingSearch: boolean = false;
  index: number = 0;

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  onSameAsInsuredIsYes() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured = null
  }

  created() {
    this.submission = this.$store.state.submission;

      this.uiUtils.instantiateLossObject(this.submission)

    if (this.submission.lossInformation.accidentInformation != null
        && this.submission.lossInformation.accidentInformation.hasDriverInfo == null) {
      this.submission.lossInformation.accidentInformation.hasDriverInfo = false;
    }

    if (this.submission.lossInformation.accidentInformation.hasDriverInfo == true) {

      if (this.submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact) {
        this.driverSameAsPrimaryContactOnSwitch();
      }

      if (this.submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured) {
        this.driverAddressSameAsInsuredOnSwitch();
      }

      if (this.submission.lossInformation.accidentInformation.driver.insuredEmployee == null) {
        this.submission.lossInformation.accidentInformation.driver.insuredEmployee = true;
      }
    }

    if (this.submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured == null) {
      this.submission.lossInformation.accidentInformation.vehicleInformation.ownerSameAsInsured = true;
    }

    if(this.submission.lossInformation.accidentInformation.vehicleInformation.vehicleKey){
      this.usingSearch = true;
    }

  }

  applicationTimeoutTimer() {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  async trimAccidentInfoObject() {
    if (this.submission.lossInformation.accidentInformation.driver != null && this.submission.lossInformation.accidentInformation.hasDriverInfo == true) {
      this.submission.lossInformation.accidentInformation.driver.firstName = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.firstName)
      this.submission.lossInformation.accidentInformation.driver.lastName = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.lastName)
      this.submission.lossInformation.accidentInformation.driver.primaryEmail = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.primaryEmail)
      this.submission.lossInformation.accidentInformation.driver.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.location.addressLine1)
      this.submission.lossInformation.accidentInformation.driver.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.location.addressLine2)
      this.submission.lossInformation.accidentInformation.driver.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.location.city)
      this.submission.lossInformation.accidentInformation.driver.injury = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.injury)
    }

    this.submission.lossInformation.accidentInformation.vehicleInformation.vin = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.vin)
    this.submission.lossInformation.accidentInformation.vehicleInformation.plateNumber = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.plateNumber)
    this.submission.lossInformation.accidentInformation.vehicleInformation.make = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.make)
    this.submission.lossInformation.accidentInformation.vehicleInformation.model = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.model)
    this.submission.lossInformation.accidentInformation.vehicleInformation.damage = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.damage)
    this.submission.lossInformation.accidentInformation.vehicleInformation.location = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.location)
    this.submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured)

    await this.submission.lossInformation.accidentInformation.passengers.forEach((passenger) => {
      passenger.firstName = this.uiUtils.testForTrim(passenger.firstName)
      passenger.lastName = this.uiUtils.testForTrim(passenger.lastName)
      passenger.primaryEmail = this.uiUtils.testForTrim(passenger.primaryEmail)
      passenger.location.addressLine1 = this.uiUtils.testForTrim(passenger.location.addressLine1)
      passenger.location.addressLine2 = this.uiUtils.testForTrim(passenger.location.addressLine2)
      passenger.location.city = this.uiUtils.testForTrim(passenger.location.city)
      passenger.injuryInformationDescription = this.uiUtils.testForTrim(passenger.injuryInformationDescription)
    });
  }

  isAccidentInfoPageValid(){
    return this.$store.state.accidentInformationDriverIsValid &&
        this.$store.state.accidentInformationVehicleIsValid &&
        this.$store.state.accidentInformationPassengersComponentIsValid;

}

  async navigateTo(route: string) {
    await this.trimAccidentInfoObject();

    if (this.isAccidentInfoPageValid()) {
      this.$store.state.showValidationSnackbar = false
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    }else{
      this.$store.state.showValidationSnackbar = true

      window.scrollTo(0, 0);
      if (!this.$store.state.accidentInformationDriverIsValid) {
        this.uiUtils.openPanelContent('driver-content')
      }

      if (!this.$store.state.accidentInformationVehicleIsValid) {
        this.uiUtils.openPanelContent('passenger-content')
      }
    }
  }

  vehicleDamageOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.damage = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.damage)
    this.vehicleDamageValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  addNewPassenger() {
    this.submission.lossInformation.accidentInformation.passengers.push(new Passenger())
  }

  reset() {
    this.$store.state.selectedVehicle = null;
    this.submission.lossInformation.accidentInformation.vehicleInformation.year = null;
    this.submission.lossInformation.accidentInformation.vehicleInformation.make = null;
    this.submission.lossInformation.accidentInformation.vehicleInformation.model = null;
    this.submission.lossInformation.accidentInformation.vehicleInformation.vin = null;
    this.submission.lossInformation.accidentInformation.vehicleInformation.vehicleKey = null;
    this.usingSearch = false;
  }

  setVehicleInfo(vehicle: PsarVehicle) {
    this.submission.lossInformation.accidentInformation.vehicleInformation.year = vehicle.vehicleYear.trim()
    this.submission.lossInformation.accidentInformation.vehicleInformation.make = vehicle.vehicleMake.trim()
    this.submission.lossInformation.accidentInformation.vehicleInformation.model = vehicle.vehicleModel.trim()
    this.submission.lossInformation.accidentInformation.vehicleInformation.vin = vehicle.vin.trim()
    this.submission.lossInformation.accidentInformation.vehicleInformation.vehicleKey = vehicle.vehicleKey.trim();
    this.usingSearch = true;
  }

  getStatesList(): Array<string> {
    return Object.values(States);
  }

  getCountriesList(): Array<string> {
    return Object.values(Countries);
  }

  async onYesIsInjured() {
    await this.$forceUpdate()
  }

  async onNoIsInjured() {
    this.submission.lossInformation.accidentInformation.driver.injury = null
    await this.$forceUpdate()
  }

  driverFirstNameValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.firstName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.firstName)
    this.driverFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false);
  }

  driverLastNameValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.lastName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.lastName)
    this.driverLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false);
  }

  driverAddressLine1ValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.location.addressLine1)
    this.driverAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  driverAddressLine2ValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.location.addressLine2)
    this.driverAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  driverStateOnSelect() {
    this.submission.lossInformation.accidentInformation.driver.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.location.state)
    this.submission.lossInformation.accidentInformation.driver.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.accidentInformation.driver.location.state);
  }

  driverCityValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.location.city = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.location.city)
    this.cityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  driverCountryOnSelect() {
    this.submission.lossInformation.accidentInformation.driver.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.location.country)
    this.submission.lossInformation.accidentInformation.driver.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.accidentInformation.driver.location.country);
  }

  driverPhoneValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.primaryPhone = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.primaryPhone)
    this.driverPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  driverZipCodeValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.driver.location.postalCode)
    this.driverZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules_isRequired(false)
  }

  driverInjuryInfoValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.injury = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.injury)
    this.driverInjuryInfoValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  vinLookupValidationOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.vin = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.vin)
    this.vinLookupValidationRules = this.aplFrontEndValidation.runVinValidationRules()
  }

  yearValidationOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.year = this.uiUtils.testForTrim(this.submission.lossInformation.accidentInformation.vehicleInformation.year)
    this.yearValidationRules = this.aplFrontEndValidation.runYearValidationRules()
  }

  makeValidationOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.make = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.make);
    this.makeValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  modelValidationOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.model = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.model);
    this.modelValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  plateNumberOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.plateNumber = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.plateNumber);
    this.plateNumberValidationRules = this.aplFrontEndValidation.runPlateValidationRules_isRequired(false)
  }

  vehicleCurrentLocationOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.location = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.location);
    this.vehicleCurrentLocationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }

  emailValidationOnInput() {
    this.submission.lossInformation.accidentInformation.driver.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.driver.primaryEmail);
    this.emailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  ownerNotSameAsInsuredOnInput() {
    this.submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured = this.uiUtils.checkIfEmpty(this.submission.lossInformation.accidentInformation.vehicleInformation.ownerNotSameAsInsured)
    this.ownerNotSameAsInsuredRules = this.aplFrontEndValidation.runTextInputValidationRules_isRequired(false)
  }

  async driverSameAsPrimaryContactOnSwitch() {
    if (this.submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured) {
      this.submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured = false;
    }
    this.$forceUpdate()
    this.uiUtils.contactSameAsToggle(this.submission.lossInformation.accidentInformation.driver,
        this.submission.customerInformation.primaryContact,
        this.submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact)
  }

  driverAddressSameAsInsuredOnSwitch() {
    this.$forceUpdate()
    this.uiUtils.addressSameAsToggle(this.submission.lossInformation.accidentInformation.driver.location,
        this.submission.customerInformation.insured.location,
        this.submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured)
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  shouldShowVehicleSearch() {
    return this.$store.state.wasPolicySearchSuccessful
        && (this.$store.state.loadingRetrieveCall || this.$store.state.wasPolicyRetrieveSuccessful)
  }

  getVehicleName(vehicle: PsarVehicle) {
    return `${vehicle.vin} ${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
  }

  async onDoesHaveDriverInfo(hasDriverInfo: boolean) {
    if (hasDriverInfo) {
      this.submission.lossInformation.accidentInformation.driver = new Driver();
      this.submission.lossInformation.accidentInformation.driver.isSameAsPrimaryContact = false;
      this.submission.lossInformation.accidentInformation.driver.isAddressSameAsInsured = false;
      this.submission.lossInformation.accidentInformation.driver.sustainInjury = "No";
    } else {
      if (this.submission.lossInformation.accidentInformation != null) {
        this.submission.lossInformation.accidentInformation.hasDriverInfo = false;

        // await this.$vuetify.application.update(this.$store.state)
        this.submission.lossInformation.accidentInformation.driver = null;
      }
    }
      this.$forceUpdate()
  }
}

</script>

<style lang="scss" scoped>

.reset-button-label {
  font-family: proxima-nova, sans-serif;
  text-decoration: underline;
  font-size: 16px;
  color: #002743;
}

.read-only {
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00274e;
}

</style>
