<template>
  <v-form autocomplete="off" data-cy="shipper-form" v-model="$store.state.isShipperComponentValid">
    <v-row>
      <v-col>
    <v-card class="card-background" elevation="0" >
      <v-row>
        <v-col>
          <div>
              <span data-cy="shipper-header" class="section-label berninaSansCompressedFont pl-8">Shipper</span>
          </div>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8 pb-8': 'px-8 pb-8'">
        <v-col class="input-spacing" md="8">
          <p class="label-text" data-cy="is-shipper-info-available-label">DO YOU HAVE THE SHIPPER'S INFORMATION?</p>
          <v-radio-group v-model="submission.lossInformation.cargoLossInformation.isShipperInfoAvailable"
                         :row="$vuetify.breakpoint.mdAndUp"
                         class="radio-container"
                         data-cy="is-shipper-info-available-radio-group">
            <v-radio
                :value=true
                data-cy="is-shipper-info-available-radio-yes"
                label="Yes"
            ></v-radio>
            <v-radio
                :value=false
                data-cy="is-shipper-info-available-radio-no"
                label="No"
                @change="clearShipper"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row v-if="submission.lossInformation.cargoLossInformation.isShipperInfoAvailable">
        <v-col class="input-spacing">
          <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8':'px-8'">
          <v-col cols="12" md="8" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="shipper-name-label" class="label-text">SHIPPER NAME<span class="req-asterisk">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="shipper-name-column">
                <v-text-field
                    data-cy="shipper-name-input"
                    v-model="submission.lossInformation.cargoLossInformation.shipper.fullName"
                    dense
                    outlined
                    clearable
                    background-color="white"
                    counter="100"
                    maxLength="100"
                    :rules="shipperNameValidationRules"
                    @input="shipperNameOnInput"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8':'px-8'">
        <v-col cols="12" md="4" class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-email-label" class="label-text">EMAIL</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-email-column">
              <v-text-field
                  data-cy="shipper-email-input"
                  id="shipper-email"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.primaryEmail"
                  dense
                  outlined
                  clearable
                  autocomplete="off"
                  background-color="white"
                  counter="50"
                  maxLength="50"
                  :rules="shipperEmailValidationRules"
                  @input="shipperEmailOnInput"
                  @blur="shipperEmailOnInput"/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4" class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-phone-label" class="label-text">PHONE</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-phone-column">
              <v-text-field v-model="submission.lossInformation.cargoLossInformation.shipper.primaryPhone"
                            autocomplete="off"
                            data-cy="shipper-phone-input"
                            id="shipper-phone"
                            dense outlined clearable
                            background-color="white"
                            v-mask="'(###) ###-####'"
                            :rules="shipperPhoneValidationRules"
                            @input="shipperPhoneOnInput"
                            @blur="shipperPhoneOnInput">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8':'px-8'">
        <v-col cols="12" md="8"  class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-address-line-1-label" class="label-text">ADDRESS LINE 1</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-address-line-1-column">
              <v-text-field
                  data-cy="shipper-address-line-1-input"
                  id="shipper-address1"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.location.addressLine1"
                  dense
                  outlined
                  clearable
                  autocomplete="off"
                  background-color="white"
                  counter="60"
                  maxLength="60"
                  :rules="shipperAddressLine1ValidationRules"
                  @input="shipperAddressLine1OnInput"
                  @blur="shipperAddressLine1OnInput"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8':'px-8'">
        <v-col cols="12" md="8" class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-address-line-2-label" class="label-text">ADDRESS LINE 2</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-address-line-2-column">
              <v-text-field
                  data-cy="shipper-address-line-2-input"
                  id="shipper-address2"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.location.addressLine2"
                  dense
                  outlined
                  clearable
                  autocomplete="off"
                  background-color="white"
                  counter="60"
                  maxLength="60"
                  :rules="shipperAddressLine2ValidationRules"
                  @input="shipperAddressLine2OnInput"
                  @blur="shipperAddressLine2OnInput"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8':'px-8'">
        <v-col cols="12" md="4" class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-city-label" class="label-text">CITY</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-city-column">
              <v-text-field
                  data-cy="shipper-city-input"
                  id="shipper-city"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.location.city"
                  dense
                  outlined
                  clearable
                  autocomplete="off"
                  background-color="white"
                  counter="60"
                  maxLength="60"
                  :rules="shipperCityValidationRules"
                  @input="shipperCityOnInput"
                  @blur="shipperCityOnInput"/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4"  class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-state-label" class="label-text">STATE</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-state-column">
              <v-autocomplete
                  data-cy="shipper-state-select"
                  id="shipper-state-select"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.location.state"
                  autocomplete="off"
                  :items="states"
                  dense
                  outlined
                  clearable
                  background-color="white"
                  @input="shipperStateOnSelect"
                  @blur="shipperStateOnSelect; uiUtils.triggerEnterKeyPress('shipper-state-select')"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row :class="$vuetify.breakpoint.mdAndUp? 'pl-8':'px-8'">
        <v-col cols="12" md="4" class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-zip-code-label" class="label-text">ZIP CODE</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-zip-code-column">
              <v-text-field
                  data-cy="shipper-zip-code-input"
                  id="shipper-zip"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.location.postalCode"
                  dense
                  outlined
                  autocomplete="off"
                  v-mask="['#####' ,'#####-####']"
                  clearable
                  :rules="shipperZipValidationRules"
                  @input="shipperZipOnInput"
                  background-color="white"
                  @blur="shipperZipOnInput"/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4"  class="input-spacing">
          <v-row>
            <v-col class="input-spacing">
              <p data-cy="shipper-country-label" class="label-text">COUNTRY</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="input-spacing" data-cy="shipper-country-column">
              <v-autocomplete
                  data-cy="shipper-country-select"
                  v-model="submission.lossInformation.cargoLossInformation.shipper.location.country"
                  :items="countries"
                  autocomplete="new-password"
                  dense
                  outlined
                  clearable
                  background-color="white"
                  @input="shipperCountryOnInput"
                  @blur="shipperCountryOnInput"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
        </v-col>
      </v-row>
    </v-card>
      </v-col>
    </v-row>
  </v-form>

</template>


<script lang="ts">
import {Vue, Component} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import { Shipper } from '@/models/Shipper';

@Component
export default class ShipperComponent extends Vue {

  submission: FnolSubmission;
  states: Array<String> = [];
  countries: Array<String> = [];

  uiUtils: UiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();

  shipperNameValidationRules: Array<string> = [];
  shipperEmailValidationRules: Array<string> = [];
  shipperPhoneValidationRules: Array<string> = [];
  shipperAddressLine1ValidationRules: Array<string> = [];
  shipperAddressLine2ValidationRules: Array<string> = [];
  shipperCityValidationRules: Array<string> = [];
  shipperZipValidationRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
    this.states = Object.values(States)
    this.countries = Object.values(Countries)
  }

  clearShipper(){
    this.submission.lossInformation.cargoLossInformation.shipper = new Shipper();
  }

  shipperNameOnInput() {
      this.submission.lossInformation.cargoLossInformation.shipper.fullName = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.shipper.fullName)
      this.shipperNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false);
    }

  shipperEmailOnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.primaryEmail = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.shipper.primaryEmail)
    this.shipperEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  shipperPhoneOnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.primaryPhone = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.primaryPhone)
    this.shipperPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false);
  }

  shipperAddressLine1OnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine1);
    this.shipperAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  shipperAddressLine2OnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.shipper.location.addressLine2)
    this.shipperAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  shipperCityOnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.location.city = this.uiUtils.checkIfEmpty(this.submission.lossInformation.cargoLossInformation.shipper.location.city)
    this.shipperCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false);
  }

  shipperZipOnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.location.postalCode);
    this.shipperZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules();
  }


  shipperStateOnSelect() {
    this.submission.lossInformation.cargoLossInformation.shipper.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.location.state);
    this.submission.lossInformation.cargoLossInformation.shipper.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.cargoLossInformation.shipper.location.state);
  }

  shipperCountryOnInput() {
    this.submission.lossInformation.cargoLossInformation.shipper.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.cargoLossInformation.shipper.location.country);
    this.submission.lossInformation.cargoLossInformation.shipper.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.cargoLossInformation.shipper.location.country);
  }

}
</script>

<style>

</style>