<template>
  <v-col data-cy="additional-information-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>
      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
              <span data-cy="additional-information-label" class="page-label berninaSansCompressedFont">Additional Information</span>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{ submission.reportNumber }}</span>
          </v-col>
        </v-row>

        <v-form autocomplete="off" v-model="additionalInformationPageValid">
              <v-card class="card-background" elevation="0">
                <v-row class="pl-8">
                  <v-col md="8" class="input-spacing">
                    <v-row>
                      <v-col>
                        <span data-cy="email-confirmation-message" class="label-text">A confirmation will be sent to the following email addresses:</span>
                        <div data-cy="email-confirmation-both-emails" v-if="showBothEmails()" class="label-text">
                          {{ submission.customerInformation.claimReporter.primaryEmail }} {{ submission.customerInformation.primaryContact.primaryEmail }}
                        </div>
                        <div data-cy="email-confirmation-one-email" v-else class="label-text">
                          {{ submission.customerInformation.claimReporter.primaryEmail }}
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="input-spacing">
                        <p data-cy="send-additional-copies-label" class="label-text">EMAIL ADDITIONAL COPIES OF THE CONFIRMATION TO:</p>
                      </v-col>
                    </v-row>


                    <v-chip
                        v-for="email in emailToAddress"
                        :key="'to-' + email"
                        :data-cy="'email-to-address-chip-' + email"
                        @click="removeToEmailAddress(email)"
                        color="#83a0eb"
                        text-color="white"
                        class="chip-space chip chip-content-override">
                      <v-icon left> mdi-close-circle-outline</v-icon>
                      {{ email }}
                    </v-chip>

                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 11" class="input-spacing pl-0" data-cy="email-to-address-column">
                      <v-combobox
                          data-cy="email-to-address-input"
                          v-model="emailToAddress"
                          :delimiters="delimiters"
                          :rules="emailValidationRules"
                          @input="emailValidationOnInput"
                          maxLength="100"
                          multiple
                          outlined
                          dense
                          clearable
                          data-lpignore="true"/>
                    </v-col>


                        <v-row>
                          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 11" class="input-spacing" >
                            <p data-cy="translator-needed-label" class="label-text">IS A TRANSLATOR / INTERPRETER NEEDED?</p>
                            <v-radio-group data-cy="translator-needed-radio-group"
                                           :row="!$vuetify.breakpoint.smAndDown"
                                           class="radio-container"
                                           v-model="submission.additionalInformation.translatorNeeded">
                              <v-radio
                                  label="Yes"
                                  :value=true
                                  data-cy="translator-needed-radio-yes"
                              ></v-radio>
                              <v-radio
                                  label="No"
                                  :value=false
                                  @change="onNoTranslatorNeeded"
                                  data-cy="translator-needed-radio-no"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                    <v-row v-if="submission.additionalInformation.translatorNeeded">
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 11" class="pb-0">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="preferred-language-label" class="label-text">PREFERRED LANGUAGE</p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col class="input-spacing" data-cy="preferred-language-column">
                            <v-text-field data-cy="preferred-language-input"
                                          v-model="submission.additionalInformation.preferredLanguage"
                                          dense
                                          outlined
                                          clearable
                                          background-color="white"
                                          counter="60"
                                          maxLength="60"
                                          :rules="prefLanguageValidationRules"
                                          @input="prefLanguageValidationOnInput"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- CSR Data -->
                    <v-row class="input-spacing" v-if="this.$store.state.isAccessInternal && this.$store.state.isAuthenticated == false" data-cy="csr-column">
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 11" class="input-spacing">
                        <v-row>
                          <v-col class="input-spacing">
                            <p data-cy="csr-label" class="label-text">CLAIM INTAKE SERVICE REPRESENTATIVE<span class="req-asterisk">*</span></p>
                          </v-col>
                        </v-row>
                        <v-autocomplete v-model="submission.additionalInformation.customerServiceRep"
                                        :items=customerServiceRepArray
                                        :item-text="rep => rep.firstName +' '+ rep.lastName"
                                        return-object
                                        autocomplete="new-password"
                                        data-cy="csr-select"
                                        outlined dense clearable
                                        @input="csrValidationOnInput"
                                        @blur="csrValidationOnInput"
                                        :error-messages="csrRules"
                                        background-color="white"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 12 : 11" class="input-spacing pt-5" data-cy="additional-notes-column">
                            <div data-cy="additional-notes-label" class="label-text">ADDITIONAL NOTES</div>
                            <v-textarea data-cy="additional-notes-input"
                                        v-model="submission.additionalInformation.additionalNotes"
                                        dense
                                        outlined
                                        clearable
                                        auto-grow
                                        background-color="white"
                                        counter="950"
                                        maxLength="950"
                                        :rules="additionalNotesValidationRules"
                                        @input="additionalNotesValidationOnInput"/>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </v-card>


          <DocumentUploadComponent data-cy="document-upload-component" :submission="submission"/>

          <v-row>
            <v-col :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-0'">
              <v-card class="card-background pl-7 pr-7" elevation="0">
                <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
                  <v-btn @click="navigateTo('additional-contacts')" data-cy="previous-button" outlined class="previous-button"
                         v-if="$vuetify.breakpoint.mdAndUp">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>

                  <v-btn @click="navigateTo('review-and-submit')" data-cy="next-button" class="next-button" :disabled="isNextButtonDisabled">
                    <span>Next</span>
                    <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-row>

                <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center">
                  <v-btn @click="navigateTo('additional-contacts')" data-cy="previous-button" outlined class="previous-button" v-if="$vuetify.breakpoint.smAndDown">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>
                </v-row>

                <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                    <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                  </v-btn>
                </v-row>

              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission"
                      v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {UiUtils} from "@/utils/ui-utils";
import {Status} from "@/models/typelists/Status";
import {CustomerServiceRep} from "../../src/models/CustomerServiceRep";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import DocumentUploadComponent from "@/documentUpload/DocumentUploadComponent.vue";
import UploadedFileStatus from '@/documentUpload/UploadedFileStatus';
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";

@Component({
  components: {BrowserBackButtonModal, DocumentUploadComponent, SideStepperComponent, TimeoutModal, CancelClaimModal}
})
export default class AdditionalInformation extends Vue {

  submission: FnolSubmission;
  uiUtils = new UiUtils();
  aplFrontEndValidation = new FrontEndValidation();
  delimiters: Array<string> = [' ', ',', ';']
  emailToAddress: Array<string> = [];

  emailValidationRules: Array<string> = [];
  prefLanguageValidationRules: Array<string> = [];
  additionalNotesValidationRules: Array<string> = [];
  eachEmailRule: Array<string> = [];
  csrRules: Array<string> = [];
  additionalInformationPageValid: boolean = true;
  customerServiceRepArray: Array<CustomerServiceRep> = [];

  created() {
    console.log('additional information page created')
    this.submission = this.$store.state.submission;

    // Check for "back button".
    if (this.submission.status === Status.SUBMITTED) {
      this.uiUtils.navigate('landing-page')
    }

    if (this.submission.additionalInformation && this.submission.additionalInformation.additionalCopies) {
      this.emailToAddress = this.submission.additionalInformation.additionalCopies.split(';');
    }

    if (this.$store.state.isAccessInternal) {
      this.$store.dispatch('getInternalAdminData').then(() => {
        this.customerServiceRepArray = this.$store.state.customerServiceRepList
      });
    }
  }

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  applicationTimeoutTimer() {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  removeToEmailAddress(email: string) {
    const index = this.emailToAddress.indexOf(email);
    if (index > -1) {
      this.emailToAddress.splice(index, 1);
    }
    this.emailValidationOnInput()
  }

  prefLanguageValidationOnInput() {
    this.submission.additionalInformation.preferredLanguage = this.uiUtils.checkIfEmpty(this.submission.additionalInformation.preferredLanguage)
    this.prefLanguageValidationRules = this.aplFrontEndValidation.runTextInputValidationRules_isRequired(false)
  }

  emailValidationOnInput() {
    this.emailValidationRules = [];
    this.emailToAddress.forEach((email) => {
      this.eachEmailRule = this.aplFrontEndValidation.runEmailArrayValidationRules(email);
      this.emailValidationRules = this.emailValidationRules.concat(this.eachEmailRule)
    })
    if (this.emailToAddress.length == 0) {
      this.emailValidationRules = [];
      this.eachEmailRule = [];
    }
  }

  additionalNotesValidationOnInput() {
    this.submission.additionalInformation.additionalNotes = this.uiUtils.checkIfEmpty(this.submission.additionalInformation.additionalNotes);
    this.additionalNotesValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }

  csrValidationOnInput() {
    this.csrRules = [];
    if (this.submission.additionalInformation.customerServiceRep == null) {
      this.csrRules.push("Please enter required field");
    }
  }

  trimAdditionalInfoObject() {
    this.submission.additionalInformation.preferredLanguage = this.uiUtils.testForTrim(this.submission.additionalInformation.preferredLanguage)
    this.submission.additionalInformation.additionalNotes = this.uiUtils.testForTrim(this.submission.additionalInformation.additionalNotes);
  }

  async triggerNonRequiredValidations() {
    this.csrValidationOnInput()
  }

  navigateTo(route: string) {
    this.trimAdditionalInfoObject()
    //timeout is required for the combobox to activate the change event in time for the store update
    setTimeout(() => {
      if (this.additionalInformationPageValid) {
        this.$store.state.showValidationSnackbar = false
        this.submission.additionalInformation.additionalCopies = this.emailToAddress.join(";")
        this.submission.additionalInformation.additionalCopies = this.uiUtils.testForTrim(this.submission.additionalInformation.additionalCopies);
        this.$store.state.idleVue.idleTime = 1740000

        this.getRidOfFailedUploads()
        this.uiUtils.navigateAndSaveSubmission(this.submission, route)
      } else {
        this.$store.state.showValidationSnackbar = true
        window.scrollTo(0, 0)
      }
    }, 100);
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  showBothEmails(): boolean {
    return this.submission.customerInformation.claimReporter.primaryEmail !==
        this.submission.customerInformation.primaryContact.primaryEmail;

  }

  onNoTranslatorNeeded() {
    this.submission.additionalInformation.preferredLanguage = null;
  }

  get isNextButtonDisabled(): boolean {
    let isNextButtonDisabled = false;
    this.submission.additionalInformation.uploadedFileStatuses.forEach((ufs: UploadedFileStatus) => {
      isNextButtonDisabled = ufs.status == 'in progress';
    })
    return isNextButtonDisabled;
  }

  getRidOfFailedUploads() {
    let indexesToRemove = []
    let currentIndex = 0
    this.submission.additionalInformation.uploadedFileStatuses.forEach((ufs: UploadedFileStatus) => {
      if (ufs.status == 'failure' || ufs.status == "infected") {
        console.log("Found a file to remove at index: " + currentIndex)
        indexesToRemove.push(currentIndex)
      }
      currentIndex++
    })
    indexesToRemove.slice().reverse().forEach((index) => {
      this.submission.additionalInformation.uploadedFileStatuses.splice(index, 1)
    })
  }
}
</script>
