<template>
  <v-form
      autocomplete="off"
      v-model="$store.state.otherPartyVehicleInformationPageValid">
  <v-row data-cy="other-party-vehicle-component" class="proximaNovaFont">
    <v-col>
      <v-card :class="$vuetify.breakpoint.smAndDown ? 'px-2' : ''" class="card-background" elevation="0">
        <v-row>
          <v-col md="6" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="other-vin-lookup-label" class="label-text">VIN</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="other-vin-lookup-column">
                <v-text-field data-cy="other-vin-lookup-input"
                              v-model="vehicle.vin"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              maxLength="17"
                              counter="17"
                              :rules="vinValidationRules"
                              @input="runVinValidationRules"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col md="6" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="other-plate-number-label" class="label-text">PLATE NUMBER</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="other-plate-number-column">
                <v-text-field data-cy="other-plate-number-input"
                              v-model="vehicle.plateNumber"
                              dense
                              outlined
                              clearable
                              :rules="plateNumberValidationRules"
                              @input="runPlateNumberValidationRules"
                              background-color="white"
                              maxLength="10"
                              counter="10"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="year-label" class="label-text">YEAR</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="year-column">
                <v-text-field data-cy="year-input"
                              v-model="vehicle.year"
                              dense
                              outlined
                              clearable
                              background-color="white"
                              maxLength="4"
                              v-mask="'####'"
                              :rules="yearValidationRules"
                              @input="runYearValidationRules"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col md="4" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="make-label" class="label-text">MAKE</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="make-column">
                <v-text-field data-cy="make-input"
                              v-model="vehicle.make"
                              dense
                              outlined
                              clearable
                              :rules="makeValidationRules"
                              @input="runMakeValidationRules"
                              background-color="white"
                              maxLength="60"
                              counter="60"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="model-label" class="label-text">MODEL</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="model-column">
                <v-text-field data-cy="model-input"
                              v-model="vehicle.model"
                              dense
                              outlined
                              clearable
                              :rules="modelValidationRules"
                              @input="runModelValidationRules"
                              background-color="white"
                              maxLength="60"
                              counter="60"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col  class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="vehicle-damage-info-label" class="label-text">VEHICLE DAMAGE</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="vehicle-damage-info-column">
                <v-textarea data-cy="vehicle-damage-info-input"
                            v-model="vehicle.damage"
                            dense
                            outlined
                            clearable
                            auto-grow
                            :rules="damageInformationValidationRules"
                            @input="runDamageInformationValidationRules"
                            background-color="white"
                            maxLength="950"
                            counter="950"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col  class="input-spacing">
            <v-row>
              <v-col class="input-spacing">
                <p data-cy="current-location-label" class="label-text">CURRENT LOCATION</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="input-spacing" data-cy="current-location-column">
                <v-textarea data-cy="current-location-input"
                              v-model="vehicle.location"
                              dense
                              outlined
                              clearable
                            auto-grow
                              background-color="white"
                              maxLength="950"
                              counter="950"
                              :rules="currentLocationValidationRules"
                              @input="runCurrentLocationValidationRules"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card>
    </v-col>
  </v-row>
  </v-form>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import {UiUtils} from "@/utils/ui-utils";
import {VehicleInformation} from "@/models/VehicleInformation";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";

@Component({
  components: {SideStepperComponent}
})

export default class OtherPartyVehicleInformation extends Vue {

  @Prop()
  vehicle: VehicleInformation;

  uiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  vinValidationRules: Array<string> = [];
  plateNumberValidationRules: Array<string> = [];
  makeValidationRules: Array<string> = [];
  modelValidationRules: Array<string> = [];
  yearValidationRules: Array<string> = [];
  currentLocationValidationRules: Array<string> = [];
  damageInformationValidationRules: Array<string> = [];
  created() {
    console.log('other party vehicle info comp created')
  }

  runVinValidationRules(){
    this.vehicle.vin = this.uiUtils.checkIfEmpty(this.vehicle.vin)
    this.vinValidationRules = this.aplFrontEndValidation.runVinValidationRules()
  }
  runPlateNumberValidationRules(){
    this.vehicle.plateNumber = this.uiUtils.checkIfEmpty(this.vehicle.plateNumber)
    this.plateNumberValidationRules = this.aplFrontEndValidation.runPlateValidationRules_isRequired(false)
  }
  runMakeValidationRules(){
    this.vehicle.make = this.uiUtils.checkIfEmpty(this.vehicle.make)
    this.makeValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }
  runModelValidationRules(){
    this.vehicle.model = this.uiUtils.checkIfEmpty(this.vehicle.model)
    this.modelValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }
  runYearValidationRules(){
    this.vehicle.year = this.uiUtils.checkIfEmpty(this.vehicle.year)
    this.yearValidationRules = this.aplFrontEndValidation.runYearRangeValidationRules()
  }
  runCurrentLocationValidationRules(){
    this.vehicle.location = this.uiUtils.checkIfEmpty(this.vehicle.location)
    this.currentLocationValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  runDamageInformationValidationRules(){
    this.vehicle.damage = this.uiUtils.checkIfEmpty(this.vehicle.damage)
    this.damageInformationValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }
}
</script>
