import Vue from 'vue'
import Vuex from 'vuex'
import {FnolSubmission} from "@/models/FnolSubmission";
import {FnolService} from "@/services/fnol-service";
import {Party} from "@/models/Party";
import {CustomerServiceRep} from "@/models/CustomerServiceRep";
import {User} from "@/models/User";
import {PolicySearchRequest} from "@/models/PolicyData/PolicySearchRequest";
import {PolicySearchResponse} from "@/models/PolicyData/PolicySearchResponse";
import {PolicyRetrieveRequest} from "@/models/PolicyData/PolicyRetrieveRequest";
import {PsarVehicle} from "@/models/PolicyData/PsarVehicle";
import {PsarAddress} from "@/models/PolicyData/PsarAddress";
import {FdwInquiryRequest} from "@/models/PolicyData/FdwInquiryRequest";
import {PolicyDetails} from "@/models/PolicyDetails";
import {RiskInformation} from "@/models/PolicyData/RiskInformation";
import {UiUtils} from "@/utils/ui-utils";
import {Insured} from "@/models/Insured";
import {PolicyData} from "@/models/PolicyData";
import {AplLoss} from "@/models/Loss/AplLoss";
import {Branding} from "@/Brands/Branding";

Vue.use(Vuex)
const service = new FnolService();

export default new Vuex.Store({

    state: {
        siteKey: "",
        isRecaptchaTokenValid: false,
        loadRecaptchaWidget: false,
        ssValidationError: false,
        isAccessInternal: Boolean,
        isEnvDevOrLocal: Boolean,
        isAuthenticated: false,
        userPolicies: Array<PolicyData>(),
        customerServiceRepList: Array<CustomerServiceRep>(),
        showTimeoutModalMessage: false,
        siteBrand : new Branding(),
        canNavigate: false,

        // contact-information
        insuredComponentIsValid: false,
        claimReporterComponentIsValid: false,
        primaryContactComponentIsValid: false,
        customerInfoIsValid: false,

        //loss-details
        lossInfoIsValid: false,

        // accident-information
        accidentInformationDriverIsValid: false,
        accidentInformationVehicleIsValid: false,
        accidentInformationPassengersComponentIsValid: false,
        showFailedToUploadModal: false,

        //  liability-loss
        liabilityLossInformationPageValid: false,
        injuredPersonComponentIsValid: false,

        // cargo-information
        cargoLossInformationPageValid: false,

        // property-information
        propertyLossInformationPageValid: false,

        //  other-party
        primaryContactAlreadyEnabled: false,
        otherPartyDriverInformationPageValid: true,
        otherPartyVehicleInformationPageValid: true,
        otherPartyPassengerInformationPageValid: true,
        currentTab: 0,

        submission: new FnolSubmission(),
        showRemovePartyModal: false,
        showChangeLossTypeModal: false,
        isDetailedLossCauseVisible: false,
        isOtherDescriptionVisible: false,
        showValidationSnackbar: false,
        showAlertIconCustInfo: false,
        showAlertIconLossInfo: false,
        showAlertIconCargoInfo: false,
        showAlertIconPropertyInfo: false,
        showAlertIconLiabilityInfo: false,
        showAlertIconAccidentInformation: false,
        showAlertIconOtherParty: false,
        showAlertIconAdditionalContacts: false,
        isShipperComponentValid: false,
        isConsigneeComponentValid: false,
        isTowCompanyComponentValid: false,
        isPropertyOwnerComponentValid: false,
        isDamagedPropertyComponentValid: false,
        isWitnessComponentValid: false,
        isMedicalComponentValid: false,
        isFirePoliceEMTComponentValid: false,
        oldLossType: "",
        lossCauseList: Array<AplLoss>(),
        lossCauseNameList: [],
        lossCauseDetailList: Array<AplLoss>(),
        lossCauseDetailNameList: [],
        showRemovePassengerModal: false,
        showAccidentInfoRemovePassengerModal: false,
        currentPassengerIndex: 0,
        currentWitnessIndex: 0,
        currentMedicalProviderIndex: 0,
        currentFirePoliceEMTIndex: 0,
        currentAccidentInfoPassengerIndex: 0,
        currentParty: new Party(),
        currentPassengerFullName: "",
        currentAccidentInfoPassengerFullName: "",
        currentWitnessFullName: "",
        currentMedicalProviderFullName: "",
        currentFirePoliceEMTFullName: "",
        aplLossInformation: null,
        showRemoveWitnessModal: false,
        showRemoveMedicalProviderModal: false,
        showRemoveFirePoliceEMTModal: false,
        showRemoveInjuredPersonModal: false,
        showCancelClaimModal: false,
        showBackButtonModal: false,
        totalNumberOfPages: 7,
        additionalContactsStepNumber: 5,
        additionalInformationStepNumber: 6,
        reviewStepNumber: 7,
        loggedInUser: new User(),
        displayUserInitials: String,
        hasBeenPassedGettingStarted: false,
        showPolicyResetModal: false,
        policyResetProceed: false,

        //User Roles
        isUserInternal: false,
        isUserAgent: false,
        isUserAnInsured: false,

        //Policy Search
        isPolicySearchVisible: false,
        insuredAddress: new PsarAddress(),
        policySearchResponse: new PolicySearchResponse(),
        policyVehicles: Array<PsarVehicle>(),
        policyAddresses: Array<PsarAddress>(),
        wasPolicySearchSuccessful: false,
        wasPolicyRetrieveSuccessful: false,
        loadingRetrieveCall: false,
        selectedVehicle: new PsarVehicle(),
        selectedLocation: new PsarAddress(),
        oldPolicyKey: "",
        selectedPolicyInfo: null,
    },
    actions: {
        async getKey({commit}) {
            await service.getKey().then(response => {
                commit('setKey', response)
            });
        },
        async verifyToken({commit}, token: string) {
            return service.isTokenValid(token).then(response => {
                commit('setIsRecaptchaTokenValid', response)
            });
        },
        async getSubmission({commit}, submissionId: string) {
            console.log("retrieving fnol with ID: " + submissionId);
            return service.getFnol(submissionId).then(submission => {
                commit('setSubmission', submission)
            });
        },
        async saveSubmission({commit}, submission: FnolSubmission) {
            return await service.saveFnol(submission).then(submission => {
                if (submission.submissionId === "ERROR") {
                    console.log(submission.message)
                    commit("setValidationError", submission)
                } else {
                    commit('setSubmission', submission);
                }
            });
        },

        async cancelSubmission({commit}, submission: FnolSubmission) {
            return await service.cancelFnol(submission).then(submission => {
                if (submission.submissionId === "ERROR") {
                    console.log(submission.message)
                    commit("setValidationError", submission)
                } else {
                    commit('resetStore')
                    commit('setSubmission', submission);
                }
            });
        },

        async getInternalAdminData({commit}) {
            return await service.getInternalAdminData().then(data => {
                commit("setInternalAdminData", data)
            })
        },

        async startNewSubmission({dispatch}, submission : FnolSubmission): Promise<boolean> {
            return await dispatch("saveSubmission", submission)
        },

        async getIsInternal({commit}) {
            return service.isInternal().then(response => {
                commit('setIsAccessInternal', response)
            });
        },

        async getIsEnvDevOrLocal({commit}) {
            return service.isEnvDevOrLocal().then(response => {
                commit('setIsEnvDevOrLocal', response)
            });
        },

        async getLoggedInUser({commit, state}) {
            console.log("retrieving logged in user");
            return service.getUser().then(user => {
                console.log("service returned user:" + user.emailAddress);
                commit('setLoggedInUser', user)
                commit('setIsAuthenticated', user)
                if (user.isAuthenticated === true) {
                    user.roles.forEach((role: string) => {
                        if (role === "People.FNOL.APL.User.Internal") {
                            commit('setUserIsInternal')
                        }
                        if (role === "People.FNOL.APL.User.Agent") {
                            commit('setUserIsAgent')
                        }
                    })
                    if (!state.isUserInternal && !state.isUserAgent && user.emailAddress) {
                            commit('setUserIsInsured')
                    }
                }
            });
        },

        async getAgentPolicies({commit, state}, dateOfLoss: string) {
            console.log("Getting the Agent's PolicyNumber based off of " + state.loggedInUser.producerCodes.length + " producerNumbers")
            state.userPolicies = []
            const fdwRetrieveRequest = new FdwInquiryRequest();

            fdwRetrieveRequest.producerNumbers = state.loggedInUser.producerCodes;
            fdwRetrieveRequest.dateOfLoss = dateOfLoss;
            return service.getAgentPolicies(fdwRetrieveRequest).then(response => {
                commit('setAgentPolicies', response.results)
            })
        },

        async getInsuredPolicies({commit, state}) {
            console.log("Getting the Insured's PolicyNumbers based off of email: " + state.loggedInUser.emailAddress)
            return service.getInsuredPolicies(state.loggedInUser.emailAddress).then(response => {
                commit('setInsuredPolicies', response)
            })
        },

        async getBusinessUnit({commit}, submission: FnolSubmission) {
            const fdwRetrieveRequest = new FdwInquiryRequest();
            fdwRetrieveRequest.policyNumber = submission.policyDetails.policyNumber;
            fdwRetrieveRequest.submissionId = submission.submissionId;

            const response = await service.getBusinessUnit(fdwRetrieveRequest);
            if (response.results != null && response.results[0] != null) {
                if (submission.policyDetails.policyBusinessUnit != response.results[0].businessUnitCode) {
                    console.log("BU Found");
                    submission.policyDetails.policyBusinessUnit = response.results[0].businessUnitCode;
                }
            } else {
                console.log("BU call yielded no results, setting to brand in submission");
                submission.policyDetails.policyBusinessUnit = this.state.siteBrand.brandName;
            }
        },

        async doPolicySearch({commit}, submission: FnolSubmission) {
            const uiUtils = new UiUtils()
            const policySearchRequest = new PolicySearchRequest();
            policySearchRequest.policyNumber = submission.gettingStarted.policyNumber;
            policySearchRequest.dateOfLoss = submission.gettingStarted.dateOfLoss;
            policySearchRequest.submissionId = submission.submissionId;
            return service.doPolicySearch(policySearchRequest).then(response => {
                if (response.insuredName) {
                    commit('setPolicySearchResponse', response)
                    commit('setWasPolicySearchSuccessful', true)
                    if (!submission.customerInformation.insured) {
                            submission.customerInformation.insured = new Insured();
                    }

                    submission.customerInformation.insured.fullName = response.insuredName
                    submission.customerInformation.insured.location.addressLine1 = response.insuredAddress.streetAddress1
                    submission.customerInformation.insured.location.addressLine2 = null
                    submission.customerInformation.insured.location.city = response.insuredAddress.city
                    submission.customerInformation.insured.location.postalCode = uiUtils.testForTrim(response.insuredAddress.postalCode)
                    submission.customerInformation.insured.location.stateCode = response.insuredAddress.state
                    submission.customerInformation.insured.location.state = uiUtils.getStateFromCode(response.insuredAddress.state)

                    submission.policyDetails.policySymbol = response.policySymbol;
                    submission.policyDetails.policyNumber = response.policyNumber;
                    submission.policyDetails.policyModule = response.policyModule;
                    submission.policyDetails.policyVersion = response.policyVersion;
                    submission.policyDetails.policyTermKey = response.policyTermKey;
                    submission.policyDetails.effectiveDate = response.effectiveDate;
                    submission.policyDetails.expirationDate = response.expirationDate;
                    submission.policyDetails.insuredName = response.insuredName;
                    submission.policyDetails.insuredAddress = response.insuredAddress;
                    submission.policyDetails.policySourceSystem = response.policySourceSystem;
                }
                else {
                    if (!submission.customerInformation.insured || this.state.wasPolicyRetrieveSuccessful) {
                        submission.customerInformation.insured = new Insured();
                    }
                    console.log('Nothing returned from Search')
                    commit('setWasPolicySearchSuccessful', false)
                    submission.policyDetails = new PolicyDetails();
                    submission.policyDetails.policyBusinessUnit = this.state.siteBrand.brandName;
                }

            })
        },

        doPolicyRetrieve: async function ({commit}, submission: FnolSubmission) {
            const policyRetrieveRequest = new PolicyRetrieveRequest();
            policyRetrieveRequest.policySource = submission.policyDetails.policySourceSystem;
            policyRetrieveRequest.policyKey = submission.policyDetails.policyTermKey;
            policyRetrieveRequest.submissionId = submission.submissionId;
            commit('setRiskInformation', new RiskInformation())
            return service.doPolicyRetrieve(policyRetrieveRequest).then(response => {
                if (response) {
                    if (!response.addresses){
                        console.log('Risk Info returned no locations. Setting addresses to insured address')
                        commit('setRiskInformationToSearchAddress')
                    } else {
                        console.log('Risk Info returned. Setting policy addresses & vehicles')
                        commit('setRiskInformation', response)
                    }
                    commit('setWasPolicyRetrieveSuccessful', true)
                } else {
                    console.log('Risk Info is null. Setting addresses to insured address')
                    commit('setRiskInformationToSearchAddress')
                    commit('setWasPolicyRetrieveSuccessful', false)
                }
            })
        },

         getLossCause:async function ({commit}, aplLoss: AplLoss) {
            const service = new FnolService();
            await service.getLossCauseList(aplLoss).then((result) => {
                commit('setLossCauseList', result)
            })
        },

        getLossCauseDetail: async function ({commit}, aplLoss: AplLoss) {
            const service = new FnolService();
            await service.getLossCauseDetailList(aplLoss).then((result) => {
                commit('setLossCauseDetailList', result)
            })
        },

        getLossCauseListFromStore: function () {
            return this.state.lossCauseList
        },
        resetStore: function ({commit}) {
            commit('resetStore')
        }
    },
    mutations: {
        setKey(state, response) {
            state.siteKey = response;
        },
        setIsRecaptchaTokenValid(state, response) {
            state.isRecaptchaTokenValid = response
        },
        setSubmission(state, submission) {
            state.submission = submission
            state.ssValidationError = false;

        },
        setValidationError(state) {
            state.ssValidationError = true;
        },

        setInternalAdminData(state, data) {
            state.customerServiceRepList = [];
            state.customerServiceRepList = data?.customerServiceRepList;
        },
        setIsAccessInternal(state, response) {
            state.isAccessInternal = response;
        },

        setIsEnvDevOrLocal(state, response) {
            state.isEnvDevOrLocal = response;
        },

        setLoggedInUser(state, loggedInUser) {
            state.loggedInUser = loggedInUser;
        },

        setIsAuthenticated(state, user){
          state.isAuthenticated = user.isAuthenticated;
        },

        setUserIsInternal(state) {
          state.isUserInternal = true;
          state.isUserAgent = false;
          state.isUserAnInsured = false;
          state.isPolicySearchVisible = true;
        },

        setUserIsAgent(state) {
            state.isUserInternal = false;
            state.isUserAgent = true;
            state.isUserAnInsured = false;
            state.isPolicySearchVisible = false;
        },

        setUserIsInsured(state) {
            state.isUserInternal = false;
            state.isUserAgent = false;
            state.isUserAnInsured = true;
        },

        setIsPolicySearchVisible(state) {
            state.isPolicySearchVisible = true;
        },

        setAgentPolicies(state, fdwInquiryData : PolicyData[]) {
            state.userPolicies = fdwInquiryData
        },

        setInsuredPolicies(state, insuredPolicies : PolicyData[]) {
            if (insuredPolicies != null && insuredPolicies.length != 0) {
                state.userPolicies = insuredPolicies
            }
        },

        setPolicySearchResponse(state, response) {
          response.insuredName = response.insuredName.trim();
          state.policySearchResponse = response;
          state.insuredAddress = response.insuredAddress;
        },

        setWasPolicySearchSuccessful(state, response) {
            state.wasPolicySearchSuccessful = response;
        },

        setRiskInformation(state, response) {
            if (response.addresses) {
                response.addresses.push(state.insuredAddress)
                state.policyAddresses = response.addresses;
            }
            else {
                state.policyAddresses = [response.insuredAddress]
            }
            if(response.vehicles) {
                state.policyVehicles = response.vehicles;
            }
        },

        setRiskInformationToSearchAddress(state) {
            state.policyAddresses = [state.insuredAddress]
        },

        setWasPolicyRetrieveSuccessful(state, response) {
            state.wasPolicyRetrieveSuccessful = response;
        },

        clearLossCauseInformation(state, lossInfo) {
            lossInfo.lossCause = null;
            lossInfo.lossCauseCode = null;
            lossInfo.detailedLossCause = null;
            lossInfo.detailedLossCauseCode = null;
        },

        clearAccidentInfoSelectedVehicle(state) {
            state.selectedVehicle = new PsarVehicle();
        },

        clearLossCauseLocation(state) {
            state.selectedLocation = new PsarAddress();
        },

        storeOldPolicyKey(state, oldKey) {
            state.oldPolicyKey = oldKey;
        },

        resetOldLossType(state) {
            state.oldLossType = "";
        },

        resetOldPolicyKey(state) {
            state.oldPolicyKey = "";
        },
        resetFoundPoliciesOnError(state){
            state.userPolicies = []
            const pd = new PolicyData()
            pd.formattedPolicyString = "Manual Entry"
            pd.policySymbol = "Manual Entry"
            state.userPolicies.push(pd)
        },
        setLossCauseList(state, lossCauseList) {
            state.lossCauseList = lossCauseList
        },
        setLossCauseNameList(state, lossCauseNameList) {
            state.lossCauseNameList = lossCauseNameList
        },
        setLossCauseDetailList(state, lossCauseDetailList) {
            state.lossCauseDetailList = lossCauseDetailList
        },
        setLossCauseDetailNameList(state, lossCauseDetailNameList) {
            state.lossCauseDetailNameList = lossCauseDetailNameList
        },

        //This is for anything that persists when a submission is cancelled and needs to be reset
        resetStore(state) {
            state.lossCauseList = []
            state.lossCauseNameList = []
            state.lossCauseDetailList = []
            state.lossCauseDetailNameList = []
            state.isDetailedLossCauseVisible = false
            state.hasBeenPassedGettingStarted = false;
            if(state.isAccessInternal) {
                state.wasPolicyRetrieveSuccessful = false;
                state.policyAddresses = [];
                state.policyVehicles = [];
            }
        }

    }
})
