<template>
  <div data-cy="additional-information-review-vue">
    <v-row data-cy="additional-information-section" class="mx-0">
      <v-btn :block="$vuetify.breakpoint.smAndDown" tile class="elevation-0" outlined>
        <span class="edit-section-label" data-cy="edit-additional-information-cta" @click="editAdditionalInformationOnClick">
          <v-icon>mdi-pencil</v-icon> Go To Additional Information</span>
      </v-btn>
    </v-row>


  <v-row class="rs-labels">
    <v-col class="pb-0" data-cy="rs-add-info-email-list-label">SEND ADDITIONAL COPIES OF CLAIM SUMMARY TO</v-col>
  </v-row>
  <v-row class="rs-values" v-for="(email, emailIndex) in emailList" :key="'email-' + emailIndex">
    <v-col class="pt-0" :data-cy="'rs-add-info-email-' + emailIndex + '-value'">{{email}}</v-col>
  </v-row>

  <v-row class="rs-labels pl-3">
    <v-col class="pb-0">
      <v-row data-cy="rs-add-info-interpreter-needed-label">IS A TRANSLATOR / INTERPRETER NEEDED?</v-row>
      <v-row class="rs-values" data-cy="rs-add-info-interpreter-needed-value" v-if="submission.additionalInformation.translatorNeeded != null">{{isInterpreterNeeded}}</v-row>
    </v-col>
  </v-row>

    <v-row class="rs-labels pl-3">
      <v-col class="pb-0">
        <v-row v-if="submission.additionalInformation.translatorNeeded == true" data-cy="rs-add-info-preferred-language-label">PREFERRED LANGUAGE</v-row>
        <v-row class="rs-values" v-if="submission.additionalInformation.translatorNeeded == true" data-cy="rs-add-info-preferred-language-value">{{submission.additionalInformation.preferredLanguage}}</v-row>
    </v-col>
  </v-row>

  <v-row class="rs-labels">
    <v-col v-if="$store.state.isAccessInternal" class="pb-0" data-cy="rs-add-info-csr-label">CLAIM INTAKE SERVICE REPRESENTATIVE</v-col>
  </v-row>
  <v-row class="rs-values">
    <v-col v-if="submission.additionalInformation.customerServiceRep != null && $store.state.isAccessInternal" cols="8" class="pt-0" data-cy="rs-add-info-csr-value">{{uiUtils.testForTrim(submission.additionalInformation.customerServiceRep.firstName) + ' ' + uiUtils.testForTrim(submission.additionalInformation.customerServiceRep.lastName)}}</v-col>
  </v-row>

  <v-row class="rs-labels">
    <v-col class="pb-0" data-cy="rs-add-info-additional-notes-label">ADDITIONAL NOTES</v-col>
  </v-row>
  <v-row class="rs-values">
    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8"  v-if="submission.additionalInformation.additionalNotes != null" class="pt-0" data-cy="rs-add-info-additional-notes-value">{{submission.additionalInformation.additionalNotes}}</v-col>
  </v-row>

  <v-row class="rs-labels">
    <v-col class="pb-0" data-cy="rs-add-info-attached-files-label">ATTACHED FILES</v-col>
  </v-row>
  <v-row class="rs-values" :data-cy="'rs-add-info-attached-files-value-' + fileIndex" v-for="(file, fileIndex) in submission.additionalInformation.uploadedFileStatuses.filter(file => file.status === 'success' && file.filePath != null )" :key="'file-' + fileIndex">
    <v-col class="pt-0 pb-0">{{ file.fileName }}</v-col>
  </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class AdditionalInformationReview extends Vue {

  uiUtils = new UiUtils()
  submission: FnolSubmission;
  isInterpreterNeeded: string;
  emailList: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
    this.getInterpreterNeededString();
    this.createAdditionalEmailsArray();
  }

  createAdditionalEmailsArray() {
    if (this.submission.additionalInformation && this.submission.additionalInformation.additionalCopies) {
      this.emailList = this.submission.additionalInformation.additionalCopies.split(';');
    }
  }

  getInterpreterNeededString() {
    if(this.submission.additionalInformation.translatorNeeded == true) {
      this.isInterpreterNeeded = 'Yes';
    }
    else {
      this.isInterpreterNeeded = 'No';
    }
  }

  editAdditionalInformationOnClick() {
    this.uiUtils.navigate('additional-information');
  }
}
</script>
