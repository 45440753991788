<template>
  <SubmissionLoaderComponent/>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {UiUtils} from "@/utils/ui-utils";
import SubmissionLoaderComponent from "@/views/components/SubmissionLoaderComponent.vue";

@Component({
  components: {SubmissionLoaderComponent}
})
export default class SubmissionLoader extends Vue {

  uiUtils = new UiUtils();

  async created() {
    if(this.$store.state.isUserAnInsured) {
      await this.$store.dispatch('getInsuredPolicies')
    }
  }

  navigateTo(route: string) {
    this.$store.state.idleVue.idleTime = 1740000
    window.scrollTo(0, 0);
    this.$store.state.showTimeoutModalMessage = false;
    this.uiUtils.navigate(route);
  }
}
</script>

<style>


</style>
