<template>
  <div data-cy="side-stepper-vue" v-if="this.$route.name === 'getting-started'
                                     || this.$route.name === 'contact-information'
                                     || this.$route.name === 'loss-information'
                                     || this.$route.name === 'accident-information'
                                     || this.$route.name === 'property-loss-information'
                                     || this.$route.name === 'cargo-loss-information'
                                     || this.$route.name === 'liability-loss-information'
                                     || this.$route.name === 'other-party'
                                     || this.$route.name === 'additional-contacts'
                                     || this.$route.name === 'additional-information'
                                     || this.$route.name === 'review-and-submit'">
    <v-row class="pl-4">
      <v-col>
        <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
          <span><v-icon left>mdi-close</v-icon>Cancel Claim</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row class="ml-6 proximaNova policy-information"
               data-cy="insured-name-side-stepper-row"
               v-if="checkDoesInsuredExist()">
          <p data-cy="insured-name">{{submission.customerInformation.insured.fullName}}</p>
        </v-row>
        <v-row class="ml-6 proximaNova policy-information"
               style="font-size: 16px"
               data-cy="policy-number-side-stepper-row" v-if="getPolicyNumberAndSymbol()">Policy#: {{getPolicyNumberAndSymbol()}}
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col data-cy="side-stepper-column">
        <v-stepper data-cy="side-stepper-component" v-model="currentStep" vertical style="box-shadow: none">
            <v-stepper-step
                data-cy="getting-started-step"
                class="pb-0 mb-0"
                step="1"
                :class="this.ignoreHover('getting-started') ? 'ignore-underline' : 'show-hover'"
                :editable="this.$route.name === 'getting-started'"
                :complete="currentStep > 1"
                :color="this.$route.name === 'getting-started' ? 'indigo--text blue-grey lighten-5' : '#00274e'">
              <p data-cy="getting-started-step-label" class="mb-0 v-label" @click="goTo('getting-started')">GETTING STARTED</p>
            </v-stepper-step>
          <v-divider class="ml-9 py-0 my-0" vertical color="#00274e" style="display: inline"></v-divider>
            <v-stepper-step
                data-cy="customer-info-step"
                step="2"
                class="py-0 my-0"
                :class="this.ignoreHover('contact-information') ? 'ignore-underline' : 'show-hover'"
                :editable="this.$route.name === 'contact-information'"
                :complete="currentStep > 2"
                :color="this.$route.name === 'getting-started' ? '#ffffff' : this.$route.name === 'contact-information' ? 'indigo--text blue-grey lighten-5' : '#00274e'"
            >
              <v-divider class="ml-9 py-0 my-0" vertical color="#00274e" style="display: inline"></v-divider>
              <p data-cy="customer-info-step-label" class="mb-0 v-label" @click="goTo('contact-information')">CONTACT INFORMATION <v-icon v-if="this.$store.state.showAlertIconCustInfo" data-cy="contact-info-alert-icon" color="red">mdi-alert-circle</v-icon></p>
              <v-divider class="ml-9 py-0 my-0" vertical color="#00274e" style="display: inline"></v-divider>
            </v-stepper-step>
            <v-divider class="mx-9 py-0 my-0" vertical color="#00274e" style="display: inline"></v-divider>
            <v-stepper-step data-cy="loss-information-step"
                            class="py-0 my-0"
                            step="3"
                            :class="this.ignoreHover('loss-information') ? 'ignore-underline' : 'show-hover'"
                            :editable="this.$route.name === 'loss-information'"
                            :complete="currentStep > 3"
                            :color="this.$route.name === 'getting-started'
                                || this.$route.name === 'contact-information' ? '#ffffff' : this.$route.name === 'loss-information' ? 'indigo--text blue-grey lighten-5' : '#00274e'"
            >
              <v-divider class="ml-9 py-0 my-0" vertical color="#00274e" style="display: inline"></v-divider>
              <p data-cy="loss-info-step-label" class="mb-0 v-label" @click="goTo('loss-information')">LOSS INFORMATION <v-icon v-if="this.$store.state.showAlertIconLossInfo" data-cy="loss-info-alert-icon" color="red">mdi-alert-circle</v-icon></p>
              <v-divider class="ml-9 py-0 my-0" vertical color="#00274e" style="display: inline"></v-divider>
            </v-stepper-step>
            <v-divider class="py-0 my-0 mx-9" vertical color="#00274e" style="display: inline"></v-divider>
            <v-stepper-step data-cy="loss-type-step"
                            class="py-0 my-0"
                            step="4"
                            :editable="isStepFourActive"
                            :complete="currentStep > 4"
                            :class="this.ignoreHover(stepPage.routeName) ? 'ignore-underline' : 'show-hover'"
                            :color="this.$route.name === 'getting-started'
                                || this.$route.name === 'contact-information'
                                || this.$route.name === 'loss-information' ? '#ffffff'
                                : isStepFourActive ? 'indigo--text blue-grey lighten-5' : '#00274e'">
              <p data-cy="loss-type-step-label" class="mb-0 v-label"
                       :class="this.ignoreUnderline() ? 'ignore-underline' : ''"   @click="goTo(stepPage.routeName)">{{stepPage.pageName}}
                          <v-icon v-if="this.$store.state.showAlertIconCargoInfo ||
                                                 this.$store.state.showAlertIconPropertyInfo ||
                                                         this.$store.state.showAlertIconLiabilityInfo ||
                                                                 this.$store.state.showAlertIconAccidentInformation" data-cy="selected-loss-type-alert-icon" color="red">mdi-alert-circle</v-icon></p>
            </v-stepper-step>
            <v-divider class="py-0 my-0 mx-9" vertical color="#00274e" style="display: inline" v-if="this.showOtherPartiesLabel"></v-divider>
            <v-stepper-step data-cy="other-party-step"
                            v-if="this.showOtherPartiesLabel"
                            class="py-0 my-0"
                            step="5"
                            :editable="this.$route.name === 'other-party'"
                            :complete="currentStep > 5"
                            :class="this.ignoreHover('other-party') ? 'ignore-underline' : 'show-hover'"
                            :color="this.$route.name === 'getting-started'
                                || this.$route.name === 'contact-information'
                                || this.$route.name === 'loss-information'
                                || this.$route.name === 'accident-information' ? '#ffffff' : this.$route.name === 'other-party' ? 'indigo--text blue-grey lighten-5 red--border' : '#00274e'">
              <p data-cy="other-party-step-label" v-if="this.showOtherPartiesLabel" class="mb-0 v-label" @click="goTo('other-party')">OTHER PARTIES<v-icon v-if="this.$store.state.showAlertIconOtherParty" data-cy="other-party-alert-icon" color="red">mdi-alert-circle</v-icon></p>
            </v-stepper-step>
            <v-divider class="py-0 my-0 mx-9" vertical color="#00274e" style="display: inline"></v-divider>
            <v-stepper-step data-cy="additional-contacts-step"
                            class="py-0 my-0"
                            :step="additionalContactsStepNumber"
                            :editable="this.$route.name === 'additional-contacts'"
                            :class="this.ignoreHover('additional-contacts') ? 'ignore-underline' : 'show-hover'"
                            :complete="currentStep > additionalContactsStepNumber"
                            :color="this.$route.name === 'getting-started'
                                || this.$route.name === 'contact-information'
                                || this.$route.name === 'loss-information'
                                || this.$route.name === 'accident-information'
                                || this.$route.name === 'cargo-loss-information'
                                || this.$route.name === 'liability-loss-information'
                                || this.$route.name === 'property-loss-information'
                                || this.$route.name === 'other-party' ? '#ffffff' : this.$route.name === 'additional-contacts' ? 'indigo--text blue-grey lighten-5' : '#00274e'">
              <p data-cy="additional-contacts-step-label" class="mb-0 v-label" @click="goTo('additional-contacts')">ADDITIONAL CONTACTS <v-icon v-if="this.$store.state.showAlertIconAdditionalContacts" data-cy="additional-contacts-info-alert-icon" color="red">mdi-alert-circle</v-icon></p>
            </v-stepper-step>
            <v-divider class="py-0 my-0 mx-9" vertical color="#00274e" style="display: inline"></v-divider>
            <v-stepper-step data-cy="additional-information-step"
                            class="py-0 my-0"
                            :step="additionalInformationStepNumber"
                            :editable="this.$route.name === 'additional-information'"
                            :class="this.ignoreHover('additional-information') ? 'ignore-underline' : 'show-hover'"
                            :complete="currentStep > additionalInformationStepNumber"
                            :color="this.$route.name === 'getting-started'
                                || this.$route.name === 'contact-information'
                                || this.$route.name === 'loss-information'
                                || this.$route.name === 'accident-information'
                                || this.$route.name === 'cargo-loss-information'
                                || this.$route.name === 'liability-loss-information'
                                || this.$route.name === 'property-loss-information'
                                || this.$route.name === 'other-party'
                                || this.$route.name === 'additional-contacts' ? '#ffffff' : this.$route.name === 'additional-information' ? 'indigo--text blue-grey lighten-5' : '#00274e'">
              <p data-cy="additional-information-step-label" class="mb-0 v-label" @click="goTo('additional-information')">ADDITIONAL INFORMATION</p>
            </v-stepper-step>
            <v-divider class="py-0 my-0 mx-9" vertical color="#00274e" style="display: inline"></v-divider>
            <v-stepper-step data-cy="review-step"
                            class="py-0 my-0"
                            :step="reviewStepNumber"
                            :editable="this.$route.name === 'review-and-submit'"
                            :class="this.ignoreHover('review-and-submit') ? 'ignore-underline' : 'show-hover'"
                            :complete="currentStep > reviewStepNumber"
                            :color="this.$route.name === 'getting-started'
                                || this.$route.name === 'contact-information'
                                || this.$route.name === 'loss-information'
                                || this.$route.name === 'accident-information'
                                || this.$route.name === 'cargo-loss-information'
                                || this.$route.name === 'liability-loss-information'
                                || this.$route.name === 'property-loss-information'
                                || this.$route.name === 'other-party'
                                || this.$route.name === 'additional-contacts'
                                || this.$route.name === 'additional-information' ? '#ffffff' : this.$route.name === 'review-and-submit' ? 'indigo--text blue-grey lighten-5' : '#00274e'">
              <p data-cy="review-step-label" class="mb-0 v-label" @click="goTo('review-and-submit')">REVIEW</p>
            </v-stepper-step>
        </v-stepper>
      </v-col>
    </v-row>

    <v-row >
      <v-col data-cy="side-stepper-key-column" class="pl-9"
             v-if=" this.$store.state.showAlertIconCustInfo
                  || this.$store.state.showAlertIconLossInfo
                  || this.$store.state.showAlertIconCargoInfo
                  || this.$store.state.showAlertIconPropertyInfo
                  || this.$store.state.showAlertIconLiabilityInfo
                  || this.$store.state.showAlertIconAccidentInformation
                  || this.$store.state.showAlertIconOtherParty
                  || this.$store.state.showAlertIconAdditionalContacts">
        <p data-cy="required-fields-key-label">
          <v-icon  data-cy="required-fields-missing-icon" color="red">mdi-alert-circle</v-icon> = Required Fields Missing
        </p>
      </v-col>
    </v-row>

    <v-row>
      <CancelClaimModal :submission="submission" v-if="$store.state.showCancelClaimModal"></CancelClaimModal>
    </v-row>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import {ValidationUtils} from "@/utils/validation-utils";

@Component({
  components: {CancelClaimModal}
})
export default class SideStepperComponent extends Vue {
  currentStep: number = 0;
  isStepFourActive: boolean;
  additionalContactsStepNumber: number = 0;
  additionalInformationStepNumber: number = 0;
  reviewStepNumber: number = 0;
  validationUtils = new ValidationUtils();
  submission = new FnolSubmission();
  lossTypeChanged: boolean = false;
  showOtherPartiesLabel: boolean = false
  stepPage = { pageName:"", routeName:"" }


    //This asynchronously updates step four name
    @Watch('$store.state.oldLossType')
    ifUserChangesLossType(): void {
        if (!this.lossTypeChanged) {
            this.stepPage.pageName = "LOSS TYPE"
            this.stepPage.routeName = "loss-information"

            this.lossTypeChanged = true
            this.$store.state.showAlertIconAccidentInformation = false
            this.$store.state.showAlertIconOtherParty = false
            this.$store.state.showAlertIconCargoInfo = false
            this.$store.state.showAlertIconPropertyInfo = false
            this.$store.state.showAlertIconLiabilityInfo = false
            this.$forceUpdate()
            this.showOtherPartiesLabel = false
        }
    }

  created() {
    this.submission = this.$store.state.submission;
    this.updateStepNumbersBasedOnLossType();
    this.setCurrentStep();
    this.getStepFourPageName();
    this.isStepFourActive = this.checkIsStepFourActive();
    this.setAlertIcons();
  }

    getStepFourPageName() {
        if(this.submission.lossInformation.lossType === 'Auto'){
            this.stepPage.pageName = 'ACCIDENT INFORMATION'
            this.stepPage.routeName = 'accident-information'
        }
        if(this.submission.lossInformation.lossType === 'Property'){
            this.stepPage.pageName = 'PROPERTY INFORMATION'
            this.stepPage.routeName = 'property-loss-information'
        }
        if(this.submission.lossInformation.lossType === 'Liability'){
            this.stepPage.pageName = 'LIABILITY INFORMATION'
            this.stepPage.routeName = 'liability-loss-information'
        }
        if(this.submission.lossInformation.lossType === 'Cargo'){
            this.stepPage.pageName = 'CARGO INFORMATION'
            this.stepPage.routeName = 'cargo-loss-information'
        }
        if(!this.submission.lossInformation.lossType){
            this.stepPage.pageName = 'LOSS TYPE'
            this.stepPage.routeName = 'loss-information'
        }
    }

    setAlertIcons(): void {
        this.$store.state.showAlertIconCustInfo = this.shouldShowCustomerInfoAlertIcon();
        this.$store.state.showAlertIconLossInfo = this.shouldShowLossInfoAlertIcon();
        this.$store.state.showAlertIconAccidentInformation = this.shouldShowAccidentInfoAlertIcon();
        this.$store.state.showAlertIconOtherParty = this.shouldShowOtherPartyAlertIcon();
        this.$store.state.showAlertIconLiabilityInfo = this.shouldShowLiabilityInfoAlertIcon();
        this.$store.state.showAlertIconAdditionalContacts = this.shouldShowAdditionalContactsAlertIcon();
    }

  shouldShowCustomerInfoAlertIcon(): boolean {
    if (this.$route.name == 'review-and-submit' || (this.$route.name != 'review-and-submit' && this.$store.state.customerInfoIsValid)){
      if ((!this.validationUtils.validateRequiredFieldsOnInsured(this.submission.customerInformation.insured) && (!this.submission.gettingStarted.knowPolicyNumberInfo || this.submission.policyDetails.policyBusinessUnit == 'PIM')) ||
          !this.validationUtils.validateContactRequiredFields(this.submission.customerInformation.claimReporter) ||
          !this.validationUtils.validateContactRequiredFields(this.submission.customerInformation.primaryContact)) {
        return true
      }
    }
    return false
    }

   shouldShowLossInfoAlertIcon(): boolean {
     if (this.$route.name == 'review-and-submit' || (this.$route.name != 'review-and-submit' && this.$store.state.lossInfoIsValid)){
       if (!this.validationUtils.validateRequiredFieldsOnLossInfo(this.submission)) {
         return true;
       }
     }
     return false;
   }

    shouldShowLiabilityInfoAlertIcon(): boolean{
      if (this.submission.lossInformation?.lossType == "Liability") {
          if (!this.validationUtils.validateRequiredFieldsOnLiability(this.submission.lossInformation.liabilityLossInformation)) {
              return true;
          }
      }
        return false;
    }

    shouldShowAccidentInfoAlertIcon(): boolean{
      if(this.submission.lossInformation?.lossType == "Auto"){
          if(!this.validationUtils.validateRequiredFieldsOnInsuredDriver(this.submission.lossInformation.accidentInformation)){
              return true
          }
          if (!this.validationUtils.validateRequiredFieldsOnInsuredPassengers(this.submission.lossInformation.accidentInformation)) {
              return true
          }
      }
      return false;
    }

    shouldShowOtherPartyAlertIcon(): boolean{
            if(this.submission.lossInformation.lossType == "Auto" && this.submission.otherParty?.parties){
                for (const party of this.submission?.otherParty?.parties){
                    if (!this.validationUtils.validateRequiredFieldsOnOtherPartyDriver(party)) {
                        return true
                    }
                    if (!this.validationUtils.validateRequiredFieldsOnOtherPartyPassenger(party)) {
                        return true
                    }
                }
            }
        return false
    }

  shouldShowAdditionalContactsAlertIcon(): boolean {
    if (this.submission.additionalContacts) {
        if(!this.validationUtils.validateRequiredFieldsOnAdditionalContactsWitness(this.submission.additionalContacts)){
          return true;
        }
        if(!this.validationUtils.validateRequiredFieldsOnAdditionalContactsMedicalProviders(this.submission.additionalContacts)){
          return true;
        }
        if(!this.validationUtils.validateRequiredFieldsOnAdditionalContactsFirePoliceEMT(this.submission.additionalContacts)){
          return true;
        }
    }
    return false;
  }

  checkIsStepFourActive() {
    if(this.$route.name === 'accident-information'
        || this.$route.name === 'property-loss-information'
        || this.$route.name === 'cargo-loss-information'
        || this.$route.name === 'liability-loss-information') {
      return true;
    }
  }

  checkDoesInsuredExist(): boolean {
    return !!(this.submission?.customerInformation?.insured && this.submission.customerInformation.insured.fullName);
  }

  getPolicyNumberAndSymbol() {
    if(!this.submission.gettingStarted) {
      return;
    }
    if (this.submission.gettingStarted.policySymbol == null || this.submission.gettingStarted.policySymbol == 'undefined'){
      return this.submission.gettingStarted.policyNumber;
    }
    else {
      return this.submission.gettingStarted.policySymbol + " " + this.submission.gettingStarted.policyNumber;
    }
  }

  updateStepNumbersBasedOnLossType() {
    if(this.submission.lossInformation.lossType === 'Auto') {
      this.additionalContactsStepNumber = 6;
      this.additionalInformationStepNumber = 7;
      this.reviewStepNumber = 8;
      this.showOtherPartiesLabel = true
    }
    else {
      this.additionalContactsStepNumber = 5;
      this.additionalInformationStepNumber = 6;
      this.reviewStepNumber = 7;
    }
  }


  setCurrentStep() {
    if (this.$route.name == 'getting-started') {
      this.currentStep = 1;
    }
    if (this.$route.name == 'contact-information') {
      this.currentStep = 2;
    }
    if (this.$route.name == 'loss-information') {
      this.currentStep = 3;
    }
    if (this.$route.name == 'accident-information'
        || this.$route.name === 'property-loss-information'
        || this.$route.name === 'cargo-loss-information'
        || this.$route.name === 'liability-loss-information') {
      this.currentStep = 4;
    }
    if (this.$route.name == 'other-party') {
      this.currentStep = 5;
    }
    if (this.$route.name == 'additional-contacts') {
      this.currentStep = this.additionalContactsStepNumber;
    }
    if (this.$route.name == 'additional-information') {
      this.currentStep = this.additionalInformationStepNumber;
    }
    if (this.$route.name == 'review-and-submit') {
      this.currentStep = this.reviewStepNumber;
    }
  }

  CancelClaimModalOnClick(){
    this.$store.state.showCancelClaimModal = true;
  }

  ignoreUnderline(): boolean {
     return this.stepPage.pageName === "LOSS TYPE";
  }

  ignoreHover(givenStep: string): boolean {
    if(givenStep === 'LOSS TYPE') return true;
    return givenStep === this.$route.name
  }

  goTo(route: string) {
      if(this.$route.name != route) {
        this.$emit('navigate', route)
    }
  }

}

</script>

<style scoped lang="scss">

.v-label {
  font-size: 13px !important;
  color: #00274e;
}

.v-label:hover{
  text-decoration: underline;
}

.show-hover {
  cursor: pointer;
}

.ignore-underline {
  pointer-events: none;
  text-decoration: none !important;
}

.policy-information {
  color: #00274e;
  font-size: 20px;
  font-weight: 550
}

</style>
