<template>
  <v-col>
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"/>
      </v-col>
      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div data-cy="loss-information-vue">
              <span data-cy="loss-info-title" class="page-label berninaSansCompressedFont">Loss Information</span>
            </div>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{ submission.reportNumber }}</span>
          </v-col>
        </v-row>

        <v-form v-model="lossInformationPageValid">
          <v-row>
            <v-col>
              <v-card elevation="0" class="card-background">
                <v-row>
                  <v-col cols="8">
                    <div>
                      <span data-cy="loss-details-header" class="section-label berninaSansCompressedFont pl-8">Loss Details</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col cols="8" class="input-spacing">

                    <v-row>
                      <v-col class="input-spacing" data-cy="loss-type-column">
                        <p data-cy="loss-type-label" class="label-text">LOSS TYPE<span class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>

                    <v-row class="input-spacing">
                      <v-col data-cy="loss-type-radio-group-column" cols="12" class="input-spacing">
                        <v-radio-group data-cy="loss-type-radio-group"
                                       v-model="submission.lossInformation.lossType"
                                       id="loss-type"
                                       :error-messages="lossTypeValidationRules"
                                       @change="lossTypeValidation"
                                       class="pt-0 radio-buttons-mobile-background">

                          <div class="loss-type-radio-holder">
                            <v-radio data-cy="loss-type-auto-input" value="Auto" class="radio-buttons-auto-loss-type-mobile">
                              <template v-slot:label>
                                <div class="loss-type-name">Auto
                                  <span data-cy="loss-type-auto-text" class="loss-type-description">- Loss involving damage to your and/or another vehicle (including injuries)</span>
                                  <span data-cy="auto-policy-examples" class="loss-type-description">{{$store.state.siteBrand.autoPolicyExamples}}</span>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="Cargo" data-cy="loss-type-cargo-input" class="radio-buttons-cargo-loss-type-mobile">
                              <template v-slot:label>
                                <div class="loss-type-name">Cargo
                                  <span data-cy="loss-type-cargo-text" class="loss-type-description">- Loss involving damage to cargo being hauled by a motor carrier</span>
                                  <span data-cy="cargo-policy-examples" class="loss-type-description">{{$store.state.siteBrand.cargoPolicyExamples}}</span>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="Property" data-cy="loss-type-property-input" class="radio-buttons-property-loss-type-mobile">
                              <template v-slot:label>
                                <div class="loss-type-name">Property
                                  <span data-cy="loss-type-property-text" class="loss-type-description">- Loss involving damage to your building, contents, equipment or other property</span>
                                  <span data-cy="property-policy-examples" class="loss-type-description">{{$store.state.siteBrand.propertyPolicyExamples}}</span>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="Liability" data-cy="loss-type-liability-input" class="radio-buttons-liability-loss-type-mobile">
                              <template v-slot:label>
                                <div class="loss-type-name">Liability
                                  <span data-cy="loss-type-liability-text" class="loss-type-description">- Loss or injury to a third party (not the insured)</span>
                                  <span data-cy="liability-policy-examples" class="loss-type-description">{{$store.state.siteBrand.liabilityPolicyExamples}}</span>
                                </div>
                              </template>
                            </v-radio>
                          </div>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>


                <v-row class="pl-8 pt-4 loss-cause-dropdown">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="loss-cause-column">
                        <p data-cy="loss-cause-label" class="label-text">LOSS CAUSE<span class="req-asterisk">*</span>
                        </p>
                      </v-col>
                    </v-row>

                    <v-row class="proximaNovaFont">
                      <v-col class="input-spacing" data-cy="loss-cause-select-column">
                        <v-autocomplete v-model="submission.lossInformation.lossCause"
                                        data-cy="loss-cause-select"
                                        id="loss-cause"
                                        :items="this.$store.state.lossCauseNameList"
                                        outlined
                                        dense
                                        background-color="white"
                                        @input="lossCauseOnSelect"
                                        :error-messages="lossCauseRules"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8" v-if="$store.state.isDetailedLossCauseVisible">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="detailed-loss-cause-column">
                        <p data-cy="detailed-loss-cause-label" class="label-text">DETAILED LOSS CAUSE<span
                            class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>
                    <v-row class="proximaNovaFont">
                      <v-col class="input-spacing" data-cy="detailed-loss-cause-select-column">
                        <v-autocomplete v-model="submission.lossInformation.detailedLossCause"
                                        data-cy="detailed-loss-cause-select"
                                        id="detailed-loss-cause"
                                        :items="this.$store.state.lossCauseDetailNameList"
                                        outlined
                                        dense
                                        background-color="white"
                                        @input="detailedLossCauseValidation"
                                        :error-messages="detailedLossCauseRules"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8" data-cy="other-desc-row" v-if="$store.state.isOtherDescriptionVisible">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="other-desc-column">
                        <p data-cy="other-desc-label" class="label-text">OTHER DESCRIPTION<span
                            class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>

                    <v-row class="proximaNovaFont">
                      <v-col class="input-spacing" data-cy="other-desc-input-column">
                        <v-textarea v-model="submission.lossInformation.otherDescription"
                                    data-cy="other-desc-input"
                                    id="other-description"
                                    outlined
                                    auto-grow
                                    clearable
                                    maxLength="500"
                                    counter="500"
                                    dense
                                    background-color="white"
                                    :rules="otherDescriptionRules"
                                    @input="otherDescriptionOnInput"
                                    @blur="otherDescriptionOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="loss-desc-column">
                        <p data-cy="loss-desc-label" class="label-text">LOSS DESCRIPTION<span
                            class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="input-spacing" data-cy="loss-desc-input-column">
                        <v-textarea v-model="submission.lossInformation.lossDescription"
                                    data-cy="loss-desc-input"
                                    id="loss-description"
                                    outlined
                                    auto-grow
                                    clearable
                                    :maxLength="950"
                                    counter="950"
                                    dense
                                    background-color="white"
                                    :rules="lossDescriptionRules"
                                    @input="lossDescriptionOnInput"
                                    @blur="lossDescriptionOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="pl-8 pb-6">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing">
                        <p data-cy="weather-related-label" class="label-text">WEATHER RELATED?</p>
                      </v-col>
                    </v-row>

                    <v-row cols="12">
                      <v-col class="input-spacing">
                        <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                       class="radio-container"
                                       data-cy="weather-related-radio-group"
                                       v-model="submission.lossInformation.weatherRelated">
                          <v-radio
                              label="Yes"
                              :value=true
                              data-cy="weather-related-radio-yes"
                          ></v-radio>
                          <v-radio
                              label="No"
                              :value=false
                              data-cy="weather-related-radio-no"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

                <v-row class="pl-8 pb-6" v-if="submission.lossInformation.lossType === 'Liability'">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing">
                        <p data-cy="in-litigation-label" class="label-text">IS THIS IN LITIGATION?</p>
                      </v-col>
                    </v-row>

                    <v-row cols="12">
                      <v-col class="input-spacing">
                        <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                       class="pt-0 radio-buttons-mobile-background"
                                       data-cy="in-litigation-radio-group"
                                       v-model="submission.lossInformation.inLitigation"
                                       $radio-group-padding="0px">
                          <v-radio
                              class="radio-buttons-loss-info-mobile-yes"
                              label="Yes"
                              :value=true
                              data-cy="in-litigation-radio-yes"
                          ></v-radio>
                          <v-radio
                              class="radio-buttons-loss-info-mobile-no"
                              label="No"
                              :value=false
                              data-cy="in-litigation-radio-no"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="0" class="card-background">
                <v-row class="pl-8">
                  <v-col cols="8" md="4">
                    <div>
                      <span data-cy="loss-location-header"
                            class="section-label berninaSansCompressedFont">Loss Location</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="pl-6">
                  <v-col cols="6" md="4">
                    <v-col cols="12" class="input-spacing">
                      <p data-cy="date-of-loss-label" class="label-text">DATE OF LOSS</p>
                      <p data-cy="date-of-loss-value" class="loss-datetime-text">
                        {{ this.dateUtils.formatDateForDisplay(this.submission.gettingStarted.dateOfLoss) }}</p>
                    </v-col>
                  </v-col>

                  <v-col cols="6" md="4">
                    <v-col cols="12" class="input-spacing">
                      <p data-cy="time-of-loss-label" class="label-text">TIME</p>
                      <p data-cy="time-of-loss-value" class="loss-datetime-text">{{ formattedTimeOfLoss }}
                      </p>
                    </v-col>
                  </v-col>
                </v-row>

                <v-row class="toggle-spacing" v-if="submission.customerInformation.insured.location.state">
                  <v-col class="input-spacing">
                    <v-row>
                      <v-col md="8" lg="6" class="input-spacing" data-cy="loss-location-same-as-insured-column">
                        <v-row>
                          <v-switch
                              v-model="submission.lossInformation.lossLocationSameAsInsured"
                              inset
                              class="v-switch-label"
                              data-cy="loss-location-same-as-insured-switch"
                              label="Did the loss occur at the Insured's primary address?"
                              dense
                              @change="lossLocationSameAsInsuredOnSwitch"/>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="toggle-spacing" v-if="submission.customerInformation.primaryContact.location.state && !submission.customerInformation.primaryContact.isSameAddressAsInsured">
                  <v-col class="input-spacing">
                    <v-row>
                      <v-col md="8" lg="6" class="input-spacing" data-cy="loss-location-same-as-primary-contact-column">
                        <v-row>
                          <v-switch
                              v-model="submission.lossInformation.lossLocationSameAsPrimaryContact"
                              class="v-switch-label"
                              inset
                              data-cy="loss-location-same-as-primary-contact-switch"
                              label="Did the loss occur at the Primary Contact's address?"
                              dense
                              @change="lossLocationSameAsPrimaryContactOnSwitch"/>
                          </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>


                <v-row class="pl-11 pr-6 py-4" v-if="showLocationListDropdown()">
                  <v-col cols="8" class="input-spacing address-search-box" data-cy="autocomplete-row">
                    <v-row class="py-1 px-4 pt-2">
                      <p data-cy="locations-label" class="label-text" style="color: #00274e">ADDRESS SEARCH</p>
                    </v-row>
                    <v-row class="py-0 px-4">
                      <p data-cy="start-typing-label" class="label-text" style="color: #00274E">You can start typing an insured address or open dropdown</p>
                    </v-row>
                    <v-row>
                      <v-col cols="10" class="input-spacing" data-cy="locations-select-column">
                        <v-autocomplete
                            data-cy="locations-select"
                            id="locations-list"
                            :items="$store.state.policyAddresses"
                            v-model="$store.state.selectedLocation"
                            :item-text="location => getLocationName(location)"
                            outlined
                            dense
                            return-object
                            autocomplete="off"
                            @input="lossLocationListOnInput"
                            background-color="white"
                            append-icon="">
                          <template v-slot:selection="{ item }">
                            <span class="autocomplete-blue-text pr-2">{{ item.streetAddress1 }}</span>
                            <span class="autocomplete-text pl-1">{{ item.city }}</span>
                            <span class="autocomplete-text pl-1">{{ item.state + ',' }}</span>
                            <span class="autocomplete-text pl-1">{{ item.postalCode }}</span>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content @click="setLossLocationFromList()">
                              <v-list-item-title>
                                <span class="autocomplete-blue-text pr-2">{{ item.streetAddress1 }}</span>
                                <span class="autocomplete-text">{{ item.city }}</span>
                                <span class="autocomplete-text">{{ item.state + ',' }}</span>
                                <span class="autocomplete-text">{{ item.postalCode }}</span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="2" class="px-0 pt-2 pb-0" @click="resetButtonOnClick()" data-cy="autocomplete-reset-column">
                        <v-icon data-cy="autocomplete-reset-icon" color="#003366" style="cursor: pointer">mdi-restore</v-icon>
                        <span data-cy="autocomplete-reset-span" class="reset-button-label px-0" style="cursor: pointer">Reset</span>
                      </v-col>
                    </v-row>
                    <v-overlay
                        v-if="this.$store.state.loadingRetrieveCall"
                        absolute
                        data-cy="retrieve-loading-bar-row">
                      <div data-cy="retrieve-loader">
                        <label data-cy="retrieve-loading-text">Gathering your addresses...</label>
                        <v-progress-linear data-cy="retrieve-loading-bar" indeterminate color="white" class="mb-0"/>
                      </div>
                    </v-overlay>
                  </v-col>
                </v-row>

                <div v-if="submission.lossInformation.lossLocationSameAsInsured
                || submission.lossInformation.lossLocationSameAsPrimaryContact" data-cy="loss-location-address-read-only">
                  <v-row class="pl-8">
                    <v-col cols="12">
                      <div class="label-text" data-cy="loss-address-label">LOSS ADDRESS</div>
                      <div class="rs-values" data-cy="loss-address-line-1-text">{{ submission.lossInformation.location.addressLine1 }}</div>
                      <div class="rs-values" data-cy="loss-address-line-2-text">{{ submission.lossInformation.location.addressLine2 }}</div>
                      <div class="rs-values" data-cy="loss-address-city-state-zip-text">{{ submission.lossInformation.location.city }}, {{ submission.lossInformation.location.state }} {{ submission.lossInformation.location.postalCode }}</div>
                      <div class="rs-values" data-cy="loss-address-country-text">{{ submission.lossInformation.location.country }}</div>
                    </v-col>
                  </v-row>
                </div>

                <div v-else>
                  <v-row class="pl-8">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="address-line1-column">
                        <p data-cy="address-line1-label" class="label-text">ADDRESS LINE 1</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="address-line1-text-column">
                        <v-text-field
                                      v-model="submission.lossInformation.location.addressLine1"
                                      data-cy="address-line1-input"
                                      id="address-line1"
                                      outlined
                                      dense
                                      clearable
                                      autocomplete="off"
                                      :maxLength="60"
                                      counter="60"
                                      background-color="white"
                                      :rules="addressLine1ValidationRules"
                                      @input="addressLine1OnInput"
                                      @blur="addressLine1OnInput">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                  <v-row class="pl-8">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="address-line2-column">
                        <p data-cy="address-line2-label" class="label-text">ADDRESS LINE 2</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="address-line2-text-column">
                        <v-text-field v-model="submission.lossInformation.location.addressLine2"
                                      data-cy="address-line2-input"
                                      id="address-line2"
                                      :maxLength="60"
                                      counter="60"
                                      outlined
                                      dense
                                      clearable
                                      autocomplete="off"
                                      background-color="white"
                                      :rules="addressLine2ValidationRules"
                                      @input="addressLine2OnInput"
                                      @blur="addressLine2OnInput">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                  <v-row class="pl-8">
                  <v-col cols="4" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="pt-2 pb-0" data-cy="city-column">
                        <p data-cy="city-label" class="label-text">CITY</p>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col cols="12" class="input-spacing" data-cy="city-text-column">
                        <v-text-field v-model="submission.lossInformation.location.city"
                                      id="city"
                                      data-cy="city-input"
                                      :maxLength="60"
                                      counter="60"
                                      outlined
                                      dense
                                      clearable
                                      autocomplete="off"
                                      background-color="white"
                                      :rules="cityValidationRules"
                                      @input="cityOnInput"
                                      @blur="cityOnInput">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="state-column">
                        <p data-cy="state-label" class="label-text">STATE<span class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col cols="12" class="input-spacing" data-cy="state-select-column">
                        <v-autocomplete v-model="submission.lossInformation.location.state"
                                        data-cy="state-select"
                                        id="state-select"
                                        :items="states"
                                        outlined
                                        dense
                                        clearable
                                        autocomplete="off"
                                        background-color="white"
                                        @blur="uiUtils.triggerEnterKeyPress('state-select')"
                                        @input="lossInfoStateValidation"
                                        :error-messages="stateValidationRules">
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                  <v-row class="pl-8">
                  <v-col cols="4" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="zip-column">
                        <p data-cy="zip-label" class="label-text">ZIP CODE</p>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col cols="12" class="input-spacing" data-cy="zip-text-column">
                        <v-text-field v-model="submission.lossInformation.location.postalCode"
                                      data-cy="zip-input"
                                      id="zip"
                                      v-mask="['#####' ,'#####-####']"
                                      outlined
                                      dense
                                      clearable
                                      autocomplete="off"
                                      background-color="white"
                                      :rules="zipValidationRules"
                                      @input="zipOnInput">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" class="input-spacing">
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="country-column">
                        <p data-cy="country-label" class="label-text">COUNTRY</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="input-spacing" data-cy="country-select-column">
                        <v-autocomplete v-model="submission.lossInformation.location.country"
                                        data-cy="country-select"
                                        :items="countries"
                                        outlined
                                        dense
                                        background-color="white"
                                        @input="lossInfoCountryOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                </div>

                <v-row class="pl-8">
                  <v-col cols="8" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing" data-cy="details-column">
                        <p data-cy="details-label" class="label-text">LOSS LOCATION DETAILS</p>
                        <p data-cy="details-information-label" class="label-text">Feel free to add any additional information concerning the location that you want to include in the claim.</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="input-spacing"  data-cy="details-input-column">
                        <v-textarea v-model="submission.lossInformation.lossLocationDetails"
                                    data-cy="details-input"
                                    id="location-details"
                                    outlined
                                    auto-grow
                                    clearable
                                    :maxLength="950"
                                    counter="950"
                                    dense
                                    background-color="white"
                                    :rules="locationDetailsRules"
                                    @input="locationDetailsOnInput"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </v-card>
            </v-col>
          </v-row>


          <v-row>
            <ChangeLossTypeModal :submission="submission" v-if="$store.state.showChangeLossTypeModal"></ChangeLossTypeModal>
          </v-row>

          <v-row>
            <v-col>
              <v-card class="card-background pl-7 pr-10" elevation="0">

                <v-row class=""  :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">

                    <v-btn @click="navigateTo('contact-information')" data-cy="previous-button" outlined class="previous-button"
                           v-if="$vuetify.breakpoint.mdAndUp">
                      <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                    </v-btn>

                  <v-btn @click="navigateToLossPage" data-cy="next-button" class="next-button">
                    <span>Next</span><v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-row>

                <v-row v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn @click="navigateTo('contact-information')" data-cy="previous-button" outlined class="previous-button" v-if="$vuetify.breakpoint.smAndDown">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>
                </v-row>

                <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                    <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                  </v-btn>
                </v-row>

              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission"
                      v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries"
import {FnolSubmission} from "@/models/FnolSubmission";
import {DateUtils} from "@/utils/date-utils";
import {UiUtils} from "@/utils/ui-utils";
import {ValidationUtils} from "@/utils/validation-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import ChangeLossTypeModal from "@/views/modals/ChangeLossTypeModal.vue";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import {PsarAddress} from "@/models/PolicyData/PsarAddress";
import {Locus} from '@/models/Locus';
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";
import {LossUtils} from "@/utils/loss-utils";


@Component({
  components: {
    BrowserBackButtonModal,
    SideStepperComponent,
    ChangeLossTypeModal, TimeoutModal, CancelClaimModal
  }
})
export default class LossInformation extends Vue {

  submission: FnolSubmission = new FnolSubmission();
  radioLossTypeSelected: boolean = false;

  lossInformationPageValid: boolean = true;
  dateUtils: DateUtils = new DateUtils();
  uiUtils: UiUtils = new UiUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  validationUtils: ValidationUtils = new ValidationUtils();
  lossUtils: LossUtils = new LossUtils();

  formattedTimeOfLoss: string = '';

  states: Array<String> = [];
  countries: Array<String> = [];
  addressLine1ValidationRules: Array<string> = [];
  lossTypeValidationRules: Array<string> = [];
  addressLine2ValidationRules: Array<string> = [];
  cityValidationRules: Array<string> = [];
  stateValidationRules: Array<string> = [];
  zipValidationRules: Array<string> = [];
  locationDetailsRules: Array<string> = [];
  lossCauseRules: Array<string> = [];
  detailedLossCauseRules: Array<string> = [];
  lossDescriptionRules: Array<string> = [];
  otherDescriptionRules: Array<string> = [];

  //This asynchronously updates policy location from the Retrieve call
  @Watch('submission.lossInformation.location', { immediate: true, deep: true })
  isPolicyAddressesChange(): void {
    this.submission.lossInformation.wasLocationManuallyEntered = true
  }

  created() {
    this.submission = this.$store.state.submission;
    this.states = Object.values(States)
    this.countries = Object.values(Countries)
    this.setPreselected()
    this.checkAllOtherSelection();
    if (this.submission.lossInformation.lossLocationSameAsInsured) {
      if (this.submission.customerInformation.insured.location.state == null) {
        this.submission.lossInformation.lossLocationSameAsInsured = false;
      }
      this.lossLocationSameAsInsuredOnSwitch();
    }
    if (this.submission.lossInformation.lossLocationSameAsPrimaryContact) {
      if (this.submission.customerInformation.primaryContact.location.state == null) {
        this.submission.lossInformation.lossLocationSameAsPrimaryContact = false;
      }
      this.lossLocationSameAsPrimaryContactOnSwitch();
    }

    if (this.submission.gettingStarted.timeOfLoss != null) {
      this.formattedTimeOfLoss = this.dateUtils.formatTimeForDisplay(this.submission.gettingStarted.dateOfLoss + " " +
          this.submission.gettingStarted.timeOfLoss)
    }
    if (this.$store.state.lossCauseDetailList && this.$store.state.lossCauseDetailList.length > 0) {
      this.setDetailedLossCauseList();
    }

    this.setTotalNumberOfPages();
  }

  setTotalNumberOfPages(){
    if (this.submission.lossInformation.lossType == "Auto") {
      this.$store.state.totalNumberOfPages = 8
      this.$store.state.additionalContactsStepNumber = 6
      this.$store.state.additionalInformationStepNumber = 7
      this.$store.state.reviewStepNumber = 8
    }else{
      this.$store.state.totalNumberOfPages = 7
      this.$store.state.additionalContactsStepNumber = 5
      this.$store.state.additionalInformationStepNumber = 6
      this.$store.state.reviewStepNumber = 7
    }
  }

  resetButtonOnClick(){
    this.$store.state.selectedLocation = new PsarAddress();
    this.submission.lossInformation.location = new Locus();
  }

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  applicationTimeoutTimer () {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  lossLocationSameAsInsuredOnSwitch() {
    this.resetButtonOnClick()
    this.submission.lossInformation.lossLocationSameAsPrimaryContact = false;
    this.uiUtils.addressSameAsToggle(this.submission.lossInformation.location,
        this.submission.customerInformation.insured.location,
        this.submission.lossInformation.lossLocationSameAsInsured)
  }

  lossLocationSameAsPrimaryContactOnSwitch() {
    this.resetButtonOnClick()
    this.submission.lossInformation.lossLocationSameAsInsured = false;
    this.uiUtils.addressSameAsToggle(this.submission.lossInformation.location,
        this.submission.customerInformation.primaryContact.location,
        this.submission.lossInformation.lossLocationSameAsPrimaryContact)
  }

  async setPreselected() {
    if (this.submission.lossInformation.lossType) {
      this.radioLossTypeSelected = true;
    }
    await this.lossUtils.getLossCauseSelections(this.submission)
  }

  lossInfoStateValidation() {
    this.stateValidationRules = [];
    this.submission.lossInformation.location.state = this.uiUtils.testForTrim(this.submission.lossInformation.location.state)
    this.submission.lossInformation.location.stateCode = this.uiUtils.getStateCode(this.submission.lossInformation.location.state);
  }

  lossCauseOnSelect() {
    this.lossCauseRules = [];
    this.submission.lossInformation.lossCause = this.uiUtils.testForTrim(this.submission.lossInformation.lossCause)
    this.submission.lossInformation.lossCauseCode = this.uiUtils.testForTrim(this.lossUtils.getLossCauseCode(this.submission.lossInformation.lossCause))
    if (this.submission.lossInformation.lossCauseCode) {
      this.checkAllOtherSelection();
      this.clearDetailedLossCause();
      this.setDetailedLossCauseList();
    }
  }

  lossInfoCountryOnInput() {
    this.submission.lossInformation.location.country = this.uiUtils.testForTrim(this.submission.lossInformation.location.country)
    this.submission.lossInformation.location.countryCode = this.uiUtils.getCountryCode(this.submission.lossInformation.location.country);
  }

  async lossTypeValidation() {
    this.lossTypeValidationRules = []
    this.lossCauseRules = []

    if (this.radioLossTypeSelected && (this.submission.lossInformation.lossType != this.$store.state.oldLossType)) {
      this.$store.state.showChangeLossTypeModal = true;
    } else {
      if (!this.radioLossTypeSelected && this.submission.lossInformation.lossType) {
        this.radioLossTypeSelected = true;
        this.$store.state.oldLossType = this.submission.lossInformation.lossType;
        await this.lossUtils.getLossCauseSelections(this.submission)
        this.submission.lossInformation.lossTypeCode = await this.lossUtils.getLossTypeCode(this.submission.lossInformation.lossType)

      }
    }

    //need to set total Number Of Pages for Mobile side stepper
    this.setTotalNumberOfPages();
  }

  checkAllOtherSelection() {
    if (this.submission.lossInformation.lossCause == 'All Other') {
      this.$store.state.isOtherDescriptionVisible = true;
    } else {
      this.$store.state.isOtherDescriptionVisible = false;
      this.submission.lossInformation.otherDescription = null;
    }
  }

  async setDetailedLossCauseList() {
    await this.lossUtils.getLossCauseDetailSelections(this.submission)

    if (this.$store.state.lossCauseDetailNameList && this.$store.state.lossCauseDetailNameList.length > 1) {
      this.$store.state.isDetailedLossCauseVisible = true;
    } else if (this.$store.state.lossCauseDetailNameList && this.$store.state.lossCauseDetailNameList.length == 1 && this.$store.state.lossCauseDetailNameList[0] != "N/A") {
        this.submission.lossInformation.detailedLossCause = this.$store.state.lossCauseDetailNameList[0];
        this.submission.lossInformation.detailedLossCauseCode = this.lossUtils.getLossCauseDetailCode(this.submission.lossInformation.detailedLossCause);
    } else {
      this.clearDetailedLossCause()
    }
  }

  detailedLossCauseValidation() {
    this.detailedLossCauseRules = [];
    if (this.submission.lossInformation.detailedLossCause) {
        this.submission.lossInformation.detailedLossCauseCode = this.lossUtils.getLossCauseDetailCode(this.submission.lossInformation.detailedLossCause)
    }
  }
  clearDetailedLossCause() {
    this.$store.state.isDetailedLossCauseVisible = false;
    this.submission.lossInformation.detailedLossCause = null;
    this.submission.lossInformation.detailedLossCauseCode = null;
  }

  addressLine1OnInput() {
    this.submission.lossInformation.location.addressLine1 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.location.addressLine1)
    this.addressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  addressLine2OnInput() {
    this.submission.lossInformation.location.addressLine2 = this.uiUtils.checkIfEmpty(this.submission.lossInformation.location.addressLine2)
    this.addressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  cityOnInput() {
    this.submission.lossInformation.location.city = this.uiUtils.checkIfEmpty(this.submission.lossInformation.location.city)
    this.cityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  zipOnInput() {
    this.submission.lossInformation.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.location.postalCode)
    this.zipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules_isRequired(false);
  }

  locationDetailsOnInput() {
    this.submission.lossInformation.lossLocationDetails = this.uiUtils.checkIfEmpty(this.submission.lossInformation.lossLocationDetails)
    this.locationDetailsRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  lossDescriptionOnInput() {
    this.submission.lossInformation.lossDescription = this.uiUtils.checkIfEmpty(this.submission.lossInformation.lossDescription)
    this.lossDescriptionRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  otherDescriptionOnInput() {
    this.submission.lossInformation.otherDescription = this.uiUtils.checkIfEmpty(this.submission.lossInformation.otherDescription)
    if (this.$store.state.isOtherDescriptionVisible) {
      this.otherDescriptionRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
    }
  }

  //TODO This needs to include each field that is user input-able
  trimLossInfoObject() {
    this.submission.lossInformation.lossDescription = this.uiUtils.testForTrim(this.submission.lossInformation.lossDescription)
    this.submission.lossInformation.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.location.addressLine1)
    this.submission.lossInformation.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.location.addressLine2)
    this.submission.lossInformation.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.location.city)
    this.submission.lossInformation.lossLocationDetails = this.uiUtils.testForTrim(this.submission.lossInformation.lossLocationDetails)
    this.submission.lossInformation.otherDescription = this.uiUtils.testForTrim(this.submission.lossInformation.otherDescription)
  }

  async navigateTo(route: string) {
    this.trimLossInfoObject();
    await this.triggerNonRequiredValidations();
    this.submission.lossInformation.wasLocationManuallyEntered = this.wasLossLocationManuallyEntered();
      if(!this.validationUtils.validateRequiredFieldsOnLossInfo(this.submission)){
          this.$store.state.lossInfoIsValid = true
      }
    if (this.lossInformationPageValid) {
      this.$store.state.showValidationSnackbar = false
      this.uiUtils.getLossPageRoute(this.submission.lossInformation.lossType)
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    } else {
      this.$store.state.showValidationSnackbar = true
      window.scrollTo(0, 0);
    }
  }

  async navigateToLossPage() {
    this.submission.lossInformation.wasLocationManuallyEntered = this.wasLossLocationManuallyEntered();
    this.trimLossInfoObject();
    await this.triggerNonRequiredValidations();
    if(!this.validationUtils.validateRequiredFieldsOnLossInfo(this.submission)){
      this.$store.state.lossInfoIsValid = true
    }
    if (this.lossInformationPageValid) {
      this.$store.state.showValidationSnackbar = false
      // Decide whether was from dropdown or not
      let route = this.uiUtils.getLossPageRoute(this.submission.lossInformation.lossType)
        if(this.submission.lossInformation.lossType == null){
            route = 'additional-contacts'
        }
      this.uiUtils.instantiateLossObject(this.submission);
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    } else {
      this.$store.state.showValidationSnackbar = true
      window.scrollTo(0, 0);
    }
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

  async triggerNonRequiredValidations() {

    //  Validating Select/Radio : Order matters!
    this.lossTypeValidation()
    if (this.$store.state.isDetailedLossCauseVisible) {
      this.detailedLossCauseValidation()
    }
    this.lossInfoStateValidation()

    // Validating input boxes
    await this.validationUtils.triggerBlurs(['loss-description']);

    if (this.$store.state.isOtherDescriptionVisible) {
      await this.validationUtils.triggerBlur('other-description');
    }
  }

  checkIsSameAsLossLocationIsSameAsToggled() {
    return this.submission.lossInformation.lossLocationSameAsPrimaryContact || this.submission.lossInformation.lossLocationSameAsInsured;
  }

  showLocationListDropdown() {
    //Do not show the dropdown if the toggles for insured or primary contact are active
    if(this.$store.state.wasPolicySearchSuccessful && !this.checkIsSameAsLossLocationIsSameAsToggled()) {
      if(this.submission.lossInformation.lossType != null) {
        if (this.submission.lossInformation.lossType == 'Liability' || this.submission.lossInformation.lossType == 'Property') {
          return true;
        }
      }
    }
    return false;
  }

  getLocationName(location : PsarAddress) {
   return location.streetAddress1 + ', ' + location.city + ' ' + location.state + ', ' + location.postalCode
  }

  lossLocationListOnInput() {
    if(this.$store.state.selectedLocation) {
      this.submission.lossInformation.location = this.uiUtils.convertPsarAddressToLocation(this.$store.state.selectedLocation, this.isPsarLocationInTheUS())
    }
  }

  setLossLocationFromList() {
    if(this.$store.state.selectedLocation) {
      this.submission.lossInformation.location = this.uiUtils.convertPsarAddressToLocation(this.$store.state.selectedLocation, this.isPsarLocationInTheUS())
    }
  }

  isPsarLocationInTheUS() {
    if(this.states.includes(this.$store.state.selectedLocation.state)) {
      return true;
    }
  }

  wasLossLocationManuallyEntered(): boolean {
    //Either no locations came back from search/retrieve or the search call was not successful at all,
    //The location had to be manually entered
    if(this.submission.lossInformation.lossType === 'Auto' || this.submission.lossInformation.lossType === 'Cargo') {
      return true;
    }
    if (this.$store.state.policyAddresses.length === 0 || !this.$store.state.wasPolicySearchSuccessful ||
        this.submission.lossInformation.location.addressLine2) {
      return true;
    }
    if (this.$store.state.policyAddresses.length > 0) {
      let doesLocationMatch = false;

        for(let i = 0; i < this.$store.state.policyAddresses.length; i++) {
          doesLocationMatch = this.doesLossLocationMatchPsarAddresses(this.$store.state.policyAddresses[i]);
          if (doesLocationMatch) {
            break;
          }
        }

        return !doesLocationMatch
    }
   return true;
  }

  doesLossLocationMatchPsarAddresses(location: PsarAddress): boolean {
    if(this.submission.lossInformation.location.addressLine1 && this.submission.lossInformation.location.addressLine1.match(location.streetAddress1)) {
      if(this.submission.lossInformation.location.city && this.submission.lossInformation.location.city.match(location.city)) {
        if(this.submission.lossInformation.location.stateCode && this.submission.lossInformation.location.stateCode.match(location.state)) {
          if(this.submission.lossInformation.location.postalCode && this.submission.lossInformation.location.postalCode.match(location.postalCode)) {
            console.log("Location matches Dropdown address. Setting manually entered to false")
            return true;
          }
        }
      }
    }
    return false;
  }

}
</script>

<style scoped lang="scss">

.reset-button-label{
  font-family: proxima-nova;
  text-decoration: underline;
  font-size: 16px;
  color: #002743;
}

</style>
