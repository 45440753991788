<template>
  <v-form autocomplete="off" v-model="$store.state.injuredPersonComponentIsValid" class="pt-4" data-cy="injured-person-component">
    <v-col v-for="(injuredPerson, index) in submission.lossInformation.liabilityLossInformation.injuredPersons" :key="index" class="pa-0">
    <v-divider color="lightgray"/>
    <v-row class="pl-8">
      <v-col :data-cy="'injured-person-vue-' + index" >
              <v-card class="card-background" elevation="0" >
                <v-row>
                  <v-col>
                    <div>
                    <span :data-cy="'injured-person-label-'+ index" class="card-label">Injured Person {{index + 1}}</span>
                    </div>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.mdAndDown">
                    <v-row>
                      <v-btn style="width:auto" class="outlined-button" outlined @click="removeInjuredPerson(index)"  :data-cy="'mobile-remove-injured-person-button-' + index">
                        <span><v-icon>mdi-minus</v-icon>Remove</span>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="3" class="pb-0">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-first-name-label-' + index" class="label-text">FIRST NAME<span class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-first-name-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-first-name-input-' + index"
                            class="field-to-validate"
                            v-model="injuredPerson.firstName"
                            dense
                            outlined
                            clearable
                            background-color="white"
                            counter="30"
                            maxLength="30"
                            :rules="injuredPersonFirstNameValidationRules"
                            @input="runInjuredPersonFirstNameValidationOnInput(injuredPerson)"/>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4" lg="3" class="pb-0">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-last-name-label-' + index" class="label-text">LAST NAME<span class="req-asterisk">*</span></p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-last-name-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-last-name-input-' + index"
                            class="field-to-validate"
                            v-model="injuredPerson.lastName"
                            dense
                            outlined
                            clearable
                            background-color="white"
                            counter="30"
                            maxLength="30"
                            :rules="injuredPersonLastNameValidationRules"
                            @input="runInjuredPersonLastNameValidationOnInput(injuredPerson)"/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" lg="3" class="component-button" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-btn class="outlined-button" outlined @click="removeInjuredPerson(index)"  :data-cy="'remove-injured-person-button-' + index">
                      <span><v-icon>mdi-minus</v-icon>Remove Injured Person</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="4" lg="3" class="pb-0">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-email-label-' + index" class="label-text">EMAIL</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-email-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-email-input-' + index"
                            v-model="injuredPerson.primaryEmail"
                            dense
                            outlined
                            clearable
                            counter="50"
                            maxLength="50"
                            background-color="white"
                            :rules="injuredPersonEmailValidationRules"
                            @blur="runInjuredPersonEmailOnInput(injuredPerson)"
                            @input="runInjuredPersonEmailOnInput(injuredPerson)"/>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col md="4" lg="3" class="pb-0">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-phone-label-' + index" class="label-text">PHONE</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-phone-column-' + index">
                        <v-text-field v-model="injuredPerson.primaryPhone"
                                      :data-cy="'injured-person-phone-input-' + index"
                                      outlined dense
                                      clearable
                                      background-color="white"
                                      v-mask="'(###) ###-####'"
                                      :rules="injuredPersonPhoneValidationRules"
                                      @blur="runInjuredPersonPhoneOnInput(injuredPerson)"
                                      @input="runInjuredPersonPhoneOnInput(injuredPerson)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="8" lg="6" class="pb-0">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-address-line-1-label-' + index"
                           class="label-text">ADDRESS LINE 1</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-address-line-1-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-address-line-1-input-' + index"
                            v-model="injuredPerson.location.addressLine1"
                            dense
                            outlined
                            clearable
                            background-color="white"
                            counter="60"
                            :rules="injuredPersonAddressLine1ValidationRules"
                            @blur="runInjuredPersonAddressLine1OnInput(injuredPerson)"
                            @input="runInjuredPersonAddressLine1OnInput(injuredPerson)"
                            maxlength="60"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row >
                  <v-col md="8" lg="6" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-address-line-2-label-' + index" class="label-text">ADDRESS LINE 2</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-address-line-2-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-address-line-2-input-' + index"
                            v-model="injuredPerson.location.addressLine2"
                            dense
                            outlined
                            clearable
                            counter="60"
                            maxLength="60"
                            :rules="injuredPersonAddressLine2ValidationRules"
                            @blur="runInjuredPersonAddressLine2OnInput(injuredPerson)"
                            @input="runInjuredPersonAddressLine2OnInput(injuredPerson)"
                            background-color="white"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="4" lg="3" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-city-label-' + index" class="label-text">CITY</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-city-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-city-input-' + index"
                            v-model="injuredPerson.location.city"
                            dense
                            outlined
                            clearable
                            background-color="white"
                            counter="60"
                            maxLength="60"
                            :rules="injuredPersonCityValidationRules"
                            @input="runInjuredPersonCityOnInput(injuredPerson)"
                            @blur="runInjuredPersonCityOnInput(injuredPerson)"/>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col md="4" lg="3" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-state-label-' + index" class="label-text">STATE</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-state-column-' + index">
                        <v-autocomplete v-model="injuredPerson.location.state"
                                        :data-cy="'injured-person-state-select-' + index"
                                        :id="'injured-person-state-select-' + index"
                                        autocomplete="new-password"
                                        :items="statesList"
                                        outlined dense
                                        clearable
                                        @input="runInjuredPersonStateOnSelect(injuredPerson)"
                                        @blur="runInjuredPersonStateOnSelect(injuredPerson); uiUtils.triggerEnterKeyPress('injured-person-state-select-' + index)"
                                        background-color="white"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row >
                  <v-col md="4" lg="3" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-zip-code-label-' + index" class="label-text">ZIP CODE</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-zip-code-column-' + index">
                        <v-text-field
                            :data-cy="'injured-person-zip-code-input-' + index"
                            v-model="injuredPerson.location.postalCode"
                            v-mask="['#####' ,'#####-####']"
                            dense
                            outlined
                            clearable
                            :rules="injuredPersonZipValidationRules"
                            @input="runInjuredPersonZipOnInput(injuredPerson)"
                            @blur="runInjuredPersonZipOnInput(injuredPerson)"
                            background-color="white"/>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col md="4" lg="3" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                        <p :data-cy="'injured-person-country-label-' + index" class="label-text">COUNTRY</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" md="12" class="input-spacing" :data-cy="'injured-person-country-column-' + index">
                        <v-autocomplete v-model="injuredPerson.location.country"
                                        :data-cy="'injured-person-country-select-' + index"
                                        :items="countriesList"
                                        clearable
                                        outlined
                                        dense
                                        @input="runInjuredPersonCountryOnSelect(injuredPerson)"
                                        @blur="runInjuredPersonCountryOnSelect(injuredPerson)"
                                        background-color="white">
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row >
                  <v-col md="4" lg="3" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                      <p :data-cy="'injured-person-relation-label-' + index" class="label-text">RELATIONSHIP TO INSURED</p>
                      </v-col>
                    </v-row>
                     <v-row class="input-spacing" :data-cy="'injured-person-relation-column-' + index">
                       <v-col cols="8" md="12" class="input-spacing">
                       <v-autocomplete
                          v-model="injuredPerson.relationshipToInsured"
                          :data-cy="'injured-person-relation-select-' + index"
                          :items='["Employee", "Other"]'
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @change="clearDescribeOtherRelationship(injuredPerson)"/>
                       </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="4" lg="3" class="input-spacing" v-if="injuredPerson.relationshipToInsured === 'Other'">
                    <v-row>
                      <v-col class="input-spacing">
                      <p :data-cy="'injured-person-other-relation-label-' + index" class="label-text">DESCRIBE OTHER RELATIONSHIP</p>
                      </v-col>
                    </v-row>
                    <v-row class="input-spacing" :data-cy="'injured-person-other-relation-column-' + index">
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-text-field
                          v-model="injuredPerson.describeOtherRelationship"
                          :data-cy="'injured-person-other-relation-input-' + index"
                          dense
                          outlined
                          clearable
                          :rules="InjuredPersonDescribeOtherRelationshipValidationRules"
                          @input="describeOtherRelationshipOnInput(injuredPerson)"
                          background-color="white"
                          counter="50"
                          :maxLength="50"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class=" input-spacing" >
                  <v-col md="8" lg="6" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                      <p :data-cy="'injury-description-label-' + index" class="label-text">PLEASE DESCRIBE THE INJURY</p>
                      </v-col>
                    </v-row>
                    <v-row class="input-spacing" :data-cy="'injured-description-column-' + index">
                      <v-col cols="8" md="12" class="input-spacing">
                      <v-text-field
                          v-model="injuredPerson.injuryDescription"
                          :data-cy="'injured-description-input-' + index"
                          :rules="injuredPersonInjuryDescriptionValidationRules"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          counter="60"
                          :maxLength="60"
                          @input="injuryDescriptionOnInput(injuredPerson)"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <!-- START:   Delete Additional Injured Person Dialog   -->
              <v-dialog v-model="$store.state.showRemoveInjuredPersonModal" overlay-opacity="0.7"
                        overlay-color="#00172F"
                        persistent max-width="500px"
                        :data-cy="'remove-additional-injured-person-dialog-'+index">
                <v-card v-if="$store.state.showRemoveInjuredPersonModal" style="overflow-x: hidden; border-radius: 12px"
                        :data-cy="'remove-additional-injured-person-card-'+index" color="#f2f4f6">
                  <v-row justify="end">
                    <v-col style="text-align: right" class="mr-4 py-0 col">
                    <span class="exit-remove-button" @click="noButton"
                          :data-cy="'remove-additional-injured-person-exit-'+index">x</span>
                    </v-col>
                  </v-row>
                  <v-card-title class="py-0">
                  <span style="color: #003366; font-size: 24px; font-weight: 500" class="headline"
                        :data-cy="'remove-additional-injured-person-header-'+index">Remove Injured Person</span>
                  </v-card-title>
                  <v-card-text class="pb-0 pt-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="pb-0 pt-0">
                          <p class="modal-text" :data-cy="'remove-additional-injured-person-text-'+index">
                            Are you sure you want to remove Injured Person {{
                              $store.state.currentInjuredPersonFullName
                            }}?
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-row justify="end" class="pr-8">
                    <v-card-actions class="pb-8">
                      <v-btn depressed color="primary"
                             :data-cy="'remove-additional-injured-person-yes-'+index"
                             @click="yesButton($store.state.currentInjuredPersonIndex)">Yes
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions class="pb-8">
                      <v-btn depressed text color="primary" outlined
                             :data-cy="'remove-additional-injured-person-no-'+index" @click="noButton">No
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-card>
              </v-dialog>
              <!-- END:   Delete Injured Person Dialog   -->
            </v-col>
    </v-row>
    </v-col>
  </v-form>
</template>


<script lang="ts">
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {Component, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {InjuredPerson} from '@/models/InjuredPerson';
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";

@Component
export default class InjuredPersonComponent extends Vue {

  submission: FnolSubmission;

  uiUtils = new UiUtils();
  statesList: Array<string> = Object.values(States);
  countriesList: Array<string> = Object.values(Countries);

  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  injuredPersonFirstNameValidationRules: Array<string> = [];
  injuredPersonLastNameValidationRules: Array<string> = [];
  injuredPersonEmailValidationRules: Array<string> = [];
  injuredPersonAddressLine1ValidationRules: Array<string> = [];
  injuredPersonAddressLine2ValidationRules: Array<string> = [];
  injuredPersonZipValidationRules: Array<string> = [];
  injuredPersonPhoneValidationRules: Array<string> = [];
  injuredPersonCityValidationRules: Array<string> = [];
  injuredPersonInjuryDescriptionValidationRules: Array<string> = [];
  InjuredPersonDescribeOtherRelationshipValidationRules: Array<string> = [];


  created() {
    console.log("injuredPerson component created")
    this.submission = this.$store.state.submission;
    this.$store.state.showRemoveInjuredPersonModal = false;
  }

  injuryDescriptionOnInput(injuredPerson: InjuredPerson){
    injuredPerson.injuryDescription = this.uiUtils.checkIfEmpty(injuredPerson.injuryDescription)
    this.injuredPersonInjuryDescriptionValidationRules = this.aplFrontEndValidation.runTextInputValidationRules_isRequired(false);
  }

  removeInjuredPerson(idx: number) {
    this.$store.state.currentInjuredPersonIndex = idx
    this.$store.state.currentInjuredPersonFullName = ""

    if (this.submission.lossInformation.liabilityLossInformation.injuredPersons[idx].firstName && this.submission.lossInformation.liabilityLossInformation.injuredPersons[idx].lastName) {
      this.$store.state.currentInjuredPersonFullName = this.submission.lossInformation.liabilityLossInformation.injuredPersons[idx].firstName + " " + this.submission.lossInformation.liabilityLossInformation.injuredPersons[idx].lastName
    }
    this.$store.state.showRemoveInjuredPersonModal = true;
  }

  yesButton(wIndex: number) {
    this.submission.lossInformation.liabilityLossInformation.injuredPersons.splice(wIndex, 1);
    this.$store.state.showRemoveInjuredPersonModal = false;
  }

  noButton() {
    this.$store.state.showRemoveInjuredPersonModal = false;
  }

  clearDescribeOtherRelationship(injuredPerson: InjuredPerson) {
    injuredPerson.describeOtherRelationship = null

  }

  runInjuredPersonFirstNameValidationOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.firstName = this.uiUtils.checkIfEmpty(injuredPerson.firstName)
    this.injuredPersonFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  runInjuredPersonLastNameValidationOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.lastName = this.uiUtils.checkIfEmpty(injuredPerson.lastName)
    this.injuredPersonLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  runInjuredPersonEmailOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.primaryEmail = this.uiUtils.checkIfEmpty(injuredPerson.primaryEmail)
    this.injuredPersonEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  runInjuredPersonPhoneOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.primaryPhone = this.uiUtils.testForTrim(injuredPerson.primaryPhone)
    this.injuredPersonPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  runInjuredPersonAddressLine1OnInput(injuredPerson: InjuredPerson) {
    injuredPerson.location.addressLine1 = this.uiUtils.checkIfEmpty(injuredPerson.location.addressLine1)
    this.injuredPersonAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  runInjuredPersonAddressLine2OnInput(injuredPerson: InjuredPerson) {
    injuredPerson.location.addressLine2 = this.uiUtils.checkIfEmpty(injuredPerson.location.addressLine2)
    this.injuredPersonAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules();
  }

  runInjuredPersonZipOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.location.postalCode = this.uiUtils.testForTrim(injuredPerson.location.postalCode)
    this.injuredPersonZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules();
  }

  runInjuredPersonStateOnSelect(injuredPerson: InjuredPerson) {
    injuredPerson.location.state = this.uiUtils.testForTrim(injuredPerson.location.state);
    injuredPerson.location.stateCode = this.uiUtils.getStateCode(injuredPerson.location.state);
  }

  runInjuredPersonCountryOnSelect(injuredPerson: InjuredPerson) {
    injuredPerson.location.country = this.uiUtils.testForTrim(injuredPerson.location.country);
    injuredPerson.location.countryCode = this.uiUtils.getCountryCode(injuredPerson.location.country);
  }

  runInjuredPersonCityOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.location.city = this.uiUtils.checkIfEmpty(injuredPerson.location.city)
    this.injuredPersonCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false);
  }

  describeOtherRelationshipOnInput(injuredPerson: InjuredPerson) {
    injuredPerson.describeOtherRelationship = this.uiUtils.checkIfEmpty(injuredPerson.describeOtherRelationship)
    this.InjuredPersonDescribeOtherRelationshipValidationRules = this.aplFrontEndValidation.runTextInputValidationRules_isRequired(false);
  }

}


</script>
<style scoped lang="scss">

</style>
