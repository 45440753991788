export default class UploadedFileStatus {
  public fileUploadFailureReason: string;

  constructor(
              public fileName: string,
              public fileSize: number,
              public status : 'in progress' | 'success' | 'failure' | 'infected',
              public filePath?: string) {
  }
}
