<template>
  <div>
    <v-dialog v-model="this.$store.state.showRemovePartyModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px">
      <v-card style="overflow-x: hidden" data-cy="remove-party-modal">
        <v-row justify="end">
          <v-col style="text-align: right" class="mr-4 py-0 col">
            <span class="exit-remove-party-button" @click="noButton" data-cy="remove-party-x">x</span>
          </v-col>
        </v-row>
        <v-card-title class="py-0">
          <span style="color: #003366" class="headline" data-cy="remove-party-header">Remove Party</span>
        </v-card-title>
        <v-card-text class="pb-0 pt-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0">
                <span style="color: #003366" data-cy="remove-party-body">Are you sure you want to remove party {{ getOtherPartyName() }}?</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row justify="end" class="pr-8">
          <v-card-actions class="pb-8">
            <v-btn depressed color="primary" data-cy="remove-party-yes" @click="yesButton">Yes</v-btn>
          </v-card-actions>
          <v-card-actions class="pb-8">
            <v-btn depressed text color="primary" outlined data-cy="remove-party-no" @click="noButton">No</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";

@Component
export default class RemovePartyModal extends Vue {

  @Prop()
  submission: FnolSubmission;

  @Prop()
  index: number;

  created() {
    console.log("remove party modal created")
  }

  yesButton() {
    this.$store.state.showRemovePartyModal = false;
    this.submission.otherParty.parties.splice(this.index, 1);
    this.$store.state.currentTab -= 1;
  }

  noButton() {
    this.$store.state.showRemovePartyModal = false;
  }

  getOtherPartyName() {
    if (this.submission.otherParty.parties[this.index].driver != null) {
      if (this.submission.otherParty.parties[this.index].driver.firstName && this.submission.otherParty.parties[this.index].driver.lastName) {
        return this.submission.otherParty.parties[this.index].driver.firstName + ' ' + this.submission.otherParty.parties[this.index].driver.lastName
      }
    }
    return 'New Party'
  }
}
</script>

<style lang="scss" scoped>

.exit-remove-party-button {
  background-color: white;
  font-size: 35px;
  font-weight: bold;
  color: #979797;
  border-radius: 30px;
  width: 50px;
  height: 50px;
}

</style>
