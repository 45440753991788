<template>
  <v-form autocomplete="off" v-model="$store.state.otherPartyDriverInformationPageValid" class="card-background">

  <v-row data-cy="other-party-driver-info" class="proximaNovaFont px-2">
    <v-col :cols="$vuetify.breakpoint.mdAndUp">
      <v-card class="card-background" elevation="0">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12" class="input-spacing">
                <p data-cy="have-driver-info-label" class="label-text">DO YOU HAVE THE DRIVER'S INFORMATION?</p>
              </v-col>
            </v-row>
              <v-row cols="12">
                <v-col class="input-spacing">
                <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                               :data-cy="'have-driver-info-radio-group-' + this.index"
                               class="radio-container"
                               v-model="party.hasDriverInfo">
                  <v-radio
                      label="Yes"
                      :value=true
                      :data-cy="'have-driver-info-radio-yes-' + this.index"
                      @change="onDoesHaveDriverInfo"
                  ></v-radio>
                  <v-radio
                      label="No"
                      :value=false
                      :data-cy="'have-driver-info-radio-no-' + this.index"
                      @change="onDoesNotHaveDriverInfo"
                  ></v-radio>
                </v-radio-group>
                </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="party.hasDriverInfo">
          <v-col>
              <v-row>
                <v-col v-if="!uiUtils.isInsuredDriverSameAsPrimaryContact(submission.lossInformation)" md="8" lg="6" class="input-spacing" data-cy="driver-same-as-primary-contact-column">
                  <v-switch
                      v-model="party.driver.isSameAsPrimaryContact"
                      inset
                      data-cy="driver-same-as-primary-contact-switch"
                      class="v-switch-label"
                      label="Is the driver the same as the primary contact?"
                      dense
                      @change="driverSameAsPrimaryContactOnSwitch"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-first-name-label" class="label-text">FIRST NAME<span class="req-asterisk">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-first-name-column">
                      <div data-cy="driver-first-name-text" v-if="party.driver.isSameAsPrimaryContact" class="rs-values pb-5">{{ party.driver.firstName }}</div>
                      <v-text-field v-else
                                    :data-cy="'driver-first-name-input-' + this.index"
                                    v-model="party.driver.firstName"
                                    autocomplete="off"
                                    dense
                                    outlined
                                    clearable
                                    background-color="white"
                                    counter="30"
                                    maxLength="30"
                                    :rules="driverFirstNameValidationRules"
                                    @input="driverFirstNameValidationOnInput" />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-last-name-label" class="label-text">LAST NAME<span class="req-asterisk">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-last-name-column">
                      <div data-cy="driver-last-name-text" v-if="party.driver.isSameAsPrimaryContact" class="rs-values pb-5">{{ party.driver.lastName }}</div>
                      <v-text-field v-else
                                    :data-cy="'driver-last-name-input-' + this.index"
                                    v-model="party.driver.lastName"
                                    dense
                                    outlined
                                    clearable
                                    autocomplete="off"
                                    background-color="white"
                                    counter="30"
                                    maxLength="30"
                                    :rules="driverLastNameValidationRules"
                                    @input="driverLastNameValidationOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-email-label" class="label-text">EMAIL</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-email-column">
                      <div data-cy="driver-email-text" v-if="party.driver.isSameAsPrimaryContact" class="rs-values pb-5">{{ party.driver.primaryEmail }}</div>
                      <v-text-field
                          v-else
                          data-cy="driver-email-input"
                          id="driver-email-input"
                          v-model="party.driver.primaryEmail"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          maxLength="50"
                          counter="50"
                          :rules="driverEmailValidationRules"
                          @input="driverEmailValidationOnInput"
                          @blur="driverEmailValidationOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-phone-label" class="label-text">PHONE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-phone-column">
                      <div data-cy="driver-phone-text" v-if="party.driver.isSameAsPrimaryContact" class="rs-values pb-5">{{ party.driver.primaryPhone }}</div>
                      <v-text-field
                          v-else
                          data-cy="driver-phone-input"
                          v-model="party.driver.primaryPhone"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          v-mask="'(###) ###-####'"
                          :rules="driverPhoneValidationRules"
                          @input="driverPhoneValidationOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            <div v-if="party.driver.isSameAsPrimaryContact || party.driver.isAddressSameAsInsured" data-cy="party-driver-address-read-only">
              <v-row>
                <v-col cols="12">
                  <div class="label-text" data-cy="party-driver-address-label">DRIVER ADDRESS</div>
                  <div class="rs-values" data-cy="party-driver-address-line-1-text">{{ party.driver.location.addressLine1 }}</div>
                  <div class="rs-values" data-cy="party-driver-address-line-2-text">{{ party.driver.location.addressLine2 }}</div>
                  <div class="rs-values" data-cy="party-driver-city-state-zip-text">{{ party.driver.location.city }}, {{ party.driver.location.state }} {{ party.driver.location.postalCode }}</div>
                  <div class="rs-values" data-cy="party-driver-country-text">{{ party.driver.location.country }}</div>
                </v-col>
              </v-row>
            </div>

            <div v-else>
              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-address-line-1-label" class="label-text">ADDRESS LINE 1</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-address-line-1-column">
                      <v-text-field data-cy="driver-address-line-1-input"
                                    v-model="party.driver.location.addressLine1"
                                    dense
                                    outlined
                                    clearable
                                    autocomplete="off"
                                    background-color="white"
                                    counter="60"
                                    maxLength="60"
                                    :rules="driverAddress1ValidationRules"
                                    @input="driverAddress1ValidationOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-address-line-2-label" class="label-text">ADDRESS LINE 2</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-address-line-2-column">
                      <v-text-field data-cy="driver-address-line-2-input"
                                    v-model="party.driver.location.addressLine2"
                                    dense
                                    outlined
                                    clearable
                                    autocomplete="off"
                                    background-color="white"
                                    counter="60"
                                    maxLength="60"
                                    :rules="driverAddress2ValidationRules"
                                    @input="driverAddress2ValidationOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-city-label" class="label-text">CITY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-city-column">
                      <v-text-field
                          data-cy="driver-city-input"
                          v-model="party.driver.location.city"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          counter="60"
                          maxLength="60"
                          :rules="driverCityValidationRules"
                          @input="driverCityValidationOnInput"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-state-label" class="label-text">STATE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-state-column">
                      <v-autocomplete
                          data-cy="driver-state-select"
                          id="driver-state-select"
                          v-model="party.driver.location.state"
                          autocomplete="new-password"
                          :items="statesList"
                          dense
                          outlined
                          clearable
                          @blur="uiUtils.triggerEnterKeyPress('driver-state-select')"
                          @input="driverStateOnSelect()"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-zip-code-label" class="label-text">ZIP CODE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-zip-code-column">
                      <v-text-field
                          data-cy="driver-zip-code-input"
                          v-model="party.driver.location.postalCode"
                          dense
                          outlined
                          clearable
                          autocomplete="off"
                          background-color="white"
                          v-mask="['#####' ,'#####-####']"
                          :rules="driverZipCodeValidationRules"
                          @input="driverZipCodeValidationOnInput()"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col  class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-country-label" class="label-text">COUNTRY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-country-column">
                      <v-autocomplete
                          data-cy="driver-country-select"
                          v-model="party.driver.location.country"
                          autocomplete="new-password"
                          :items="countriesList"
                          dense
                          outlined
                          clearable
                          @input="driverCountryOnSelect"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

            </div>

              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="input-spacing" md="8" lg="6" >
                      <p data-cy="driver-injuries-sustained-label" class="label-text">DID THE DRIVER SUSTAIN AN INJURY DURING THE ACCIDENT?</p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="input-spacing" cols="12">
                    <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                   data-cy="driver-injuries-sustained-radio-group"
                                   v-model="party.driver.sustainInjury"
                                   class="radio-container">
                      <v-radio
                          label="Yes"
                          value="Yes"
                          data-cy="driver-injuries-sustained-radio-yes"
                          @change="sustainInjuryOnChange('Yes')"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="No"
                          data-cy="driver-injuries-sustained-radio-no"
                          @change="sustainInjuryOnChange('No')"
                      ></v-radio>
                      <v-radio
                          label="I'm unsure"
                          value="I'm unsure"
                          data-cy="driver-injuries-sustained-radio-unsure"
                          @change="sustainInjuryOnChange('I\'m unsure')"
                      ></v-radio>
                    </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="party.driver.sustainInjury === 'Yes'">
                <v-col class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="driver-injury-label" class="label-text">INJURY INFORMATION</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" data-cy="driver-injury-column">
                      <v-textarea data-cy="driver-injury-input"
                                  v-model="party.driver.injury"
                                  dense
                                  outlined
                                  clearable
                                  auto-grow
                                  background-color="white"
                                  :rules="injuryInformationRules"
                                  @input="driverInjuryInformationOnInput"
                                  :maxLength="950"
                                  counter="950"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </v-form>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";
import {Countries} from "@/models/typelists/Countries";
import {States} from "@/models/typelists/States";
import {Driver} from '@/models/Driver';
import {UiUtils} from "@/utils/ui-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {ValidationUtils} from "@/utils/validation-utils";
import {FnolSubmission} from "@/models/FnolSubmission";
import {Party} from "@/models/Party";

@Component
export default class OtherPartyDriverInformation extends Vue {

  @Prop()
  party: Party;

  @Prop()
  index: number;

  uiUtils = new UiUtils();
  validationUtils = new ValidationUtils();
  statesList: Array<string> = this.getStatesList();
  countriesList: Array<string> = this.getCountriesList();

  injuryInformationRules: Array<string> = [];
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  submission: FnolSubmission;

  driverFirstNameValidationRules: Array<string> = [];
  driverLastNameValidationRules: Array<string> = [];
  driverEmailValidationRules: Array<string> = [];
  driverPhoneValidationRules: Array<string> = [];
  driverAddress1ValidationRules: Array<string> = [];
  driverAddress2ValidationRules: Array<string> = [];
  driverCityValidationRules: Array<string> = [];
  driverZipCodeValidationRules: Array<string> = [];

  created() {
    console.log("OtherPartyDriverInformation created")
    this.submission = this.$store.state.submission;

    if (this.party.hasDriverInfo == true){
      if (this.party.driver != null){
        if(this.party.driver.isSameAsPrimaryContact == true){
          this.driverSameAsPrimaryContactOnSwitch()
        }
      }
    }
  }

  sustainInjuryOnChange(value : string){
    this.party.driver.sustainInjury = value;
    this.$forceUpdate()
  }

  onDoesHaveDriverInfo(){
    this.party.driver = new Driver()
  }

  onDoesNotHaveDriverInfo() {
    this.party.driver = null;
  }

  async driverSameAsPrimaryContactOnSwitch() {
    this.uiUtils.contactSameAsToggle(this.party.driver,
          this.submission.customerInformation.primaryContact,
          this.party.driver.isSameAsPrimaryContact)
    this.$forceUpdate()
    }

  driverFirstNameValidationOnInput(){
    this.$emit('update-tab-names')
    this.party.driver.firstName = this.uiUtils.checkIfEmpty(this.party.driver.firstName)
    this.driverFirstNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  driverLastNameValidationOnInput(){
    this.$emit('update-tab-names')
    this.party.driver.lastName = this.uiUtils.checkIfEmpty(this.party.driver.lastName)
    this.driverLastNameValidationRules = this.aplFrontEndValidation.runNameValidationRules_isRequired(false)
  }

  driverEmailValidationOnInput(){
    this.party.driver.primaryEmail = this.uiUtils.checkIfEmpty(this.party.driver.primaryEmail)
    this.driverEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  driverPhoneValidationOnInput() {
    this.party.driver.primaryPhone = this.uiUtils.testForTrim(this.party.driver.primaryPhone)
    this.driverPhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  driverAddress1ValidationOnInput() {
    this.party.driver.location.addressLine1 = this.uiUtils.checkIfEmpty(this.party.driver.location.addressLine1)
    this.driverAddress1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  driverAddress2ValidationOnInput() {
    this.party.driver.location.addressLine2 = this.uiUtils.checkIfEmpty(this.party.driver.location.addressLine2)
    this.driverAddress2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  driverCityValidationOnInput() {
    this.party.driver.location.city = this.uiUtils.checkIfEmpty(this.party.driver.location.city)
    this.driverCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false)
  }

  driverZipCodeValidationOnInput(){
    this.party.driver.location.postalCode = this.uiUtils.testForTrim(this.party.driver.location.postalCode)
    this.driverZipCodeValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

  driverInjuryInformationOnInput(){
    this.party.driver.injury = this.uiUtils.checkIfEmpty(this.party.driver.injury)
    this.injuryInformationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }
  getStatesList(): Array<string> {
    return Object.values(States);
  }

  getCountriesList(): Array<string> {
    return Object.values(Countries);
  }

  driverStateOnSelect() {
    this.party.driver.location.state = this.uiUtils.testForTrim(this.party.driver.location.state);
    this.party.driver.location.stateCode = this.uiUtils.getStateCode(this.party.driver.location.state);
  }

  driverCountryOnSelect() {
    this.party.driver.location.country = this.uiUtils.testForTrim(this.party.driver.location.country);
    this.party.driver.location.countryCode = this.uiUtils.getCountryCode(this.party.driver.location.country);
  }

}
</script>
