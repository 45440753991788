<template>
  <div>
    <v-dialog v-model="this.$store.state.showChangeLossTypeModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px">
      <v-card style="overflow-x: hidden" data-cy="change-loss-type-modal">
        <v-row justify="end">
          <v-col style="text-align: right" class="mr-4 py-0 col">
            <span class="exit-change-loss-type-button" @click="noButton" data-cy="change-loss-type-x">x</span>
          </v-col>
        </v-row>
        <v-card-title class="py-0">
          <span style="color: #003366" class="headline" data-cy="change-loss-type-header">Change Loss Type</span>
        </v-card-title>
        <v-card-text class="pb-0 pt-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0" style="color: #003366">
                <p  data-cy="change-loss-type-body">Changing the Loss Type will remove any information entered specifically related to <b>{{$store.state.oldLossType}}</b> once you navigate to the next page.
                  <br><br>Would you like to continue?</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row justify="end" class="pr-8">
          <v-card-actions class="pb-8">
            <v-btn depressed color="primary" data-cy="change-loss-type-yes" @click="yesButton">Yes</v-btn>
          </v-card-actions>
          <v-card-actions class="pb-8">
            <v-btn depressed text color="primary" outlined data-cy="change-loss-type-no" @click="noButton">No</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";
import {LossUtils} from "@/utils/loss-utils";

@Component
export default class ChangeLossTypeModal extends Vue {

  @Prop()
  submission: FnolSubmission;

  uiUtils: UiUtils = new UiUtils();
  lossUtils: LossUtils = new LossUtils();

  created() {
    console.log("change loss type modal created")
  }

  yesButton() {
    this.$store.state.showChangeLossTypeModal = false;
    this.$store.state.isDetailedLossCauseVisible = this.uiUtils.clearLossCause(this.submission);
    this.submission.lossInformation.lossTypeCode = this.lossUtils.getLossTypeCode(this.submission.lossInformation.lossType)
    this.$store.state.isOtherDescriptionVisible = this.uiUtils.checkAllOtherSelection(this.submission);
    this.lossUtils.getLossCauseSelections(this.submission)
    this.$store.state.oldLossType = this.submission.lossInformation.lossType;
  }

  noButton() {
    this.$store.state.showChangeLossTypeModal = false;
    this.submission.lossInformation.lossType = this.$store.state.oldLossType;
    this.submission.lossInformation.lossTypeCode = this.lossUtils.getLossTypeCode(this.submission.lossInformation.lossType)
  }
}
</script>

<style lang="scss" scoped>

.exit-change-loss-type-button {
  background-color: white;
  font-size: 35px;
  font-weight: bold;
  color: #979797;
  border-radius: 30px;
  width: 50px;
  height: 50px;
}

</style>
