<template>
  <div>
    <v-dialog v-model="this.$store.state.showCancelClaimModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px">
      <v-card style="overflow-x: hidden" data-cy="cancel-claim-modal">
        <v-row justify="end">
          <v-col style="text-align: right" class="mr-4 py-0 col">
            <span class="exit-cancel-claim-button" @click="noButton" data-cy="cancel-claim-x">x</span>
          </v-col>
        </v-row>
        <v-card-title class="py-0">
          <span style="color: #003366" class="headline" data-cy="cancel-claim-header">Cancel Submission</span>
        </v-card-title>
        <v-card-text class="pb-0 pt-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0">
                <span style="color: #003366" data-cy="cancel-claim-body">
                  All data entered will be lost. Are you sure you want to proceed with canceling this submission?
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row justify="end" class="pr-8">
          <v-card-actions class="pb-8">
            <v-btn depressed color="primary" data-cy="cancel-claim-yes" @click="yesButton">Yes</v-btn>
          </v-card-actions>
          <v-card-actions class="pb-8">
            <v-btn depressed text color="primary" outlined data-cy="cancel-claim-no" @click="noButton">No</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>

  </div>
</template>

<script lang="ts">

import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import {FnolSubmission} from "@/models/FnolSubmission";
import {Status} from "@/models/typelists/Status"
import {UiUtils} from "@/utils/ui-utils";

@Component
export default class CancelClaimModal extends Vue {

  @Prop()
  submission: FnolSubmission;

  uiUtils = new UiUtils()

  created() {
    console.log("cancel claim modal created")
  }

  yesButton() {
    this.$store.state.showCancelClaimModal = false;
    if(this.submission.status != Status.SUBMITTED) {
      this.submission.status = Status.CANCELLED;
      this.$store.state.hasBeenPassedGettingStarted = false;
    }
    this.$store.dispatch('cancelSubmission', this.submission).then(() => {
      this.$store.state.submission = new FnolSubmission()
      this.uiUtils.navigate('landing-page')
    });
  }

  noButton() {
    this.$store.state.showCancelClaimModal = false;
  }
}
</script>

<style lang="scss" scoped>

.exit-cancel-claim-button {
  background-color: white;
  font-size: 35px;
  font-weight: bold;
  color: #979797;
  border-radius: 30px;
  width: 50px;
  height: 50px;
}

</style>
