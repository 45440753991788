<template>
  <div data-cy="property-loss-information-review-vue">
  <!-- Property Loss Information -->
    <v-card color="white" elevation="0" outlined class="px-3 py-0">
<!--    <div class="divider" data-cy="property-loss-information-section"><span class="px-4">Property Information</span></div>-->
  <v-row data-cy="property-loss-information-section">
    <v-btn outlined tile class="elevation-0">
              <span class="edit-section-label" data-cy="edit-property-loss-information-section-cta" @click="editPropertyLossInformationOnClick">
              <v-icon>mdi-pencil</v-icon> Go To Property Loss Information</span>
    </v-btn>
  </v-row>
      <v-row>
        <v-col class="pb-0 mb-n2">
          <v-row class="sub-section-label"  data-cy="property-loss-information-section-owner-title">Property Owner</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row class="rs-labels" data-cy="property-loss-information-section-owner-label">PROPERTY OWNER NAME</v-row>
          <v-row class="rs-values" data-cy="property-loss-information-section-owner-value" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo">{{submission.lossInformation.propertyLossInformation.propertyOwner.fullName}}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="property-loss-information-section-email-label">EMAIL</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo" data-cy="property-loss-information-section-email-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail}}</v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="property-loss-information-section-phone-label">PHONE</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo" data-cy="property-loss-information-section-phone-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.primaryPhone}}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="property-loss-information-section-address-line-1-label">ADDRESS LINE 1</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo" data-cy="property-loss-information-section-address-line-1-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1}}</v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="property-loss-information-section-address-line-2-label">ADDRESS LINE 2</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo" data-cy="property-loss-information-section-address-line-2-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2}}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="property-loss-information-section-city-label">CITY</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo" data-cy="property-loss-information-section-city-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.location.city}}</v-row>
        </v-col>
        <v-col cols="8" class="pb-0">
          <v-row data-cy="property-loss-information-section-state-label">STATE</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo" data-cy="property-loss-information-section-state-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.location.state}}</v-row>
        </v-col>
      </v-row>

      <v-row class="rs-labels">
        <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : ''">
          <v-row data-cy="property-loss-information-section-zip-label">ZIP</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo"  data-cy="property-loss-information-section-zip-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode}}</v-row>
        </v-col>

        <v-col cols="8" class="pb-0">
          <v-row data-cy="property-loss-information-section-country-label">COUNTRY</v-row>
          <v-row class="rs-values" v-if="submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo"  data-cy="property-loss-information-section-country-value">{{submission.lossInformation.propertyLossInformation.propertyOwner.location.country}}</v-row>
          <v-row v-else class="rs-values" data-cy="property-loss-information-section-country-value">United States</v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto" class="pb-0 mb-n2">
          <v-row class="sub-section-label" data-cy="property-loss-information-section-damage-title">Damage</v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-row class="rs-labels" data-cy="property-loss-information-section-damage-label">PLEASE DESCRIBE THE DAMAGED PROPERTY/EQUIPMENT</v-row>
          <v-row class="rs-values" data-cy="property-loss-information-section-damage-value">{{submission.lossInformation.propertyLossInformation.damagedPropertyDesc}}</v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-row class="rs-labels" data-cy="property-loss-information-section-close-label">DID THE PROPERTY / BUSINESS HAVE TO CLOSE DUE TO THE LOSS?</v-row>
          <v-row class="rs-values" data-cy="property-loss-information-section-close-value">{{uiUtils.convertBooleanToYesNo(submission.lossInformation.propertyLossInformation.doesBusinessNeedToClose)}}</v-row>
        </v-col>
      </v-row>

      <v-row v-if="submission.lossInformation.propertyLossInformation.doesBusinessNeedToClose">
        <v-col class="pb-0 mt-0">
          <v-row class="rs-labels" data-cy="property-loss-information-section-reason-closure-label">REASON FOR CLOSURE</v-row>
          <v-row class="rs-values" data-cy="property-loss-information-section-reason-closure-value">{{submission.lossInformation.propertyLossInformation.reasonForClosure}}</v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-0">
          <v-row class="rs-labels" data-cy="property-loss-information-section-loss-label">ESTIMATE OF LOSS</v-row>
          <v-row class="rs-values" data-cy="property-loss-information-section-loss-value">{{submission.lossInformation.propertyLossInformation.estimateOfLoss}}</v-row>
        </v-col>
      </v-row>

    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {UiUtils} from "@/utils/ui-utils";


@Component
export default class PropertyLossInformationReview extends Vue {

  submission: FnolSubmission;
  uiUtils = new UiUtils()


  created() {
    console.log('Loss Information Review component created')
    this.submission = this.$store.state.submission;
  }

  editPropertyLossInformationOnClick() {
    this.uiUtils.navigate('property-loss-information');
  }

}
</script>

<style scoped lang="scss">

</style>

