<template>
  <div data-cy="home-vue"></div>
</template>

<script lang="ts">

  import {Component, Vue} from "vue-property-decorator";
  import {Branding} from "@/Brands/Branding";
  import {UiUtils} from "@/utils/ui-utils";

  @Component
  export default class Home extends Vue {

    uiUtils = new UiUtils();

    async created() {
      this.setBranding()
      console.log('home page created')
      this.rerouteToLandingPage()
    }

    rerouteToLandingPage() {
      this.uiUtils.navigate('landing-page');
    }

    setBranding() {
      if (!sessionStorage.getItem("brand")) {
        this.$store.state.siteBrand = new Branding().createBranding(document.baseURI);
        sessionStorage.setItem("brand", this.$store.state.siteBrand.brandName)
      } else {
        this.$store.state.siteBrand = new Branding().createBranding(sessionStorage.getItem("brand").toLowerCase());
      }
      if (this.$store.state.siteBrand.cssFile) {
        import("@/styles/" +  this.$store.state.siteBrand.cssFile);
      }
    }
  }
</script>
