<template>
  <v-form
      autocomplete="off"
      data-cy="fire-police-emt-form"
      v-model="$store.state.isFirePoliceEMTComponentValid" >
      <v-col :data-cy="'police-vue-'+ index" v-for="(police, index) in submission.additionalContacts.firePoliceEMTs" :key="index" style="padding: 0 0 12px 0">
            <v-card class="card-background" elevation="0" >
              <v-row>
                    <v-col md="4">
                      <div>
                        <span :data-cy="'police-label-'+index" class="section-label berninaSansCompressedFont">Fire/Police/EMT {{ index + 1 }}</span>
                      </div>
                    </v-col>
                    <v-col md="4" v-if="$vuetify.breakpoint.mdAndDown"  class="text-right">
                      <v-btn @click="removeFirePoliceEMT(index)" :data-cy="'remove-police-button-' + index"
                             outlined class="mobile-remove-button"><span
                          style="color: #00274e"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove</span>
                      </v-btn>
                    </v-col>
              </v-row>
              <v-row>

                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-type-label-' + index" class="label-text">TYPE<span class="req-asterisk">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-type-column-' + index">
                      <v-autocomplete
                              :data-cy="'police-type-select-' + index"
                              :id="'police-type-select-' + index"
                              v-model="police.type"
                              autocomplete="new-password"
                              :items="policeTypeList"
                              dense
                              outlined
                              data-lpignore="true"
                              background-color="white"
                              @blur="policeTypeValidation(police)"
                              :error-messages="policeTypeValidationRules"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-fullname-label-' + index" class="label-text">NAME<span class="req-asterisk">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-fullname-column-' + index">
                      <v-text-field :data-cy="'police-fullname-input-' + index"
                                    :id="'police-fullname-input-' + index"
                                    v-model="police.fullName"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="policeFullNameValidationRules"
                                    @input="policeFullNameOnInput(police)"
                                    @blur="policeFullNameOnInput(police)"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.lgAndUp" class="pl-8 pt-7 input-spacing" md="4" lg="3">
                  <v-btn @click="removeFirePoliceEMT(index)" :data-cy="'remove-police-button-' + index"
                         outlined class="outlined-button"><span
                      style="color: #00274e"><v-icon color="#00274e" style="padding-right: 8px">mdi-minus</v-icon>Remove Fire/Police/EMT</span>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-email-label-' + index"
                         class="label-text">EMAIL</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-email-column-' + index">
                      <v-text-field
                          :data-cy="'police-email-input-' + index"
                          v-model="police.primaryEmail"
                          dense
                          outlined
                          clearable
                          counter="50"
                          maxLength="50"
                          :rules="policeEmailValidationRules"
                          @input="policeEmailOnInput(police)"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-phone-label-' + index"
                         class="label-text">PHONE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-phone-column-' + index">
                      <v-text-field
                          :data-cy="'police-phone-input-' + index"
                          v-model="police.primaryPhone"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          :rules="policePhoneValidationRules"
                          @input="policePhoneOnInput(police)"
                          v-mask="'(###) ###-####'"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-phone-ext-label-' + index"
                         class="label-text">EXTENSION</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-phone-ext-column-' + index">
                      <v-text-field
                          :data-cy="'police-phone-ext-input-' + index"
                          v-model="police.primaryPhoneExt"
                          dense
                          outlined
                          clearable
                          maxLength="4"
                          v-mask="'####'"
                          @input="policePhoneExtOnInput(police)"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-address-1-label-' + index" class="label-text">ADDRESS LINE 1</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-address-1-column-' + index">
                      <v-text-field :data-cy="'police-address-1-input-' + index"
                                    v-model="police.location.addressLine1"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="policeAddressLine1ValidationRules"
                                    @input="policeAddressLine1OnInput(police)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-address-2-label-' + index" class="label-text">ADDRESS LINE 2</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-address-2-column-' + index">
                      <v-text-field :data-cy="'police-address-2-input-' + index"
                                    v-model="police.location.addressLine2"
                                    dense
                                    outlined
                                    clearable
                                    counter="60"
                                    maxLength="60"
                                    background-color="white"
                                    :rules="policeAddressLine2ValidationRules"
                                    @input="policeAddressLine2OnInput(police)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-city-label-' + index"
                         class="label-text">CITY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-city-column-' + index">
                      <v-text-field
                          :data-cy="'police-city-input-' + index"
                          v-model="police.location.city"
                          dense
                          outlined
                          clearable
                          counter="60"
                          maxLength="60"
                          :rules="policeCityValidationRules"
                          @input="policeCityOnInput(police)"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-state-label-' + index"
                         class="label-text">STATE / PROVINCE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-state-column-' + index">
                      <v-autocomplete
                          :data-cy="'police-state-select-' + index"
                          :id="'police-state-' + index"
                          v-model="police.location.state"
                          autocomplete="new-password"
                          :items="statesList"
                          dense
                          outlined
                          clearable
                          background-color="white"
                          @blur="uiUtils.triggerEnterKeyPress('police-state-' + index)"
                          @input="firePoliceEMTStateOnSelect(police)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-zip-code-label-' + index"
                         class="label-text">ZIP CODE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-zip-code-column-' + index">
                      <v-text-field
                          :data-cy="'police-zip-code-input-' + index"
                          v-model="police.location.postalCode"
                          dense
                          outlined
                          clearable
                          v-mask="['#####' ,'#####-####']"
                          :rules="policeZipValidationRules"
                          @input="policeZipOnInput(police)"
                          background-color="white"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="4" lg="3" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-country-label-' + index"
                         class="label-text"
                      >COUNTRY</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-country-column-' + index">
                      <v-autocomplete v-model="police.location.country"
                                      :data-cy="'police-country-select-' + index"
                                      :items="countriesList"
                                      clearable
                                      outlined
                                      dense
                                      @input="firePoliceEMTCountryOnSelect(police)"
                                      background-color="white">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" lg="3" class="input-spacing">
                  <p :data-cy="'police-report-number-label-' + index" class="label-text">REPORT #</p>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-report-number-column-' + index">
                      <v-text-field
                          :data-cy="'police-report-number-input-' + index"
                          v-model="police.reportNumber"
                          clearable
                          outlined
                          dense
                          :maxLength="60"
                          :counter="60"
                          background-color="white"
                          :rules="policeReportNumberValidationRules"
                          @input="reportNumberOnInput(police)">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="8" lg="6" class="input-spacing">
                  <v-row>
                    <v-col class="input-spacing">
                      <p :data-cy="'police-notes-label-' + index" class="label-text">NOTES</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="input-spacing" :data-cy="'police-notes-column-' + index">
                      <v-textarea :data-cy="'police-notes-input-' + index"
                                    v-model="police.notes"
                                    dense
                                    outlined
                                    clearable
                                    auto-grow
                                    counter="950"
                                    maxLength="950"
                                    background-color="white"
                                    :rules="policeNotesValidationRules"
                                    @input="policeNotesOnInput(police)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-divider :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:1.7em;' : ''"></v-divider>
              </v-row>
            </v-card>

            <!-- START:   Delete Fire Police EMT Dialog   -->
            <v-dialog v-model="$store.state.showRemoveFirePoliceEMTModal" overlay-opacity="0.7" overlay-color="#00172F" persistent max-width="500px" :data-cy="'remove-police-dialog-'+index">
              <v-card v-if="$store.state.showRemoveFirePoliceEMTModal" style="overflow-x: hidden; border-radius: 12px" :data-cy="'remove-police-card-'+index" color="#f2f4f6" >
                <v-row justify="end">
                  <v-col style="text-align: right" class="mr-4 py-0 col">
                    <span class="exit-remove-button" @click="noButton" :data-cy="'remove-police-exit-'+index">x</span>
                  </v-col>
                </v-row>
                <v-card-title class="py-0">
                  <span style="color: #003366; font-size: 24px; font-weight: 500" class="headline" :data-cy="'remove-police-header-'+index">Remove Fire/Police/EMT</span>
                </v-card-title>
                <v-card-text class="pb-0 pt-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="pb-0 pt-0">
                        <p class="modal-text" :data-cy="'remove-police-text-'+index">
                          Are you sure you want to remove Fire/Police/EMT {{ $store.state.currentFirePoliceEMTFullName }}?
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-row justify="end" class="pr-8">
                  <v-card-actions class="pb-8">
                    <v-btn depressed color="primary" :data-cy="'remove-police-yes-'+index"
                           @click="yesButton($store.state.currentFirePoliceEMTIndex)">Yes</v-btn>
                  </v-card-actions>
                  <v-card-actions class="pb-8">
                    <v-btn depressed text color="primary" outlined :data-cy="'remove-police-no-'+index" @click="noButton">No</v-btn>
                  </v-card-actions>
                </v-row>
              </v-card>
            </v-dialog>
            <!-- END:   Delete Fire Police EMT Dialog   -->

  </v-col>
  </v-form>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {FnolSubmission} from "@/models/FnolSubmission";
import {States} from "@/models/typelists/States";
import {UiUtils} from "@/utils/ui-utils";
import {FirePoliceEMT} from "@/models/FirePoliceEMT";
import {Countries} from "@/models/typelists/Countries";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import {PoliceType} from "@/models/typelists/PoliceType";

@Component
export default class FirePoliceEmtComponent extends Vue {

  submission: FnolSubmission;

  uiUtils = new UiUtils();
  statesList: Array<string> = Object.values(States);
  countriesList: Array<string> = Object.values(Countries);
  policeTypeList: Array<string> = Object.values(PoliceType);

  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();
  policeFullNameValidationRules: Array<string> = [];
  policeEmailValidationRules: Array<string> = [];
  policePhoneValidationRules: Array<string> = [];
  policeReportNumberValidationRules: Array<string> = [];
  policeAddressLine1ValidationRules: Array<string> = [];
  policeAddressLine2ValidationRules: Array<string> = [];
  policeCityValidationRules: Array<string> = [];
  policeZipValidationRules: Array<string> = [];
  policeNotesValidationRules: Array<string> = [];
  policeTypeValidationRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
  }

  removeFirePoliceEMT(firePoliceIndex: number) {
    this.$store.state.currentFirePoliceEMTIndex = firePoliceIndex
    this.$store.state.currentFirePoliceEMTFullName = ""

    if(this.submission.additionalContacts.firePoliceEMTs[firePoliceIndex].fullName){
      this.$store.state.currentFirePoliceEMTFullName = this.submission.additionalContacts.firePoliceEMTs[firePoliceIndex].fullName
    }
    this.$store.state.showRemoveFirePoliceEMTModal = true;
  }

  yesButton(fpIndex: number){
    this.submission.additionalContacts.firePoliceEMTs.splice(fpIndex, 1);
    this.$store.state.showRemoveFirePoliceEMTModal = false;
  }

  noButton(){
    this.$store.state.showRemoveFirePoliceEMTModal = false;
  }

  policeTypeValidation(police: FirePoliceEMT) {
    this.policeTypeValidationRules = [];

    if (police.type) {
      police.typeCode = this.uiUtils.getPoliceTypeCode(police.type);
    }
  }

  reportNumberOnInput(police: FirePoliceEMT) {
    police.reportNumber = this.uiUtils.checkIfEmpty(police.reportNumber);
    this.policeReportNumberValidationRules = this.aplFrontEndValidation.runTextInputValidationRules_isRequired(false)
  }

  firePoliceEMTStateOnSelect(police: FirePoliceEMT) {
    police.location.state = this.uiUtils.testForTrim(police.location.state);
    police.location.stateCode = this.uiUtils.getStateCode(police.location.state);
  }

  firePoliceEMTCountryOnSelect(police: FirePoliceEMT) {
    police.location.country = this.uiUtils.testForTrim(police.location.country);
    police.location.countryCode = this.uiUtils.getCountryCode(police.location.country);
  }

  policeFullNameOnInput(police: FirePoliceEMT){
    police.fullName = this.uiUtils.checkIfEmpty(police.fullName);
    this.policeFullNameValidationRules = this.aplFrontEndValidation.runFullNameValidationRules_isRequired(false)
  }

  policeEmailOnInput(police: FirePoliceEMT){
    police.primaryEmail = this.uiUtils.checkIfEmpty(police.primaryEmail);
    this.policeEmailValidationRules = this.aplFrontEndValidation.runEmailValidationRules_isRequired(false)
  }

  policePhoneOnInput(police: FirePoliceEMT){
    police.primaryPhone = this.uiUtils.testForTrim(police.primaryPhone);
    this.policePhoneValidationRules = this.aplFrontEndValidation.runPhoneValidationRules_isRequired(false)
  }

  policePhoneExtOnInput(police: FirePoliceEMT){
    police.primaryPhoneExt = this.uiUtils.testForTrim(police.primaryPhoneExt);
  }

  policeAddressLine1OnInput(police: FirePoliceEMT){
    police.location.addressLine1 = this.uiUtils.checkIfEmpty(police.location.addressLine1);
    this.policeAddressLine1ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  policeAddressLine2OnInput(police: FirePoliceEMT){
    police.location.addressLine2 = this.uiUtils.checkIfEmpty(police.location.addressLine2);
    this.policeAddressLine2ValidationRules = this.aplFrontEndValidation.runAddressValidationRules()
  }

  policeNotesOnInput(police: FirePoliceEMT){
    police.notes = this.uiUtils.checkIfEmpty(police.notes);
    this.policeNotesValidationRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false)
  }

  policeCityOnInput(police: FirePoliceEMT){
    police.location.city = this.uiUtils.checkIfEmpty(police.location.city);
    this.policeCityValidationRules = this.aplFrontEndValidation.runCityValidationRules_isRequired(false);
  }

  policeZipOnInput(police: FirePoliceEMT){
    police.location.postalCode = this.uiUtils.testForTrim(police.location.postalCode);
    this.policeZipValidationRules = this.aplFrontEndValidation.runZipCodeValidationRules()
  }

}

</script>

<style scoped lang="scss">

</style>
