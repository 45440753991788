<template>
  <v-col data-cy="property-loss-information-vue">
    <v-row>
      <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <SideStepperComponent @navigate="(route) => navigateTo(route)"> </SideStepperComponent>
      </v-col>
      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <div>
              <span data-cy="property-loss-information-label" class="page-label berninaSansCompressedFont">Property Information</span>
            </div>
          </v-col>
          <v-col data-cy="report-number-column" class="report-num-col">
            <span data-cy="report-number-label" class="report-num-label">Report#: {{submission.reportNumber}}</span>
          </v-col>
        </v-row>


        <v-form autocomplete="off" v-model="$store.state.propertyLossInformationPageValid">

          <PropertyOwnerComponent data-cy="property-owner-component"/>

        <v-row>
          <v-col >
            <v-card class="card-background" elevation="0" >
              <v-row>
                <v-col cols="8">
                  <div>
                    <span class="section-label berninaSansCompressedFont pl-8" data-cy="damage-card-title">Damage</span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="pl-8">
                  <v-col md="8" lg="6" class="input-spacing">
                    <v-row>
                      <v-col class="input-spacing">
                      <p data-cy="property-damage-label" class="label-text">PLEASE DESCRIBE THE DAMAGED PROPERTY / EQUIPMENT</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" data-cy="property-damage-column" class="input-spacing">
                      <v-textarea data-cy="property-damage-input"
                                    dense
                                    outlined
                                    clearable
                                    auto-grow
                                    background-color="white"
                                    counter="950"
                                    maxLength="950"
                                    v-model="submission.lossInformation.propertyLossInformation.damagedPropertyDesc"
                                    :rules="damagePropertyDescriptionRules"
                                    @input="validateDamagePropertyDescription()"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>


              <v-row class="pl-8">
                    <v-col class="input-spacing" style="min-width: 100%">
                      <p data-cy="property-business-label" class="label-text">DID THE PROPERTY / BUSINESS HAVE TO CLOSE DUE TO THE LOSS?</p>
                    </v-col>
                  </v-row>
                  <v-row class="pl-8 mb-6">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? '11' : '8'" lg="6" class="input-spacing">
                    <v-radio-group :row="!$vuetify.breakpoint.smAndDown"
                                   data-cy="property-business-radio-group"
                                   class="radio-container"
                                   v-model="submission.lossInformation.propertyLossInformation.doesBusinessNeedToClose">
                      <v-radio
                          label="Yes"
                          :value=true
                          data-cy="property-business-radio-yes">
                      </v-radio>

                      <v-radio
                          label="No"
                          :value=false
                          data-cy="property-business-radio-no"
                          @change="onNoPropertyBusinessClose"
                      ></v-radio>

                    </v-radio-group>
                    </v-col>
                  </v-row>

              <v-row class="pl-8" v-if="submission.lossInformation.propertyLossInformation.doesBusinessNeedToClose">
                <v-col md="8" lg="6">
                  <v-row>
                    <v-col class="input-spacing">
                    <p data-cy="property-reason-label" class="label-text">REASON FOR CLOSURE</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" data-cy="property-reason-column" class="input-spacing">
                    <v-textarea data-cy="property-reason-input"
                                  dense
                                  outlined
                                  clearable
                                  auto-grow
                                  background-color="white"
                                  counter="950"
                                  maxLength="950"
                                  v-model="submission.lossInformation.propertyLossInformation.reasonForClosure"
                                  :rules="propertyReasonRules"
                                  @input="validatePropertyReason()"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="pl-8">
                <v-col md="8" lg="6" class="input-spacing" data-cy="property-estimate-column">

                  <v-row>
                    <v-col class="input-spacing">
                      <p data-cy="property-estimate-label" class="label-text">ESTIMATE OF LOSS<span v-if="businessUnitPIM()" class="req-asterisk">*</span></p>
                    </v-col>
                  </v-row>

                  <v-row class="input-spacing">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? '8' : '12'" class="input-spacing">
                    <v-autocomplete data-cy="property-estimate-select"
                                autocomplete="new-password"
                                dense
                                outlined
                                background-color="white"
                                :items="estimatesOfLostList"
                                v-model="submission.lossInformation.propertyLossInformation.estimateOfLoss"
                                :error-messages="estimateOfLossRules"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
          <v-row>
            <v-col>
              <v-card class="card-background pl-7 pr-7" elevation="0">
                <v-row :justify="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'">
                  <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined class="previous-button"
                         v-if="$vuetify.breakpoint.mdAndUp">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>
                  <v-btn @click="navigateTo('additional-contacts')" color="#00274e" data-cy="next-button"
                         class="next-button"><span
                      style="color: white; padding-right: 4px">Next</span>
                    <v-icon color="white" style="padding-left: 8px">mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-row>

                <v-row class="" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn @click="navigateTo('loss-information')" data-cy="previous-button" outlined class="previous-button" v-if="$vuetify.breakpoint.smAndDown">
                    <span><v-icon>mdi-arrow-left-thick</v-icon>Previous</span>
                  </v-btn>
                </v-row>

                <v-row class="pb-5 mt-5" v-if="$vuetify.breakpoint.smAndDown" :justify="$vuetify.breakpoint.smAndDown ? 'center' : ''">
                  <v-btn data-cy="cancel-button" @click="CancelClaimModalOnClick" outlined class="outlined-button">
                    <span><v-icon>mdi-close</v-icon>Cancel Claim</span>
                  </v-btn>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>

      </v-col>
    </v-row>



    <TimeoutModal v-if="$store.state.showTimeoutModalMessage"/>

    <CancelClaimModal :submission="submission"
                      v-if="$store.state.showCancelClaimModal && $vuetify.breakpoint.smAndDown"></CancelClaimModal>

    <BrowserBackButtonModal v-if="$store.state.showBackButtonModal"></BrowserBackButtonModal>

  </v-col>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import SideStepperComponent from "@/views/components/SideStepperComponent.vue";
import TimeoutModal from "@/views/modals/TimeoutModal.vue";
import PropertyOwnerComponent from "@/views/components/PropertyOwnerComponent.vue";
import {FnolSubmission} from "@/models/FnolSubmission";
import {States} from "@/models/typelists/States";
import {Countries} from "@/models/typelists/Countries";
import {UiUtils} from "@/utils/ui-utils";
import {ValidationUtils} from "@/utils/validation-utils";
import {FrontEndValidation} from "@/views/components/PageValidations/FrontEndValidation";
import CancelClaimModal from "@/views/modals/CancelClaimModal.vue";
import BrowserBackButtonModal from "@/views/modals/BrowserBackButtonModal.vue";

@Component({
  components: {BrowserBackButtonModal, SideStepperComponent, PropertyOwnerComponent, TimeoutModal, CancelClaimModal}
})

export default class PropertyLossInformation extends Vue {

  submission: FnolSubmission;
  statesList: Array<string> =Object.values(States);
  countriesList: Array<string> =Object.values(Countries);
  estimatesOfLostList = ["Less than $10,000", "$10,001-$50,000", "$50,001-$100,000","$100,001-$500,000", "More than $500,000","Unknown"]
  uiUtils= new UiUtils();
  validationUtils: ValidationUtils = new ValidationUtils();
  aplFrontEndValidation: FrontEndValidation = new FrontEndValidation();

  damagePropertyDescriptionRules: Array<string> = [];
  propertyReasonRules: Array<string> = [];
  estimateOfLossRules: Array<string> = [];

  created() {
    this.submission = this.$store.state.submission;
    console.log('Property Loss Information page created')

    this.uiUtils.instantiateLossObject(this.submission)
  }

  // How often the user is checked for inactivity (1 second = 1000)
  timeout = setInterval(this.applicationTimeoutTimer, 1000);

  get isIdle() {
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
    return this.$store.state.idleVue.isIdle;
  }

  businessUnitPIM(): boolean{
   return this.submission.policyDetails.policyBusinessUnit === 'PIM'
  }

  applicationTimeoutTimer () {
    // CONTINUES TO RUN THROUGHOUT THE APPLICATION
    if (this.$store.state.idleVue.isIdle) {
      this.$store.state.showTimeoutModalMessage = true;
    }
  }

  validateDamagePropertyDescription() {
    this.submission.lossInformation.propertyLossInformation.damagedPropertyDesc = this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.damagedPropertyDesc)
    this.damagePropertyDescriptionRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }

  validatePropertyReason() {
    this.submission.lossInformation.propertyLossInformation.reasonForClosure = this.uiUtils.checkIfEmpty(this.submission.lossInformation.propertyLossInformation.reasonForClosure)
    this.propertyReasonRules = this.aplFrontEndValidation.runTextAreaValidationRules_isRequired(false);
  }

  trimPropertyLossInfoObject() {
    this.submission.lossInformation.propertyLossInformation.damagedPropertyDesc = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.damagedPropertyDesc)
    if(this.submission.lossInformation.propertyLossInformation.doesBusinessNeedToClose) {
      this.submission.lossInformation.propertyLossInformation.reasonForClosure = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.reasonForClosure)
    }

    if(this.submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo){
      this.submission.lossInformation.propertyLossInformation.propertyOwner.fullName = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.fullName)
      this.submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.primaryEmail)
      this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1 = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine1)
      this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2 = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.addressLine2)
      this.submission.lossInformation.propertyLossInformation.propertyOwner.location.city = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.city)
      this.submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode = this.uiUtils.testForTrim(this.submission.lossInformation.propertyLossInformation.propertyOwner.location.postalCode)
    }
  }

  shouldShowPropertyInfoAlertIcon(): boolean {
    if (this.submission.lossInformation?.lossType == "Property") {
      if (!this.validationUtils.validateRequiredFieldsOnProperty(this.submission)) {
            return true;
        }
    }
    return false;
  }

  async navigateTo(route: string) {
    this.$store.state.showAlertIconPropertyInfo = this.shouldShowPropertyInfoAlertIcon();
    this.trimPropertyLossInfoObject();
    await this.triggerNonRequiredValidations()
      if (!this.validationUtils.validateRequiredFieldsOnProperty(this.submission)) {
          this.$store.state.propertyLossInformationPageValid = true
      }
    if (this.$store.state.isPropertyOwnerComponentValid) {
      this.$store.state.showValidationSnackbar = false
      this.uiUtils.navigateAndSaveSubmission(this.submission, route)
    }else{
      this.$store.state.showValidationSnackbar = true
    }
  }

  async triggerNonRequiredValidations() {
    if (this.submission.lossInformation.propertyLossInformation.doesHavePropertyOwnerInfo === false) {
      this.$store.state.isPropertyOwnerComponentValid = true
    } else {
      if (this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsPrimaryContact === false &&
          this.submission.lossInformation.propertyLossInformation.propertyOwnerSameAsInsured === false) {
          await this.validationUtils.triggerBlurs(['property-owner-name-field']); //  Need @blur on field!
      }
    }
  }

  onNoPropertyBusinessClose() {
    this.submission.lossInformation.propertyLossInformation.reasonForClosure = null;
  }

  CancelClaimModalOnClick() {
    this.$store.state.showCancelClaimModal = true;
  }

}
</script>
<style>

</style>
