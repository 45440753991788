<template>
    <div data-cy="additional-contacts-review-vue">
        <!-- Additional Contacts -->
<!--        <div class="divider" data-cy="additional-contacts-section"><span class="px-4">Additional Contacts</span></div>-->
        <v-row data-cy="additional-contacts-section">
            <v-btn tile outlined class="elevation-0 ml-3 mb-2">
              <span class="edit-section-label" data-cy="edit-additional-contacts-cta"
                    @click="editAdditionalContactsOnClick">
              <v-icon>mdi-pencil</v-icon> Go To Additional Contacts</span>
            </v-btn>
        </v-row>

        <div v-if="submission.additionalContacts.witnesses.length > 0">
            <v-row cols="auto" class="pb-0 mb-n2">
                <v-col cols="auto" class="pb-0" data-cy="rs-witness-information-label">
                    <div class="sub-section-label">Witness Information</div>
                </v-col>
            </v-row>

            <div v-for="(witness, index) in submission.additionalContacts.witnesses" :key="index" class="pl-3">
                <v-row cols="auto" class="pb-0 ">
                    <v-col cols="auto" class="pb-0 pl-0">
                        <div class="sub-section-label" style="color: #333"
                             :data-cy="'rs-witness-number-label-'+index">Witness {{ index + 1 }}
                        </div>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
                      <v-row :data-cy="'rs-witness-first-name-label-'+index">FIRST NAME</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-first-name-value-'+index">{{witness.firstName}}</v-row>
                    </v-col>
                    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" >
                      <v-row :data-cy="'rs-witness-last-name-label-'+index">LAST NAME</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-last-name-value-'+index">{{witness.lastName}}</v-row>
                    </v-col>
                    <v-col class="pb-0" >
                      <v-row :data-cy="'rs-witness-suffix-label-'+index">SUFFIX</v-row>
                      <v-row class="rs-values" :data-cy="'rs-witness-suffix-value-'+index">{{witness.suffix}}</v-row>
                    </v-col>
                </v-row>


                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-email-label-'+index">EMAIL</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-email-value-'+index">{{witness.primaryEmail}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-phone-label-'+index">PHONE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-witness-phone-value-'+index">{{witness.primaryPhone}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-address-line-1-label-'+index">ADDRESS LINE 1</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-address-line-1-value-'+index">{{witness.location.addressLine1}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <v-row :data-cy="'rs-witness-address-line-2-label-'+index">ADDRESS LINE 2</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-address-line-2-value-'+index">{{witness.location.addressLine2}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-city-label-'+index">CITY</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-city-value-'+index">{{witness.location.city}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-state-label-'+index">STATE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-witness-state-value-'+index">{{witness.location.state}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-postalcode-label-'+index">ZIP</v-row>
                      <v-row class="rs-values" :data-cy="'rs-witness-postalcode-value-'+index">{{witness.location.postalCode}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-witness-country-label-'+index">COUNTRY</v-row>
                      <v-row class="rs-values" :data-cy="'rs-witness-country-value-'+index">{{witness.location.country}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="pb-0" >
                      <v-row :data-cy="'rs-witness-notes-label-'+index">NOTES</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-witness-notes-value-'+index">{{witness.notes}}</v-row>

                    </v-col>
                </v-row>


            </div> <!-- END ARRAY -->
        </div>


        <div v-if="submission.additionalContacts.medicalProviders.length > 0">
            <v-row cols="auto" class="pb-0 mb-n2">
                <v-col cols="auto" class="pb-0" data-cy="rs-provider-information-label">
                    <div class="sub-section-label">Medical Providers Information</div>
                </v-col>
            </v-row>

            <div v-for="(provider, index) in submission.additionalContacts.medicalProviders" :key="index" class="pl-3">
                <v-row cols="auto" class="pb-0" :data-cy="'rs-provider-number-header-'+index">
                    <v-col cols="auto" class="pb-0 pl-0" :data-cy="'rs-provider-number-column-'+index">
                        <div class="sub-section-label" style="color: #333"
                             :data-cy="'rs-provider-number-label-'+index">Medical Provider {{ index + 1 }}
                        </div>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" >
                      <v-row :data-cy="'rs-provider-fullname-label-'+index">NAME</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-provider-fullname-value-'+index">{{provider.fullName}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-email-label-'+index">EMAIL</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-provider-email-value-'+index">{{provider.primaryEmail}}</v-row>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-phone-label-'+index">PHONE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-provider-phone-value-'+index">{{provider.primaryPhone}}</v-row>
                    </v-col>
                    <v-col class="pb-0" >
                      <v-row :data-cy="'rs-provider-phone-ext-label-'+index">EXT</v-row>
                      <v-row class="rs-values" :data-cy="'rs-provider-phone-ext-value-'+index">{{provider.primaryPhoneExt}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-address-line-1-label-'+index">ADDRESS LINE 1</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-provider-address-line-1-value-'+index">{{provider.location.addressLine1}}</v-row>

                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-address-line-2-label-'+index">ADDRESS LINE 2</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-provider-address-line-2-value-'+index">{{provider.location.addressLine2}}</v-row>

                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-city-label-'+index">CITY</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-provider-city-value-'+index">{{provider.location.city}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-state-label-'+index">STATE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-provider-state-value-'+index">{{provider.location.state}}</v-row>
                    </v-col>
                </v-row>


                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-postalcode-label-'+index">ZIP</v-row>
                      <v-row class="rs-values" :data-cy="'rs-provider-postalcode-value-'+index">{{provider.location.postalCode}}</v-row>

                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-provider-country-label-'+index">COUNTRY</v-row>
                      <v-row class="rs-values" :data-cy="'rs-provider-country-value-'+index">{{provider.location.country}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col class="pb-0" >
                      <v-row :data-cy="'rs-provider-treatment-date-label-'+index">TREATMENT DATE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-provider-treatment-date-value-'+index">{{dateUtils.formatDateForDisplay(provider.treatmentDate)}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="pb-0">
                    <v-row :data-cy="'rs-provider-notes-label-'+index">NOTES</v-row>
                    <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-provider-notes-value-'+index">{{provider.notes}}</v-row>
                  </v-col>
                </v-row>


            </div> <!-- END ARRAY -->
        </div>


        <div v-if="submission.additionalContacts.firePoliceEMTs.length > 0">
            <v-row cols="auto" class="pb-0 mb-n2">
                <v-col cols="auto" class="pb-0" data-cy="rs-police-information-label">
                    <div class="sub-section-label">Fire/Police/EMT Information</div>
                </v-col>
            </v-row>

            <!-- Police 1,2,3 etc... goes here -->

            <div v-for="(police, index) in submission.additionalContacts.firePoliceEMTs" :key="index" class="pl-3">
                <v-row cols="auto" class="pb-0" :data-cy="'rs-police-number-header-'+index">
                    <v-col cols="auto" class="pb-0 pl-0" :data-cy="'rs-police-number-column-'+index">
                        <div class="sub-section-label" style="color: #333"
                             :data-cy="'rs-police-number-label-'+index">Fire/Police/EMT {{ index + 1 }}
                        </div>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                  <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" >
                    <v-row :data-cy="'rs-police-type-label-'+index">TYPE</v-row>
                    <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-type-value-'+index">{{police.type}}</v-row>
                  </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col class="pb-0" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" >
                      <v-row :data-cy="'rs-police-fullname-label-'+index">NAME</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-fullname-value-'+index">{{police.fullName}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col  class="pb-0" :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" >
                      <v-row :data-cy="'rs-police-email-label-'+index">EMAIL</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-email-value-'+index"> {{police.primaryEmail}}</v-row>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-police-phone-label-'+index">PHONE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-police-phone-value-'+index">{{police.primaryPhone}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-police-phone-ext-label-'+index">EXT</v-row>
                      <v-row  class="rs-values" :data-cy="'rs-police-phone-ext-value-'+index">{{police.primaryPhoneExt}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-police-report-number-label-'+index">REPORT #<div id=""></div></v-row>
                      <v-row class="rs-values" :data-cy="'rs-police-report-number-value-'+index">{{police.reportNumber}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-police-address-line-1-label-'+index">ADDRESS LINE 1</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-address-line-1-value-'+index">{{police.location.addressLine1}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-police-address-line-2-label-'+index">ADDRESS LINE 2</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-address-line-2-value-'+index">{{police.location.addressLine2}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"  class="pb-0" >
                      <v-row :data-cy="'rs-police-city-label-'+index">CITY</v-row>
                      <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-city-value-'+index">{{police.location.city}}</v-row>
                    </v-col>
                    <v-col cols="4" class="pb-0" >
                      <v-row :data-cy="'rs-police-state-label-'+index">STATE</v-row>
                      <v-row class="rs-values" :data-cy="'rs-police-state-value-'+index">{{police.location.state}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="pb-0" >
                      <v-row :data-cy="'rs-police-postalcode-label-'+index">ZIP</v-row>
                      <v-row class="rs-values" :data-cy="'rs-police-postalcode-value-'+index">{{police.location.postalCode}}</v-row>

                    </v-col>
                    <v-col cols="4" class="pb-0 mb-3">
                      <v-row :data-cy="'rs-police-country-label-'+index">COUNTRY</v-row>
                      <v-row class="rs-values" :data-cy="'rs-police-country-value-'+index">{{police.location.country}}</v-row>
                    </v-col>
                </v-row>

                <v-row class="rs-labels">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8" class="pb-0" >
                        <v-row :data-cy="'rs-police-notes-label-'+index">NOTES</v-row>
                        <v-row class="rs-values rs-wrapping-text" :data-cy="'rs-police-notes-value-'+index">{{police.notes}}</v-row>
                    </v-col>
                </v-row>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import {FnolSubmission} from "@/models/FnolSubmission";
    import { DateUtils } from '@/utils/date-utils';
    import {UiUtils} from "@/utils/ui-utils";

    @Component
    export default class AdditionalContactsReview extends Vue {

        submission: FnolSubmission;
        dateUtils = new DateUtils();
        uiUtils = new UiUtils()

        created() {
            console.log('Additional Contacts Review component created')
            this.submission = this.$store.state.submission;
        }

        editAdditionalContactsOnClick() {
          this.uiUtils.navigate('additional-contacts');
        }

    }
</script>

<style scoped lang="scss">

</style>

